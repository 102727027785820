import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import DummyAuthProvider from "./auth/DummyAuthProvider";
import DevSettingsDbProvider from "./db/DevSettingsDbProvider";
import IndexedDbProvider from "./db/IndexedDbProvider";
import Router from "./routes/Router";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { translationEn } from '../pwa/locales/en/translation.json';
import { translationEs } from '../pwa/locales/es/translation.json';
import ApiClientProvider from "./api/ApiClientProvider";
import LoginWrapper from "./auth/LoginWrapper";
import CommonDialogsCtxProvider from "./commonDialogs/CommonDialogsCtxProvider";
import DealerDataCtxProvider from "./userData/DealerDataCtxProvider";

import * as countries from "i18n-iso-countries";
import * as countries_lang_en from "i18n-iso-countries/langs/en.json";
import { actionTypeId } from "./actions/NewSystemAction";
import { OptimizationRunnerProvider } from "./workers/OptimizationWorker";

countries.registerLocale(countries_lang_en);

const resources = {
    en: {
        translation: translationEn
    },
    es: {
        translation: translationEs
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: "en",
        //lng: "es", // if you're using a language detector, do not define the lng option
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape,
            format: function (value, format, lng) {
                if (format === 'uppercase') return value.toUpperCase();
                if (format === 'lowercase') return value.toLowerCase();
                if (format === 'capitalize-each') {
                    const words = value.split(" ");
                    const capitalizedWords = words.map(word => {
                        const nocaps = ["de", "del", "el", "and", "y", "of"]
                        if (nocaps.indexOf(word) !== -1) return word;
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    });
                    return capitalizedWords.join(" ");
                }
                if (format === 'capitalize') {
                    let newVal = value.toLowerCase();
                    return `${newVal.substr(0, 1).toUpperCase()}${newVal.substr(1)}`;
                }
                return value;
              },
        },
        resources
    });

// NOTE: The new system action has been superceeded by V2.
// The V1 action has been left so to not crash existing DBs but it must be imported somewhere in the source:
const dummy = typeof actionTypeId
// END NOTE

ReactDOM.createRoot(document.getElementById("app")!).render(
    <React.StrictMode>
        <DummyAuthProvider>
            <LoginWrapper>
            <ApiClientProvider>
                <DealerDataCtxProvider>
                    <CommonDialogsCtxProvider>
                    <IndexedDbProvider>
                        <DevSettingsDbProvider>
                            <OptimizationRunnerProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Router />
                                </LocalizationProvider>
                            </OptimizationRunnerProvider>
                        </DevSettingsDbProvider>
                    </IndexedDbProvider>
                    </CommonDialogsCtxProvider>
                </DealerDataCtxProvider>
            </ApiClientProvider>
            </LoginWrapper>
        </DummyAuthProvider>
    </React.StrictMode>
);