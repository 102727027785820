import { Alert, Button, Checkbox, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import IVRIZones from "rdptypes/roe/IVRIZones";
import { FC, useContext, useState } from "react";
import { createNewUpdateSystemPropertyAction } from "../../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../../auth/AuthCtx";
import IDbProject from "../../../../db/IDbProject";
import { pageStyles, secondaryColor } from "../../styles";

import { ISprinklerPropertiesVRIZonesValveBox, ISprinklerPropertiesVRIZonesZone, VRIValveType } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import VRI_Zones from "roedata/roe_migration/VRIClass.VRI_Zones";
import NumberOfZonesDialog from "./NumberOfZonesDialog";
import { add1Sprinkler, addZone, deleteZone, equalAcres, equalSpace, exportZones, getVRIZoneValveTypeStr, subtract1Sprinkler, updateZoneControlledState } from "./VRIHelpers";
import ZoneRenderer from "./ZoneRenderer";

interface Props {
    cmp: IVRIZones,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
};

let m_Zones: VRI_Zones;

export const zoneColumns: GridColDef[] = [
    {
        field: "ZoneNumber",
        flex: 1, 
        headerName: "Zone Nr",
        editable: false,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        type: "string",
        valueGetter: (params) => {
            return (params.row as ISprinklerPropertiesVRIZonesZone).ZoneNumber + 1;
        },
        renderCell: (v) => <span>{v.value}</span>
    },
    {
        field: "Controlled",
        flex: 1, 
        headerName: "Controlled",
        editable: false,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        type: "string",
        valueGetter: (params) => {
            let row = params.row as ISprinklerPropertiesVRIZonesZone;
            return <Checkbox
                style={{}}
                checked={row.Controlled}
                onChange={(ev) => {
                    updateZoneControlledState(m_Zones, row.ZoneNumber + 1, ev.target.checked);
                    m_Zones.SetSytemZones(setVRIZones, setVRIValveBoxes);
                }}
            />
        },
        renderCell: (v) => <span>{v.value}</span>
    },
    {
        field: "StartingLocation",
        flex: 1, 
        headerName: "Start",
        editable: false,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        type: "string",
        valueGetter: (params) => {
            return (params.row as ISprinklerPropertiesVRIZonesZone).StartingLocation.toFixed(1);
        },
        renderCell: (v) => <span>{v.value}</span>
    },
    {
        field: "EndingLocation",
        flex: 1, 
        headerName: "End",
        editable: false,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        type: "string",
        valueGetter: (params) => {
            return (params.row as ISprinklerPropertiesVRIZonesZone).EndingLocation.toFixed(1);
        },
        renderCell: (v) => <span>{v.value}</span>
    },
    {
        field: "Length",
        flex: 1, 
        headerName: "Width",
        editable: false,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        type: "string",
        valueGetter: (params) => {
            return (params.row as ISprinklerPropertiesVRIZonesZone).Length.toFixed(1);
        },
        renderCell: (v) => <span>{v.value}</span>
    },
    {
        field: "Acres",
        flex: 1, 
        headerName: "Acres",
        editable: false,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        type: "string",
        valueGetter: (params) => {
            return (params.row as ISprinklerPropertiesVRIZonesZone).Acres.toFixed(1);
        },
        renderCell: (v) => <span>{v.value}</span>
    },
    {
        field: "DeviceCount",
        flex: 1, 
        headerName: "Devices",
        editable: false,
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        type: "string",
        valueGetter: (params) => {
            return (params.row as ISprinklerPropertiesVRIZonesZone).DeviceCount;
        },
        renderCell: (v) => <span>{v.value}</span>
    }
];

let setVRIZones: (newZones: ISprinklerPropertiesVRIZonesZone[]) => any;
let setVRIValveBoxes: (newBoxes: ISprinklerPropertiesVRIZonesValveBox[]) => any;

enum Status {
    nothing,
    equalSpace,
    equalAcres
}

const VRIRenderer: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);

    const project = props.dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];

    const valveType = system.SprinklerProperties?.VRIZones ? system.SprinklerProperties.VRIZones.ValveType : VRIValveType.Poly;

    m_Zones = new VRI_Zones(system);

    setVRIZones = (newZones: ISprinklerPropertiesVRIZonesZone[]) => {
        props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
            props.layoutId,
            props.systemId,
            "SprinklerProperties.VRIZones.Zone",
            newZones,
            authState));
    };
    
    setVRIValveBoxes = (newBoxes: ISprinklerPropertiesVRIZonesValveBox[]) => {
        props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
            props.layoutId,
            props.systemId,
            "SprinklerProperties.VRIZones.ValveBox",
            newBoxes,
            authState));
    };

    const setVRIValveType = (valveType: VRIValveType) => {
        props.dbPrj.pushAction(createNewUpdateSystemPropertyAction(
            props.layoutId,
            props.systemId,
            "SprinklerProperties.VRIZones.ValveType",
            valveType,
            authState));
    };

    const [zoneTableSelectedRowId, setZoneTableSelectedRowId] = useState<number>(-1);
    const [status, setStatus] = useState(Status.nothing);

    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    formInputLabelStyle["color"] = secondaryColor;

    if (m_Zones.OutletsInUseCount === 0){
        return <Alert severity="error" style={{marginTop: 5}}>
            VRI settings can only be defined once a valid sprinkler chart has been produced.
        </Alert>
    }

    return (
        <>
            <FormControl variant="standard" size="small" fullWidth>
                <InputLabel sx={formInputLabelStyle} shrink={true}>VRI Valve Type</InputLabel>
                <Select                       
                    label="VRI Valve Type" 
                    value={valveType}
                    onChange={(ev) => {
                        const nextValveType = ev.target.value as VRIValveType;
                        setVRIValveType(nextValveType);
                    }}
                >
                    {Object.keys(VRIValveType).filter(key => !isNaN(Number(VRIValveType[key]))).map((t) => {
                        return <MenuItem value={t}>{getVRIZoneValveTypeStr(VRIValveType[t])}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <Stack direction="row" sx={{marginTop: "1em"}} spacing={1}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="column" spacing={0.5}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setStatus(Status.equalSpace);
                            }}>Equal space
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setStatus(Status.equalAcres);
                            }}>Equal acres
                        </Button>
                    </Stack>
                    <Stack direction="column" spacing={0.5}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                addZone(m_Zones, zoneTableSelectedRowId + 1)
                                m_Zones.SetSytemZones(setVRIZones, setVRIValveBoxes);
                            }}>Add zone
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                deleteZone(m_Zones, zoneTableSelectedRowId + 1)
                                m_Zones.SetSytemZones(setVRIZones, setVRIValveBoxes);
                            }}>Delete zone
                        </Button>
                    </Stack>
                    <Stack direction="column" spacing={0.5}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                add1Sprinkler(m_Zones, zoneTableSelectedRowId + 1)
                                m_Zones.SetSytemZones(setVRIZones, setVRIValveBoxes);
                            }}>Add 1 sprinkler
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                subtract1Sprinkler(m_Zones, zoneTableSelectedRowId + 1)
                                m_Zones.SetSytemZones(setVRIZones, setVRIValveBoxes);
                            }}>Subtract 1 sprinkler
                        </Button>
                    </Stack>
                    <Stack direction="column">
                        <Button
                            variant="outlined"
                            disabled={!system.SprinklerProperties.VRIZones || !system.SprinklerProperties.VRIZones.Zone}
                            onClick={() => {
                                exportZones(m_Zones, system);
                            }}>Export zones
                        </Button>
                    </Stack>
                </Stack>
                <DataGrid     
                    autoHeight                
                    rows={system.SprinklerProperties.VRIZones && system.SprinklerProperties.VRIZones.Zone || []}               
                    columns={zoneColumns}
                    onRowClick={(params) => {
                        setZoneTableSelectedRowId(params.row.ZoneNumber)
                    }}
                    disableColumnMenu={true}
                    hideFooter={true}
                    rowHeight={28}         
                    headerHeight={35}          
                    experimentalFeatures={{ newEditingApi: true}}
                    editMode="row"
                    getRowId={(row: ISprinklerPropertiesVRIZonesZone) => {
                        return row.ZoneNumber;
                    }}
                />
            </Stack>
            <NumberOfZonesDialog open={status !== Status.nothing} onClose={() => {setStatus(Status.nothing);}}
                onOk={(numberOfZones) => {
                    switch(status){
                        case Status.equalAcres:
                            equalAcres(m_Zones, numberOfZones, system);
                            break;
                        case Status.equalSpace:
                            equalSpace(m_Zones, numberOfZones);
                            break;
                        default:
                            break;
                    }
                    
                    m_Zones.SetSytemZones(setVRIZones, setVRIValveBoxes);
                    setStatus(Status.nothing);
                }}
            />
            <ZoneRenderer 
                zones={system.SprinklerProperties.VRIZones && system.SprinklerProperties.VRIZones.Zone || []}
                system={system}
            />
        </>
    );
};

export default VRIRenderer;