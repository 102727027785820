import { IOutlet as Outlet } from "rdptypes/project/ISprinklerChart";
import { D3000Plates, DeviceTypes, DropTypes, KometSprayTypes, LDNChemPadTypes, LDNPadTypes, LDNTypes, RegulatorPressures, RegulatorTypes, SuperSprayPadTypes } from "rdptypes/project/ISprinklers";
import { Interprolate } from "./CommonFunctions";
import { DeviceNozzle } from "./Nozzle";
import { nozzleDiameterWithEqualPressureAndHeightMap, wateringDiametersByDevCodeDict } from "./WateringDiameter";

export const SelectSprinkler = (o: Outlet, IsSAC: boolean, OutletCount: number, AllowNelson3030: boolean = true, AllowNelson3030MT: boolean = true): number => {
    let eDevice: DeviceTypes = o.Device;
    let iNelson3030Modifier: number = AllowNelson3030 && o.UseNelson3030 ? 1000 : 0;
    iNelson3030Modifier += AllowNelson3030MT && o.UseNelsonAD3030MT ? 1000 : 0;
    let iNozzle: number = o.NozzleIndex;
    let eRegType: RegulatorTypes = o.Regulator.RegulatorType;
    let eRegPSI: RegulatorPressures = o.Regulator.Pressure;
    let iLDNType: LDNTypes = o.LDN.LDNType;
    let iLDNChemPad: LDNChemPadTypes = o.LDN.ChemPad;
    let iLDNPad: LDNPadTypes = o.LDN.Pad;
    let bBubblerShroud: boolean = o.LDN.BubblerShroud;
    let D3000Plate1: D3000Plates = o.D3000.Plate1;
    let D3000Plate2: D3000Plates = o.D3000.Plate2;
    let D3000Plate3: D3000Plates = o.D3000.Plate3;
    let eKometSprayPad: KometSprayTypes = o.KometSpray.Pad;
    let bTrash: boolean = o.TrashBusterBody;
    let iDevCode: number;
    try {
        iDevCode = DeviceNozzle(eDevice).Nozzles(iNozzle).DevCode;
    }
    catch (ex) {
/*let sb: StringBuilder;
sb.Append(`This proposal is using ${NameOf(DeviceTypes)
}.${eDevice} with Nozzle Number:${iNozzle}. `).AppendLine();
sb.AppendLine("A likely resolution is to recompute the Sprinkler Package. ").AppendLine();
sb.AppendLine("Click "OK" to continue. Then... ").AppendLine();
sb.AppendLine("At the next exception prompt, select "Yes" to continue and go to the Sprinklers screen. ");
sb.AppendLine("Select "Design Sprinklers" and make a slight change in the System GPM or End Pressure. Click "OK" And "Accept". ");
sb.AppendLine("Then go back to "Design Sprinklers" And change back to the original values. Click "OK" And "Accept". ").AppendLine();
sb.Append("Go to "File" And click Save Proposal to allow full access to this proposal in the future. ");
MessageBox.Show(sb.ToString(), "Error Retrieving Nozzle Data", MessageBoxButtons.OK, MessageBoxIcon.Information);
// new InvalidOperationException(, ex)
throw new Error();
*/}
    let validate: (number) => number = x => x /*(iterationNbr) => {
let p: Part = null;
if (!(Parts.TryGetValue(ComputePartKey(12, 15, iterationNbr), p))) {
let sb: StringBuilder;
sb.AppendLine(`${NameOf(SelectSprinkler)
}: Unable to find device iteration number(${iterationNbr}) in the Parts catalog from criteria - `);
sb.AppendLine(`Outlet( `);
sb.AppendLine(`    OutletNumber(${o.OutletNumber}), Device(${o.Device}), UseNelson3030(${o.UseNelson3030}), UseNelsonAD3030MT(${o.UseNelsonAD3030MT})`);
sb.AppendLine(`    , NozzleIndex(${o.NozzleIndex}), RegulatorType(${o.Regulator.RegulatorType}), Pressure(${o.Regulator.Pressure})`);
sb.AppendLine(`    , LDNType(${o.LDN.LDNType}), ChemPad(${o.LDN.ChemPad}), Pad(${o.LDN.Pad})`);
sb.AppendLine(`    , BubblerShroud(${o.LDN.BubblerShroud}), Plate1(${o.D3000.Plate1}), Plate2(${o.D3000.Plate2}), Plate3(${o.D3000.Plate3})`);
sb.AppendLine(`    , KometSpray.Pad(${o.KometSpray.Pad}), TrashBusterBody(${o.TrashBusterBody})`);
sb.AppendLine(`    , DevCode(${DeviceNozzle(eDevice).Nozzles(iNozzle).DevCode})`);
sb.AppendLine(")");
sb.AppendLine(`, IsSAC(${IsSAC}), OutletCount${OutletCount}, AllowNelson3030${AllowNelson3030} `);
sb.AppendLine(`BAD Return value: ${iterationNbr}`);
// new InvalidOperationException()
throw new Error();
}
return iterationNbr;
}
*/;
    switch (eDevice) {
        case DeviceTypes.Nelson15Single3RNBrassImpact:
        case DeviceTypes.Nelson15Double3RNBrassImpact:
        case DeviceTypes.Nelson15DoubleFCNBrassImpact:
        case DeviceTypes.Nelson8Double3RNPlasticImpact:
        case DeviceTypes.Nelson8DoubleFCNPlasticImpact:
        case DeviceTypes.Senninger12MediumSpacingPlasticImpact:
        case DeviceTypes.Senninger6DoubleWhiteVanePlasticImpact:
        case DeviceTypes.Senninger6DoubleRedVanePlasticImpact:
        case DeviceTypes.NelsonR3000RotatorRed:
        case DeviceTypes.NelsonR3000RotatorOrange:
        case DeviceTypes.NelsonR3000RotatorBrown:
        case DeviceTypes.NelsonR3000RotatorOlive:
        case DeviceTypes.NelsonR3000RotatorWhite:
        case DeviceTypes.NelsonS3000SpinnerPurple:
        case DeviceTypes.NelsonS3000SpinnerPurpleLowPressure:
        case DeviceTypes.NelsonS3000SpinnerYellow:
        case DeviceTypes.NelsonS3000SpinnerYellowLowPressure:
        case DeviceTypes.NelsonA3000AcceleratorMaroon:
        case DeviceTypes.NelsonA3000AcceleratorGold:
        case DeviceTypes.NelsonO3000OrbitorBlack:
        case DeviceTypes.NelsonO3000OrbitorBlue:
        case DeviceTypes.NelsonO3000OrbitorPurple:
        case DeviceTypes.NelsonO3030OrbitorFXBlack:
        case DeviceTypes.NelsonO3030OrbitorFXWhite:
        case DeviceTypes.SenningerLowAngleIWobWhite:
        case DeviceTypes.SenningerLowAngleIWobBlue:
        case DeviceTypes.SenningerStandardAngleIWobGray:
        case DeviceTypes.SenningerHighAngleIWobBlack:
        case DeviceTypes.SenningerXiWobBlack:
        case DeviceTypes.SenningerXiWobWhite:
        case DeviceTypes.SenningerXiWobBlue:
        case DeviceTypes.SenningerXiWobGray:
        case DeviceTypes.NelsonA3000AcceleratorNavy:
        case DeviceTypes.NelsonA3000AcceleratorNavyLowPressure:
        case DeviceTypes.KometTwisterBlack:
        case DeviceTypes.KometTwisterBlue:
        case DeviceTypes.KometTwisterYellow:
        case DeviceTypes.KometTwisterWhite:
        case DeviceTypes.KometTwisterPartCircle:
            return validate(iDevCode + iNelson3030Modifier);
            break;
        case DeviceTypes.NelsonR3000RotatorBlue:
        case DeviceTypes.NelsonR3000FCNRotatorBlue:
            if (bTrash) {
                return validate(184);
            }
            else {
                return validate(181 + iNelson3030Modifier);
            }
            break;
        case DeviceTypes.NelsonR3000RotatorGreen:
        case DeviceTypes.NelsonR3000FCNRotatorGreen:
            if (bTrash) {
                return validate(185);
            }
            else {
                return validate(182 + iNelson3030Modifier);
            }
            break;
        case DeviceTypes.NelsonS3000SpinnerRed:
            if (eRegType !== RegulatorTypes.None) {
                if (eRegPSI === RegulatorPressures.a10 && DeviceNozzle(DeviceTypes.NelsonS3000SpinnerRed).Nozzles(iNozzle).MinPSI > 10) {
                    return validate(DeviceNozzle(DeviceTypes.NelsonS3000SpinnerPurple).Nozzles(iNozzle).DevCode + iNelson3030Modifier);
                }
                else {
                    return validate(iDevCode + iNelson3030Modifier);
                }
            }
            else {
                return validate(iDevCode + iNelson3030Modifier);
            }
            break;
        case DeviceTypes.NelsonD3000Spray:
        case DeviceTypes.NelsonD3000FCNSpray:
            switch (D3000Plate1) {
                case D3000Plates.Gray:
                    switch (D3000Plate2) {
                        case D3000Plates.Red:
                            return validate(218 + iNelson3030Modifier);
                            break;
                        default:
                            return validate(211 + iNelson3030Modifier);
                            break;
                    }
                    break;
                case D3000Plates.Black:
                    return validate(212 + iNelson3030Modifier);
                    break;
                case D3000Plates.Blue:
                    switch (D3000Plate2) {
                        case D3000Plates.Red:
                            return validate(219 + iNelson3030Modifier);
                            break;
                        case D3000Plates.Green:
                            return validate(220 + iNelson3030Modifier);
                            break;
                        default:
                            return validate(213 + iNelson3030Modifier);
                            break;
                    }
                    break;
                case D3000Plates.Purple:
                    return validate(214 + iNelson3030Modifier);
                    break;
                case D3000Plates.Green:
                    if (bTrash) {
                        return validate(221);
                    }
                    else {
                        return validate(216 + iNelson3030Modifier);
                    }
                    break;
                case D3000Plates.Turqoise:
                    return validate(iDevCode + iNelson3030Modifier);
                    break;
                case D3000Plates.Orange:
                    return validate(223 + iNelson3030Modifier);
                    break;
                case D3000Plates.Brown:
                    return validate(224 + iNelson3030Modifier);
                    break;
                case D3000Plates.Tan:
                    return validate(225 + iNelson3030Modifier);
                    break;
            }
            break;
        case DeviceTypes.Nelson1518MediumSpacingBrassImpact:
            return validate(-1);
            break;
        case DeviceTypes.SenningerSuperSpray:
            return validate(SelectSuperSpray(iDevCode, o.SuperSprayPad));
            break;
        case DeviceTypes.SenningerLDNSpray:
            if (iLDNType === LDNTypes.SinglePad) {
                switch (iDevCode) {
                    case 253:
                    case 254:
                        iDevCode = 252;
                        break;
                }
            }
            switch (iLDNChemPad) {
                case LDNChemPadTypes.None:
                    switch (iDevCode) {
                        case 251:
                            switch (iLDNPad) {
                                case LDNPadTypes.Blue:
                                    return validate(251);
                                    break;
                                case LDNPadTypes.Black:
                                    return validate(255);
                                    break;
                            }
                            break;
                        case 252:
                            switch (iLDNPad) {
                                case LDNPadTypes.Blue:
                                    return validate(252);
                                    break;
                                case LDNPadTypes.Black:
                                    return validate(256);
                                    break;
                            }
                            break;
                        case 253:
                        case 254:
                            return validate(iDevCode);
                            break;
                    }
                    break;
                case LDNChemPadTypes.Red:
                    switch (iDevCode) {
                        case 251:
                            return validate(261);
                            break;
                        case 252:
                            return validate(262);
                            break;
                        case 253:
                            return validate(263);
                            break;
                        case 254:
                            return validate(264);
                            break;
                    }
                    break;
                case LDNChemPadTypes.White:
                    switch (iDevCode) {
                        case 251:
                            return validate(265);
                            break;
                        case 252:
                            return validate(266);
                            break;
                        case 253:
                            return validate(267);
                            break;
                        case 254:
                            return validate(268);
                            break;
                    }
                    break;
                case LDNChemPadTypes.Beige:
                    switch (iLDNPad) {
                        case LDNPadTypes.Blue:
                            switch (iDevCode) {
                                case 251:
                                    if (bBubblerShroud) {
                                        return validate(241);
                                    }
                                    else {
                                        return validate(257);
                                    }
                                    break;
                                case 252:
                                    if (bBubblerShroud) {
                                        return validate(242);
                                    }
                                    else {
                                        return validate(258);
                                    }
                                    break;
                            }
                            break;
                        case LDNPadTypes.White:
                            return validate(259);
                            break;
                        case LDNPadTypes.Black:
                            switch (iDevCode) {
                                case 251:
                                    return validate(311);
                                    break;
                                case 252:
                                    return validate(312);
                                    break;
                            }
                            break;
                        case LDNPadTypes.Black24Deep:
                            if ([251, 252].indexOf(iDevCode) !== -1) {
                                return validate(313);
                            }
                            break;
                    }
                    break;
            }
            break;
        case DeviceTypes.KometSpray:
            switch (eKometSprayPad) {
                case KometSprayTypes.Black:
                    return validate(31);
                    break;
                case KometSprayTypes.Blue:
                    return validate(30);
                    break;
                case KometSprayTypes.Grey:
                    return validate(33);
                    break;
                case KometSprayTypes.Yellow:
                    return validate(32);
                    break;
            }
            break;
        case DeviceTypes.SenningerQuadSpray:
            return validate(-1);
            break;
        case DeviceTypes.KometSpray:
            switch (o.KometSpray.Pad) {
                case KometSprayTypes.Black:
                    return validate(31);
                    break;
                case KometSprayTypes.Blue:
                    return validate(30);
                    break;
                case KometSprayTypes.Grey:
                    return validate(33);
                    break;
                case KometSprayTypes.Yellow:
                    return validate(32);
                    break;
            }
            break;
        case DeviceTypes.SenningerXcelWobbler:
            return validate(iDevCode);
            break;
        default:
            return validate(-1);
            break;
    }
}
export const D3000Plate2ToPlate1 = (p: D3000Plates): number => {
switch (p) {
case D3000Plates.Blue:
return 213;
break;
case D3000Plates.Green:
return 216;
break;
case D3000Plates.Gray:
return 211;
break;
case D3000Plates.Black:
return 212;
break;
case D3000Plates.Turqoise:
return 217;
break;
case D3000Plates.Purple:
return 214;
break;
case D3000Plates.Orange:
return 223;
break;
case D3000Plates.Red:
return 213;
break;
case D3000Plates.Brown:
return 224;
break;
case D3000Plates.Tan:
return 225;
break;
}
}
const SelectSuperSpray = (iDevCode: number, iSuperSpray: SuperSprayPadTypes): number => {
    switch (iSuperSpray) {
        case SuperSprayPadTypes.CottonChemWhite:
            return iDevCode;
            break;
        case SuperSprayPadTypes.Concave36DeepBlue:
            return 291;
            break;
        case SuperSprayPadTypes.Concave36MedBlue:
            return 292;
            break;
        case SuperSprayPadTypes.Flat24DeepBlack:
            return 293;
            break;
        case SuperSprayPadTypes.Flat36DeepBlack:
            return 294;
            break;
        case SuperSprayPadTypes.Flat36MedBlack:
            return 295;
            break;
        case SuperSprayPadTypes.FlatSmoothBlack:
            return 296;
            break;
    }
}
export const OutletRegulatedPressure = (o: Outlet): number => {
    if (o.Regulator.RegulatorType !== RegulatorTypes.None) {
        switch (o.Regulator.Pressure){
            case RegulatorPressures.a6:
                return 6;
            case RegulatorPressures.a10:
                return 10;
            case RegulatorPressures.a15:
                return 15;
            case RegulatorPressures.a20:
                return 20;
            case RegulatorPressures.a25:
                return 25;
            case RegulatorPressures.a30:
                return 30;
            case RegulatorPressures.a40:
                return 40;
        }
    }
    else {
        return o.Pressure;
    }
}
export const DeviceGroundClearance = (o: Outlet): number => {
    if (o.Drop === DropTypes.None) {
        return o.AbsoluteHeight;
    }
    switch (o.Drop) {
        case DropTypes.Rigid:
            return o.RigidDrop.GroundClearance;
        case DropTypes.Hose:
            return o.HoseDrop.GroundClearance;
    }
}

export const DeviceWateringDiameter = (bSAC: boolean, iOutletCount: number, o: Outlet): number => {
    let diameter1 = EstimatedDiameter(SelectSprinkler(o, bSAC, iOutletCount, false, false), DeviceNozzle(o.Device).Nozzles(o.NozzleIndex).Nozzle1Size, OutletRegulatedPressure(o), DeviceGroundClearance(o));
    let diameter2 = 0;
    let diameter3 = 0;
    if ((o.D3000.Plate2 ?? D3000Plates.None) !== D3000Plates.None) {
        diameter2 = EstimatedDiameter(D3000Plate2ToPlate1(o.D3000.Plate2), DeviceNozzle(o.Device).Nozzles(o.NozzleIndex).Nozzle1Size, OutletRegulatedPressure(o), DeviceGroundClearance(o));
    }
    if ((o.D3000.Plate3 ?? D3000Plates.None) !== D3000Plates.None) {
        diameter3 = EstimatedDiameter(D3000Plate2ToPlate1(o.D3000.Plate3), DeviceNozzle(o.Device).Nozzles(o.NozzleIndex).Nozzle1Size, OutletRegulatedPressure(o), DeviceGroundClearance(o));
    }
    return Math.max(diameter1, diameter2, diameter3);
}

interface WateringDiameter {
    DevCode: number;
    Nozzle1: number;
    Pressure: number;
    Height: number;
    Diameter: number;
}

export const EstimatedDiameter = (iDev: number, Nozzle: number, Pressure: number, Height: number): number => {
    
    if (isNaN(Height)){//GroundClearance treated as 0 elsewhere but is NaN by default
        Height = 0;
    }
    const DeviceDiameters: WateringDiameter[] = (wateringDiametersByDevCodeDict[iDev.toString()] ?? []).slice();

    if (DeviceDiameters.length === 0) {
        return 0;
    }

    if (DeviceDiameters.length === 1) {
        return DeviceDiameters[0].Diameter;
    }

    let NozzleDiameters: WateringDiameter[] = DeviceDiameters.filter((d) => d.Nozzle1 === Nozzle);

    if (NozzleDiameters.length === 0) {
        if (Nozzle < DeviceDiameters[0].Nozzle1) {
            let n: number = DeviceDiameters[0].Nozzle1;
            NozzleDiameters = DeviceDiameters.filter((d) => d.Nozzle1 === n);
        }
    }

    if (NozzleDiameters.length === 0) {
        if (Nozzle > DeviceDiameters[DeviceDiameters.length - 1].Nozzle1) {
            let n: number = DeviceDiameters[DeviceDiameters.length - 1].Nozzle1;
            NozzleDiameters = DeviceDiameters.filter((d) => d.Nozzle1 === n);
        }
    }

    if (NozzleDiameters.length === 0) {
        const nozzleLookup = nozzleDiameterWithEqualPressureAndHeightMap[iDev.toString()];
        if (nozzleLookup) {
            for (let i: number = 0; i < DeviceDiameters.length; i++) {
                if (Nozzle > DeviceDiameters[i].Nozzle1 && Nozzle < DeviceDiameters[i + 1].Nozzle1) {
                    let n1: number = DeviceDiameters[i].Nozzle1;
                    let n2: number = DeviceDiameters[i + 1].Nozzle1;
                    const l1 = nozzleLookup[n1];
                    if (l1) {
                        const l2 = l1[n2];
                        if (l2) {
                            l2.forEach(x => {
                                const d = x.wd1;
                                const d2 = x.wd2;
                                let diam = Interprolate(Nozzle, d.Nozzle1, d2.Nozzle1, d.Diameter, d2.Diameter);
                                NozzleDiameters.push({DevCode: d.DevCode, Nozzle1: Nozzle, Pressure: d.Pressure, Height: d.Height, Diameter: diam});
                            })
                        }
                    }
                }
            }

        }
    }

    if (NozzleDiameters.length === 0) {
        return 0;
    }

    if (NozzleDiameters.length === 1) {
        return NozzleDiameters[0].Diameter;
    }

    let PressureDiameters: WateringDiameter[] = NozzleDiameters.filter((d) => d.Pressure === Pressure);

    if (PressureDiameters.length === 0) {
        if (Pressure < NozzleDiameters[0].Pressure) {
            let p: number = NozzleDiameters[0].Pressure;
            PressureDiameters = NozzleDiameters.filter((d) => d.Pressure === p);
        }
    }

    if (PressureDiameters.length === 0) {
        if (Pressure > NozzleDiameters[NozzleDiameters.length - 1].Pressure) {
            let p: number = NozzleDiameters[NozzleDiameters.length - 1].Pressure;
            PressureDiameters = NozzleDiameters.filter((d) => d.Pressure === p);
        }
    }

    if (PressureDiameters.length === 0) {
        for (let i: number = 0; i < NozzleDiameters.length; i++) {
            if (Pressure > NozzleDiameters[i].Pressure && Pressure < NozzleDiameters[i + 1].Pressure) {
                let p1: number = NozzleDiameters[i].Pressure;
                let p2: number = NozzleDiameters[i + 1].Pressure;

                NozzleDiameters.forEach((d) => {
                    if (d.Pressure === p1) {
                        NozzleDiameters.forEach((d2) => {
                            if (d2.Pressure === p2 && d2.Height === d.Height) {
                                let diam = Interprolate(Pressure, d.Pressure, d2.Pressure, d.Diameter, d2.Diameter);
                                PressureDiameters.push({DevCode: d.DevCode, Nozzle1: Nozzle, Pressure: Pressure, Height: d.Height, Diameter: diam});
                            }
                        });
                    }
                });
            }
        }
    }

    if (PressureDiameters.length === 0) {
        return 0;
    }

    if (PressureDiameters.length === 1) {
        return PressureDiameters[0].Diameter;
    }

    for (const d of PressureDiameters) {
        if (d.Height === Height) {
            return d.Diameter;
        }
    }

    if (Height < PressureDiameters[0].Height) {
        return PressureDiameters[0].Diameter;
    }

    if (Height > PressureDiameters[PressureDiameters.length - 1].Height) {
        return PressureDiameters[PressureDiameters.length - 1].Diameter;
    }
    for (let i: number = 0; i < PressureDiameters.length; i++) {
        if (Height > PressureDiameters[i].Height && Height < PressureDiameters[i + 1].Height) {
            return Interprolate(Height, PressureDiameters[i].Height, PressureDiameters[i + 1].Height, PressureDiameters[i].Diameter, PressureDiameters[i + 1].Diameter);
        }
    }
    return 0;
}