import { EndOfSystemTypes, SACGPSGuidanceBaseOptions, SystemTypes } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { FieldSets } from "../SystemFunctions";
import { AirCompressorOk, ControlLineBoosterPumpOk, DistributionFlowRateOK, GPSBaseOK, SACPathCreatorOK, VRISACSpanOptionOk } from "../Valids.Valids";
import { AirCompressorOkDto } from "../Valids.dto.Valids";

export default class SwingArmForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        if (this.Quote.System?.FlangedSide?.EndOfSystem?.EndOfSystemType === EndOfSystemTypes.SAC && this.Quote.System?.Circle?.SwingArm){
            let sa = this.Quote.System?.Circle?.SwingArm;

            if (!GPSBaseOK(sa.GPSBase, {
                Country: this.Quote.System.DealerProperties.Country,
            })){
                this.Quote.Warnings.Add(`Dealer country is invalid for selected SAC Guidance Options`);
            }

            if(
                (sa.GPSBase === SACGPSGuidanceBaseOptions.RadioBase || 
                sa.GPSBase === SACGPSGuidanceBaseOptions.NoBaseFreewave || 
                sa.GPSBase === SACGPSGuidanceBaseOptions.SingleSystemFreewaveRadioBase)
                && sa.GPSCoverageStudyCompleted === false && (!sa.GPSCoverageStudyBaseLocation || !sa.GPSCoverageStudyPivotLocation) ){
                    this.Quote.Warnings.Add(`Base Location and Pivot Location are obligatory fields when GPS Coverage Study Needed`);
            }

            if (!SACPathCreatorOK(sa.SACPathCreator,  this.Quote.SwingArmClass.RequireNoChargeReinkeSACPath())){
                this.Quote.Warnings.AddWithTargets(["Circle.SwingArm.SACPathCreator"], `SAC path creator is invalid.`);
            }

            if (!ControlLineBoosterPumpOk(sa.ControlLineBooster, {
                ZoneControlType: sa.ZoneControlType,
                VRISACSpanSelected: sa.VRISACSpan,
            })){
                this.Quote.Warnings.AddWithTargets(["Circle.SwingArm.ControlLineBooster"], `Control line booster pump cannot be selected for the current configuration (Swing Arm Corner Options).`);
            }

            let dto: AirCompressorOkDto = {
                ZoneControlType: sa.ZoneControlType,
                ControlLineBoosterSelected: sa.ControlLineBooster,
                ControlLineBoosterESPSelected: sa.ControlLineBoosterESP,
                VRISACSpanSelected: sa.VRISACSpan
            }

            if (!AirCompressorOk(sa.AirCompressor, dto)){
                this.Quote.Warnings.AddWithTargets(["Circle.SwingArm.AirCompressor"], `Air compressor is not valid for the current configuration (Swing Arm Corner Options).`);
            }

            if (!VRISACSpanOptionOk(sa.VRISACSpan, {
                ZoneControlType: sa.ZoneControlType,
                SystemType: this.Quote.System.SystemProperties.SystemType,
                ControlPanelDataValid:
                this.Quote.System.SystemProperties.SystemType === SystemTypes.SwingArmRetro ?
                    !!this.Quote.System.Circle.SACRetro.IsControlPanelVRISACSpanCompliant :
                    FieldSets(this.Quote.System).ControlPanel.DataValid(),
                EndTowerPositioning: this.Quote.System.ControlPanel.EndTowerPositioning,
                PanelModel: this.Quote.System.ControlPanel.PanelModel,
                Country: this.Quote.System.DealerProperties.Country,
            })){
                this.Quote.Warnings.AddWithTargets(["Circle.SwingArm.VRISACSpan"], `VRI SAC span option cannot be selected for the current configuration (Swing Arm Corner Options).`);
            }

            if (!DistributionFlowRateOK(sa.DistributionFlowRate, {
                ZoneControlType: sa.ZoneControlType,
                VRISACSpanSelected: sa.VRISACSpan,
                Country: this.Quote.System.DealerProperties.Country,
            })){
                this.Quote.Warnings.AddWithTargets(["Circle.SwingArm.DistributionFlowRate"], `Invalid distribution flow rate for the current configuration (Swing Arm Corner Options).`);
            }
        }
    }
}