import * as React from "react";
import { PropsWithChildren, useEffect } from "react";
import EndGunWorkerContext, { Props } from "./EndGunWorkerContext";

const isSupported = !!window.Worker;
let didWarn = false;

const EndGunWorkerContextWrapper: React.FC<PropsWithChildren<Props>> = ({ 
    children, ...rest
}) => {
    useEffect(() => {
        if (!isSupported && !didWarn) {
            didWarn = true;
            alert("Your browser does not support workers. As a result End Guns will not be generated.")
        }
    }, [])
    
    if (!isSupported) {
        {children}
    }

    return (
        <EndGunWorkerContext {...rest}>
            {children}
        </EndGunWorkerContext>
    );
}

export default EndGunWorkerContextWrapper;