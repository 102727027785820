import { DeviceTypes } from "rdptypes/project/ISprinklers";
import {
    DualSprinklerPackageControlTypes,
    EndTowerPositioningTypes,
    HoseAndCouplerKitTypes,
    PanelModels,
    PressureGaugeTypes,
    PressureTransducerOptions,
    SpanTypes,
    SprinklerManufacturerTypes,
    SprinklerValveTypes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import { RollsToOrder, SelectDualSprinklerPackageValveBoxTowerNumber } from "./CommonFunctions";
import * as ohf from "./OtherHelpers";
import PartsAssembler, { LocationCodes } from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as spanf from "./SpanFunctions";
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { TSpans as Spans } from "./Types";
import { StringFormat } from "./VbCompat";
export class SprinklersConfigClass extends CSection2 {
    /*private CheckPackages = (WithWarnings: boolean): boolean => {
        let pc: SprinklersPackagesClass;
        return pc.DataOK(null, WithWarnings);
    }
*//*public AllHoseDrops = (): boolean => {
if (!(FieldSet.DataValid())) {
return false;
}
let iPackageCount: number = this.Quote.System.FlangedSide.Sprinklers.Packages.Count;
if (iPackageCount === 0) {
return false;
}
for (let i: number = 1; i <= iPackageCount; i++
) {
if (this.Quote.System.FlangedSide.Sprinklers.Package(i).Drop !== DropTypes.Hose) {
return false;
}
}
if (sysf.IsDualSided(this.Quote.System)) {
let iPackageCount: number = this.Quote.System.FlexSide.Sprinklers.Packages.Count;
if (iPackageCount === 0) {
return false;
}
for (let i: number = 1; i <= iPackageCount; i++
) {
if (this.Quote.System.FlexSide.Sprinklers.Package(i).Drop !== DropTypes.Hose) {
return false;
}
}
}
return true;
}
*/public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): boolean => {
        let sys = this.Quote.System;
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }
        if (!(this.CheckPackages(true))) {
        return false;
        }*/
        if (sys.SprinklerProperties.PivotPressure + this.Quote.BasePressureMod() > 100) {
            this.Quote.Warnings.Add(strLanguageText("sltSystemPressureTooHighWarning"), WarningLevels.Critical, true);
            this.Quote.Warnings.Add(strLanguageText("sltSystemPressureTooHighWarning2"), WarningLevels.Critical, true);
        }
        if (ohf.VRIZonesCount(sys.SprinklerProperties.VRIZones) > 0) {
            if (sys.ControlPanel.PanelModel !== PanelModels.RPMPreferred || sys.ControlPanel.EndTowerPositioning !== EndTowerPositioningTypes.GPS) {
                this.Quote.Warnings.Add(strLanguageText("sltVRI"));
                return false;
            }
            if (sysf.HasEnergySaverPackage(sys)) {
                this.Quote.Warnings.Add(strLanguageText("sltVRI"));
                return false;
            }
        }
        if (sysf.HasPressureTransducer0to25PSI(sys) && sys.SprinklerProperties.PivotPressure > 25) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.PressureTransducer0to25PSI"], strLanguageText("sltPressureTransducer0to25PSI"));
            return false;
        }
        let baseInletPressure: number = sys.SprinklerProperties.PivotPressure + this.Quote.BasePressureMod();
        if (sysf.FieldSets(this.Quote.System).HoseFeed.DataValid() && [HoseAndCouplerKitTypes.HoseWithCouplers, HoseAndCouplerKitTypes.CouplersOnly].indexOf(sys.Lateral.HoseFeed.HoseAndCouplerKit) !== -1 && baseInletPressure < 45) {
            this.Quote.Warnings.Add(StringFormat(strLanguageText("sltHoseFeedSoftHoseMin45PSIRequired"), `${45 - baseInletPressure}`), WarningLevels.Decision);
        }
        let ep = this.Quote.System.SprinklerProperties.DesignedEndPressure;
        if (ep > 100) {
            this.Quote.Warnings.Add(strLanguageText("sltEndPressureMax100PSI"));
            return false;
        }
        else if (sysf.HasSwingArmCorner(this.Quote.System) && ep < 15) {
            this.Quote.Warnings.Add(strLanguageText("sltEndPressureMin15PSI"));
            return false;
        }
        else if (ep < 3) {
            this.Quote.Warnings.Add(strLanguageText("sltEndPressureMin3PSI"));
            return false;
        }
        if (sysf.HasEnergySaverPackage(sys)) {
            switch (sys.SprinklerProperties.SprinklerEngineCode) {
                case SprinklerManufacturerTypes.Komet:
                case SprinklerManufacturerTypes.Senninger:
                    this.Quote.Warnings.Add(strLanguageText("sltSprinklerBrand"));
                    return false;
                    break;
            }
        }
        if (sys.SprinklerProperties.DualSprinklerPackage) {
            let towerCount: number = sidef.Towers(sys.FlangedSide).Count;
            if (towerCount < 2) {
                this.Quote.Warnings.Add(strLanguageText("sltSprinklerDualPackageMinimum2Spans"));
                return false;
            }
            let sprinklerBoxTowerNumber: number = SelectDualSprinklerPackageValveBoxTowerNumber(sys, sys.FlangedSide);
            if (sys.FlangedSide.Tower[sprinklerBoxTowerNumber - 1].Guidance) {
                this.Quote.Warnings.Add(`Dual Sprinkler Package requires placement of Sprinkler Valve Box at Tower:(${sprinklerBoxTowerNumber}). Ensure 'Lateral Move Guidance' is on the Power Tower or any other Outer Tower.`);
                return false;
            }
        }
        return true;
    }
    public WritePO = (PO: PartsAssembler) => {
        const sys = this.Quote.System;
        const sp = sys.SprinklerProperties;

        switch (this.Quote.System.SprinklerProperties.PressureGauge) {
            case PressureGaugeTypes.a30PSI:
                PO.InsertPart(schema.groups.Sprinklers_PressureGauge.groupId, "351831", 1); // WATER PRESSURE GAUGE 0-30#
                break;
            case PressureGaugeTypes.a100PSI:
                PO.InsertPart(schema.groups.Sprinklers_PressureGauge.groupId, "351832", 1); // WATER PRESSURE GAUGE 0-100#
                break;
            case PressureGaugeTypes.a200PSI:
                PO.InsertPart(schema.groups.Sprinklers_PressureGauge.groupId, "351833", 1); // WATER PRESSURE GAUGE 0-200#
                break;
            case PressureGaugeTypes.a60PSI:
                PO.InsertPart(schema.groups.Sprinklers_PressureGauge.groupId, "351821", 1); // WATER PRESSURE GAUGE 0-60#
                break;
        }
        if (this.Quote.System.SprinklerProperties.EndPressureGauge) {
            if (sysf.IsCenterFeed(this.Quote.System)) {
                PO.InsertPart(schema.groups.Sprinklers_PressureGauge.groupId, "351558", 2); // SENN-PRES DROP 0-30#FP
                PO.InsertPart(schema.groups.Sprinklers_PressureGauge.groupId, "351836", 2); // 3/4-INCH CLOSE NIPPLE-BLK PLAS
            }
            else {
                PO.InsertPart(schema.groups.Sprinklers_PressureGauge.groupId, "351558", 1); // SENN-PRES DROP 0-30#FP
                PO.InsertPart(schema.groups.Sprinklers_PressureGauge.groupId, "351836", 1); // 3/4-INCH CLOSE NIPPLE-BLK PLAS
            }
        }
        let iVRIZoneCount: number = ohf.VRIZonesCount(this.Quote.System.SprinklerProperties.VRIZones);
        if (iVRIZoneCount > 1) {
            let iVRIControlledZoneCount = 0;
            let iDevicesControlled = 0;
            for (let i: number = 1; i <= iVRIZoneCount; i++
            ) {
                if (this.Quote.System.SprinklerProperties.VRIZones.Zone[i - 1].Controlled) {
                    iVRIControlledZoneCount += 1;
                    iDevicesControlled += this.Quote.System.SprinklerProperties.VRIZones.Zone[i - 1].DeviceCount;
                }
            }
            let iVRIValveBoxCount: number = PO.VRI.ValveBoxCount;
            let iLastTower: number = sidef.NumberOfTowers(this.Quote.System.FlangedSide);
            let bMaxi: boolean = sysf.IsMaxigator(this.Quote.System);
            let bGPSValveBoxOrdered: boolean;
            if (this.Quote.System.ControlPanel.PanelModel === PanelModels.RPMPreferred) {
                if (sysf.FieldSets(this.Quote.System).SprinklerChart.DataValid()) {
                    if (ohf.VRIZonesCount(this.Quote.System.SprinklerProperties.VRIZones) > 0) {
                        if (PO.VRI.ValveBoxCount > 0) {
                            if (!(PO.VRI.LastTowerHasValveBox)) {
                                switch (this.Quote.System.ControlPanel.EndTowerPositioning) {
                                    case EndTowerPositioningTypes.None:
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                                        PO.InsertPart(schema.groups.ControlPanel_VRIPanelKit.groupId, "350964", 1); // VRI CONTROL PANEL OPTION
                                        break;
                                    case EndTowerPositioningTypes.Resolver:
                                        if (this.Quote.System.ControlPanel.PressureTransducer0to25PSI) {
                                            PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670-25", 1); // TRANS 25 PSI OP PREF TOUCH MCP
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670", 1); // TRANSDUCER OPT PREF TOUCH MCP
                                        }
                                        PO.InsertPart(schema.groups.ControlPanel_VRIPanelKit.groupId, "350964", 1); // VRI CONTROL PANEL OPTION
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                                        break;
                                    case EndTowerPositioningTypes.GPS:
                                        PO.InsertPart(schema.groups.ControlPanel_VRIPanelKit.groupId, "350964", 1); // VRI CONTROL PANEL OPTION
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                        if (!((this.Quote.HoseFeedClass.DataValid() || sysf.FieldSets(this.Quote.System).CanalFeed.DataValid()))) {
                                            if (!(this.Quote.System.ControlPanel.PressureTransducerOptions === PressureTransducerOptions.AtEnd)) {
                                                PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                                            }
                                        }
                                        if (this.Quote.System.ControlPanel.PressureTransducerOptions === PressureTransducerOptions.AtEnd) {
                                            PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "357093", 1); // GPS CDW EOS KIT PSI TRANS
                                        }
                                        if (this.Quote.System.ControlPanel.PressureTransducerOptions === PressureTransducerOptions.AtPivot) {
                                            if (this.Quote.System.ControlPanel.PressureTransducer0to25PSI) {
                                                PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670-25", 1); // TRANS 25 PSI OP PREF TOUCH MCP
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670", 1); // TRANSDUCER OPT PREF TOUCH MCP
                                            }
                                        }
                                        break;
                                }
                            }
                            else {
                                switch (this.Quote.System.ControlPanel.EndTowerPositioning) {
                                    case EndTowerPositioningTypes.None:
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                                        PO.InsertPart(schema.groups.VRI_VRIValveBox.groupId, "352927GPS", 1, { locationCode: LocationCodes.TowersRight, locationPosition: iLastTower }); // VALVE BOX OPT-VRI-GPS
                                        break;
                                    case EndTowerPositioningTypes.Resolver:
                                        if (this.Quote.System.ControlPanel.PressureTransducer0to25PSI) {
                                            PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670-25", 1); // TRANS 25 PSI OP PREF TOUCH MCP
                                        }
                                        else {
                                            PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670", 1); // TRANSDUCER OPT PREF TOUCH MCP
                                        }
                                        PO.InsertPart(schema.groups.ControlPanel_VRIPanelKit.groupId, "350964", 1); // VRI CONTROL PANEL OPTION
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                        PO.InsertPart(schema.groups.VRI_VRIValveBox.groupId, "352927GPS", 1, { locationCode: LocationCodes.TowersRight, locationPosition: iLastTower }); // VALVE BOX OPT-VRI-GPS
                                        break;
                                    case EndTowerPositioningTypes.GPS:
                                        if (this.Quote.System.ControlPanel.PressureTransducerOptions === PressureTransducerOptions.AtPivot) {
                                            if (this.Quote.System.ControlPanel.PressureTransducer0to25PSI) {
                                                PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670-25", 1); // TRANS 25 PSI OP PREF TOUCH MCP
                                            }
                                            else {
                                                PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670", 1); // TRANSDUCER OPT PREF TOUCH MCP
                                            }
                                        }
                                        PO.InsertPart(schema.groups.ControlPanel_VRIPanelKit.groupId, "350964", 1); // VRI CONTROL PANEL OPTION
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                        break;
                                }
                            }
                        }
                    }
                }
            }
            for (let i: number = 1; i <= iVRIValveBoxCount; i++
            ) {
                let eStiffenedSpanType: SpanTypes;
                if (PO.VRI.ValveBoxes.Get()[i].Tower < 0) {
                    eStiffenedSpanType = this.Quote.LeftTowersClass.StiffenedSpanType(-PO.VRI.ValveBoxes.Get()[i].Tower);
                    PO.InsertPart(schema.groups.VRI_VRIValveBox.groupId, "352927", 1, { locationCode: LocationCodes.TowersLeft, locationPosition: -PO.VRI.ValveBoxes.Get()[i].Tower }); // VALVE BOX OPTION-6 VALVE-VRI
                }
                else if (PO.VRI.ValveBoxes.Get()[i].Tower === 0) {
                    eStiffenedSpanType = this.Quote.System.FlangedSide.Span[1 - 1].SpanType;
                    PO.InsertPart(schema.groups.VRI_VRIValveBox.groupId, "352927", 1, { locationCode: LocationCodes.TowersRight, locationPosition: 0 }); // VALVE BOX OPTION-6 VALVE-VRI
                }
                else if (PO.VRI.ValveBoxes.Get()[i].Tower > 0) {
                    eStiffenedSpanType = this.Quote.RightTowersClass.StiffenedSpanType(PO.VRI.ValveBoxes.Get()[i].Tower);
                    if (!(bGPSValveBoxOrdered) && !(bMaxi) && PO.VRI.ValveBoxes.Get()[i].Tower === iLastTower) {
                        bGPSValveBoxOrdered = true;
                        if (this.Quote.System.ControlPanel.PressureTransducerOptions === PressureTransducerOptions.AtEnd) {
                            PO.InsertPart(schema.groups.VRI_VRIValveBox.groupId, "353977", 1); // VALVE BOX 6 VRIGPS RBST TRANS
                        }
                        else {
                            PO.InsertPart(schema.groups.VRI_VRIValveBox.groupId, "352927GPS", 1, { locationCode: LocationCodes.TowersRight, locationPosition: PO.VRI.ValveBoxes.Get()[i].Tower }); // VALVE BOX OPT-VRI-GPS
                        }
                    }
                    else {
                        PO.InsertPart(schema.groups.VRI_VRIValveBox.groupId, "352927", 1, { locationCode: LocationCodes.TowersRight, locationPosition: PO.VRI.ValveBoxes.Get()[i].Tower }); // VALVE BOX OPTION-6 VALVE-VRI
                    }
                }
                switch (eStiffenedSpanType) {
                    case SpanTypes.E2100:
                        PO.InsertPart(schema.groups.VRI_VRIMountKit.groupId, "357030", 1); // TOWER BOX-RETRO MNT KIT-10"
                        break;
                    case SpanTypes.S2085G:
                    case SpanTypes.PL2085G:
                    case SpanTypes.E2085:
                        PO.InsertPart(schema.groups.VRI_VRIMountKit.groupId, "357029", 1); // TOWER BOX-RETRO MNT KIT-8 5/8"
                        break;
                    case SpanTypes.A80G:
                        PO.InsertPart(schema.groups.VRI_VRIMountKit.groupId, "357028", 1); // TOWER BOX-RETRO MNT KIT-8"
                        break;
                    case SpanTypes.E2065:
                    case SpanTypes.E2665:
                    case SpanTypes.S2065G:
                    case SpanTypes.PL2065G:
                        PO.InsertPart(schema.groups.VRI_VRIMountKit.groupId, "357027", 1); // TOWER BOX-RETRO MNT KIT 6-5/8"
                        break;
                    case SpanTypes.E2060:
                    case SpanTypes.E2660:
                    case SpanTypes.A60G:
                    case SpanTypes.AlumIV:
                        PO.InsertPart(schema.groups.VRI_VRIMountKit.groupId, "357026", 1); // TOWER BOX-RETRO MNT KIT-6"
                        break;
                    case SpanTypes.E2045:
                        PO.InsertPart(schema.groups.VRI_VRIMountKit.groupId, "357025", 1); // TOWER BOX-RETRO MNT KIT-4 1/2"
                        break;
                }
            }
            let iTubingLargeRolls = Math.floor(PO.VRI.QuarterInchTubingLength / 1000);
            let iTubingSmallRolls: number;
            iTubingSmallRolls = RollsToOrder(PO.VRI.QuarterInchTubingLength - (iTubingLargeRolls * 1000), 250);
            if (iTubingSmallRolls === 4) {
                iTubingSmallRolls = 0;
                iTubingLargeRolls += 1;
            }
            if (iTubingLargeRolls > 0) {
                PO.InsertPart(schema.groups.VRI_VRITubing.groupId, "355258", iTubingLargeRolls); // TUBE OPT-1/4" PER 1000'-VRI
            }
            if (iTubingSmallRolls > 0) {
                PO.InsertPart(schema.groups.VRI_VRITubing.groupId, "355260", iTubingSmallRolls); // TUBE OPT-1/4" PER 250'
            }
            let LocationOfEndOfTubing = 0;
            if (iVRIValveBoxCount > 0) {
                LocationOfEndOfTubing = PO.VRI.ValveBoxes.Get()[iVRIValveBoxCount].TowerLocation;
            }
            let bSACAirCompressor: boolean = sysf.HasSwingArmCorner(this.Quote.System) && this.Quote.System.Circle.SwingArm.AirCompressor;
            if (bSACAirCompressor) {
                let s = this.Quote.System.FlangedSide;
                LocationOfEndOfTubing = spanf.EndingLocation(this.Quote.System, s, s.Span[sidef.NumberOfTowers(s) + 1 - 1]);
            }
            PO.InsertPartByIteration(schema.groups.VRI_VRITubing.groupId, 3, RollsToOrder(LocationOfEndOfTubing - PO.VRI.ValveBoxes.Get()[1].TowerLocation + iVRIValveBoxCount * 6, 250));
            if (!(bSACAirCompressor)) {
                let iACLocation: number = PO.VRI.AirCompressorLocation;
                if (iACLocation < 0) {
                    PO.InsertPart(schema.groups.VRI_VRICompressor.groupId, "352911", 1, { locationCode: LocationCodes.TowersLeft, locationPosition: -iACLocation }); // AIR COMP OPT-JENNY-TWR MNT
                }
                else {
                    PO.InsertPart(schema.groups.VRI_VRICompressor.groupId, "352911", 1, { locationCode: LocationCodes.TowersRight, locationPosition: iACLocation }); // AIR COMP OPT-JENNY-TWR MNT
                }
            }
            
            if (sp.SprinklerValveType === SprinklerValveTypes.Poly) {
                PO.InsertPart(schema.groups.VRI_VRIValves.groupId, "351995", iVRIControlledZoneCount);  // 3/4" POLY VALVE-1/4 STRAIGHT
                PO.InsertPart(schema.groups.VRI_VRIValves.groupId, "351994", iDevicesControlled - iVRIControlledZoneCount);  // 3/4" POLY VALVE-1/4 TEE
            } else {
                PO.InsertPart(schema.groups.VRI_VRIValves.groupId, "351990", iVRIControlledZoneCount); // 3/4" REINKE VLV-1/4 STRAIGHT
                PO.InsertPart(schema.groups.VRI_VRIValves.groupId, "351991", iDevicesControlled - iVRIControlledZoneCount); // 3/4" REINKE VLV-1/4 TEE
            }
        }
        if (sp.DualSprinklerPackage) {
            let DeviceCount: number = ohf.Outlets(this.Quote.System.FlangedSide.SprinklerChart).ToList().filter((o) => o.Device !== DeviceTypes.None).length;
            if (sp.SprinklerValveType === SprinklerValveTypes.Poly) {
                PO.InsertPart(schema.groups.DualSprinklerPackage_DualSprinklerPackageValves.groupId, "354290", 2);  // 3/4" POLY VALVE W/TEE
                PO.InsertPart(schema.groups.DualSprinklerPackage_DualSprinklerPackageValves.groupId, "351997", DeviceCount - 2);  // 3/4" POLY VALVE W/TEE
            } else {
                PO.InsertPart(schema.groups.DualSprinklerPackage_DualSprinklerPackageValves.groupId, "351977", 2); // 3/4" REINKE VALVE W/3/8" ELBOW
                PO.InsertPart(schema.groups.DualSprinklerPackage_DualSprinklerPackageValves.groupId, "351987", DeviceCount - 2); // 3/4" REINKE VALVE W/TEE
            }
            let spans: Spans = sidef.Spans(this.Quote.System, this.Quote.System.FlangedSide);
            let dSystemLength: number = 0;
            if (spans.Count > 0) {
                dSystemLength = spanf.EndingLocation(this.Quote.System, this.Quote.System.FlangedSide, spans.ToList().slice(-1)[0]);
            }
            PO.InsertPartByIteration(schema.groups.DualSprinklerPackage_DualSprinklerPackageTubing.groupId, 1, RollsToOrder(dSystemLength, 250));
            PO.InsertPart(schema.groups.DualSprinklerPackage_DualSprinklerPackageMountKit.groupId, "357026", 1); // TOWER BOX-RETRO MNT KIT-6"
            let sprinklerBoxTowerNumber: number = SelectDualSprinklerPackageValveBoxTowerNumber(this.Quote.System, this.Quote.System.FlangedSide);
            PO.InsertPart(schema.groups.DualSprinklerPackage_DualSprinklerPackageValveBox.groupId, "395998", 1, { locationCode: LocationCodes.TowersRight, locationPosition: sprinklerBoxTowerNumber }); // VALVE BOX-DUAL SPRKLR-PIV LAT
            switch (sp.DualSprinklerPackageControlType) {
                case DualSprinklerPackageControlTypes.Water:
                    PO.InsertPart(schema.groups.DualSprinklerPackage_DualSprinklerPackageTubing.groupId, "351975", 1); // STRAINER W/STRAIGHT CONNECTOR
                    if (sp.ControlLineBoosterPump) {
                        PO.InsertPart(schema.groups.DualSprinklerPackage_DualSprinklerPackageTubing.groupId, "395997", 1); // CONTROL LINE-BP-PIVOTING MAXI
                    }
                    break;
                case DualSprinklerPackageControlTypes.Air:
                    PO.InsertPart(schema.groups.DualSprinklerPackage_DualSprinklerPackageCompressor.groupId, "352911", 1); // AIR COMP OPT-JENNY-TWR MNT
                    break;
            }
            this.Quote.SprinklerChartClass.RightSprinklersClass.WritePO(PO, true);
        }
    }
}