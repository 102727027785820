/* 
 * NOTE: 
 *   Can't easily use workers to calculate this context.
 *   Alot of the context contains functions which cant easily be posted from the worker
 * 
 * 
 * There has been an update made to this code as it was noticed that the ROE side was slowed down too much.
 * 
 * The code aims to:
 *  detect any change in a layout which will effect:
 *      - system geometry
 *      - elevations
 *      - system vlaidity
 *  So to not slow down ROE, the detection aims to determin if the geometry should be recalculated immediatley or delayed
 *  If immediate, the geometry is caclulated on the UI thread so to not cause the geometry to become out of sync
 *      the immediate actions are those the user makes in the map UI, for example:
 *          - moving center, switching layout, wraps and drop angles etc.
 *  Delayed actions are done via a worker so to not slow the UI down. These will be things such as:
 *          - adding spans etc
 * 
 * The elevation code is quite slow in large fields, as such this is always done in a worker with a delayed update sometimes rendered on screen
 * 
 * The module needs a good refactor. Elevataions need not updating unless a system changes. The geometry calculation could be faster etc.
 */

import * as turf from "@turf/turf";
import * as _ from "lodash";
import * as mapboxgl from "mapbox-gl";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { ISide } from "rdptypes/project/Types";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { createFieldSettingsBufferedObstacleFeatures, getLayoutDrawFeatures, getProjectDrawFeatures } from "../../components/LayoutMap/drawFeaturesFactory";
import Spinner from "../../components/Spinner";
import { createTimerLog } from "../../helpers/logging";
import ILayout from "../../model/project/ILayout";
import IProject, { ProjectType } from "../../model/project/IProject";
import ISystem from "../../model/project/ISystem";
import { SystemIrrigatedInfo } from "../IrrigatedAreaHelper";
import CenterPivotGeometryHelper from "../SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../SystemGeometryHelpers/LateralGeometryHelper";
import { SystemValidity } from "../SystemGeometryHelpers/interfaces";
import { IElevationPointFeature } from "./IElevationPointFeature";
import { mapElevationToFeature } from "./mapElevationToFeature";
import { IGeometryWorkerRequest, IGeometryWorkerResponse, IIahResult, IRecalculateCtxResult, IWorkerCenterPivotSystem, RESOLUTION_FEET, recaclulateCtx, recalculatePointClouds } from "./worker";

export interface ILateralElevation {
    type: "lateral",
    feedLine: ILineElevation,
    system: {
        max: IElevationPointFeature,
        feed: IElevationPointFeature
    }
}
export interface ICenterPivotElevation {
    type: "centerPivot",
    center: IElevationPointFeature;
    system: {
        min: IElevationPointFeature,
        max: IElevationPointFeature,
    }
}
export interface ILineElevation {
    min: IElevationPointFeature,
    max: IElevationPointFeature,
    start: IElevationPointFeature,
    end: IElevationPointFeature,
}
interface ISystemElevations {
    [ systemId: string ]: {
        loading: boolean;
        systemElevation: ICenterPivotElevation | ILateralElevation | null;
    }
}
export interface ILayoutElevation {
    loading: boolean;
    available: boolean;
    systems: ISystemElevations,
    getLine: (line: turf.LineString) => null | ILineElevation;
    getPoint: (point: turf.Point) => null | IElevationPointFeature;
    getPosition: (position: turf.Position) => null | IElevationPointFeature;
};
export interface IGeometryCtx {
    project: {
        drawFeatures: turf.Feature[];
    },
    layout: {
        drawFeatures: turf.Feature[];
    },
    system: {
        drawFeatures: (activeSystemIds?: string[], drawMode?: string) => turf.Feature[];
        geometryHelpers: {
            [ systemId: string ]: (
                {
                    type: "centerPivot",
                    active: CenterPivotGeometryHelper,
                    inactive: CenterPivotGeometryHelper
                } | {
                    type: "lateral",
                    active: LateralGeometryHelper,
                    inactive: LateralGeometryHelper,
                }
            ) & {
                systemValidity: SystemValidity,
                bufferedObstacleFeatures: turf.Feature[],
                irrigatedInfo: SystemIrrigatedInfo
            }
        }
    },
    elevation: ILayoutElevation;
    iahResult: IIahResult | null
}
export const GeometryCtx = React.createContext<IGeometryCtx | null>(null);

interface Props {
    project: IProject;
    layoutId: string;
}

const createEmptyCtx = (): IGeometryCtx => ({
    project: {
        drawFeatures: []
    },
    layout: {
        drawFeatures: []
    },
    system: {
        drawFeatures: () => [],
        geometryHelpers: {},
    },
    elevation: {
        loading: true,
        available: false,
        systems: {},
        getLine: (line) => null,
        getPoint: (point) => null,
        getPosition: (position) => null
    },
    iahResult: null
});

const generateInitialCtxFromWorkerSystemsResponse = (project: IProject, layoutId: string, result: IRecalculateCtxResult) => {
    if (!project?.layouts[layoutId]) return null;
    const t = createTimerLog("generateInitialCtxFromWorkerSystemsResponse")
    const newValue = createEmptyCtx();
    if (project?.projectType === ProjectType.LayoutAndDesign) {
        newValue.project.drawFeatures = getProjectDrawFeatures(project);
        newValue.layout.drawFeatures = getLayoutDrawFeatures(project, layoutId);
        const layout = project.layouts[layoutId];
        for (const [ systemId, system ] of Object.entries(layout.systems)) {
            const ss = result.systems[systemId];
            switch (system.SystemProperties.SystemType) {
                case SystemTypes.CenterPivot: {
                    const activeSystem = new CenterPivotGeometryHelper(
                        {
                            layoutId,
                            systemId, 
                            project, 
                        },
                        {
                            isActive: true
                        }
                    );
                    const inactiveSystem = new CenterPivotGeometryHelper(
                        {
                            layoutId,
                            systemId, 
                            project, 
                        },
                        {
                            isActive: false
                        }
                    );
                    const systemValidity = ss.systemValidity;
                    const areaPolygon = ss.areaPolygon;
                    newValue.system.geometryHelpers[systemId] = {
                        type: 'centerPivot',
                        active: activeSystem,
                        inactive: inactiveSystem,
                        systemValidity,
                        bufferedObstacleFeatures: areaPolygon
                            ? createFieldSettingsBufferedObstacleFeatures(areaPolygon, project, { systemId })
                            : [],
                        irrigatedInfo: ss.irrigatedInfo
                    }
                    break;
                }
                case SystemTypes.CanalFeedMaxigator:     
                case SystemTypes.HoseFeedMaxigator: {
                    const activeSystem = new LateralGeometryHelper(
                        {
                            layoutId,
                            systemId, 
                            project, 
                        },
                        {
                            isActive: true
                        }
                    );
                    const inactiveSystem = new LateralGeometryHelper(
                        {
                            layoutId,
                            systemId, 
                            project, 
                        },
                        {
                            isActive: false
                        }
                    );
                    const systemValidity = ss.systemValidity;
                    const areaPolygon = ss.areaPolygon;
                    newValue.system.geometryHelpers[systemId] = {
                        type: 'lateral',
                        active: activeSystem,
                        inactive: inactiveSystem,
                        systemValidity,
                        bufferedObstacleFeatures: areaPolygon
                            ? createFieldSettingsBufferedObstacleFeatures(areaPolygon, project, { systemId })
                            : [],
                            irrigatedInfo: ss.irrigatedInfo
                    }
                    break;
                }
            }
        }
        newValue.system.drawFeatures = (activeSystemIds = undefined, drawMode) => {
            const t = createTimerLog("GeometryCtx: newValue.system.drawFeatures")
            const f: turf.Feature[] = [];
            for (const [ systemId, ghObj ] of Object.entries(newValue.system.geometryHelpers)) {
                let isActive = typeof activeSystemIds === "undefined" || activeSystemIds.indexOf(systemId) !== -1;
                const gh = isActive ? ghObj.active : ghObj.inactive;
                f.push(...gh.getDrawFeatures(drawMode).map(x => {
                    x.properties.validity = ghObj.systemValidity;
                    return x;
                }));
                f.push(...ghObj.bufferedObstacleFeatures);
            }
            t.logS();
            return f;
        }
        newValue.iahResult = {
            layoutArea: result.iahResult.layoutArea,
            fieldAcres: result.iahResult.fieldAcres
        }
    }
    t.logMS();
    return newValue;
}
const minElevationPointFeature = (f1: IElevationPointFeature, f2: IElevationPointFeature): IElevationPointFeature => {
    if (f1.properties.elevationMeters === null) return f2;
    if (f2.properties.elevationMeters === null) return f1;
    return f2.properties.elevationMeters < f1.properties.elevationMeters
        ? f2
        : f1;
}
const maxElevationPointFeature = (f1: IElevationPointFeature, f2: IElevationPointFeature): IElevationPointFeature => {
    if (f1.properties.elevationMeters === null) return f2;
    if (f2.properties.elevationMeters === null) return f1;
    return f2.properties.elevationMeters > f1.properties.elevationMeters
        ? f2
        : f1;
}
const addRelvativeElevations = (f: IElevationPointFeature, center: IElevationPointFeature) => {
    const e = f.properties.elevationMeters;
    const eCenter = center.properties.elevationMeters;
    f.properties.relativeToCenter = (e && eCenter)
        ? e - eCenter
        : null;
}
const getSystemElevations = (map: mapboxgl.Map, points: turf.Feature<turf.Point>[]) :{
    min: IElevationPointFeature;
    max: IElevationPointFeature;
} | null => {
    if (!points.length || points.length > 250000) return null;
    let min = mapElevationToFeature(map, points[0].geometry.coordinates);
    let max = mapElevationToFeature(map, points[0].geometry.coordinates);
    // lets only calculate elevation min/max if < 250,000 points to check
    for (const p of points) {
        const current = mapElevationToFeature(map, p.geometry.coordinates);
        min = minElevationPointFeature(min, current);
        max = maxElevationPointFeature(max, current);
    }
    return {
        min, max
    }
}
const getLateralElevations = (map: mapboxgl.Map, gh: LateralGeometryHelper): ILateralElevation | null => {
    const data = gh.calculateElevationProfile(map);
    if (!data.length) return null;

    let systemMax: null | { difference: number, feedFeature: IElevationPointFeature, systemFeature: IElevationPointFeature } = null;
    const feedLineStart = data[0].feedLineElevationFeature;
    const feedLineEnd = data.slice(-1)[0].feedLineElevationFeature;
    let feedLineMin = feedLineStart;
    let feedLineMax = feedLineStart;
    for (const feedLineOffset of data) {
        const feedLineElevation = feedLineOffset.feedLineElevationFeature.properties.elevationMeters;
        if (feedLineElevation === null) continue;
        let max = feedLineOffset.feedLineElevationFeature;
        for (const radiusOffset of [ ...feedLineOffset.flangedSide, ...feedLineOffset.flexSide ]) {
            const radiusOffsetElevation = radiusOffset.feedLineElevationFeature.properties.elevationMeters;
            if (!radiusOffsetElevation) continue;
            if (radiusOffsetElevation > max.properties.elevationMeters) {
                max = radiusOffset.feedLineElevationFeature;
            }
        }

        const diffCenterToMax = max.properties.elevationMeters - feedLineElevation;
        if (!systemMax || diffCenterToMax > systemMax.difference) {
            systemMax = {
                difference: diffCenterToMax,
                feedFeature: feedLineOffset.feedLineElevationFeature,
                systemFeature: max
            }
        }

        if (feedLineElevation < feedLineMin.properties.elevationMeters) {
            feedLineMin = feedLineOffset.feedLineElevationFeature;
        }
        if (feedLineElevation > feedLineMax.properties.elevationMeters) {
            feedLineMax = feedLineOffset.feedLineElevationFeature;
        }
    }

    addRelvativeElevations(systemMax.systemFeature, systemMax.feedFeature);
    addRelvativeElevations(systemMax.feedFeature, systemMax.feedFeature);
    addRelvativeElevations(feedLineStart, feedLineMin);
    addRelvativeElevations(feedLineEnd, feedLineMin);
    addRelvativeElevations(feedLineMin, feedLineMin);
    addRelvativeElevations(feedLineMax, feedLineMin);

    return {
        type: 'lateral',
        system: {
            max: systemMax.systemFeature, 
            feed: systemMax.feedFeature
        },
        feedLine: {
            start: feedLineStart,
            end: feedLineEnd,
            min: feedLineMin,
            max: feedLineMax,
        }
    }

}
const getCenterPivotElevations = (map: mapboxgl.Map, centerPoint: turf.Point, points: turf.Feature<turf.Point>[]): ICenterPivotElevation | null => {
    const center = mapElevationToFeature(map, centerPoint.coordinates);
    addRelvativeElevations(center, center);    
    const sysElevations = getSystemElevations(map, points);
    let min: IElevationPointFeature | null = null;
    let max: IElevationPointFeature | null = null;
    if (sysElevations) {
        min = sysElevations.min;
        max = sysElevations.max;
        addRelvativeElevations(min, center);
        addRelvativeElevations(max, center);
    }

    return {
        type: 'centerPivot',
        system: {
            min, max
        },
        center
    }
}
const getLineElevations = (map: mapboxgl.Map, line: turf.LineString): null | ILineElevation => {
    if (line.coordinates.length < 2) return null;
    const lineStart = mapElevationToFeature(map, line.coordinates[0]);
    const lineEnd = mapElevationToFeature(map, line.coordinates.slice(-1)[0]);
    let lineMin = mapElevationToFeature(map, line.coordinates[0]);
    let lineMax = mapElevationToFeature(map, line.coordinates[0]);
    const feedLineLength = turf.length(turf.feature(line), { units: 'feet' });
    for (let i = 0; i < feedLineLength; i += RESOLUTION_FEET) {
        const p = turf.along(line, i, { units: 'feet' });
        const current = mapElevationToFeature(map, p.geometry.coordinates);
        lineMin = minElevationPointFeature(lineMin, current);
        lineMax = maxElevationPointFeature(lineMax, current);
    }

    addRelvativeElevations(lineStart, lineStart);
    addRelvativeElevations(lineEnd, lineStart);
    addRelvativeElevations(lineMin, lineStart);
    addRelvativeElevations(lineMax, lineStart);
    return {
        start: lineStart, end: lineEnd, min: lineMin, max: lineMax
    }
}
const initMapAndElevations = async (partialCtx: IGeometryCtx, map: mapboxgl.Map) => {
    if (!map) return null;
    const boundaryFeature = partialCtx.layout.drawFeatures.find(x => x.properties?.rdpFeatureType === 'fieldBoundary');
    if (!boundaryFeature) return null;
    const [ lng, lat ] = turf.center(boundaryFeature).geometry.coordinates;
    map.setCenter({ lng, lat });
    map.setZoom(14);
    await map.once("idle");

    return {
        getLine: (line: turf.LineString) => getLineElevations(map, line),
        getPoint: (point: turf.Point) => mapElevationToFeature(map, point.coordinates),
        getPosition: (position: turf.Position) => mapElevationToFeature(map, position)
    }
}

enum EDifferenceType {
    NONE, IMMEDIATE, DELAYED
}
const layoutsDiffer = (prevProject: IProject, nextProject: IProject, layoutId: string): EDifferenceType => {
    if (!prevProject && !nextProject) return EDifferenceType.NONE;
    if (!prevProject && nextProject) return EDifferenceType.IMMEDIATE;
    if (prevProject && !nextProject) return EDifferenceType.IMMEDIATE;

    // project specific:
    if (prevProject.systemClearance !== nextProject.systemClearance) return EDifferenceType.IMMEDIATE;

    // layout specific:
    const prevLayout = prevProject.layouts[layoutId];
    const nextLayout = nextProject.layouts[layoutId];
    if (!prevLayout && !nextLayout) return EDifferenceType.NONE;
    if (!prevLayout && nextLayout) return EDifferenceType.IMMEDIATE;
    if (prevLayout && !nextLayout) return EDifferenceType.IMMEDIATE;

    {
        const getImmidiateLayoutEntries = (layout: ILayout) => {
            return [
                layout.annotations,
                layout.fieldBoundary,
                layout.measurements,
                layout.obstacles,
                layout.pivotCenterBoundary,
                layout.wetAreaBoundary,
                layout.wheelObstacles
            ]
        }
        if (!_.isEqual(getImmidiateLayoutEntries(prevLayout), getImmidiateLayoutEntries(nextLayout))) return EDifferenceType.IMMEDIATE;
    }

    // system specific:
    const prevSystemIds = Object.keys(prevLayout.systems);
    const nextSystemIds = Object.keys(nextLayout.systems);
    if (!_.isEqual(prevSystemIds, nextSystemIds)) return EDifferenceType.IMMEDIATE;
    let runDelayed = false;
    for (const systemId of nextSystemIds) {
        const getSystemEntriesForDelayed = (system: ISystem) => {
            const delayed: any[] = [];
            
            delayed.push(system.Circle?.SwingArm?.LeadingSwingArm);

            delayed.push(system.Lateral?.CanalFeed);
            delayed.push(system.Lateral?.HoseFeed);
            delayed.push(system.Lateral?.WaterFeed);

            delayed.push(system.endGuns);
            delayed.push(system.flexIsFwd);
            delayed.push(system.overlapping);
            delayed.push(system.pathData);
            delayed.push(system.sacOptimizerResult);

            const addSideProperties = (side: ISide) => {
                if (!side) return;
                delayed.push(side.EndOfSystem?.EndGun);
                delayed.push(side.EndOfSystem?.EndOfSystemType);
                delayed.push(side.EndOfSystem?.SwingArmLength);
                side.Span?.forEach(s => {
                    delayed.push(s.Disconnecting);
                    delayed.push(s.EndBoom);
                    delayed.push(s.Extension);
                    delayed.push(s.Length);
                    delayed.push(s.SwingArm);
                });
                side.Tower?.forEach(s => {
                    delayed.push(s.WrapAroundSpan);
                });
            }
            addSideProperties(system.FlangedSide);
            addSideProperties(system.FlexSide);
            return delayed;
        }

        const compareSystemsForImmediate = (system1: ISystem, system2: ISystem) => {
            if (!_.isEqual(system1.Circle?.CenterPivot?.isPartialPivot, system2.Circle?.CenterPivot?.isPartialPivot)) return true;
            if (!_.isEqual(system1.centerPivot, system2.centerPivot)) return true;
            if (!_.isEqual(system1.lateral, system2.lateral)) return true;
            if (!_.isEqual(system1.lateral, system2.lateral)) return true;
            if (!_.isEqual(system1.Circle?.CenterPivot?.clockwiseCompassHeadingEnd, system2.Circle?.CenterPivot?.clockwiseCompassHeadingEnd)) return true;
            if (!_.isEqual(system1.Circle?.CenterPivot?.clockwiseCompassHeadingStart, system2.Circle?.CenterPivot?.clockwiseCompassHeadingStart)) return true;
            if (!_.isEqual(system1.Lateral?.DropFlexSide, system2.Lateral?.DropFlexSide)) return true;
            if (!_.isEqual(system1.Lateral?.dropSpanEndRelativeToPreviousSpanEnd, system2.Lateral?.dropSpanEndRelativeToPreviousSpanEnd)) return true;
            if (!_.isEqual(system1.Lateral?.dropSpanStartRelativeToPreviousSpanStart, system2.Lateral?.dropSpanStartRelativeToPreviousSpanStart)) return true;
            
            const considerSide = (side1: ISide, side2: ISide) => {
                // we will only act immidiatley if the number of spans match:
                if (side1?.Span?.length && side1?.Span?.length === side2?.Span?.length) {
                    for (let i = 0; i < side1.Span.length; i++) {
                        const s1 = side1.Span[i];
                        const s2 = side2.Span[i];
                        if (!_.isEqual(s1.dropSpanEndRelativeToPreviousSpanEnd, s2.dropSpanEndRelativeToPreviousSpanEnd)) return true;
                        if (!_.isEqual(s1.dropSpanStartRelativeToPreviousSpanStart, s2.dropSpanStartRelativeToPreviousSpanStart)) return true;
                    }
                }
                // we will only act immidiatley if the number of towers match:
                if (side1?.Tower?.length && side1?.Tower?.length === side2?.Tower?.length) {
                    for (let i = 0; i < side1.Tower.length; i++) {
                        const s1 = side1.Tower[i];
                        const s2 = side2.Tower[i];
                        if (!_.isEqual(s1.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees, s2.anticlockwiseWrapAngleRelativeToPreviousSpanDegrees)) return true;
                        if (!_.isEqual(s1.clockwiseWrapAngleRelativeToPreviousSpanDegrees, s2.clockwiseWrapAngleRelativeToPreviousSpanDegrees)) return true;
                    }
                }

            }
            if (considerSide(system1.FlangedSide, system2.FlangedSide)) return true;
            if (considerSide(system1.FlexSide, system2.FlexSide)) return true;            
        }

        if (compareSystemsForImmediate(prevLayout.systems[systemId], nextLayout.systems[systemId])) {
            return EDifferenceType.IMMEDIATE;
        }

        runDelayed = runDelayed || (
            !_.isEqual(
                getSystemEntriesForDelayed(prevLayout.systems[systemId]),
                getSystemEntriesForDelayed(nextLayout.systems[systemId])
            )
        )
    }

    return runDelayed ? EDifferenceType.DELAYED : EDifferenceType.NONE;
}


export const GeometryProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, project, layoutId }) => {

    const [ value, setValue ] = React.useState<IGeometryCtx | null>(null);
    const currentAsyncTaskId = React.useRef<string | null>(null);
    const [map, setMap] = React.useState<mapboxgl.Map | undefined>(undefined);
    const mapContainer = React.useRef<HTMLDivElement | null>(null);
    const [layoutDoesNotExist, setLayoutDoesNotExist] = React.useState(
        project?.layouts
            ? !(layoutId in project.layouts)
            : true
    );
    const prevProjectLayout = React.useRef<{ project: IProject, layoutId: string }>({ project: null, layoutId: null });
    const [workers,setWorkers] = React.useState<Worker[]>([]);
    const iWorker = React.useRef(0);

    const handleWorkerSystems = async (project: IProject, layoutId: string, result: IRecalculateCtxResult | null): Promise<{
        doElevations: false
    } | {
        doElevations: true,
        systems: IWorkerCenterPivotSystem[]
    }> => {
        const systemIds = Object.keys(project.layouts[layoutId]?.systems || {});
        const initialCtx = generateInitialCtxFromWorkerSystemsResponse(project, layoutId, result);
        console.log("GeometryCtx: setting initial ctx");
        setValue(initialCtx);
        if (!initialCtx) {
            console.log("GeometryCtx: layout does not exist, setting layout does not exist");
            setLayoutDoesNotExist(true);
            return { doElevations: false };
        }
    
        console.log("GeometryCtx: initializing map to field location");
        const elevationFns = await initMapAndElevations(initialCtx, map);
        if (!elevationFns) {
            console.log("GeometryCtx: elevation functions unavailable");
            setValue({
                ...initialCtx,
                elevation: {
                    ...initialCtx.elevation,
                    loading: false,
                    available: false,
                }
            });
            return { doElevations: false };
        }
        else {
            console.log("GeometryCtx: calculating elevations using worker");
            const systemElevations: ISystemElevations = {};
            for (const systemId of systemIds) {
                systemElevations[systemId] = {
                    loading: true,
                    systemElevation: null
                }
            }
            setValue({
                ...initialCtx,
                elevation: {
                    loading: false,
                    available: true,
                    systems: systemElevations,
                    getLine: elevationFns.getLine,
                    getPoint: elevationFns.getPoint,
                    getPosition: elevationFns.getPosition,
                }
            });
    
            const centerPivotSystems = Object.entries(initialCtx.system.geometryHelpers)
                .filter(([_, gh]) => gh.type === 'centerPivot')
                .map(([systemId]) => ({ systemId, polygon: result.systems[systemId].areaPolygon ? turf.feature(result.systems[systemId].areaPolygon) : undefined }));
            return { doElevations: true, systems: centerPivotSystems };
        }
    }

    React.useEffect(() => {
        const nextWorkers: Worker[] = [];
        const nWorkers = navigator.hardwareConcurrency ?? 8;
        for (let i = 0; i < nWorkers; i++) {
            const w = new Worker(
                new URL("./worker.ts", import.meta.url),
                { type: "module" }
            );
            nextWorkers.push(w);

        }
        setWorkers(nextWorkers);
    
        // Clean up the worker when the component unmounts
        return () => {
          nextWorkers.forEach(x => x.terminate());
        };
      }, []); // Run this effect only once when the component mounts

    const handlePointCloudResults = (systemIds: string[], systems: {
        [systemId: string]: {
            points: turf.Feature<turf.Point>[];
        };
    }) => {
        const t = createTimerLog("GeometryCtx: pointClouds message")
        setValue(prev => {
            const next = { 
                ...prev,
                elevation: {
                    ...prev.elevation,
                    systems: {
                        ...prev.elevation.systems
                    }
                }
            };
             
            for (const systemId of systemIds) {
                next.elevation.systems[systemId] = {
                    loading: false,
                    systemElevation: null
                }
                const gh = prev.system.geometryHelpers[systemId];
                if (gh?.type === 'centerPivot') {
                    const points = systems[systemId]?.points;
                    if (points?.length) {
                        const center = gh.inactive.center;
                        next.elevation.systems[systemId].systemElevation = getCenterPivotElevations(map, center, points);
                    }
                }
                else {
                    // the lateral geometry calculation is fast enough without a worker
                    next.elevation.systems[systemId].systemElevation = getLateralElevations(map, gh.inactive);
                }
            }

            t.logS();
            return next;
        });
    }
    const setWorkerOnMessages = () => {
        for (let iw = 0; iw < workers.length; iw++) {
            const w = workers[iw];
            w.onmessage = async (ev: MessageEvent<IGeometryWorkerResponse>) => {
                console.log("GeometryCtx w:", iw, ev.data, currentAsyncTaskId.current);
                if (ev.data.id !== currentAsyncTaskId.current) {
                    console.log("GeometryCtx w:", iw, "current task id does not match");
                }
                if (ev.data.type === 'geometry') {
                    const { result } = ev.data;
                    const preElevation = await handleWorkerSystems(project, layoutId, result);
                    console.log("GeometryCtx: pre", preElevation)
                    if (preElevation.doElevations) {
                        const i = iWorker.current < workers.length - 1 ? iWorker.current + 1 : 0;
                        iWorker.current = i;
                        const worker = workers[i];
                        worker.postMessage({
                            id: currentAsyncTaskId.current,
                            type: 'pointClouds',
                            systems: preElevation.systems
                        } as IGeometryWorkerRequest);
                    }
                }
                else if (ev.data.type === 'pointClouds') {
                    const { systems } = ev.data;
                    const systemIds = Object.keys(project.layouts[layoutId]?.systems || {});
                    handlePointCloudResults(systemIds, systems);
                }
            }
        }
    }
      
    React.useEffect(() => {
        console.log("GeometryCtx: recalculating geometries");
        if (!map) {
            setValue(null);
            return;
        }
        const t = createTimerLog("GeometryCtx: layoutsDiffer");
        const updatedNeeded = prevProjectLayout.current.layoutId !== layoutId
            ? EDifferenceType.IMMEDIATE
            : layoutsDiffer(prevProjectLayout.current.project, project, layoutId);
        prevProjectLayout.current = { project: structuredClone(project), layoutId };
        t.logS();


        if (updatedNeeded === EDifferenceType.NONE) {
            console.log("GeometryCtx: not updating");
            return;
        }

        const id = uuidv4();
        currentAsyncTaskId.current = id;
        setWorkerOnMessages();

        setLayoutDoesNotExist(false);
        if (updatedNeeded === EDifferenceType.IMMEDIATE || workers.length === 0) {
            console.log("GeometryCtx: update immediate");
            const result = recaclulateCtx(project, layoutId);
            const runImmediate = async () => {
                const preElevation = await handleWorkerSystems(project, layoutId, result);
                console.log("GeometryCtx: pre", preElevation)
                if (preElevation.doElevations) {
                    if (workers.length !== 0) {
                        const i = iWorker.current < workers.length - 1 ? iWorker.current + 1 : 0;
                        iWorker.current = i;
                        const worker = workers[i];
                        worker.postMessage({
                            id: currentAsyncTaskId.current,
                            type: 'pointClouds',
                            systems: preElevation.systems
                        } as IGeometryWorkerRequest);        
                    }
                    else {
                        const pc = recalculatePointClouds(preElevation.systems);
                        const systemIds = Object.keys(project.layouts[layoutId]?.systems || {});
                        handlePointCloudResults(systemIds, pc);
                    }
                }
            }
            runImmediate();
        }
        else {
            console.log("GeometryCtx: update delayed");
            let i = iWorker.current;
            iWorker.current = i < workers.length - 1 ? i + 1 : 0;
            const worker = workers[i];
            worker.postMessage({
                type: 'geometry',
                id,
                project,
                layoutId
            } as IGeometryWorkerRequest);
        }

    }, [ layoutId, JSON.stringify(project), map, workers ]);
    

    React.useEffect(() => {        
        // we can only get elevation if the map container is defined
        if (!mapContainer.current) {
            console.log("There is no map container defined");
            return;
        }

        const initializeMap = async () => {
            const insideMap = new mapboxgl.Map({
                container: mapContainer.current,
                zoom: 14
            });
            insideMap.addSource("mapbox-dem", {
                type: "raster-dem",
                url: "mapbox://mapbox.mapbox-terrain-dem-v1",
                tileSize: 512,
                maxzoom: 14
            });
            insideMap.setTerrain({ source: "mapbox-dem" });
            await insideMap.once("idle");
            map?.remove();
            setMap(insideMap);
            console.log(
                "NOTE: elevation only available in map bounds using this method"
            );
            return insideMap;
        };
        const mapPromise = initializeMap();


        return () => {
            mapPromise.then(mp => {
                mp?.remove();
            })
        }
    }, [ mapContainer ]);

    return (
        <GeometryCtx.Provider value={value}>
            {
                layoutDoesNotExist
                    ? <div>Layout does not exist in this project</div>
                    : value === null
                        ? <Spinner title="Loading..." />
                        : children
            }
            <div ref={mapContainer} style={{ display: 'none' }} />
        </GeometryCtx.Provider>
    )
}
