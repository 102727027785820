import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import i18next, { t } from "i18next";
import IComplexChoice from "rdptypes/roe/IComplexChoice";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { createNewMultiAction } from "../../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../../actions/UpdateSystemProperty";
import AuthCtx from "../../../auth/AuthCtx";
import DevSettingsCtx from "../../../db/DevSettingsCtx";
import IDbProject from '../../../db/IDbProject';
import { forceRoeDisabled } from "../../../helpers/configrationConstants";
import { getValue } from "../../../helpers/objectPathResolver";
import { translate, translateUnit } from "../../../helpers/translation";
import { pageStyles, primaryColor, secondaryColor } from "../styles";
import { SystemValidationResult } from "../validation/SystemValidator";
import IComponentRenderContext from "./IComponentRenderContext";
import { getComponentRenderer } from "./componentRendererFactory";

interface Props {
  cmp: IComplexChoice,
  dbPrj: IDbProject,
  layoutId: string,
  systemId: string,
  ctx: IComponentRenderContext,
  parentGroupLabel: string | null, //either the same as the current component, don't show title; different from parent, do show title in grey; null, not in a group so display in blue
  small?: boolean;
};

const OPTION_CUSTON_VALUE_DUMMY_VALUE = "__OPTION_CUSTON_VALUE__";

const ComplexChoiceRenderer: FC<Props> = (props) => {
  const authState = useContext(AuthCtx);
  
  const [ showCustomDialog, setShowCustomDialog ] = useState(false);
  const handleCustomDialogOK = () => {
    setShowCustomDialog(false);
  }


  const settings = React.useContext(DevSettingsCtx);
  const displaySettings = settings.dealerSettings.display.current;
  const project = props.dbPrj.state;
  const layout = project.layouts[props.layoutId];
  const system = layout.systems[props.systemId];

  let showLabel = props.parentGroupLabel !== props.cmp.title;

  // We use JSON.stringify(cmp.options) to identify this component. The validator must use the same.
  const fieldPath = props.ctx.fieldRoot + JSON.stringify(props.cmp.options);

  const [ fullValidationResult, setFullValidationResult ]
    = useState<SystemValidationResult | undefined>(undefined);
  const svr = fullValidationResult ?? props.ctx.vws.validationResult;
  const fvr = svr?.getField(fieldPath);
  let errorSeverity = fvr?.severity;

  

    let sx = showLabel ? (props.parentGroupLabel === null ? {marginLeft: props.small ? "4px" : "10px", marginRight: props.small ? "4px" : "10px"} :{}) : {
      '& .MuiSelect-select .notranslate::after':  translate(props.cmp)
          ? {
              content: `"${ translate(props.cmp)}"`,
              opacity: 0.42,
            }
          : {},
    };

    let options = [ ...props.cmp.options ];
    if (props.ctx.editingDealerDefaults) {
      const allUndefined = {};
      for (const opt of options) {
        for (const key of Object.keys(opt.values)) {
          allUndefined[key] = undefined;
        }
      }
      options = [ {
        title: "Select (No Default)",
        values: allUndefined
      }, ...options];
    }

    const getCurrentValue = () => {
      const currentValue = options.findIndex(opt => 
        Object.keys(opt.values).every(fieldPath => 
          getValue(system, props.ctx.fieldRoot + fieldPath) === opt.values[fieldPath]));
    
        
        if (currentValue !== -1) return currentValue;
        if (props.cmp.customValueOption) {
          return OPTION_CUSTON_VALUE_DUMMY_VALUE;
        }

        return -1;
    }
    
    let formInputLabelStyle = {...pageStyles.formInputLabelStyle};
    if (!errorSeverity){
      formInputLabelStyle["color"] = primaryColor;
    }
    if (props.parentGroupLabel === null)
    {
        formInputLabelStyle["marginLeft"] = props.small ? "4px" : "10px";
        formInputLabelStyle["marginRight"] = props.small ? "4px" :"10px";
    }
    else if (!errorSeverity && props.parentGroupLabel !== props.cmp.title)
    {
        formInputLabelStyle["color"] = secondaryColor;
    }
  
    const [ selectOpen, setOpen ] = React.useState(false);
    const handleClose = () => {
      setFullValidationResult(undefined);
      setOpen(false);
    };
  
    const handleOpen = () => {
      setFullValidationResult(
        props.ctx.vws.validateCmp(system, props.cmp, props.ctx.fieldRoot, ""));
      setOpen(true);
    };

  return (
      <FormControl variant="standard" sx={{ m: 1, width: '100%', margin: 0, maxWidth: props.cmp.maxWidth }} error={!!errorSeverity}>
        <InputLabel shrink={true} style={{...formInputLabelStyle}}>{translate(props.cmp)}</InputLabel>
        <Select sx={{...sx, marginTop: !props.cmp.title ? '0px !important' : 'unset', color: (errorSeverity === "error" || errorSeverity === "notset") ? "red": undefined, fontSize: props.small ? "12px" : "1rem"}}
          labelId="demo-multiple-checkbox-label"
          id="multiple-with-checkboxes"
          value={getCurrentValue()}
          open={selectOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          
          onChange={(ev) => {
            const selectedOption = options[ev.target.value];

            if (!selectedOption) {
              return;
            }

            props.ctx.pushActionAndImproveScores(createNewMultiAction(props.ctx.changeFieldRoots.flatMap(fieldRoot => {
              const actions = Object.keys(selectedOption.values).map(fieldPath =>
              createNewUpdateSystemPropertyAction(
              props.layoutId,
              props.systemId,
              fieldRoot + fieldPath,
              selectedOption.values[fieldPath],
              authState
            ));

            const hookResult = props.cmp.hook?.(system) || [];
            for (const r of hookResult) {
                actions.push(
                    createNewUpdateSystemPropertyAction(
                        props.layoutId,
                        props.systemId,
                        fieldRoot + r.fieldPath,
                        r.value,
                        authState,
                    )
                )
            }
            return actions;

            return actions;
              }), authState),
              props.ctx.changeFieldRoots.flatMap(fieldRoot =>
                Object.keys(selectedOption.values).map(fieldPath =>fieldRoot + fieldPath)),
              true);
          }}
          renderValue={(v) => {
            let renderValue: string;
            if (v === -1) {
              renderValue = "Select";
            }
            else if (v === OPTION_CUSTON_VALUE_DUMMY_VALUE && props.cmp.customValueOption) {
              const customValue = getValue(system, props.ctx.fieldRoot + props.cmp.customValueOption.cmp.fieldPath);
              if (customValue === undefined) {
                renderValue =  "Select";
              }
              else {
                renderValue = customValue + "' custom";
              }
            }
            else {
              let opt = options[v];
              renderValue = translateUnit(opt, displaySettings);
            }

            if (props.cmp.maxWidth) {
              return (
                <Tooltip title={renderValue}>
                  <div
                    className="MuiSelect-select MuiSelect-standard MuiInputBase-input MuiInput-input css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input"
                    style={{fontSize: props.small ? "12px" : "1rem"
                  }}     
                  >
                    {renderValue}
                  </div>
                </Tooltip>
              )
            }
            return renderValue

          }} 
        >
           {options.map((opt, i) => {
            
            const optId = JSON.stringify(opt.values);
            const disabled = fvr?.badValues.has(optId);
            let toolTipText = (fvr?.badValues.get(optId)?.reasons ?? []).join(", ");
            if (toolTipText !== "") {
              toolTipText = "Selecting this option would introduce validation errors: " + toolTipText;
            }

            return (<MenuItem
              key={i}
              value={i}
              disabled={forceRoeDisabled && disabled}
              style={{color: (!forceRoeDisabled && disabled) ? "lightgrey" : undefined}}
              title={toolTipText}
              >
              <ListItemText>{translateUnit(opt, displaySettings)}</ListItemText>
          </MenuItem>);
          })}
          {
            props.cmp.customValueOption && (
              <>
                <Divider />
                <MenuItem 
                  key={OPTION_CUSTON_VALUE_DUMMY_VALUE} 
                  value={OPTION_CUSTON_VALUE_DUMMY_VALUE} 
                  onClick={() => {
                    setShowCustomDialog(true);
                  }}
                >
                  <em>{i18next.format(t('custom'), 'capitalize')}...</em>
                </MenuItem>
              </>
            )
          }
        </Select>
      {
        showCustomDialog && props.cmp.customValueOption?.cmp && (
          <Dialog open={true}>
            <DialogTitle>{i18next.format(t('custom-value'), 'capitalize-each')}</DialogTitle>
            <DialogContent>
              <Box sx={{ width: "95%" }}>
                {
                  getComponentRenderer(
                    props.cmp.customValueOption.cmp, 
                    props.dbPrj, 
                    props.layoutId, 
                    props.systemId, 
                    props.ctx, 
                    props.parentGroupLabel
                  )
                }
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCustomDialogOK}>{t("ok")}</Button>
            </DialogActions>
          </Dialog>
        )
      }
      </FormControl>
  );
}

export default ComplexChoiceRenderer;