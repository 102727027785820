import { Box, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { ISprinklerPropertiesVRIZonesZone, ISystemBase } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import { IsDualSided } from "roedata/roe_migration/SystemFunctions";
import DevSettingsCtx from "../../../../db/DevSettingsCtx";
import { DisplayLengthUnitBuilder } from "../../../../helpers/lengths";
import Rectangle from "./Rectangle";

interface Props {
    zones: ISprinklerPropertiesVRIZonesZone[];
    system: ISystemBase;
};

const ZoneRendererRectangle: FC<Props> = (props) => {
    const displaySettings = React.useContext(DevSettingsCtx).dealerSettings.display.current;

    const maxWidth = 600;
    const maxHeight = 400;
    const lengthOfField = props.system.Lateral?.LengthOfField;

    const systemRenderingTrueHeight = props.zones.reduce((prev, crnt) => prev + crnt.Length, 0);
    const systemRenderingTrueWidth = lengthOfField;
    const systemRenderingTrueStartingLocation = props.zones.reduce((prev, crnt) => Math.min(prev, crnt.StartingLocation), 0);

    const scale = Math.min(
        maxHeight / systemRenderingTrueHeight, 
        maxWidth / systemRenderingTrueWidth
    )
    const width = systemRenderingTrueWidth * scale;
    const height = systemRenderingTrueHeight * scale;


    const rectangles: JSX.Element[] = props.zones.map((zone) => {
        const y = (zone.StartingLocation - systemRenderingTrueStartingLocation) * scale;
        const h = zone.Length * scale;
        return (
            <Rectangle 
                key={`zone-${zone.ZoneNumber}`}
                x={0}
                y={y}
                width={width}
                height={h}
                fill={zone.Controlled} 
            />
        );
    });
    rectangles.reverse();

    const leftWidth = 200;

    const lengthOfFieldLabel = new DisplayLengthUnitBuilder(lengthOfField, 'feet')
        .appendString(t("common-phrases.sltFieldLength"))
        .appendString(": ")
        .convert(displaySettings.lengths)
        .appendValue(1)
        .appendSymbol()
        .toString();
    const systemLengthLabel = new DisplayLengthUnitBuilder(systemRenderingTrueHeight, 'feet')
        .appendString(t("common-phrases.sltSystemLength"))
        .appendString(": ")
        .convert(displaySettings.lengths)
        .appendValue(1)
        .appendSymbol()
        .toString();

    return (
        <Box style={{textAlign: "center", marginTop: 20}}>
            <Stack direction={'column'} alignItems={'center'} >

                <Stack direction={'row'} gap={1}>
                    <div style={{ width: leftWidth }} />
                    <div style={{ width: width, textAlign: "left" }}>
                        <Typography fontSize={12}>{lengthOfFieldLabel}</Typography>
                    </div>
                </Stack>

                <Stack direction={'row'} gap={1}>
                    <div style={{ width: leftWidth, textAlign: "right" }}>
                        <Typography fontSize={12}>
                            {IsDualSided(props.system) && t("common-phrases.sltFlexEndofSystem")}
                            {!IsDualSided(props.system) && t("common-phrases.sltPowerTower")}                            
                        </Typography>
                    </div>
                    <div style={{ width: width }} />
                </Stack>

                <Stack direction={'row'} gap={1}>
                    <div style={{ width: leftWidth, alignContent: "center", textAlign: "right" }}>
                        <Typography fontSize={12}>{systemLengthLabel}</Typography>
                    </div>
                    <div style={{ width: width }}>
                        <svg height={height} width={width}>
                            {rectangles}
                        </svg>
                    </div>
                </Stack>

                <Stack direction={'row'} gap={1}>
                    <div style={{ width: leftWidth, textAlign: "right" }}>
                        <Typography fontSize={12}>{t("common-phrases.sltFlangedEndofSystem")}</Typography>
                    </div>
                    <div style={{ width: width, textAlign: "left" }} />
                </Stack>

            </Stack>
        </Box>
    );
  };

  export default ZoneRendererRectangle;