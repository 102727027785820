import { ElectricalFrequencies, EndTowerPositioningTypes, FlowmeterTypes, HoseFeedTypes, ISystemBase, PanelModels, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import { IRule } from "rdptypes/roe/IRule";
import { IsLateralOnly } from "../../../../../roe_migration/SystemFunctions";

export default {
    pages: [
        {
            changeRequiresNewSprinklers: true,
            id: "mainControlPanel",
            title: { "en" : "Main Control Panel", "es": "Panel de Control Principal"},
            position: 3,
            visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.SwingArmRetro && sys.SystemProperties.SystemType !== SystemTypes.Ancillary,
            components: [
                {
                    type: "choice",
                    fieldPath: "ControlPanel.PanelModel",
                    title:  { "en" : "Panel Type", "es": "Tipo de Panel"},
                    includeOnlyValidationRules: [
                        "Panel Type"
                    ],
                    options: [
                        { id: "RPMBasic", title: "RPM Basic", "es": "RPM Basico"},
                        { id: "RPMStandard", title:  { "en" :"RPM Standard"}, default: "true"},
                        { id: "RPMAdvanced", title:  { "en" :"RPM Advanced", "es": "RPM Avanzado"}},
                        { id: "RPMAdvancedPlus", title: "RPM Advanced Plus", default: "true"},
                        { id: "RPMConnect", title: "RPM Connect"},
                        { id: "RPMPreferred", title:  { "en" :"RPM Preferred Touch Screen", "es": "RPM Preferred con Touch Screen" }},
                    ],
                    enumNameHint: "PanelModels",
                    hook: (value: string) => {
                        if (value) {
                            return [
                                { fieldPath: "ControlPanel.IntlManual", value: undefined },
                                { fieldPath: "ControlPanel.CollectorRing", value: undefined },
                                { fieldPath: "ControlPanel.EndTowerPositioning", value: undefined },
                                { fieldPath: "ControlPanel.SwitchMount", value: undefined },
                                { fieldPath: "ControlPanel.Location", value: undefined },
                                { fieldPath: "ControlPanel.RemotePanelOptions.RemotePanel", value: false },
                                { fieldPath: "ControlPanel.RemoteMCPLessMount", value: false },
                                { fieldPath: "ControlPanel.RemotePanelOptions.RollsOfUnshieldedResolverWire", value: 0 },
                                { fieldPath: "ControlPanel.RemotePanelOptions.RollsOfShieldedResolverWire", value: 0 },
                                { fieldPath: "ControlPanel.TenFourPowerCord", value: 0 },
                                { fieldPath: "ControlPanel.FourteenTwoPowerCord", value: 0 },
                                { fieldPath: "ControlPanel.LowPressureSafetyLP", value: false },
                                { fieldPath: "ControlPanel.LowPressureSafetyReg", value: false },
                                { fieldPath: "ControlPanel.PressureTransducerOptions", value: undefined },
                                { fieldPath: "ControlPanel.PressureTransducer0to25PSI", value: false },
                                { fieldPath: "ControlPanel.FlowmeterModel", value: undefined },
                                { fieldPath: "ControlPanel.Flowmeter", value: undefined },
                                { fieldPath: "ControlPanel.FlowmeterPulse", value: false },
                                { fieldPath: "ControlPanel.FlowtubeWithVanes", value: false },
                                { fieldPath: "ControlPanel.FlowtubeWithFS100", value: false },
                                { fieldPath: "ControlPanel.LargeServiceWireKit", value: false },
                                { fieldPath: "ControlPanel.ChemAuxControl", value: false },
                                { fieldPath: "ControlPanel.InjectorPump", value: false },
                                { fieldPath: "ControlPanel.InjectorPump30Amp", value: false },
                                { fieldPath: "ControlPanel.PivotAutoStop", value: false },
                                { fieldPath: "ControlPanel.PivotAutoReverse", value: false },
                                { fieldPath: "ControlPanel.EndGunShutoffOverride", value: false },
                                { fieldPath: "ControlPanel.PowerAutoRestart", value: false },
                                { fieldPath: "ControlPanel.PressureRestart", value: false },
                                { fieldPath: "ControlPanel.LightningArrestor", value: false },
                                { fieldPath: "ControlPanel.LowVoltageSafety", value: false },
                                { fieldPath: "ControlPanel.PACTimer", value: false },
                                { fieldPath: "ControlPanel.NonFuseCarrier", value: false },
                                { fieldPath: "ControlPanel.MCPSpanCableDisconnect", value: false },
                                { fieldPath: "ControlPanel.FrostControl", value: false },
                                { fieldPath: "ControlPanel.RainGauge", value: false },
                                { fieldPath: "ControlPanel.WindGauge", value: false },
                                { fieldPath: "ControlPanel.TemperatureProbe", value: false },
                                { fieldPath: "ControlPanel.WeepHolePlug5Pack", value: false },
                                { fieldPath: "ControlPanel.WeepHolePlug50Pack", value: false },
                                { fieldPath: "ControlPanel.CartAutoReverse", value: false },
                                { fieldPath: "ControlPanel.PanelUL", value: false },
                                { fieldPath: "ControlPanel.TowerUL", value: false },
                                { fieldPath: "ControlPanel.GFI", value: false },
                                { fieldPath: "ControlPanel.GroundingBar", value: false },
                            ]
                        }
                    }
                },
                {
                    type: "choice",
                    fieldPath: "ControlPanel.IntlManual",
                    title:  { "en" :"Manual Language", "es": "Idioma del Manual"},
                    options: [
                        { id: "English", title:  { "en" :"English", "es": "Inglés"}, },  
                        { id: "Spanish", title:  { "en" :"Spanish", "es": "Español"} },  
                        { id: "Russian", title:  { "en" :"Russian", "es": "Ruso" }},
                    ],
                    enumNameHint: "Languages",
                },                
                {
                    type: "booleangroup",
                    layoutHint: "secondary",
                    disableDealerSettings: true,
                    components: [
                        { type: "boolean", title:  { "en" :"Large Service Wire Adapter Kit", "es": "Kit de Adaptación de cable de uso rudo"}, fieldPath: "ControlPanel.LargeServiceWireKit"},   
                        { type: "boolean", title:  { "en" :"Chem/Aux Control", "es": "Control de Quim/Aux"}, fieldPath: "ControlPanel.ChemAuxControl"},                       
                        { type: "boolean", title:  { "en" :"Injector Pump Receptacle (20 Amp)", "es": "Receptáculo Trifásico p/Bomba Inyectora (20 Amp)"}, fieldPath: "ControlPanel.InjectorPump"},
                        { type: "boolean", title:  { "en" :"Injector Pump Receptacle (30 Amp)", "es": "Receptáculo Trifásico p/Bomba Inyectora (30 Amp)"}, fieldPath: "ControlPanel.InjectorPump30Amp"},
                        { type: "boolean", title:  { "en" :"Pivot Auto Stop", "es": "Auto Stop en Pivote"}, fieldPath: "ControlPanel.PivotAutoStop" },                       
                        { type: "boolean", title:  { "en" :"Pivot Auto Reverse", "es": "Auto Reversa en Punto Pivote"}, fieldPath: "ControlPanel.PivotAutoReverse" },            
                        { type: "boolean", title:  { "en" :"End Gun Shutoff Override", "es": "Corte de Cañón Suprimido"}, fieldPath: "ControlPanel.EndGunShutoffOverride"},  
                        { type: "boolean", title: "Power Auto Restart", fieldPath: "ControlPanel.PowerAutoRestart"},                                  
                        { type: "boolean", title:  { "en" :"Pressure Auto Restart", "es": "Re-inicio por presión"}, fieldPath: "ControlPanel.PressureRestart", includeOnlyValidationRules: []},                       
                        { type: "boolean", title:  { "en" :"Lightning Arrestor", "es": "Pararayos"}, fieldPath: "ControlPanel.LightningArrestor"},                                            
                        { type: "boolean", title:  { "en" :"Low Voltage Safety (NOT to be used with Phase Converters)", "es": "Seguridad de Bajo Voltaje"}, fieldPath: "ControlPanel.LowVoltageSafety"},  
                        { type: "boolean", title: "PAC Timer", fieldPath: "ControlPanel.PACTimer"},                      
                        { type: "boolean", title:  { "en" :"Non Fuse Carrier Option for corner grounded delta electrical service", "es": "Sin Opción de Porta Fusible para una conexión delta aterrizada de esquinero"}, fieldPath: "ControlPanel.NonFuseCarrier"},                                                 
                        { type: "boolean", title: "MCP Span Cable Disconnect", fieldPath: "ControlPanel.MCPSpanCableDisconnect"},      
                        { type: "boolean", title:  { "en" :"Frost Control", "es": "Control de Heladas"}, fieldPath: "ControlPanel.FrostControl"},
                        { type: "boolean", title: "Rain Gauge", "es": "Pluviómetro", fieldPath: "ControlPanel.RainGauge"},   
                        { type: "boolean", title: "Wind Vane", "es": "Anemómetro", fieldPath: "ControlPanel.WindGauge"}, 
                        { type: "boolean", title:  { "en" :"Temperature Probe", "es": "Termostato"}, fieldPath: "ControlPanel.TemperatureProbe"},    
                        { type: "boolean", title: "Plug - Weep Hole (5 Pack)", fieldPath: "ControlPanel.WeepHolePlug5Pack"},        
                        { type: "boolean", title: "Plug - Weep Hole (50 Pack)", fieldPath: "ControlPanel.WeepHolePlug50Pack"}, 
                        { type: "boolean", title:  { "en" :"Cart Auto Reverse", "es": "Carro con Auto Reversa"}, fieldPath: "ControlPanel.CartAutoReverse"},    
                    
                    ],
                },  {
                    type: "card",
                    layoutHint: "secondary",
                    children: [
                        { type: "boolean", title:  { "en" :"MCP UL/C Certification (Req. in CO, ID, MN, OR, SD, WA & Canada)", "es": "Certificación UL/C (Req en CO, ID,MN,OR,SD,WA y Canada)"}, fieldPath: "ControlPanel.PanelUL"},   
                        { type: "boolean", title: "Tower Box UL/C Certification (Req. in WA & Canada)", "es": "Caja de Torre c/Certificación UL/C (Req. en WA y Cabada)", fieldPath: "ControlPanel.TowerUL"},   
                        { type: "boolean", title:  { "en" :"Ground Fault Interrupter (Req. in Canada)", "es": "Interruptor de Fallas a Tierra (Req. en Canada)"}, fieldPath: "ControlPanel.GFI"},   
                        { type: "boolean", title:  { "en" :"Grounding Bar/Ground Rod Kit (Req. in WA)", "es": "Barra de Tierra para tramos (Req. en WA)"}, fieldPath: "ControlPanel.GroundingBar"}, 
                    ]
                },         
                {
                    type: "choice",
                    fieldPath: "ControlPanel.CollectorRing",
                    title: "Collector Reel",
                    options: [
                        { id: "None", title:  { "en" :"None", "es": "Ninguno"}},
                        { id: "a10Ring", title:  { "en" :"10 Ring United", "es": "10 Anillos United"}, },
                        { id: "a11RingICII", title:  { "en" :"11 Ring ICII", "es": "11 Anillos ICII"}},
                        { id: "a11RingUnited", title:  { "en" :"11 Ring United", "es": "11 Anillos United"}},
                        { id: "a14Ring", title:  { "en" :"14 Ring United", "es": "14 Anillos United"}},
                    ],
                    enumNameHint: "CollectorRingTypes",
                    visible: (sys: ISystemBase) => !IsLateralOnly(sys)
                },   
                {
                    type: "choice",
                    fieldPath: "ControlPanel.EndTowerPositioning",
                    title:  "Position Control",
                    options: [
                        { id: "None", title:  { "en" :"None", "es": "Ninguno"}, },
                        { id: "Resolver", title: "Resolver"},
                        { id: "GPS", title: "Navigator GPS"},                      
                    ],
                    enumNameHint: "EndTowerPositioningTypes",
                    includeOnlyValidationRules: []
                },  
                {
                    type: "choice",
                    fieldPath: "ControlPanel.SwitchMount",
                    title: "Switch Mount",
                    options: [
                        { id: "None", title:  { "en" :"None", "es": "Ninguno"}},
                        { id: "CamWheel", title:  { "en" :"Cam Wheel", "es": "Rueda de Controles"}, },
                        { id: "CamPlate", title:  { "en" :"Cam Plate", "es": "Placa de Controles"}},
                    ],
                    enumNameHint: "SwitchMountTypes",
                    visible: (sys: ISystemBase) => sys.SystemProperties.SystemType !== SystemTypes.CanalFeedMaxigator && sys.SystemProperties.SystemType !== SystemTypes.HoseFeedMaxigator
                },      
                {
                    type: "complexChoice",
                    title:  "Pressure Sensors",
                    options: [
                        { title:  "None", values: {
                            "ControlPanel.LowPressureSafetyLP": false,
                            "ControlPanel.LowPressureSafetyReg": false,
                            "ControlPanel.PressureTransducerOptions": "None",
                            "ControlPanel.PressureTransducer0to25PSI": false
                        } },
                        { title:  "Switch (0-15 PSI)", title_bar: {"en": "Switch (0-1.03 bar)"},  values: {
                            "ControlPanel.LowPressureSafetyLP": true,
                            "ControlPanel.LowPressureSafetyReg": false,
                            "ControlPanel.PressureTransducerOptions": "None",
                            "ControlPanel.PressureTransducer0to25PSI": false
                        } },
                        { title:  "Switch (15-90 PSI)", title_bar: {"en": "Switch (1.03-6.21 bar)"}, values: {
                            "ControlPanel.LowPressureSafetyLP": false,
                            "ControlPanel.LowPressureSafetyReg": true,
                            "ControlPanel.PressureTransducerOptions": "None",
                            "ControlPanel.PressureTransducer0to25PSI": false
                        } },
                        { title:  "Transducer at Panel (0-25 PSI)", title_bar: {"en": "Transducer at Panel (0-1.72 bar)"}, values: {
                            "ControlPanel.LowPressureSafetyLP": false,
                            "ControlPanel.LowPressureSafetyReg": false,
                            "ControlPanel.PressureTransducerOptions": "AtPivot",
                            "ControlPanel.PressureTransducer0to25PSI": true
                        } },
                        { title:  "Transducer at Panel (0-200 PSI)", title_bar: {"en": "Transducer at Panel (0-13.79 bar)"}, values: {
                            "ControlPanel.LowPressureSafetyLP": false,
                            "ControlPanel.LowPressureSafetyReg": false,
                            "ControlPanel.PressureTransducerOptions": "AtPivot",
                            "ControlPanel.PressureTransducer0to25PSI": false
                        } },
                        { title:  "Transducer at End of System (0-200 PSI)", title_bar: {"en": "Transducer at End of System (0-13.79 bar)"}, values: {
                            "ControlPanel.LowPressureSafetyLP": false,
                            "ControlPanel.LowPressureSafetyReg": false,
                            "ControlPanel.PressureTransducerOptions": "AtEnd",
                            "ControlPanel.PressureTransducer0to25PSI": false
                        } },
                    ],
                    includeOnlyValidationRules: []
                },
                {
                    type: "complexChoice",
                    title:  "Flowmeter Type",
                    options: [
                        { title:  "None", values: {
                            "ControlPanel.FlowmeterModel": "None",
                            "ControlPanel.Flowmeter": "None",
                            "ControlPanel.FlowmeterPulse": false
                        } },
                        { title:  "Propeller Vertical", values: {
                            "ControlPanel.FlowmeterModel": "McPropeller",
                            "ControlPanel.Flowmeter": "Vertical",
                            "ControlPanel.FlowmeterPulse": false
                        } },
                        { title:  "Propeller Vertical w/pulse feature", values: {
                            "ControlPanel.FlowmeterModel": "McPropeller",
                            "ControlPanel.Flowmeter": "Vertical",
                            "ControlPanel.FlowmeterPulse": true
                        } },
                        { title:  "Propeller Horizontal", values: {
                            "ControlPanel.FlowmeterModel": "McPropeller",
                            "ControlPanel.Flowmeter": "Horizontal",
                            "ControlPanel.FlowmeterPulse": false
                        } },
                        { title:  "Propeller Horizontal w/pulse feature", values: {
                            "ControlPanel.FlowmeterModel": "McPropeller",
                            "ControlPanel.Flowmeter": "Horizontal",
                            "ControlPanel.FlowmeterPulse": true
                        } },
                        { title:  "Mag Vertical", values: {
                            "ControlPanel.FlowmeterModel": "McMag",
                            "ControlPanel.Flowmeter": "Vertical",
                            "ControlPanel.FlowmeterPulse": false
                        } },
                        { title:  "Mag Vertical w/pulse feature", values: {
                            "ControlPanel.FlowmeterModel": "McMag",
                            "ControlPanel.Flowmeter": "Vertical",
                            "ControlPanel.FlowmeterPulse": true
                        } },
                        { title:  "Mag Horizontal", values: {
                            "ControlPanel.FlowmeterModel": "McMag",
                            "ControlPanel.Flowmeter": "Horizontal",
                            "ControlPanel.FlowmeterPulse": false
                        } },
                        { title:  "Mag Horizontal w/pulse feature", values: {
                            "ControlPanel.FlowmeterModel": "McMag",
                            "ControlPanel.Flowmeter": "Horizontal",
                            "ControlPanel.FlowmeterPulse": true
                        } },
                        { title:  "Seametrics", values: {
                            "ControlPanel.FlowmeterModel": "Seametrics",
                            "ControlPanel.Flowmeter": "None",
                            "ControlPanel.FlowmeterPulse": false
                        } }
                    ],
                    includeOnlyValidationRules: []
                },
                {
                    visible: (sys: ISystemBase) => sys.ControlPanel.Flowmeter === FlowmeterTypes.Horizontal,
                    type: "complexChoice",
                    title:  "Flowmeter Options",
                    options: [
                        { title:  "14\" Flow Tube", title_centimeters:{"en": "35.56cm Flow Tube"}, values: {
                            "ControlPanel.FlowtubeWithVanes": false,
                            "ControlPanel.FlowtubeWithFS100": false
                        } },
                        { title:  "34\" Flow Tube with Vanes", title_centimeters:{"en": "86.36cm Flow Tube with Vanes"}, values: {
                            "ControlPanel.FlowtubeWithVanes": true,
                            "ControlPanel.FlowtubeWithFS100": false
                        } },
                        { title:  "34\" Flow Tube with FS100", title_centimeters:{"en": "86.36cm Flow Tube with FS100"}, values: {
                            "ControlPanel.FlowtubeWithVanes": false,
                            "ControlPanel.FlowtubeWithFS100": true
                        } }
                    ],
                    includeOnlyValidationRules: []
                },       
                {
                    visible: (sys: ISystemBase) => sys.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator,
                    type: "choice",
                    fieldPath: "ControlPanel.Location",
                    title:  { "en" :"Panel Location", "es": "Localización del Panel"},
                    options: [
                        { id: "FlangedSide", title:  { "en" : "Flanged Side", "es": "Lado Bridado"}},
                        { id: "FlexSide", title:  { "en" :"Flex Side", "es": "Lado Flex"}, },
                    ],
                    enumNameHint: "PanelLocations",
                },  
                { 
                    visible: (sys: ISystemBase) => [PanelModels.RPMAdvancedPlus, PanelModels.RPMPreferred, PanelModels.RPMConnect].indexOf(sys.ControlPanel.PanelModel) !== -1,
                    type: "boolean", 
                    title: "Include RC10d",
                    disableDealerSettings: true,
                    fieldPath: "RC10.IsEnabled",
                    validate: false,
                },        
                { type: "boolean", title:  { "en" :"Remote Main Control Panel", "es": "Panel de Control Principal Remoto"}, fieldPath: "ControlPanel.RemotePanelOptions.RemotePanel", disableDealerSettings: true,},                                   
                { type: "boolean", title: "Remote MCP Less Mount", fieldPath: "ControlPanel.RemoteMCPLessMount", disableDealerSettings: true,},
                {
                    visible: (sys: ISystemBase) => sys.ControlPanel.EndTowerPositioning === EndTowerPositioningTypes.Resolver
                    && sys.ControlPanel.RemotePanelOptions.RemotePanel,
                    type: "number",
                    disableDealerSettings: true,
                    fieldPath: "ControlPanel.RemotePanelOptions.RollsOfUnshieldedResolverWire",
                    title:  { "en" :"Unshielded Resolver Wire", "es": "Cable sin Blindaje para Resolver"},
                    default: 0,
                    units: {"en": "in 2000' rolls", "es": "En rollos de 2000'"},
                    units_meters: {"en": "in 609.57m rolls", "es": "En rollos de 609.57m"},
                }, 
                {
                    visible: (sys: ISystemBase) => sys.ControlPanel.EndTowerPositioning === EndTowerPositioningTypes.Resolver
                    && sys.ControlPanel.RemotePanelOptions.RemotePanel,
                    type: "number",
                    disableDealerSettings: true,
                    fieldPath: "ControlPanel.RemotePanelOptions.RollsOfShieldedResolverWire",
                    title:  { "en" :"Shielded Resolver Wire",  "es": "Cable Blindado para Resolver"},
                    default: 0,
                    units: {"en" :"in 2000' rolls", "es": "En rollos de 2000'",},
                    units_meters: {"en": "in 609.57m rolls", "es": "En rollos de 609.57m"},
                    disabled: (sys: ISystemBase) => sys.ControlPanel.EndTowerPositioning === EndTowerPositioningTypes.Resolver
                        && sys.ControlPanel.RemotePanelOptions.RemotePanel ? false
                        : "Resolver wire is only needed for remote main control panels with Resolver position control."
                },
                { 
                    type: "boolean",
                    autoChange: false,
                    improveScores: true,
                    disableDealerSettings: true,
                    includeOnlyValidationRules: [],
                    title:  { "en" :"Generator Start/Run Control", "es": "Control de Arranque de Generador"}, 
                    fieldPath: "ControlPanel.GenStartControl"
                },                       
                {
                    visible: (sys: ISystemBase) => sys.ControlPanel.GenStartControl && sys.SystemProperties.SystemType !== SystemTypes.CanalFeedMaxigator,
                    type: "number",
                    fieldPath: "ControlPanel.TenFourPowerCord",
                    title:  { "en" :"10/4 SO Power Cord","es": "Cable de Energía Flexible 10/4"},
                    default: 0,
                    units: {"en" : "feet", "es" : "pies"}
                },
                {
                    visible: (sys: ISystemBase) => sys.ControlPanel.GenStartControl && sys.SystemProperties.SystemType !== SystemTypes.CanalFeedMaxigator,
                    type: "number",
                    fieldPath: "ControlPanel.FourteenTwoPowerCord",
                    title:  { "en" :"14/2 SO Power Cord", "es": "Cable de Energía Flexible 14/2"},
                    default: 0,
                    units: {"en" : "feet", "es" : "pies"}
                }, 
            ],
        },
    ],
    rules: [
        {
            apply: (system, user, grower, quick) =>
                ([ElectricalFrequencies.a50].indexOf(system.ControlPanel?.ElectricalFrequency) !== -1 && system.Circle?.SinglePhase230VoltSystem) ? [{
                id: "roeLegacy.ElectricalFrequencyOK.1",
                description: "50 Hz electrical frequency only supports three-phase.",
                severity: "error",
                targets: [
                    "ControlPanel.ElectricalFrequency",
                    "Circle.SinglePhase230VoltSystem"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                ([ElectricalFrequencies.a50].indexOf(system.ControlPanel?.ElectricalFrequency) !== -1
                    && [SystemTypes.HoseFeedMaxigator].indexOf(system.SystemProperties?.SystemType) !== -1
                    && [HoseFeedTypes.A100, HoseFeedTypes.CF200].indexOf(system.Lateral?.HoseFeed?.HoseFeedType) !== -1) ? [{
                id: "roeLegacy.ElectricalFrequencyOK.2",
                description: "CF200 does not support 50 Hz electrical frequency.",
                severity: "error",
                targets: [
                    "ControlPanel.ElectricalFrequency"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                (system.ControlPanel?.MCPSpanCableDisconnect
                    && [SystemTypes.HoseFeedMaxigator].indexOf(system.SystemProperties?.SystemType) !== -1
                    && [HoseFeedTypes.CF200].indexOf(system.Lateral?.HoseFeed?.HoseFeedType) !== -1) ? [{
                id: "roeLegacy.MCPSpanCableDisconnectOk",
                description: "CF200 does not support MCP span cable disconnect.",
                severity: "error",
                targets: [
                    "ControlPanel.MCPSpanCableDisconnect"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                (system.ControlPanel?.PowerAutoRestart
                    && [SystemTypes.HoseFeedMaxigator].indexOf(system.SystemProperties?.SystemType) !== -1
                    && [HoseFeedTypes.CF200].indexOf(system.Lateral?.HoseFeed?.HoseFeedType) !== -1) ? [{
                id: "roeLegacy.PowerAutoRestartOK.1",
                description: "CF200 does not support power auto restart.",
                severity: "error",
                targets: [
                    "ControlPanel.PowerAutoRestart"
                ]
            }] : []
        },
        {
            apply: (system, user, grower, quick) =>
                (system.ControlPanel.PowerAutoRestart === false
                    && [PanelModels.RPMAdvanced, PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(system.ControlPanel?.PanelModel) !== -1) ? [{
                id: "roeLegacy.PowerAutoRestartOK.2",
                description: "The selected control panel type requires power auto restart.",
                severity: "error",
                targets: [
                    "ControlPanel.PowerAutoRestart"
                ]
            }] : []
        },
    ] as IRule[],
};