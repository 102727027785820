import { Box } from "@mui/material";
import { ISprinklerPropertiesVRIZonesZone } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import Circle from "./Circle";

interface Props {
    zones: ISprinklerPropertiesVRIZonesZone[];
};

const ZoneRendererCircle: FC<Props> = (props) => {
    let width = 400;
    let center = 200;

    let realZoneWidths = 0;
    props.zones.forEach((zone) => {
        realZoneWidths += zone.Length;
    });

    let circles: JSX.Element[] = [];
    let currentWidth = 0;
    props.zones.forEach((zone) => {
        currentWidth += zone.Length / (realZoneWidths/(width*0.49));//not 0.5 otherwise circle goes right to the edge and doesn't draw properly
        circles.push(<Circle key={`zone-${zone.ZoneNumber}`} svgCenter={center} fill={zone.Controlled} radius={currentWidth}/>);
    });

    circles = circles.slice().reverse();

    return <Box style={{textAlign: "center", marginTop: 10}}>
        <svg height={width} width={width}>
            {circles}
      </svg>
      </Box>
    ;
  };

  export default ZoneRendererCircle;