import { ISprinklerPropertiesVRIZonesZone, ISystemBase, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import ZoneRendererCircle from "./ZoneRendererCircle";
import ZoneRendererRectangle from "./ZoneRendererRectangle";

interface Props {
    zones: ISprinklerPropertiesVRIZonesZone[];
    system: ISystemBase;
};

const ZoneRenderer: FC<Props> = (props) => {
    switch (props.system.SystemProperties?.SystemType) {
        case SystemTypes.CenterPivot:
        case SystemTypes.KwikTow:
        case SystemTypes.SwingArmRetro:
            return <ZoneRendererCircle zones={props.zones} />;
        case SystemTypes.CanalFeedMaxigator:
        case SystemTypes.HoseFeedMaxigator:
            return <ZoneRendererRectangle zones={props.zones} system={props.system}/>;
        default:
            return null;
    }
};

  export default ZoneRenderer;