import IBoolean from "rdptypes/roe/IBoolean";
import ICalculator from "rdptypes/roe/ICalculator";
import IChoice from "rdptypes/roe/IChoice";
import IComplexChoice from "rdptypes/roe/IComplexChoice";
import IComponent from "rdptypes/roe/IComponent";
import IEndOfSystemTypeChoice from "rdptypes/roe/IEndOfSystemTypeChoice";
import INumber from "rdptypes/roe/INumber";
import IPage from "rdptypes/roe/IPage";
import IPrecisionEndGunAcres from "rdptypes/roe/IPrecisionEndGunAcres";
import IProposal from "rdptypes/roe/IProposal";
import IShippingDate from "rdptypes/roe/IShippingDate";
import ISideTable from "rdptypes/roe/ISideTable";
import ISprinklerDesign from "rdptypes/roe/ISprinklerDesign";
import IText from "rdptypes/roe/IText";
import { partsPackages } from "roedata/data";
import { visitComponents } from "../../components/roe/validation/SystemValidator";
import ISystem from "../../model/project/ISystem";

export interface ComponentToValidate {
    cmp: IComponent;
    fieldRoot: string;
    pageId: string;
}

export const getComponents = (system: ISystem, mainPageId: string) => {
    if (!system) {
        // TODO figure out why this is happening
        return [];
    }

    const { roeData } = partsPackages[system.partsPackageId];

    const cmps: ComponentToValidate[] = [];

    const handlePage = (page: IPage) => {
        const pageId = page.id;
        for (const cmp of page.components) {
            visitComponents(cmp, "", system, (cmp, fieldRoot) => {
                let cpy: IComponent = undefined;

                if (cmp.type === "choice") {
                    const cpyChoice: IChoice = { ...(cmp as IChoice), visible: undefined, customValueOption: undefined, disabled: undefined, hook: undefined };
                    if (typeof cpyChoice.autoChange === "function") {
                        cpyChoice.autoChange = cpyChoice.autoChange(system);
                    }
                    cpy = cpyChoice;
                }

                if (cmp.type === "complexChoice") {
                    const cpyChoice: IComplexChoice = { ...(cmp as IComplexChoice), visible: undefined, disabled: undefined, hook: undefined };
                    if (typeof cpyChoice.autoChange === "function") {
                        cpyChoice.autoChange = cpyChoice.autoChange(system);
                    }
                    cpy = cpyChoice;
                }

                if (cmp.type === "boolean") {
                    cpy = { ...cmp, visible: undefined, hook: undefined } as IBoolean;
                }

                if (cmp.type === "sidetable" || cmp.type === "generaltable") {
                    cpy = { ...cmp, visible: undefined, columns: [] } as ISideTable;
                }

                if (cmp.type === "sprinklers") {
                    cpy = { ...cmp, visible: undefined } as ISprinklerDesign;
                }

                if (cmp.type === "endOfSystemType") {
                    cpy = { ...cmp, visible: undefined } as IEndOfSystemTypeChoice;
                }

                if (cmp.type === "text") {
                    cpy = { ...cmp, visible: undefined } as IText;
                }

                if (cmp.type === "number") {
                    cpy = { ...cmp, visible: undefined } as INumber;
                }

                if (cmp.type === "precisionEndGunAcres"){
                    cpy = { ...cmp, visible: undefined} as IPrecisionEndGunAcres;
                }

                if (cmp.type === "calculator"){
                    cpy = { ...cmp, visible: undefined, expr: undefined} as ICalculator;
                }

                if (cmp.type === "shippingDate") {
                    cpy = { ...cmp, visible: undefined } as IShippingDate;
                }

                if (cmp.type === "proposal") {
                    cpy = { ...cmp, visible: undefined } as IProposal;
                }

                if (cpy) {
                      cmps.push({
                        cmp: cpy,
                        fieldRoot,
                        pageId
                    });
                }
            });
        }
    };

    for (const page of roeData.pages) {
        if (page.title === mainPageId) {
            handlePage(page);
        }
    }

    for (const page of roeData.pages) {
        if (page.title !== mainPageId) {
            handlePage(page);
        }
    }

    return cmps;
}