import * as turf from "@turf/turf";
import { SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import IProject, { ProjectType } from "../../model/project/IProject";
import { applyObstacleClearanceTo } from "../../optimization/helpers/base";
import { IrrigatedAreaHelper, SystemIrrigatedInfo } from "../IrrigatedAreaHelper";
import CenterPivotGeometryHelper from "../SystemGeometryHelpers/CenterPivotGeometryHelper";
import LateralGeometryHelper from "../SystemGeometryHelpers/LateralGeometryHelper";
import { getSystemValidity } from "../SystemGeometryHelpers/getSystemValidity";
import { IBufferedSystemPolygonsForSystemClearancePolygons, SystemValidity, getLayoutClearancePolygons } from "../SystemGeometryHelpers/interfaces";

const ctx: Worker = self as any;

export interface IWorkerCenterPivotSystem {
    systemId: string;
    polygon?: turf.Feature<turf.Polygon>;
}
export type IGeometryWorkerRequest = {
    type: "pointClouds",
    id: string;
    systems: IWorkerCenterPivotSystem[];
} | {
    type: "geometry",
    id: string;
    layoutId: string;
    project: IProject;
}

export interface IGeometryWorkerSystemGeometry {
    [systemId: string]: {
        systemValidity: SystemValidity;
        areaPolygon?: turf.helpers.Polygon;
        irrigatedInfo: SystemIrrigatedInfo;
        type: "centerPivot" | "lateral"
    };
}

export type IGeometryWorkerResponse = {
    id: string;
    type: "geometry"
    result: IRecalculateCtxResult | null;
} | {
    id: string;
    type: "pointClouds"
    systems: {
        [systemId: string]: {
            points: turf.Feature<turf.Point>[];
        };
    }
}
export const RESOLUTION_FEET = 10;
ctx.onmessage = (ev: MessageEvent<IGeometryWorkerRequest>) => {
    console.log("Inworker", ev.data)
    if (ev.data.type === 'geometry') {
        const { id, project, layoutId } = ev.data;
        const result = recaclulateCtx(project, layoutId);
        postMessage({
            type: 'geometry',
            id,
            result
        } as IGeometryWorkerResponse);
    }
    else if (ev.data.type === 'pointClouds') {
        const { id, systems } = ev.data;
        postMessage({
            type: 'pointClouds',
            id,
            systems: recalculatePointClouds(systems)
        } as IGeometryWorkerResponse);
    }
}

const getCenterPivotElevationPoints = (polygon: turf.Feature<turf.Polygon>): turf.Feature<turf.Point>[] => {
    const pg = turf.pointGrid(
        turf.bbox(polygon), RESOLUTION_FEET, { units: 'feet', mask: polygon }
    )
    return pg.features;
}

export const recalculatePointClouds = (systems: IWorkerCenterPivotSystem[]) => {
    const elevations: {
        [systemId: string]: {
            points: turf.Feature<turf.Point>[];
        }
    } = {};
    for (const { systemId, polygon } of systems) {
        elevations[systemId] = {
            points: polygon ? getCenterPivotElevationPoints(polygon) : []
        }
    }
    return elevations;
}

export interface IIahResult { layoutArea: number, fieldAcres: number }
export interface IRecalculateCtxResult { systems: IGeometryWorkerSystemGeometry, iahResult: IIahResult };
export const recaclulateCtx = (project: IProject, layoutId: string): IRecalculateCtxResult | null => {
    if (!project?.layouts[layoutId]) return null;
    const res: IGeometryWorkerSystemGeometry = {};
    let layoutArea = 0;
    let fieldAcres = 0;
    if (project?.projectType === ProjectType.LayoutAndDesign) {
        const ghs: { [ systemId: string ]: CenterPivotGeometryHelper | LateralGeometryHelper} = {};
        const bufferedSystemPolygons: IBufferedSystemPolygonsForSystemClearancePolygons = {};
        const layoutClearancePolygons = getLayoutClearancePolygons(project, layoutId);
        const layout = project.layouts[layoutId];
        for (const [ systemId, system ] of Object.entries(layout.systems)) {
            switch (system.SystemProperties.SystemType) {
                case SystemTypes.CenterPivot: {
                    const inactiveSystem = new CenterPivotGeometryHelper(
                        {
                            layoutId,
                            systemId, 
                            project, 
                        },
                        {
                            isActive: false
                        }
                    );
                    const areaPolygon = inactiveSystem.getAreaPolygon({ includeEndguns: false, includeSAC: true });
                    bufferedSystemPolygons[systemId] = {
                        polygons: areaPolygon ? applyObstacleClearanceTo(areaPolygon, project.systemClearance) : [],
                        radiusEnvelope: inactiveSystem.systemRadiusFeetIncludingEndboomOrSac
                    };
                    const irrigatedInfo = null // adding after
                    const systemValidity = null // adding after
                    res[systemId] = {
                        systemValidity, areaPolygon, irrigatedInfo, type: "centerPivot"
                    }
                    ghs[systemId] = inactiveSystem;
                    break;
                }
                case SystemTypes.CanalFeedMaxigator:     
                case SystemTypes.HoseFeedMaxigator: {
                    const inactiveSystem = new LateralGeometryHelper(
                        {
                            layoutId,
                            systemId, 
                            project, 
                        },
                        {
                            isActive: false
                        }
                    );
                    const areaPolygon = inactiveSystem.getAreaPolygon();
                    bufferedSystemPolygons[systemId] = {
                        polygons: areaPolygon ? applyObstacleClearanceTo(areaPolygon, project.systemClearance) : []
                    };
                    const irrigatedInfo = null // adding after
                    const systemValidity = null // adding after
                    res[systemId] = {
                        systemValidity, areaPolygon, irrigatedInfo, type: 'lateral'
                    }
                    ghs[systemId] = inactiveSystem;
                    break;
                }
            }
        }
        const irrigatedAreaHelper = new IrrigatedAreaHelper({
            project,
            layoutId,
            geometryHelpers: ghs
        });
        for (const [systemId, data] of Object.entries(res)) {
            data.irrigatedInfo = irrigatedAreaHelper.getSystem(systemId);
        }
        for (const [systemId, data] of Object.entries(res)) {
            const gh = ghs[systemId];
            const systemValidityArgs = gh.getSystemValidityArgs(layoutClearancePolygons, bufferedSystemPolygons);
            const systemValidity = getSystemValidity(layoutId, systemId, systemValidityArgs);
            data.systemValidity = systemValidity;
        }
        layoutArea = irrigatedAreaHelper.getIrrigatedAcres();
        fieldAcres = irrigatedAreaHelper.getFieldAcres();
    }
    return {
        systems: res,
        iahResult: {
            layoutArea,
            fieldAcres
        }
    };
}