import {
    ControlFunctionOptions,
    ControlFunctions,
    Filters,
    IMainlineValveOptionsValvesMainlineValve as MainlineValve,
    IMainlineValveOptionsValves as MainlineValves,
    OnOffModes,
    PipeSizes,
    Pressures,
    RatesOfFlow,
    ValveMaterials,
    ValveSizes
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import partsCatalog from "../data/partsPackages/2024060101/partsCatalog";
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import * as ohf from "./OtherHelpers";
import { POSections } from "./POConstants";
import { PartLookup } from "./PartLookup";
import PartsAssembler from "./PartsAssembler";
import { strLanguageText } from "./TranslationsLib";
import { MainlineValveAdapterOK, MainlineValveControlFunctionOK, MainlineValveMaterialOK, MainlineValveRateOfFlowOK } from "./Valids.Valids";
export class MainlineValveInfoResult {
    /*constructor() {
    }*/
    private _partNumber: string = null;
    public IsSpecialPart = (): boolean => {
        if (["A4B5C2D3E50H2", "A6B5C2D3E50H2", "A8B5C2D3E50H2", "A6B5C2D3E50H3S2"].indexOf(this.PartNumber()) !== -1) {
            return true;
        }
        return false;
    }
    public BasePartNumber: string;
    public PartNumber = (): string => {
        return `${this.BasePartNumber}`;
    }
    public Description: string;
    public _price: number = 0;
    public Price = (): number => {
        return this.IsSpecialPart() ? 0.01 : this._price;
    }
    public Quantity: number;
}
export class MainlineValvesClass extends CSection2 {
    private readonly POSection: number = 14;
    private readonly POMainlineValves: number = 1;
    private readonly POAirValves: number = 2;
    private readonly POMainlineValveComponents: number = 3;
    public GetMainlineValveInfo = (mv: MainlineValve, priceList?: { [partNumber: string]: {unitPriceUsdCents: number} }): MainlineValveInfoResult => {
        let dTotalPrice: number;
        let strTotalDescription: string;
        let strTotalPartNumber: string;
        let PartIteration: number;
        let Qty = mv.Quantity;
        switch (mv.Size) {
            case ValveSizes.a4Inch:
                if (mv.ValveMaterial === ValveMaterials.Aluminum) {
                    if (mv.Adapter) {
                        PartIteration = 32;
                    }
                    else {
                        PartIteration = 29;
                    }
                }
                else {
                }
                break;
            case ValveSizes.a6Inch:
                if (mv.ValveMaterial === ValveMaterials.Aluminum) {
                    if (mv.Adapter) {
                        PartIteration = 34;
                    }
                    else {
                        PartIteration = 33;
                    }
                }
                else {
                    if (mv.Adapter) {
                        PartIteration = 35;
                    }
                    else {
                        PartIteration = 2;
                    }
                }
                break;
            case ValveSizes.a8Inch:
                if (mv.ValveMaterial === ValveMaterials.Aluminum) {
                    if (mv.Adapter) {
                    }
                    else {
                        PartIteration = 36;
                    }
                }
                else {
                    if (mv.Adapter) {
                    }
                    else {
                        PartIteration = 3;
                    }
                }
                break;
        }
        let partNumber = PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration];
        let price = typeof priceList !== "undefined" ? (priceList[partNumber] ? priceList[partNumber].unitPriceUsdCents : 0) : 0;
        let part = partsCatalog[PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration]];
        let cpKey = { Description: part && part.mapicsDescription ? part.mapicsDescription : "Unknown", PartNumber: partNumber, Price: price };
        strTotalDescription = cpKey.Description;
        strTotalPartNumber = cpKey.PartNumber;
        dTotalPrice = Qty * cpKey.Price;
        switch (mv.Pressure) {
            case Pressures.a30To200Psi:
                PartIteration = 5;
                break;
            case Pressures.a18To80Psi:
                PartIteration = 6;
                break;
        }
        partNumber = PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration];
        price = typeof priceList !== "undefined" ? (priceList[partNumber] ? priceList[partNumber].unitPriceUsdCents : 0) : 0;
        part = partsCatalog[PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration]];
        cpKey = { Description: part && part.mapicsDescription ? part.mapicsDescription : "Unknown", PartNumber: partNumber, Price: price };
        strTotalDescription += (" " + cpKey.Description);
        strTotalPartNumber += cpKey.PartNumber;
        dTotalPrice += Qty * cpKey.Price;
        switch (mv.ControlFunction) {
            case ControlFunctions.aManualOnOff:
                PartIteration = 7;
                break;
            case ControlFunctions.a5To50PSIPressureReducingValve:
                PartIteration = 8;
                break;
            case ControlFunctions.a25To120PSIPressureReducingValve:
                PartIteration = 9;
                break;
            case ControlFunctions.a25To200PSIPressureReducingValve:
                PartIteration = 10;
                break;
        }
        partNumber = PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration];
        price = typeof priceList !== "undefined" ? (priceList[partNumber] ? priceList[partNumber].unitPriceUsdCents : 0) : 0;
        part = partsCatalog[PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration]];
        cpKey = { Description: part && part.mapicsDescription ? part.mapicsDescription : "Unknown", PartNumber: partNumber, Price: price };
        strTotalPartNumber += cpKey.PartNumber;
        dTotalPrice += Qty * cpKey.Price;
        if (mv.ControlFunctionOption !== ControlFunctionOptions.None) {
            switch (mv.ControlFunctionOption) {
                case ControlFunctionOptions.SleeveExhaust:
                    PartIteration = 11;
                    break;
                case ControlFunctionOptions.RateOfFlowValve:
                    switch (mv.PipeSize) {
                        case PipeSizes.a3Inch:
                            PartIteration = 41;
                            break;
                        case PipeSizes.a4Inch:
                            PartIteration = 42;
                            break;
                        case PipeSizes.a6Inch:
                            switch (mv.RateOfFlow) {
                                case RatesOfFlow.High:
                                    PartIteration = 44;
                                    break;
                                case RatesOfFlow.Low:
                                    PartIteration = 43;
                                    break;
                            }
                            break;
                        case PipeSizes.a8Inch:
                            switch (mv.RateOfFlow) {
                                case RatesOfFlow.High:
                                    PartIteration = 46;
                                    break;
                                case RatesOfFlow.Low:
                                    PartIteration = 45;
                                    break;
                            }
                            break;
                    }
                    break;
            }
            partNumber = PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration];
            price = typeof priceList !== "undefined" ? (priceList[partNumber] ? priceList[partNumber].unitPriceUsdCents : 0) : 0;
            part = partsCatalog[PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration]];
            cpKey = { Description: part && part.mapicsDescription ? part.mapicsDescription : "Unknown", PartNumber: partNumber, Price: price };
            strTotalPartNumber += cpKey.PartNumber;
            dTotalPrice += Qty * cpKey.Price;
        }
        switch (mv.OnOffMode) {
            case OnOffModes.aNoSolenoid:
                PartIteration = 13;
                break;
            case OnOffModes.a12To24VDCLatchSolenoid_5AmpMax:
                PartIteration = 14;
                break;
            case OnOffModes.a12VDCSolenoid_6AmpMax:
                PartIteration = 15;
                break;
            case OnOffModes.a24VDCSolenoid_3AmpMax:
                PartIteration = 16;
                break;
            case OnOffModes.a24VACSolenoid_3AmpInrushCurrent:
                PartIteration = 17;
                break;
            case OnOffModes.a24VACSolenoid1_1AmpInrushHeavyDuty:
                PartIteration = 18;
                break;
            case OnOffModes.a24VACSolenoid3_6AmpInrushCurrent:
                PartIteration = 19;
                break;
            case OnOffModes.a120VACSolenoid1_16Inch:
                PartIteration = 21;
                break;
            case OnOffModes.a120VACSolenoid1_8Inch:
                PartIteration = 22;
                break;
            case OnOffModes.aHydraulicRemoteControl:
                PartIteration = 28;
                break;
        }
        partNumber = PartLookup[POSections.MainlineValves][3][PartIteration];
        price = typeof priceList !== "undefined" ? (priceList[partNumber] ? priceList[partNumber].unitPriceUsdCents : 0) : 0;
        part = partsCatalog[PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration]];
        cpKey = { Description: part && part.mapicsDescription ? part.mapicsDescription : "Unknown", PartNumber: partNumber, Price: price };
        strTotalPartNumber += cpKey.PartNumber;
        dTotalPrice += Qty * cpKey.Price;
        switch (mv.Filter) {
            case Filters.ExternalFilter:
                if (mv.TubingProtection) {
                    PartIteration = 48;
                }
                else {
                    PartIteration = 30;
                }
                break;
            case Filters.InternalFilter:
                if (mv.TubingProtection) {
                    PartIteration = 49;
                }
                else {
                    PartIteration = 31;
                }
                break;
        }
        partNumber = PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration];
        price = typeof priceList !== "undefined" ? (priceList[partNumber] ? priceList[partNumber].unitPriceUsdCents : 0) : 0;
        part = partsCatalog[PartLookup[POSections.MainlineValves][this.POMainlineValveComponents][PartIteration]];
        cpKey = { Description: part && part.mapicsDescription ? part.mapicsDescription : "Unknown", PartNumber: partNumber, Price: price };
        strTotalPartNumber += cpKey.PartNumber;
        dTotalPrice += Qty * cpKey.Price;
        let result = new MainlineValveInfoResult();
        result.BasePartNumber = strTotalPartNumber;
        result.Description = strTotalDescription;
        result._price = dTotalPrice;
        result.Quantity = Qty;
        return result;
    }
/*public TotalPrice = (PA: PartsAssembler, isInternational: boolean): number => {
let sTotalPrice: number = 0;
if (!(FieldSet.DataValid())) {
return 0;
}
let valves = this.Quote.System.MainlineValveOptions.Valves;
for (let i: number = 1; i <= valves.Count; i++
) {
let result = this.GetMainlineValveInfo(valves.MainlineValve[i - 1]);
if (!(result.IsSpecialPart())) {
sTotalPrice += result.Price;
}
}
if (AppClass.HidePrice) {
return 0.01;
}
else {
return (sTotalPrice + PA.GenerateBOM.ListPriceBySection(POSections.MainlineValves, isInternational));
}
}
*//*public TotalCost = (PA: PartsAssembler): number => {
let sTotalPrice: number = 0;
if (!(FieldSet.DataValid())) {
return 0;
}
let valves = this.Quote.System.MainlineValveOptions.Valves;
for (let i: number = 1; i <= valves.Count; i++
) {
let result = this.GetMainlineValveInfo(valves.MainlineValve[i - 1]);
if (!(result.IsSpecialPart())) {
sTotalPrice += result.Price;
}
}
if (AppClass.HidePrice) {
return 0.01;
}
else {
return (sTotalPrice + PA.GenerateBOM.ListCostBySection(POSections.MainlineValves));
}
}
*//*public NumberOfValves = (): number => {
if (FieldSet.DataValid()) {
return this.Quote.System.MainlineValveOptions.Valves.Count;
}
else {
return 0;
}
}
*/public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(this.TranslatedKey);
        return false;
        }*/
        let k: number = this.Quote.System.MainlineValveOptions.Valves.MainlineValve.length;
        for (let i: number = 1; i <= k; i++
        ) {
            let v: MainlineValve = this.Quote.System.MainlineValveOptions.Valves.MainlineValve[i - 1];
            if (!(MainlineValveMaterialOK(v.ValveMaterial, {
                ValveSize: v.Size
            }))) {
                this.Quote.Warnings.AddWithTargets([`MainlineValveOptions.Valves.MainlineValve[${i - 1}].ValveMaterial`], "Mainline Valve Aluminum / Cast Iron");
            }
            if (!(MainlineValveAdapterOK(v.Adapter, {
                ValveSize: v.Size,
                ValveMaterial: v.ValveMaterial
            }))) {
                this.Quote.Warnings.AddWithTargets([`MainlineValveOptions.Valves.MainlineValve[${i - 1}].Adapter`], "Mainline Valve Adapter");
            }
            if (!(MainlineValveControlFunctionOK(v.ControlFunction, { ValvePressure: v.Pressure }))) {
                this.Quote.Warnings.AddWithTargets([`MainlineValveOptions.Valves.MainlineValve[${i - 1}].ControlFunction`], "Mainline Valve Control Function");
            }
            if (v.ControlFunctionOption === ControlFunctionOptions.RateOfFlowValve) {
                if (!(MainlineValveRateOfFlowOK(v.RateOfFlow, {
                    PipeSize: v.PipeSize, ControlFunctionOption: v.ControlFunctionOption
                }))) {
                    this.Quote.Warnings.AddWithTargets([`MainlineValveOptions.Valves.MainlineValve[${i - 1}].RateOfFlow`], strLanguageText("sltRateOfFlowValve"));
                }
            }
        }
    }
    public WritePO = (PO: PartsAssembler) => {
        if (this.Quote.System.MainlineValveOptions.PlasticAirValve > 0) {
            PO.InsertPart(schema.groups.MainlineValves_AirValves.groupId, "113951", this.Quote.System.MainlineValveOptions.PlasticAirValve); // VALVE-AIR CNTR 2" NPT ACV200P
        }
        //if (this.Quote.System.MainlineValveOptions.BrassAirValve > 0) {
        //    PO.InsertRecord(this.POSection, this.POAirValves, 2, this.Quote.System.MainlineValveOptions.BrassAirValve);
        //}
        let v: MainlineValves = this.Quote.System.MainlineValveOptions.Valves;
        for (let i: number = 1; i <= ohf.ValvesCount(v); i++
        ) {
            let result = this.GetMainlineValveInfo(v.MainlineValve[i - 1]);
            if (result.IsSpecialPart()) {
                switch (result.BasePartNumber) {
                    case "A4B5C2D3E50H2":
                        PO.InsertPart(schema.groups.MainlineValves.groupId, "113657", result.Quantity); // VALVE 800 CNTRL A4B5C2D3E50H2
                        break;
                    case "A6B5C2D3E50H2":
                        PO.InsertPart(schema.groups.MainlineValves.groupId, "113658", result.Quantity); // VALVE 800 CNTRL A6B5C2D3E50H2
                        break;
                    case "A8B5C2D3E50H2":
                        PO.InsertPart(schema.groups.MainlineValves.groupId, "113659", result.Quantity); // VALVE 800 CNTRL A8B5C2D3E50H2
                        break;
                    case "A6B5C2D3E50H3S2":
                        PO.InsertPart(schema.groups.MainlineValves.groupId, "115343", result.Quantity); // VALVE-6" 800 SERIES CONTROL
                        break;
                }
            }
        }
    }
}