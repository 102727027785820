import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ChevronRightOutlined from "@mui/icons-material/ChevronRightOutlined";
import Error from '@mui/icons-material/Error';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import Warning from '@mui/icons-material/Warning';
import { Box, Drawer, Stack, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { partsPackages } from 'roedata/data';
import AuthCtx from "../../auth/AuthCtx";
import DbCtx from '../../db/DbCtx';
import IDbProject from "../../db/IDbProject";
import { translate } from "../../helpers/translation";
import { ValidationWorkerStatus } from "../../workers/ValidationWorker/IValidationWorkerState";
import ValidationWorkerCtx from "../../workers/ValidationWorker/ValidationWorkerCtx";
import { improveScores } from "../../workers/ValidationWorker/improveScores";
import ComponentRenderContext from "./componentRenderers/ComponentRenderContext";
import PageRenderer from "./componentRenderers/PageRenderer";
import ProposalDrawerNew from "./componentRenderers/Proposal/Live/ProposalDrawerNew";
import { FieldValidationSeverity } from "./validation/SystemValidator";

interface Props {
    dbPrj: IDbProject;
    layoutId: string;
    systemId: string;
    sideViewOpen?: boolean;

    systemDefaults?: boolean;

    ignoreImgTooltips?: boolean;
    disableStepByStep?: boolean;
    supportsLiveProposal: boolean;
}

const SystemDesigner: FC<Props> = (props) => {
    const authState = useContext(AuthCtx);
    const dbState = useContext(DbCtx);

    const sideViewOpen = props.sideViewOpen || false;
    const hasSideView = typeof (props.sideViewOpen) !== "undefined";

    console.log("SystemDesigner");

    const { dbPrj } = props;

    const project = dbPrj.state;
    const layout = project.layouts[props.layoutId];
    const system = layout.systems[props.systemId];
    const grower = dbState.growers[project.growerId];

    const { roeData } = partsPackages[system.partsPackageId];

    const validationWorkerState = useContext(ValidationWorkerCtx);

    var filteredPages = props.systemDefaults
        ? roeData.pages.filter(x => !x.disableDealerSettings)
        : roeData.pages.filter(x => x.visible && x.visible(system));

    const [selectedPage, setSelectedPage] = useState(Math.max(0,
        system.orderSubmissionStatus ? filteredPages.findIndex(x => x.id === "sendOrder") :
        system.proposalGenerated ? filteredPages.findIndex(x => x.id === "proposal") :
        0
    ));

    useEffect(() => {
        // Proposal has been removed. Go back to the beginning.
        if (!system.proposalGenerated && selectedPage !== 0) {
            setSelectedPage(0);
        }
    }, [system.proposalGenerated]);

    var pageSeverities = new Map<string, FieldValidationSeverity>();
    if (validationWorkerState.validationResult) {
        for (const fvr of Object.values(validationWorkerState.validationResult.fields)) {
            if (fvr.severity !== undefined) {
                for (const pageId of fvr.pageIds) {
                    const ps = pageSeverities.get(pageId);
                    if (fvr.severity === "notset"
                        || (fvr.severity === "error" && (ps === "warning" || ps === undefined))
                        || (fvr.severity === "warning" && ps === undefined)) {
                        pageSeverities.set(pageId, fvr.severity);
                    }
                }
            }
        }
    }
    const firstPageNotSet = system.proposalGenerated ? -1 : filteredPages.findIndex(p => pageSeverities.get(p.id) === "notset");

    const containerRef = React.createRef<Element>();

    useEffect(() => {
        (async () => {
            // When first opening system designer, validate and improve scores
            const svr = await validationWorkerState.validate(
                system,
                [],
                filteredPages[selectedPage].id
            );
            const improveAction = improveScores(system, authState, dbPrj, props.layoutId, props.systemId, svr, []);
            if (improveAction) {
                dbPrj.pushAction(improveAction);
            }
        })();
    }, []);

    const [proposalDrawerOpen, setProposalDrawerOpen] = React.useState(false);
    let width = props.supportsLiveProposal ? "calc(100% - 60px)" : "calc(100% - 10px)";
    let height = "calc(100% - 48px)";

    if (props.supportsLiveProposal && proposalDrawerOpen) {
        if (hasSideView) {
            if (sideViewOpen) {
                width = "calc(100% - 435px)";
                height = "calc(100% - 350px)";
            }
            else {
                width = "calc(100% - 499px)";
            }
        }
        else {
            width = "calc(100% - 500px)";
        }
    }
    else if (!proposalDrawerOpen && hasSideView && sideViewOpen) {
        height = "calc(100% - 350px)";
    }

    return (<>
        <Drawer open variant="permanent" anchor="left" PaperProps={{
            sx: {
                position: "absolute", width, top: "48px",
                height,
                paddingBottom: !hasSideView ? 0 : "50px"

            }
        }}>
            <Stack direction="row">
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={selectedPage}
                    onChange={(ev, newSelectedPage) => setSelectedPage(newSelectedPage)}
                    sx={{
                        borderRight: 1, borderColor: 'divider', minWidth: "255px", '& .MuiTabs-flexContainer': {
                            alignItems: "start"
                        },
                    }}
                >
                    {filteredPages.map((page, index) => {
                        const severity = pageSeverities.get(page.id);
                        let icon: React.ReactElement;

                        switch (severity) {
                            case "notset":
                                if (index === firstPageNotSet) {
                                    icon = <HourglassEmptyOutlinedIcon />;
                                }
                                break;
                            case "error":
                                icon = <Error color="error" />;
                                break;
                            case "warning":
                                icon = <Warning color="warning" />;
                                break;
                            default:
                                if (index <= firstPageNotSet) icon = <CheckOutlinedIcon color="success" />;
                                break;
                        }
                        if (index === selectedPage) {
                            icon = <ChevronRightOutlined />;
                        }
                        if (typeof icon === "undefined") {
                            //placeholder
                            icon = <Box sx={{ width: "1.5rem", marginRight: "8px", height: "1.5rem" }} />;
                        }

                        return <Tab
                            icon={icon}
                            iconPosition="start"
                            disabled={validationWorkerState.status === ValidationWorkerStatus.Validating
                                || (!props.disableStepByStep && firstPageNotSet !== -1 && index > firstPageNotSet)}
                            key={page.id} style={{ minHeight: 25 }} label={translate(page, system)} />;
                    })}
                </Tabs>
                <Box sx={{ flex: 2, padding: 2 }}>
                    <PageRenderer
                        page={filteredPages[selectedPage]}
                        rules={roeData.rules}
                        dbPrj={dbPrj}
                        ignoreImgTooltips={props.ignoreImgTooltips}
                        layoutId={props.layoutId}
                        systemId={props.systemId}
                        ctx={new ComponentRenderContext(
                            dbPrj,
                            props.layoutId,
                            props.systemId,
                            filteredPages[selectedPage],
                            validationWorkerState,
                            authState,
                            !!props.systemDefaults,
                        )}
                    />
                </Box>
            </Stack>
        </Drawer>
        {
            selectedPage !== filteredPages.length - 1 && props.supportsLiveProposal && <ProposalDrawerNew hasSideView={hasSideView} sideViewOpen={sideViewOpen} isOpen={proposalDrawerOpen} toggle={() => { setProposalDrawerOpen(!proposalDrawerOpen) }} system={system} grower={grower} />
        }
    </>
    );
};

export default SystemDesigner;