import { EndTowerPositioningTypes, GuidanceLocations, GuidanceTypes, HoseFeedTypes, PanelModels, SystemTypes, TireSizes, TireTypes, WaterFeedTypes, WheelGearSeries } from "rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import * as sidef from "../SideFunctions";
import * as sysf from "../SystemFunctions";
import * as towerf from "../TowerFunctions";
import { TowersClass } from "../TowersClass";
import { Tower } from "../Types";
import { MaxigatorGuidanceOptionOk, TowerAirCompressorOK, TowerControlLineBoosterPumpOK, TwelveLugsOK } from "../Valids.Valids";

export default class TowersSingleTowerForm {
    private bSingleSpanMaxi: boolean;
    private bOuterTowerGuidance: boolean;
    private bMaxi: boolean;
    private numberOfSpans: number;
    private ePanelModel: PanelModels;
    private eEndTowerPositioning: EndTowerPositioningTypes;
    private ePowerTowerTireSize: TireSizes;
    private ePowerTowerTireType: TireTypes;
    private eWheelGearType: WheelGearSeries;
    private bPivotingLateral: boolean;

    constructor(private Quote: QuoteClass, private CodeBehind: TowersClass) {
        this.numberOfSpans = CodeBehind.Side.Span.length;
        this.bOuterTowerGuidance = false;
        this.bPivotingLateral = false;

        if (Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator){
            if (Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower) this.bOuterTowerGuidance = true;
            this.bPivotingLateral = Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral;
            this.ePowerTowerTireSize = Quote.System.Lateral.Tires.TireSize;
            this.ePowerTowerTireType = Quote.System.Lateral.Tires.TireType;
        }
        else if (Quote.System.SystemProperties.SystemType === SystemTypes.CanalFeedMaxigator){
            if (Quote.System.Lateral.Guidance.Location === GuidanceLocations.OuterTower) this.bOuterTowerGuidance = true;
        }

        if (Quote.ControlPanelClass.FieldSet.DataValid()){
            this.ePanelModel = Quote.System.ControlPanel.PanelModel;
            this.eEndTowerPositioning = Quote.System.ControlPanel.EndTowerPositioning;   
        }

        this.bSingleSpanMaxi = Quote.IsSingleSpanMaxi();
        this.eWheelGearType = Quote.System.TowerProperties.WheelGear;
        this.bMaxi = sysf.IsMaxigator(Quote.System);
    }

    public validate = (): void => {
        let previousTower: Tower;
        for (const tower of this.CodeBehind.Side.Tower!) {
            this.validateTower(tower, previousTower);
            previousTower = tower;
        }
        if (this.Quote.System.partsPackageId >= "2024081901") {
            const airCompressorTower = towerf.getTowerForAirCompressor_post2024081901(this.Quote.System, this.CodeBehind.Side);
            if (airCompressorTower.airCompressor && airCompressorTower.towerIdx === -1) {
                this.Quote.Warnings.Add(`${this.getSideLabel()} tower air compressor not supported`);
            }
            const controlLineBoosterPumpTower = towerf.getTowerForControlLineBoosterPump_post2024081901(this.Quote.System, this.CodeBehind.Side);
            if (controlLineBoosterPumpTower.controlLineBoosterPump && controlLineBoosterPumpTower.towerIdx === -1) {
                this.Quote.Warnings.Add(`${this.getSideLabel()} tower control line booster pump not supported`);
            }
        }
    }

    private validateTower = (tower: Tower, previousTower: Tower) => {
        const eStiffenedSpanType = this.CodeBehind.StiffenedSpanType(tower.TowerNumber);
        const eStiffenedSpanLength = this.CodeBehind.StiffenedSpanLength(tower.TowerNumber);

        let bGuidanceValid = true;

        if (this.CodeBehind.Side.Span[tower.TowerNumber - 1] && this.CodeBehind.Side.Span[tower.TowerNumber - 1].Disconnecting){
            //Laterals and Pivots can have disconnecting spans. You cannot have Guidance on any span
            bGuidanceValid = false;
        }

        if (tower.TowerNumber < this.numberOfSpans && this.CodeBehind.Side.Span[tower.TowerNumber].Disconnecting) {
            bGuidanceValid = false;
        }

        if (this.bOuterTowerGuidance){
            if (this.Quote.System.Lateral.Guidance.GuidanceType === GuidanceTypes.BuriedWire || 
                this.Quote.System.Lateral.Guidance.GuidanceType === GuidanceTypes.NavigatorGPS) {

                if (
                    (tower.TowerNumber === this.numberOfSpans) &&
                    !(this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200)
                ){
                    bGuidanceValid = false;
                }
                if (
                    tower.TowerNumber === 1 && 
                    sysf.IsHoseFeedEndFeed(this.Quote.System) &&
                    !(this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator && this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200)
                ){
                    bGuidanceValid = false;
                }
            }

            const hf = [HoseFeedTypes.Standard, HoseFeedTypes.DoubleEndFeed, HoseFeedTypes.FourWheelDrive, HoseFeedTypes.Sugargator];

            if (this.CodeBehind.bLeftSide) bGuidanceValid = false;
            if (this.Quote.System.SystemProperties.SystemType === SystemTypes.HoseFeedMaxigator &&
                hf.indexOf(this.Quote.System.Lateral.HoseFeed.HoseFeedType) !== -1 && 
                this.Quote.System.Lateral.WaterFeed === WaterFeedTypes.EndFeed &&
                tower.TowerNumber === 1){
                
                bGuidanceValid = false;
            }
        }

        let bTowerReverseTow = tower.TowerNumber !== this.CodeBehind.StiffenedSpan(tower.TowerNumber) && this.Quote.IsTowable() && sysf.IsReverseTow(this.Quote.System);

        //TowerTypeOK seems to already be being called from TowersClass.ts (otherwise we should do it here)

        if (!TwelveLugsOK(tower.Tires.TwelveLugs, {TowerHeight: tower.TowerType, TireSize: tower.Tires.TireSize, TireType: tower.Tires.TireType})){
            this.Quote.Warnings.AddWithTargets(
                [`${this.getSideLabel()}Side.Tower[${tower.TowerNumber - 1}].Tires.TwelveLugs`],
                `${this.getSideLabel()} tower #${tower.TowerNumber} twelve lugs not supported in this location`);
        }
        
        if (!MaxigatorGuidanceOptionOk(tower.Guidance, {HasOuterTowerGuidance: this.bOuterTowerGuidance, IsGuidanceValid: bGuidanceValid})) {
            this.Quote.Warnings.AddWithTargets(
                [`${this.getSideLabel()}Side.Tower[${tower.TowerNumber - 1}].Guidance`],
                `${this.getSideLabel()} tower #${tower.TowerNumber} guidance not supported in this location`);
        }

        if (this.Quote.System.partsPackageId < "2024081901") {
            if (!TowerAirCompressorOK(tower.AirCompressor, {WrapAroundSpanType: tower.WrapAroundSpan, AquaValvesChecked: tower.AutomatedValves, ControlLineBoosterPumpChecked: tower.ControlLineBoosterPump})){
                this.Quote.Warnings.AddWithTargets(
                    [`${this.getSideLabel()}Side.Tower[${tower.TowerNumber - 1}].AirCompressor`],
                    `${this.getSideLabel()} tower #${tower.TowerNumber} air compressor not supported in this location`);
            }

            if (!TowerControlLineBoosterPumpOK(tower.ControlLineBoosterPump, {WrapAroundSpanType: tower.WrapAroundSpan, AquaValvesChecked: tower.AutomatedValves, AirCompressorChecked: tower.AirCompressor})){
                this.Quote.Warnings.AddWithTargets(
                    [`${this.getSideLabel()}Side.Tower[${tower.TowerNumber - 1}].ControlLineBoosterPump`],
                    `${this.getSideLabel()} tower #${tower.TowerNumber} control line booster pump not supported in this location`);
            }
        }
    }

    private getSideLabel = () => sidef.FlangedSide(this.Quote.System, this.CodeBehind.Side)
    ? "Flanged" : "Flex";
}