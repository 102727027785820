import { TireTypes, TowTypes, TowerHeights } from "../../../rdptypes/project/ISystemBase.AutoGenerated";
import * as ohf from "../OtherHelpers";
import QuoteClass from "../QuoteClass";
import { MaleRingLockOk, PipeBottomAdapter8to6OK, PipeBottomAdapterOK, TireTypeOK } from "../Valids.Valids";

export default class CenterPivotForm {
    constructor(private Quote: QuoteClass) {
    }

    public validate = (): void => {
        let centerPivot = this.Quote.System.Circle?.CenterPivot;
        if (!centerPivot){
            return;
        }

        if (centerPivot.TowOptions.TowType === TowTypes.FourWheelPivotMover && !TireTypeOK(
            centerPivot.TowOptions.Tires.TireType, {
                TowerHeight: TowerHeights.Standard,
                PowerTowerTireSize: TireTypes.Recaps,
                TireSize: centerPivot.TowOptions.Tires.TireSize,
                IsA100: false,
                IsSACTower: false,
                IsSingleSpanMaxigator: false,
                IsInternational: ohf.IsInternational(this.Quote.System.DealerProperties)
        })) {
            this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.TowOptions.Tires.TireType"], `Unsupported tire type/size combination for 4 Wheel Pivot Mover`);
        }

        if (centerPivot.MaleRingLock){
            if (!MaleRingLockOk(centerPivot.MaleRingLock, {
                CenterPivotType: centerPivot.CenterPivotType,
                PipeBottomFittingType: centerPivot.PipeBottomFitting
            })){
                this.Quote.Warnings.AddWithTargets(["Circle.CenterPivot.MaleRingLock"], `Male ring lock cannot be selected for this center pivot configuration`);
            }
        }

        if((centerPivot.PipeBottomAdapter && !PipeBottomAdapterOK(true, {PipeBottomFittingType: centerPivot.PipeBottomFitting, IsAdapterChecked: centerPivot.PipeBottomAdapter, IsAdapter8to6Checked: centerPivot.PipeBottomAdapter8to6})) ||
            (centerPivot.PipeBottomAdapter8to6 && !PipeBottomAdapter8to6OK(true, {PipeBottomFittingType: centerPivot.PipeBottomFitting, IsAdapterChecked: centerPivot.PipeBottomAdapter, IsAdapter8to6Checked: centerPivot.PipeBottomAdapter8to6}))){
                //TODO - language and metric/imperial support
                this.Quote.Warnings.Add(`Cannot have  Adapter 8” to 6” and Adapter 6-5/8” to 8” available at the same time for Elbow/SAE`);
        }
    }
}