import { Text, View } from "@react-pdf/renderer";
import { t } from "i18next";
import IUserData from "rdptypes/IUserData";
import { IMiscItem, SystemTypes } from "rdptypes/project/ISystemBase.AutoGenerated";
import * as React from "react";
import { FC } from "react";
import PriceCalculator from "roedata/roe_migration/PriceCalculator";
import QuoteClass from "roedata/roe_migration/QuoteClass";
import { HasSwingArmCorner } from "roedata/roe_migration/SystemFunctions";
import { getDiscountConfigFromUserData, getGroupedPartsForSystem, proposalSectionIsAncillary } from "../../components/roe/componentRenderers/Proposal/ProposalHelpers";
import ISystem from "../../model/project/ISystem";
import DealerDataCtx from "../../userData/DealerDataCtx";
import { formatCents } from "../DocumentGenerationHelpers";
import { documentStyles } from "../styles";
import FixedWidthTable, { ICell, IRow } from "./table/FixedWidthTable";

export interface Props {
    system: ISystem;
    quote: QuoteClass;
    miscItems: IMiscItem[];
}

export const calculateTotalPricings = (system: ISystem, quote: QuoteClass, user: IUserData) => {

    let listPriceCentsMinusAncillary = 0;
    let totalListPrice = 0;

    let groupedParts = getGroupedPartsForSystem(system);
    for (let sectionId in groupedParts.partSections){
        groupedParts.partSections[sectionId].forEach((p) => {
            let cost = p.qty * user.priceList[p.partNumber].unitPriceUsdCents;
            totalListPrice += cost;
            if (!proposalSectionIsAncillary(parseInt(sectionId))){
                listPriceCentsMinusAncillary += cost;
            }
        });
    }

    //Mainline Valves

    system.MainlineValveOptions.Valves.MainlineValve.forEach((valve) => {
        let info = quote.MainlineValvesClass.GetMainlineValveInfo(valve, user.priceList);
        if (!isNaN(info.Price())){
            totalListPrice += info.Price();
        }
    });

    let swingArmPathPriceCents = 0;
    if (HasSwingArmCorner(quote.System)){
        swingArmPathPriceCents = quote.SwingArmClass.SpecialOrderPriceCents();
    }

    let pricing = system.QuoteProperties?.ProposalInformation?.pricing;
    let dc = getDiscountConfigFromUserData(user, system);
    let pc = new PriceCalculator(new QuoteClass(system), listPriceCentsMinusAncillary, dc, groupedParts.partSections, user.priceList);

    let systemPrice: number;
    if (pricing.listPriceDiscountPercent > 0){
        systemPrice = totalListPrice - (pricing.listPriceDiscountPercent/100) * totalListPrice;
       
    }
    else if (pricing.markupPercentOverDealerCost > 0){
        systemPrice = (pc.getTotalDealerPriceCents() - swingArmPathPriceCents) + ((pc.getTotalDealerPriceCents() - swingArmPathPriceCents) * (pricing.markupPercentOverDealerCost/100)) 
    }
    else if (pricing.markupDollarOverDealerCost > 0){
        systemPrice = (pc.getTotalDealerPriceCents() - swingArmPathPriceCents) + (pricing.markupDollarOverDealerCost * 100);
    }
    else if (pricing.quotedPriceDollar > 0){
        systemPrice = pricing.quotedPriceDollar * 100;
    }
    else {
        systemPrice = totalListPrice;
    }

    return {
        systemPrice, totalListPrice, swingArmPathPriceCents
    }
}

const TotalPricesTable: FC<Props> = (props) => {
    const user = React.useContext(DealerDataCtx);
    let {system, quote, miscItems } = props;
    const {
        systemPrice, totalListPrice, swingArmPathPriceCents
    } = calculateTotalPricings(system, quote, user);
    let pricing = system.QuoteProperties?.ProposalInformation?.pricing;


    let customerSavings = totalListPrice - systemPrice;
    let salesTaxMultiplier = pricing.salesTaxPercent > 0 ? (pricing.salesTaxPercent/100) : 1;

    let miscItemsCostCents = 0;
    let miscItemsTaxCents = 0;
    let miscItemsTaxable = false;
    system.QuoteProperties?.MiscItems?.forEach((mi) => {
        miscItemsCostCents += mi.priceCents;
        if (mi.taxable){
            miscItemsTaxable = true;
            miscItemsTaxCents += mi.priceCents * salesTaxMultiplier;
        }
    });

    let installation = pricing?.installation?.priceUsdCents ?? 0;
    let freight = pricing?.freight?.priceUsdCents ?? 0;
    let pivotPad = pricing?.pivotPad?.priceUsdCents ?? 0;
    let tradeIn = pricing?.tradeIn?.priceUsdCents ?? 0;


    let subTotalMinusTax = systemPrice + installation + freight + pivotPad + miscItemsCostCents + swingArmPathPriceCents;

    let tax = miscItemsTaxCents;

    if (pricing.installation.isTaxable) tax += installation * salesTaxMultiplier;
    if (pricing.freight.isTaxable) tax += freight * salesTaxMultiplier;
    if (pricing.pivotPad.isTaxable) tax += pivotPad * salesTaxMultiplier;
    if (pricing.system.isTaxable) tax += systemPrice * salesTaxMultiplier;
    if (pricing.tradeIn.isTaxable) tax -= tradeIn * salesTaxMultiplier;

    let rows: IRow[] = [];
    let ns = "common-phrases."

    let showListPrice = pricing?.printListPrice;
    let showTotalPriceOnly = pricing?.onlyPrintTotalPrice;
    
    if (showListPrice){
        rows.push({cells: [{
            text: "",
            widthPc: 46
        }, {
            text: t(ns + "sltTotalListPrice"),
            widthPc: 24
        }, {
            text: formatCents(totalListPrice),
            textAlignRight: true,
            widthPc: 24
        }]});

        rows.push({cells: [{
            text: "",
            widthPc: 46
        }, {
            text: t(ns + "sltDiscount"),
            widthPc: 24
        }, {
            text: formatCents(customerSavings),
            textAlignRight: true,
            widthPc: 24
        }]});
    }

    let cells: ICell[] = [{
        text: "",
        widthPc: 46
    }, {
        text: t(ns + "sltCustomerPrice"),
        widthPc: 24
    }, {
        text: formatCents(systemPrice + swingArmPathPriceCents),
        textAlignRight: true,
        widthPc: 24
    }];
    
    if (pricing?.system?.isTaxable) {
        cells.push({text: "T", widthPc: 6});
    }

    rows.push({cells});

    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltInstallation"),
        widthPc: 24
    }, {
        text: showTotalPriceOnly ? t(ns + "sltIncluded") : formatCents(installation),
        widthPc: 24,
        textAlignRight: true
    }];

    if (!showTotalPriceOnly && pricing.installation?.isTaxable){
        cells.push({text: "T", widthPc: 6});
    }

    rows.push({cells});

    cells = [{
            text: "",
            widthPc: 46
    },{
        text: t(ns + "sltFreight"),
        widthPc: 24
    }, {
        text: showTotalPriceOnly ? t(ns + "sltIncluded") : formatCents(freight),
        widthPc: 24,
        textAlignRight: true
    }];

    if (!showTotalPriceOnly && pricing.freight?.isTaxable){
        cells.push({text: "T", widthPc: 6});
    }

    rows.push({cells});

    if (quote.System.SystemProperties.SystemType !== SystemTypes.Ancillary){
        cells = [{
            text: "",
            widthPc: 46
        },{
            text: t(ns + "sltPivotPad"),
            widthPc: 24
        }, {
            text: showTotalPriceOnly ? t(ns + "sltIncluded") : formatCents(pivotPad),
            widthPc: 24,
            textAlignRight: true
        }];
        
        if (pricing.pivotPad?.isTaxable){
            cells.push({text: "T", widthPc: 6});
        }

        rows.push({cells});
    }

    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltMiscItems"),
        widthPc: 24
    }, {
        text: formatCents(miscItemsCostCents),
        widthPc: 24,
        textAlignRight: true,
        bottomBorder: true
    }];

    if (miscItems.filter(x => x.taxable).length > 0){
        cells.push({text: "T", widthPc: 6});
    }

    rows.push({cells});

    rows.push({cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltPrice"),
        widthPc: 24,
        header: true
    }, {
        text: formatCents(subTotalMinusTax),
        widthPc: 24,
        textAlignRight: true,
        header: true
    }]});

    let elements: JSX.Element[] = [<FixedWidthTable
        data={{rows}}
    />];

    elements.push(<View style={{marginBottom: 10}}/>);

    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltTradeIn"),
        widthPc: 24
    },{
        text: formatCents(tradeIn),
        textAlignRight: true,
        widthPc: 24
    }];

    if (pricing.tradeIn?.isTaxable){
        cells.push({text: "T", widthPc: 6});
    }

    rows = [{cells}];

    subTotalMinusTax -= tradeIn;
    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltSubTotal"),
        widthPc: 24
    },{
        text: formatCents(subTotalMinusTax),
        textAlignRight: true,
        widthPc: 24
    }];

    rows.push({cells});
    
    cells = [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltSalesTax"),
        widthPc: 24
    },{
        text: formatCents(tax),
        textAlignRight: true,
        widthPc: 24,
        bottomBorder: true
    }];

    rows.push({cells});
    let totalCents = subTotalMinusTax + tax;
    rows.push({cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltTotalPrice"),
        widthPc: 24,
        header: true
    }, {
        text: formatCents(totalCents),
        widthPc: 24,
        textAlignRight: true,
        header: true,
    }]});

    elements.push(<FixedWidthTable
        data={{rows}}
    />);
    elements.push(<View style={{marginBottom: 10}}/>);

    let downPaymentMultiplier = pricing.paymentComputationMethod === "Percent" ? (pricing?.downPayment?.percent ? pricing.downPayment.percent/100 : 0) : 0;
    let downPaymentCents = pricing.paymentComputationMethod === "Price" ? (pricing?.downPayment?.usdCents ?? 0) : (totalCents * downPaymentMultiplier);

    rows = [{cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltDownPayment"),
        widthPc: 24
    },{
        text: formatCents(downPaymentCents),
        textAlignRight: true,
        widthPc: 24
    }]}];

    let dueOnDeliveryMultiplier = pricing.paymentComputationMethod === "Percent" ? (pricing?.dueOnDelivery?.percent ? pricing.dueOnDelivery.percent/100 : 0) : 0;
    let dueOnDeliveryCents = pricing.paymentComputationMethod === "Price" ? (pricing?.dueOnDelivery?.usdCents ?? 0) : (totalCents * dueOnDeliveryMultiplier);

    rows.push({cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltDueOnDelivery"),
        widthPc: 24
    },{
        text: formatCents(dueOnDeliveryCents),
        textAlignRight: true,
        widthPc: 24
    }]});

    rows.push({cells: [{
        text: "",
        widthPc: 46
    },{
        text: t(ns + "sltDueOnInstallation"),
        widthPc: 24
    },{
        text: formatCents(totalCents - downPaymentCents - dueOnDeliveryCents),
        textAlignRight: true,
        widthPc: 24
    }]});

    elements.push(<FixedWidthTable
        data={{rows}}
    />);

    if (system.QuoteProperties.ProposalInformation?.factoryNotes){
        elements.push(<View style={{paddingLeft: 15, paddingRight: 15}}><Text>{t(ns + "sltFactoryNotes")}: {system.QuoteProperties.ProposalInformation.factoryNotes}
            </Text>
        </View>);
    }

    if (system.QuoteProperties.ProposalInformation?.tradeinNotes){
        elements.push(<View style={{paddingLeft: 15, paddingRight: 15}}><Text>
            {t(ns + "sltTradeInNotes")}: {system.QuoteProperties.ProposalInformation.tradeinNotes}
            </Text>
        </View>);
    }
    
    let paymentElements: JSX.Element[] = [];

    paymentElements.push(<Text style={{marginBottom: 10}}>
        {t(ns + "lsltPaymentShallBe")}
    </Text>)

    if (HasSwingArmCorner(quote.System)){
        paymentElements.push(<Text style={{marginBottom: 10}}>
            {t(ns + "lsltSACAcresTerms")}
        </Text>)
    }

    if (system.QuoteProperties.ProposalInformation?.hasMidAmericaRiskInsurance){
        paymentElements.push(<Text style={{marginBottom: 10}}>
            {t(ns + "sltFullReplacementValueInsurance")}
        </Text>)
    }

    paymentElements.push(<Text>
        {t(ns + "lsltConfidential")}
    </Text>)

    return (<View>
            <View wrap={false} style={documentStyles.section}>
                <View style={[documentStyles.heading, {marginBottom: 10}]}>
                        <Text style={documentStyles.sectionHeader}>{t(ns + "sltInvestment")}</Text>
                </View>
                {elements}
            </View>
            <View wrap={false} style={{...documentStyles.section}}>
                <View style={[documentStyles.heading, {marginBottom: 10}]}>
                        <Text style={documentStyles.sectionHeader}>{t(ns + "sltPaymentTerms")}</Text>
                </View>
                <View style={{paddingLeft: 15, paddingRight: 15}}>
                    {paymentElements}
                </View>
            </View>
        
        </View>
    );
};

export default TotalPricesTable;