import * as React from "react";
import { FC } from "react";

interface Props {
    fill: boolean;
    x: number;
    y: number;
    height: number;
    width: number;
};

const Rectangle: FC<Props> = (props) => {
    return (
        <rect
            x={props.x}
            y={props.y}
            width={props.width}
            height={props.height}
            stroke="black"
            stroke-width="1"
            fill={props.fill? "#1976d2" : "white"}
        />
    );
  };

  export default Rectangle;