import IAction from "rdptypes/IAction";
import { createNewMultiAction } from "../../actions/MultiAction";
import { createNewUpdateSystemPropertyAction } from "../../actions/UpdateSystemProperty";
import IAuthState from "../../auth/IAuthState";
import { SystemValidationResult } from "../../components/roe/validation/SystemValidator";
import IDbProject from "../../db/IDbProject";
import { setValue } from "../../helpers/objectPathResolver";
import ISystem from "../../model/project/ISystem";

export const improveScores = (
    system: ISystem,
    authState: IAuthState,
    dbPrj: IDbProject,
    layoutId: string,
    systemId: string,
    vr: SystemValidationResult,
    changingFieldPaths: string[]): IAction | undefined => {
    // We apply improvements to the system passed in, not the system from the DB,
    // because the DB might not have been updated at the moment this runs.
    const updatedSystem = JSON.parse(JSON.stringify(system));

    const actions: IAction[] = [];
    for (const fp of vr.getFieldPaths()) {
        // Don't change straight back
        if (changingFieldPaths.some(x => x === fp)) continue;

        const vrf = vr.getField(fp);
        if (vrf.reccomendChange) {
            vrf.severity = undefined;
            vrf.reccomendChange = false;
            if (vrf.isComplex) {
                const recommendedValues = JSON.parse(vrf.recommendedValue);
                for (const [fieldPath, value] of Object.entries(recommendedValues)) {
                    actions.push(
                        createNewUpdateSystemPropertyAction(
                            layoutId,
                            systemId,
                            fieldPath,
                            value,
                            authState
                        )
                    );
                    setValue(updatedSystem, fieldPath, value);
                }
            }
            else {
                actions.push(
                    createNewUpdateSystemPropertyAction(
                        layoutId,
                        systemId,
                        fp,
                        vrf.recommendedValue,
                        authState
                    )
                );
                setValue(updatedSystem, fp, vrf.recommendedValue);
            }
        }
    }
    if (actions.length) {
        return createNewMultiAction(actions, authState);
    } else {
        return undefined;
    }
}