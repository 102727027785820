import * as React from "react";
import { Outlet, useParams } from "react-router-dom";
import { GeometryProvider } from "../../GeometryHelpers/GeometryProvider";
import DbCtx from "../../db/DbCtx";
import EndGunWorkerContext from "../../workers/EndGunWorker";
import SacWorkerContext from "../../workers/SacWorker";

const Layout = () => {
    const params = useParams();
    const dbCtx = React.useContext(DbCtx);
    const  { projectId, layoutId } = params;
    return (
        <SacWorkerContext projectId={projectId} layoutId={layoutId}>
            <EndGunWorkerContext projectId={projectId} layoutId={layoutId}>
                <GeometryProvider
                    project={dbCtx.projects[projectId]?.state}
                    layoutId={layoutId}
                >
                    <Outlet />
                </GeometryProvider>
            </EndGunWorkerContext>
        </SacWorkerContext>
    );
}

export default Layout;