import IPartsCatalogEntry, { ProposalSection } from "rdptypes/roe/IPartsCatalogEntry";

type TPartsDictionary = { [partNumber: string]: IPartsCatalogEntry };

const partsCatalog: TPartsDictionary = {
    "394101-G": {
        description: null,
        mapicsDescription: "PVT CTR 10\" EII GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394101-GR": {
        description: null,
        mapicsDescription: "PVT CTR 10\" RAMS EII GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394101-G",
    },
    "394102-G": {
        description: null,
        mapicsDescription: "PVT CTR 10\" LP EII GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394102-GR": {
        description: null,
        mapicsDescription: "PVT CTR 10\" LP RAMS EII GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection. Upgrade,
        upgradeToPartNumber: "394102-G",
    },
    "394201-G": {
        description: null,
        mapicsDescription: "PVT CTR 8\" MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394201-GR": {
        description: null,
        mapicsDescription: "PVT CTR 8\" RAMS MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394201-G",
    },
    "394202-G": {
        description: null,
        mapicsDescription: "PVT CTR 8\" LP MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394202-GR": {
        description: null,
        mapicsDescription: "PVT CTR 8\" LP RAMS MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394202-G",
    },
    "394203-G": {
        description: null,
        mapicsDescription: "PVT CTR 8\" SG MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394203-GR": {
        description: null,
        mapicsDescription: "PVT CTR 8\" SG RAMS MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394203-G",
    },
    "394501": {
        description: "PVT CTR 6 5/8\" 3 LEGS E2045",
        mapicsDescription: "PVT CTR 6-5/8\" 3 LEGS E2045",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, 3 Legs",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394301-G": {
        description: "PVT CTR 6 5/8\" EII GALV",
        mapicsDescription: "PVT CTR 6-5/8\" MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394301-GR": {
        description: "PVT CTR 6 5/8\" RAMS EII GALV",
        mapicsDescription: "PVT CTR 6-5/8\" RAMS MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394301-G",
    },
    "394302-G": {
        description: "PVT CTR 6 5/8\" LP EII GALV",
        mapicsDescription: "PVT CTR 6-5/8\" LP MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394302-GR": {
        description: "PVT CTR 6 5/8\" LP RAMS EII GAL",
        mapicsDescription: "PVT CTR 6-5/8\" LP RAMS MF G",
        customerReportsDescription: "Pivot Center, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394302-G",
    },
    "394303-G": {
        description: "PVT CTR 6 5/8\" SG EII GALV",
        mapicsDescription: "PVT CTR 6-5/8\" SG MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394303-GR": {
        description: "PVT CTR 6 5/8\" RAMS SG EII GALV",
        mapicsDescription: "PVT CTR 6-5/8 RAMS SG MF G",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394303-G",
    },
    "394199": {
        description: "PVT CTR 8 5/8\" RAMS SS EII",
        mapicsDescription: "PVT CTR 8-5/8 RAMS SS MF",
        customerReportsDescription: "Pivot Center, Galvanized, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394401-G",
    },
    "394200": {
        description: "PVT CTR 8 5/8\" SS EII",
        mapicsDescription: "PVT CTR 8-5/8 SS MF",
        customerReportsDescription: "Pivot Center, Galvanized, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394401-G",
    },
    "394201SS": {
        description: null,
        mapicsDescription: "PVT CTR 8\" SS MF",
        customerReportsDescription: "Pivot Center, Galvanized, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394201-G",
    },
    "394201RSS": {
        description: null,
        mapicsDescription: "PVT CTR 8\" RAMS SS",
        customerReportsDescription: "Pivot Center, Galvanized, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394201-G",
    },
    "394107": {
        description: "PVT RISER 10\" LONG CTAR EII",
        mapicsDescription: "PVT RISER 10\" LONG CTAR EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394105",
    },
    "394108": {
        description: "PVT CTR 8 5/8\" LP S2085 RAMS",
        mapicsDescription: "PVT CTR 8-5/8\" LP S2085 RAMS",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394402-G",
    },
    "394109": {
        description: "PVT CTR 8 5/8\" SG S2085",
        mapicsDescription: "PVT CTR 8-5/8\" SG S2085",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394110": {
        description: "PVT CTR 8 5/8\" SG S2085 RAMS",
        mapicsDescription: "PVT CTR 8-5/8\" SG S2085 RAMS",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394109",
    },
    "394301SS": {
        description: "PVT CTR 6 5/8\" EII SS",
        mapicsDescription: "PVT CTR 6-5/8\" MF SS",
        customerReportsDescription: "Pivot Center, Galvanized, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394301-G",
    },
    "394301RSS": {
        description: "PVT CTR 6 5/8\" RAMS SS EII",
        mapicsDescription: "PVT CTR 6-5/8\" RAMS SS MF",
        customerReportsDescription: "Pivot Center, Galvanized, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394301-G",
    },
    "394302SS": {
        description: "PVT CTR 6 5/8\" LP SS",
        mapicsDescription: "PVT CTR 6-5/8\" LP SS",
        customerReportsDescription: "Pivot Center, Galvanized, Low Profile, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394302-G",
    },
    "394302RSS": {
        description: "PVT CTR 6 5/8\" LP SS RAMS EII",
        mapicsDescription: "PVT CTR 6-5/8\" LP SS RAMS MF",
        customerReportsDescription: "Pivot Center, Galvanized, Low Profile, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394302-G",
    },
    "394303SS": {
        description: null,
        mapicsDescription: "PVT CTR 6 5/8\" SG MF SS",
        customerReportsDescription: "Pivot Center, Galvanized, High Profile, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394303-G",
    },
    "394303RSS": {
        description: "PVT CTR 6 5/8\" RAMS SG EII SS",
        mapicsDescription: "PVT CTR 6-5/8 RAMS SG MF SS",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, High Profile, Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394303-G",
    },
    "394401-G": {
        description: "PVT CTR 8 5/8\" EII GALV",
        mapicsDescription: "PVT CTR 8-5/8\" MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394401-GR": {
        description: "PVT CTR 8 5/8\" RAMS EII GALV",
        mapicsDescription: "PVT CTR 8-5/8\" RAMS MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394401-G",
    },
    "394402-G": {
        description: "PVT CTR 8 5/8\" LP EII GALV",
        mapicsDescription: "PVT CTR 8-5/8\" LP MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394402-GR": {
        description: "PVT CTR 8 5/8\" LP RAMS EII GAL",
        mapicsDescription: "PVT CTR 8-5/8\" LP RAMS MF G",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394402-G",
    },
    "394403-G": {
        description: "PVT CTR 8 5/8\" SG EII GALV",
        mapicsDescription: "PVT CTR 8-5/8\" SG MF GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394403-GR": {
        description: "PVT CTR 8 5/8\" SG RAM EII GALV",
        mapicsDescription: "PVT CTR 8-5/8\" SG RAM MF GLV",
        customerReportsDescription: "Pivot Center, Galvanized, Heavy Duty, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394403-G",
    },
    "394103-G": {
        description: null,
        mapicsDescription: "PVT CTR 10\" SG EII GALV",
        customerReportsDescription: "Pivot Center SG, Galvanized, Heavy Duty",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394103-GR": {
        description: null,
        mapicsDescription: "PVT CTR 10\" RAMS SG EII GALV",
        customerReportsDescription: "Pivot Center SG, Galvanized, Heavy Duty",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394103-G",
    },
    "394203RSS": {
        description: null,
        mapicsDescription: "PVT CTR 8\" SG MF RAMS SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394203-G",
    },
    "394203SS": {
        description: "PVT CTR 8\" SG  EII SS",
        mapicsDescription: "PVT CTR 8\" SG MF SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394203-G",
    },
    "394403RSS": {
        description: null,
        mapicsDescription: "PVT CTR 8-5/8\" SG MF RAMS SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394403-G",
    },
    "394403SS": {
        description: null,
        mapicsDescription: "PVT CTR 8-5/8\" SG MF SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394403-G",
    },
    "394105": {
        description: "PIVOT RISER PIPE-LONG-10\"-EII",
        mapicsDescription: "PIVOT RISER PIPE-LONG-10\"- EII",
        customerReportsDescription: "Riser Pipe-Long-10\" Dia.-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394106": {
        description: null,
        mapicsDescription: "PIVOT RISER PIPE SHORT-10\" EII",
        customerReportsDescription: "Riser Pipe-Short-10\" Dia.-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394305": {
        description: "PIVOT RISER PIPE-LONG-8\"-EII",
        mapicsDescription: "PIVOT RISER PIPE- LONG-8\"- EII",
        customerReportsDescription: "Riser Pipe-Long-8\" Dia.-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394306": {
        description: "PIVOT RISER PIPE-SHORT-8\"-EII",
        mapicsDescription: "PIVOT RISER PIPE-SHORT-8\"- EII",
        customerReportsDescription: "Riser Pipe-Short-8\" Dia.-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394306SS": {
        description: null,
        mapicsDescription: "PVT RISER PIPE-SHORT 8\" SS EII",
        customerReportsDescription: "Riser Pipe-Short-SS-8\" Dia.-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394306",
    },
    "394305SS": {
        description: null,
        mapicsDescription: "PVT RISER PIPE-LONG-8\" SS EII",
        customerReportsDescription: "Riser Pipe-Long-SS-8\" Dia.-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394305",
    },
    "394307": {
        description: null,
        mapicsDescription: "PIVOT RISER PIPE-SHT 8\" 316SS",
        customerReportsDescription: "Riser Pipe-Short-316SS-8\" Dia.-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394306",
    },
    "394337": {
        description: null,
        mapicsDescription: "RISER PIPE-8\" PVT-6\" SLV VLV",
        customerReportsDescription: "Pivot Riser 8\" For 6\" Nelson 800P Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394306",
    },
    "394338": {
        description: null,
        mapicsDescription: "RISER PIPE-SHORT-6\" EII",
        customerReportsDescription: "Riser Pipe-Short-6\" Dia.-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394339": {
        description: null,
        mapicsDescription: "RISER PIPE-LONG-6\" EII",
        customerReportsDescription: "Riser Pipe-Long-6\" Dia.-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353266": {
        description: null,
        mapicsDescription: "FLOW TUBE-8\" X 14\"-MCRMTR PROP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353265": {
        description: null,
        mapicsDescription: "FLOW TUBE-8\" X 14\"-MCROMTR MAG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353267": {
        description: null,
        mapicsDescription: "FLOW TUBE-8\" X 34\" W/VANES",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353268": {
        description: null,
        mapicsDescription: "FLOW TUBE-8\"-FOR FS100",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353283": {
        description: null,
        mapicsDescription: "SHORT 8\" RISER PIPE-FLOW METER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394306",
    },
    "353284": {
        description: null,
        mapicsDescription: "LONG 8\" RISER PIPE-FLOW METER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394305",
    },
    "394379": {
        description: null,
        mapicsDescription: "PIVOT RISER PIPE-SHRT-8-SG-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354155": {
        description: null,
        mapicsDescription: "SHORT 6\" FLANGED SG RISER PIPE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394387": {
        description: null,
        mapicsDescription: "PIVOT RISER PIPE-LONG-8-SG-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354156": {
        description: null,
        mapicsDescription: "LONG 6\" FLANGED SG RISER PIPE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354150": {
        description: null,
        mapicsDescription: "SHORT 6\" FLANGED RISER PIPE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354151": {
        description: null,
        mapicsDescription: "LONG 6\" FLANGED RISER PIPE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354153": {
        description: null,
        mapicsDescription: "SHORT 8\" FLANGED RISER PIPE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354154": {
        description: null,
        mapicsDescription: "LONG 8\" FLANGED RIDER PIPE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392353": {
        description: null,
        mapicsDescription: "PIVOT RISER 8\" 4WPM W/FILTERS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396125": {
        description: "PIVOT JOINT ASSY 10\" EII",
        mapicsDescription: "PIVOT JOINT ASSY 10 EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396225": {
        description: "PIVOT JOINT ASSY 8 5/8\" EII",
        mapicsDescription: "PIVOT JOINT ASSY 8-5/8 EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397034": {
        description: null,
        mapicsDescription: "JOINT PIVOT E2045 CN+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396594": {
        description: null,
        mapicsDescription: "JOINT PIVOT E2665G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396725": {
        description: null,
        mapicsDescription: "PIVOT JOINT ASSY-ALUMI 4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397025": {
        description: null,
        mapicsDescription: "PIVOT JOINT ASY-A80-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397107SS": {
        description: null,
        mapicsDescription: "PIVOT JOINT ASY S2085 EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396225",
    },
    "396024SS": {
        description: "PIVOT JOINT ASY 6 5/8\" SS EII",
        mapicsDescription: "PIVOT JOINT ASY 6-5/8 SS EII",
        customerReportsDescription: "Pivot Joint Assembly 6 5/8\" Stainless Steel EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396324",
    },
    "396549": {
        description: null,
        mapicsDescription: "PIVOT JOINT ASSY PL2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396137": {
        description: null,
        mapicsDescription: "PIVOT JOINT ASY PL2085",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396324": {
        description: null,
        mapicsDescription: "JOINT PIVOT E2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392101": {
        description: null,
        mapicsDescription: "ANCHOR BOLT PKG-NON TOW-E1060",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392102": {
        description: null,
        mapicsDescription: "ANCHOR CHAIN PKG-TOW-E1060",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392103": {
        description: "ANCHOR CHAIN PKG, E1060, 4WPM",
        mapicsDescription: "ANCHOR CHAIN PKG-E1060-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392301": {
        description: null,
        mapicsDescription: "ANCHOR BLT PK-NT-E88/65/A4/SUG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392302": {
        description: null,
        mapicsDescription: "ANCHOR CHAIN-PK-TOW-88/65/A/SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392306": {
        description: "ANCHOR CHAIN PK, E60/88/A4, 4WPM",
        mapicsDescription: "ANCHOR CHAIN PK-E60/88/A4-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392309-G": {
        description: null,
        mapicsDescription: "TOW SKID PACKAGE-NON-TOW PIVOT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392308": {
        description: null,
        mapicsDescription: "TURNBUCKLE PACKAGE-4 WH PVT MV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394320-G": {
        description: null,
        mapicsDescription: "ADAPTER CHAIN TIE DOWN-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392310": {
        description: null,
        mapicsDescription: "ANCHOR BLT PK-NT-E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394319-G": {
        description: null,
        mapicsDescription: "ADAPTER-COMPETITOR-PVT PADS",
        customerReportsDescription: "Channel Adapter Kit-Comp Pivot Pad",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394332": {
        description: null,
        mapicsDescription: "ADPTR KIT-EII SG TO E60 SG PAD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394309": {
        description: "GASKET 8\" PIVOT STANDARD",
        mapicsDescription: "GASKET 8 INCH PIVOT STANDARD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394310": {
        description: "GASKET 8\" PIVOT TRIPLE LIP",
        mapicsDescription: "GASKET 8 INCH PIVOT TRIPLE LIP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394309",
    },
    "394325": {
        description: "GASKET 10\" PVT SINGLE LIP",
        mapicsDescription: "GASKET 10 INCH PVT SINGLE LIP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394324": {
        description: "GASKET 10\" PVT TRIPLE LIP",
        mapicsDescription: "GASKET 10 INCH PVT TRIPLE LIP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394324",
    },
    "354332": {
        description: "ANCHOR BOLT PKG (4) 3/4\" X 10",
        mapicsDescription: "ANCHOR BOLT PKG (4) 3/4 X 10",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354333": {
        description: null,
        mapicsDescription: "ANCHOR BOLT PKG (4) 1 X 9",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392303-G": {
        description: null,
        mapicsDescription: "TOW SKID-PVT CTR-EII/A4",
        customerReportsDescription: "Towable Skids, Pivot Point",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392304-G": {
        description: null,
        mapicsDescription: "TOW SKID PKG-LP-ELECT-ALUMI/GA",
        customerReportsDescription: "Towable Skids, Pivot Point",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392305-G": {
        description: null,
        mapicsDescription: "TOW SKID PACKAGE-SG GAL",
        customerReportsDescription: "Towable Skids, Pivot Point",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351021": {
        description: "TOW KIT ACCESSORY #1 156' SPN",
        mapicsDescription: "TOW KIT ACCESSORY #1 156FT SPN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351040": {
        description: "TOW KIT ACCESSORY #1 175' SPN",
        mapicsDescription: "TOW KIT ACCESSORY #1 175FT SPN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351101": {
        description: "TOW KIT ACCESSORY #1 194' SPN",
        mapicsDescription: "TOW KIT ACCESSORY #1 194FT SPN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351100": {
        description: null,
        mapicsDescription: "TOW KIT ACCESSORY #1 213' SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351102": {
        description: "TOW KIT ACCESSORY #1 204' SPN",
        mapicsDescription: "TOW KIT ACCESSORY #1 204FT SPN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392351-G": {
        description: null,
        mapicsDescription: "PIVOT MOVER-4-WHEELED EII",
        customerReportsDescription: "Pivot Mover, 4 Wheel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392352": {
        description: null,
        mapicsDescription: "PIVOT MOVER-4-WHL W/FILTER EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352270-G": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR-RECAP-11X22.5-G",
        customerReportsDescription: "Tire Option, 11 x 22.5 Recap Tires-Tubeless, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352271-G": {
        description: null,
        mapicsDescription: "TIRE OPTION/TOWER-11.2 X 24-GA",
        customerReportsDescription: "Tire Option, 11.2 x 24 New Tires-Tubeless, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352271TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-11.2 X24-STD-G",
        customerReportsDescription: "Tire Option, 11.2 x 24 New Tires with Tubes, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352270TG": {
        description: null,
        mapicsDescription: "TIRE W/TUBE-TWR RECAP-11X22.5",
        customerReportsDescription: "Tire Option, 11 x 22.5 Recap Tires-w/Tubes, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "392307-G": {
        description: null,
        mapicsDescription: "EXTRA HITCH OPTION-4WPM EII",
        customerReportsDescription: "Extra Hitch, 4 Wheel Pivot Mover",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394321": {
        description: null,
        mapicsDescription: "WALKWAY-PVT CTR-EII",
        customerReportsDescription: "Walkway - Pivot Center Platform",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394370": {
        description: null,
        mapicsDescription: "MOUNTS-MCP LOW PROFILE PVT CTR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394371": {
        description: null,
        mapicsDescription: "MOUNTS-MCP STD HEIGHT PVT CTR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394372": {
        description: null,
        mapicsDescription: "MOUNTS-MCP SG HEIGHT PVT CTR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394511": {
        description: null,
        mapicsDescription: "FORM KIT E2045 PVT FOUNDATION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394311-G": {
        description: null,
        mapicsDescription: "ELBOW-BTM-PLAIN END-8\" GALV",
        customerReportsDescription: "Bottom Elbow-8\" Plain End-Galv",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394311SS": {
        description: null,
        mapicsDescription: "ELBOW-BTM-PLAIN END-8\" SS",
        customerReportsDescription: "Bottom Elbow-8\" Plain End-SS",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394312-G": {
        description: null,
        mapicsDescription: "ELBOW-BTM-SAE FLG-8\" GALV",
        customerReportsDescription: "Bottom Elbow-8\" SAE Flange-Galv",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394313-G": {
        description: null,
        mapicsDescription: "PIPE-STR-BTM PVT-8\" GALV",
        customerReportsDescription: "Bottom Straight Pipe-8\"-Galv",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394314": {
        description: "FLTR-INL 8\" -700 GPM 3/32\" RMCI",
        mapicsDescription: "FLTR-INL 8\" -700 GRM-3/32 RMCI",
        customerReportsDescription: "Filter, Mainline, 700 GPM Maximum",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394315": {
        description: "FLTR-INL 8\"-1200 GPM-3/32\" RMCI",
        mapicsDescription: "FLTR-INL 8\"-1200 GRM-3/32 RMCI",
        customerReportsDescription: "Filter, Mainline, 1200 GPM Maximum",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394318-G": {
        description: null,
        mapicsDescription: "ELBOW-BTM-PLAIN END-10\" GALV",
        customerReportsDescription: "Bottom Elbow-10\" Plain End-Galv",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394316": {
        description: "FLTR-INL 8\"-2000 GPM-3/32\" RMCI",
        mapicsDescription: "FLTR-INL 8\"-2000 GPM-3/32 RMCI",
        customerReportsDescription: "Filter, Mainline, 2000 GPM Maximum",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394317": {
        description: "FLTR-INL 10\"-2000 GPM-3/32\" RMCI",
        mapicsDescription: "FLTR-INL 10\"-2000GPM-3/32 RMCI",
        customerReportsDescription: "Filter, Mainline, 2000 GPM Maximum",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394312SS": {
        description: "ELBOW-BTM-SAE FLG-8\" SS",
        mapicsDescription: "ELBOW-BTM-SAE FLG-8\" SS",
        customerReportsDescription: "Bottom Elbow-8\" SAE Flange-SS",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394322-G": {
        description: null,
        mapicsDescription: "ELBOW-BTM-SAE FLG-10\"",
        customerReportsDescription: "Bottom Elbow-10\" SAE Flange-Galv",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394413-G": {
        description: null,
        mapicsDescription: "ELBOW-BTM W/8\" COUPLER GALV",
        customerReportsDescription: "Bottom Elbow-8\" Coupler-Galv",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394308": {
        description: "REDCR BTM ELBOW-8\"X 6 5/8\"",
        mapicsDescription: "REDCR BTM ELBOW-8 X 6 5/8",
        customerReportsDescription: "Bottom Elbow-8 to 6 5/8\" Reducing-SAE",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394323": {
        description: "ADAPTER-6 5/8\" TO 8\"",
        mapicsDescription: "ADAPTER-6 5/8 TO 8",
        customerReportsDescription: "Flange Adapter 6 5/8\" to 8\" SAE",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394326": {
        description: null,
        mapicsDescription: "ELBOW-BTM-PLAIN END 6\" GALV",
        customerReportsDescription: "Bottom Elbow-6\" Plain End-Galv",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394335": {
        description: null,
        mapicsDescription: "ELBOW-BTM-6\" SAE FLG GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394343": {
        description: null,
        mapicsDescription: "FILTER-RMCI 6\" 475 GPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394342": {
        description: null,
        mapicsDescription: "FILTER-RMCI 6\" 475 GPM OFFSET",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394410": {
        description: null,
        mapicsDescription: "8\" MALE RING LOCK OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394409": {
        description: null,
        mapicsDescription: "6\" MALE RING LOCK OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354152": {
        description: null,
        mapicsDescription: "HORIZ. 6\" FLANGED RISER PIPE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395815-G": {
        description: null,
        mapicsDescription: "POWER TWR CART-CANAL FEED-EII",
        customerReportsDescription: "Power Tower Cart Assy, Galv, Lateral Move, Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395816": {
        description: null,
        mapicsDescription: "POWER TOWER-SUGAR CANAL FEED",
        customerReportsDescription: "Power Tower Cart Assy, Galv, Lateral Move, Canal Feed, Sugargator",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392870": {
        description: null,
        mapicsDescription: "CANAL FEED CART W/16\" INTAKE",
        customerReportsDescription: "Power Tower Cart Assy, Galv, Lateral Move, Canal Feed, 16\" Intake",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392874": {
        description: null,
        mapicsDescription: "CANAL FEED,16\" INT,SLF CLN SCR",
        customerReportsDescription: "Power Tower Cart Assy, Galv, Lateral Move, Canal Feed, 16\" Intake, Self-Clean Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395818": {
        description: null,
        mapicsDescription: "POWER TWR CART-CF JCB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392467": {
        description: null,
        mapicsDescription: "HEL GEAR OPT STD SPD CANAL FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392468": {
        description: null,
        mapicsDescription: "HEL GEAR OPT HI SPD CANAL FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392467",
    },
    "352278-G": {
        description: "TIRE OPT/TWR, 14.9X24, 1LH&1RH, G",
        mapicsDescription: "TIRE OPT/TWR-14.9X24-1LH&1RH-G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Tires-Tubeless, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352278TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-14.9X24-L&R G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Tires with Tubes, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352275-G": {
        description: null,
        mapicsDescription: "TIRE OPT-TWR-14.9X24 TURF-TT-G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Turf Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352275TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-14.9X24-TURF G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Turf Tires with Tube, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352279-G": {
        description: "TIRE OPT/TWR, 16.9X24, 1LH&1RH, G",
        mapicsDescription: "TIRE OPT/TWR-16.9X24-1LH&1RH-G",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Tires, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352279TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-16.9X24-L&R G",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Tires with Tubes, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352126-G": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR-16.9X24 TURF-GALV",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Turf Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352126TG": {
        description: null,
        mapicsDescription: "WHL TIRE W/TUBE-16.9X24-TURF G",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Turf Tires with Tube, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352283TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-13.6X38-L&R G",
        customerReportsDescription: "Tire Option, 13.6 x 38 New Tires with Tubes, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352049TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-320/85R38-STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352049-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 320/85R38-STD GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352050TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-320/85R38-L&R",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352050-G": {
        description: null,
        mapicsDescription: "TIRE&WHEEL-320/85R38 1LH,1RH-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352051TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUB-320/85R38-2L&1R",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352051-G": {
        description: null,
        mapicsDescription: "TIRE & WHL-320/85R38 2LH,1RH-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352258TG": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR 14.9X24 NONDIR TT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352259-G": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR 14.9X24 NONDIR TL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352264TG": {
        description: null,
        mapicsDescription: "TIRE & WHEEL-14.9X24 ND TT 3WH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352264-G": {
        description: null,
        mapicsDescription: "TIRE & WHEEL-14.9X24 ND TL 3WH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352096-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 380/85R24-STD GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "353020": {
        description: null,
        mapicsDescription: "ENG OPT-DEERE 4045TFC03, 65 HP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353024": {
        description: null,
        mapicsDescription: "ENG OPT-DEERE 4045HFC93,109 HP",
        customerReportsDescription: "Engine-DEERE 4045HFC93, 109 HP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353025": {
        description: null,
        mapicsDescription: "ENG OPT-DEERE 6068HFC93,129 HP",
        customerReportsDescription: "Engine-DEERE 6068HFC93, 129 HP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393053": {
        description: "ENG OPT-DEERE 4045T-69 HP",
        mapicsDescription: "ENG OPT-DEERE 4045T, 69 HP",
        customerReportsDescription: "Engine-DEERE 4045T, 69 HP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393054": {
        description: "ENG OPT, JD 4045T, 250, 84 HP, T1",
        mapicsDescription: "ENG OPT-JD 4045T-250, 84 HP-T1",
        customerReportsDescription: "Engine-JD 4045T-250, 84 HP-T1",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393055": {
        description: null,
        mapicsDescription: "ENG OPT-JD 6068T-150,108 HP-T1",
        customerReportsDescription: "Engine-JD 6068T-150,108 HP-T1",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393057": {
        description: "ENG OPT, JD 6068T250, 150 HP, T1",
        mapicsDescription: "ENG OPT-JD 6068T250, 150 HP-T1",
        customerReportsDescription: "Engine-JD 6068T250, 150 HP-T1",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392846": {
        description: "ENG OPT, DEERE 6090H485, 255 HP",
        mapicsDescription: "ENG OPT-DEERE 6090H485, 255 HP",
        customerReportsDescription: "Engine-DEERE 6090H485, 255 HP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392840": {
        description: null,
        mapicsDescription: "ENG OPT-DEERE 4045TF290, 54 HP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393060": {
        description: null,
        mapicsDescription: "ENGINE-JCB CF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354021": {
        description: null,
        mapicsDescription: "FUEL FITTINGS KIT-CART CF JCB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392851": {
        description: null,
        mapicsDescription: "PUMP-CORNELL 5RBS",
        customerReportsDescription: "Pump, Cornell, 5RBS 1000-1800 GPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392852": {
        description: null,
        mapicsDescription: "PUMP-CORNELL 6RBS",
        customerReportsDescription: "Pump, Cornell, 6RBS 1800-3500 GPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392853": {
        description: null,
        mapicsDescription: "PUMP-CORNELL 8H",
        customerReportsDescription: "Pump, Cornell, 8H 3500-4500 gpm",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392854": {
        description: null,
        mapicsDescription: "PUMP-CORNEL 5YBH-1500-2500 GPM",
        customerReportsDescription: "Pump, Cornell, 5YBH 1500-2500 GPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392855": {
        description: null,
        mapicsDescription: "PUMP CORNEL 6YB-1800-3000 GPM",
        customerReportsDescription: "Pump, Cornell, 6YB 1800-3000 GPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392856": {
        description: null,
        mapicsDescription: "PUMP-CORNELL 10RB",
        customerReportsDescription: "Pump, Cornell, 10RB",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392858": {
        description: null,
        mapicsDescription: "PUMP-CORNELL 5YBQ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392859": {
        description: null,
        mapicsDescription: "PUMP-CORNELL 6YBQ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392862": {
        description: null,
        mapicsDescription: "PUMP-CORNELL 5YBQ F16",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392878": {
        description: "FUEL TNK W/GAUGE-300 GALLON-DF MX",
        mapicsDescription: "FUEL TNK W/GAUGE-300GAL-DF MX",
        customerReportsDescription: "Fuel Tank, 300 Gallon with Gauge",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392879": {
        description: "FUEL TNK W/GAUGE-550 GALLON-DF MX",
        mapicsDescription: "FUEL TNK W/GAUGE-550 GAL-DF MX",
        customerReportsDescription: "Fuel Tank, 550 Gallon with Gauge",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392835": {
        description: null,
        mapicsDescription: "GENERATOR ASY-12.5 KW-CANAL FD",
        customerReportsDescription: "Generator, 12.5 Kw",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392836": {
        description: null,
        mapicsDescription: "GENERATOR ASY-15KW-CANAL FEED",
        customerReportsDescription: "Generator, 15 Kw",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392837": {
        description: null,
        mapicsDescription: "GENERATOR ASY-20KW-CANAL FEED",
        customerReportsDescription: "Generator, 20 Kw",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392838": {
        description: null,
        mapicsDescription: "GENERATOR ASY-25KW-CANAL FEED",
        customerReportsDescription: "Generator, 25 Kw",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392839": {
        description: null,
        mapicsDescription: "GENERATOR ASY-30KW-CANAL FEED",
        customerReportsDescription: "Generator, 30 Kw",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392834": {
        description: null,
        mapicsDescription: "GENERATOR ASY-10 KW-CANAL FEED",
        customerReportsDescription: "Generator, 10 Kw",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392890": {
        description: null,
        mapicsDescription: "GENERATOR ASY-10KW-MECC - CF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392891": {
        description: null,
        mapicsDescription: "GENERATOR ASY-15KW-MECC - CF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392822": {
        description: null,
        mapicsDescription: "FLOAT OPTION-CANAL FEED",
        customerReportsDescription: "Screen, Water Intake with Float",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392823": {
        description: null,
        mapicsDescription: "SLF-CLN-SCN OPT 2900 GPM-5RB",
        customerReportsDescription: "Screen, Water Intake, Self Cleaning",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392822",
    },
    "392820": {
        description: "LIFT KIT-DLR. SUPPLIED SCREEN",
        mapicsDescription: "LIFT KIT, DLR. SUPPLIED SCREEN",
        customerReportsDescription: "Lift Kit, Dlr. Supplied Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392825": {
        description: "LIFT KIT-DLR. SUPPLIED SCREEN",
        mapicsDescription: "LIFT KIT, DLR. SUPPLIED SCREEN",
        customerReportsDescription: "Lift Kit, Dlr. Supplied Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392830": {
        description: null,
        mapicsDescription: "SLF-CLN-SCN OPT 2900 GPM 6RB",
        customerReportsDescription: "Screen, Water Intake, Self Cleaning",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392822",
    },
    "392867": {
        description: null,
        mapicsDescription: "FLOAT OPT-16\" SUCTION-CANAL FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392822",
    },
    "392872": {
        description: null,
        mapicsDescription: "FLOAT OPT,SLF-CLN,16\" SUC CF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392822",
    },
    "392829": {
        description: null,
        mapicsDescription: "BOOST PUMP OPT-SELF CLEAN SCRN",
        customerReportsDescription: "Screen, Water Intake, Self Cleaning with Booster Pump",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392850": {
        description: null,
        mapicsDescription: "BOOST PUMP-16\" SELF CLEAN SCRN",
        customerReportsDescription: "Boost Pump-16\" Self Cleaning Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392832": {
        description: null,
        mapicsDescription: "PRIMER OPT-460 VAC-CANAL FEED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392821": {
        description: null,
        mapicsDescription: "CHECK VALVE OPT-12\" CANAL FEED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395855-G": {
        description: null,
        mapicsDescription: "CENTER FEED OPT-CANAL FD-EII",
        customerReportsDescription: "Center Feed, Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395857-G",
    },
    "395856-G": {
        description: null,
        mapicsDescription: "CENTER FEED W/VALVE-CNL FD-EII",
        customerReportsDescription: "Center Feed, w/Valve, Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395857-G",
    },
    "395857-G": {
        description: null,
        mapicsDescription: "END FEED OPTION-CANAL FEED-EII",
        customerReportsDescription: "End Feed, Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395855-14": {
        description: null,
        mapicsDescription: "CEN FD OPT-CNL FD W/14C-EII",
        customerReportsDescription: "Center Feed, w/14 cond, Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395857-G",
    },
    "395856-14": {
        description: null,
        mapicsDescription: "CEN FD-CNL FD W/VLV & 14C-EII",
        customerReportsDescription: "Center Feed, w/14 cond & Valve, Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395857-G",
    },
    "392865": {
        description: "CEN CANAL FEED OPT-10\" X 8\" WYE",
        mapicsDescription: "CEN CANAL FEED OPT-10\"X8\" WYE",
        customerReportsDescription: "Center Feed, 10\" x 8\" WYE, Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392865-14": {
        description: "CEN CANAL FD-10\" X 8\" WYE W/14C",
        mapicsDescription: "CEN CANAL FD-10\"X8\" WYE W/14C",
        customerReportsDescription: "Center Feed, 10\" x 8\" WYE, w/14 cond, Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395942": {
        description: null,
        mapicsDescription: "CENTER FEED OPT-SUGAR CANAL FD",
        customerReportsDescription: "Center Feed, Sugargator Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395943": {
        description: null,
        mapicsDescription: "CENTER FEED W/VLV-SUGAR CANAL",
        customerReportsDescription: "Center Feed, w/Valve, Sugargator Canal Feed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395942-14": {
        description: null,
        mapicsDescription: "CENTER FEED 14C-SUGAR CANAL FD",
        customerReportsDescription: "Center Feed, w/14 cond, Sugargator Canal Feed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395943-14": {
        description: null,
        mapicsDescription: "CEN FEED W/VLV-14C-SUGAR CANAL",
        customerReportsDescription: "Center Feed, w/14 cond & Valve, Sugargator Canal Feed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392848-G": {
        description: "4' EXTENSION OPTION-CANAL FD",
        mapicsDescription: "4 FT EXTENSION OPTION-CANAL FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392849-G": {
        description: "6' EXTENSION OPTION-CANAL FD",
        mapicsDescription: "6 FT EXTENSION OPTION-CANAL FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392868": {
        description: null,
        mapicsDescription: "4' EXTENSION-16\" SUCTION PIPE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392869": {
        description: null,
        mapicsDescription: "6' EXTENSION-16\" SUCTION PIPE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395853-G": {
        description: "EXT-FLG WYE-8 5/8\" CANAL FD-EII",
        mapicsDescription: "EXT-FLG WYE-8 5/8-CANAL FD-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395854-G": {
        description: "WYE EXT-REDUCR-8\"-6 5/8\"-CF-EII",
        mapicsDescription: "WYE EXT-REDUCR-8\"-6 5/8-CF-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395913PL": {
        description: null,
        mapicsDescription: "TOWER TOP REDUCER-S2065PL-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395858-G": {
        description: "WYE JOINT-8 5/8\"-CANAL FEED-EII",
        mapicsDescription: "WYE JOINT-8 5/8-CANAL FEED-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395859-G": {
        description: "WYE JNT-8 5/8\"-6 5/8\"-CNL FD-EII",
        mapicsDescription: "WYE JNT-8 5/8-6 5/8-CNL FD-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395864": {
        description: null,
        mapicsDescription: "WYE JNT-CANAL FD-8\" TO A80G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395863": {
        description: null,
        mapicsDescription: "WYE JNT-CANAL FD-8\" TO A4/A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395862SS": {
        description: null,
        mapicsDescription: "WYE JNT-CNL FD-2085G/S2065G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395862PL": {
        description: null,
        mapicsDescription: "WYE JNT-CNL FD-2085G-S2065PL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394801-G": {
        description: null,
        mapicsDescription: "PIVOT TOWER ASY-KWIK TOW-GALV",
        customerReportsDescription: "Pivot Center, Galvanized, Towable, 2 Wheel Kwik Tow",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394801SS": {
        description: null,
        mapicsDescription: "PIVOT TOWER ASY-KWIK TOW-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "394801-G",
    },
    "352269-G": {
        description: null,
        mapicsDescription: "TIRE/WHEEL ASY-RECAPS-LH1RH-G",
        customerReportsDescription: "Tire Option, 11 x 22.5 Recap Tires-Tubeless, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352272-G": {
        description: "TIRE/WHEEL, 11.2X24, 1LH, 1RH, G",
        mapicsDescription: "TIRE/WHEEL-11.2X24-1LH, 1RH-G",
        customerReportsDescription: "Tire Option, 11.2 x 24 New Tires-Tubeless, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352272TG": {
        description: null,
        mapicsDescription: "WHL TIRE W/TUBE-11.2X24-L&RR G",
        customerReportsDescription: "Tire Option, 11.2 x 24 New Tires with Tubes, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352274-G": {
        description: null,
        mapicsDescription: "TIRE OPTION/TOWER-14.9X24-LH-G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Tires-Tubeless, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352274TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-14.9X24-STD-G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Tires with Tubes, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352269TG": {
        description: "TIRE, WHL, TUBE, 11X22.5, 1LH,1RH",
        mapicsDescription: "TIRE-WHL-TUBE-11X22.5-1LH,1RH",
        customerReportsDescription: "Tire Option, 11 x 22.5 Recap Tires-w/Tubes, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "392801-G": {
        description: null,
        mapicsDescription: "PIVOT ANCHOR-KWIK TOW-GALV",
        customerReportsDescription: "Anchors, Additional, Pivot Center, Kwik Tow",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392871": {
        description: null,
        mapicsDescription: "POWER KIT-ONE WHEEL-KT PIVOT",
        customerReportsDescription: "Pivot Center, Kwik Tow, Motorized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396724-G": {
        description: null,
        mapicsDescription: "PIVOT TOWER TOP-KT A80G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396329-G": {
        description: null,
        mapicsDescription: "PIVOT TOWER TOP-KT-6 5/8\" EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396729-G": {
        description: null,
        mapicsDescription: "PIVOT TOWER TOP-KT ALUMI 4-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396229-G": {
        description: "PVT TWR TOP-KT-8 5/8\"-GALV-EII",
        mapicsDescription: "PVT TWR TOP-KT-8 5/8-GALV-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396329SS": {
        description: null,
        mapicsDescription: "PIVOT TOWER TOP-KT S2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396329-G",
    },
    "392805-G": {
        description: "TOW ROD EXT-118' KT-E88/65/60-G",
        mapicsDescription: "TOW ROD EXT-118 KT-E88/65/60-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392806-G": {
        description: "TOW ROD EXT-137' KT-E88/65/60-G",
        mapicsDescription: "TOW ROD EXT-137 KT-E88/65/60-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392802-G": {
        description: "TOW ROD EXT-140' KT-E88/65/60-G",
        mapicsDescription: "TOW ROD EXT-140 KT-E88/65/60-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392807-G": {
        description: "TOW ROD EXT-156' KT-E88/65/60-G",
        mapicsDescription: "TOW ROD EXT-156 KT-E88/65/60-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392803-G": {
        description: "TOW ROD EXT-160' KT-E65/60-GALV",
        mapicsDescription: "TOW ROD EXT-160 KT-E65/60-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392808-G": {
        description: "TOW ROD EXT-175' KT-E65/60-GALV",
        mapicsDescription: "TOW ROD EXT-175 KT-E65/60-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392804-G": {
        description: "TOW ROD EXT-180' KT-E65/60-GALV",
        mapicsDescription: "TOW ROD EXT-180 KT-E65/60-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392810-G": {
        description: "TOW ROD EXT-186.7' KT-E65/60-G",
        mapicsDescription: "TOW ROD EXT-186.7 KT-E65/60-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392809-G": {
        description: "TOW ROD EXT-194' KT-E65/60-GALV",
        mapicsDescription: "TOW ROD EXT-194 KT-E65/60-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353349": {
        description: null,
        mapicsDescription: "HYDRAULIC LIFT OPTION-KWIK TOW",
        customerReportsDescription: "Hydraulic Lift",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395810-G": {
        description: null,
        mapicsDescription: "TOWER ASY-MASTER-MAXI-EII",
        customerReportsDescription: "Power Tower Cart, Galvanized, Lateral Move, Hose Feed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395822": {
        description: null,
        mapicsDescription: "PWR TWR-8\" MAXI W/O VALVE-EII",
        customerReportsDescription: "Power Tower Cart, Galvanized, Lateral Move, Hose Feed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395821": {
        description: null,
        mapicsDescription: "PWR TWR-8\" MAXI W/8\" VALVE EII",
        customerReportsDescription: "Power Tower Cart, Galvanized, Lateral Move, Hose Feed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395822",
    },
    "395831-G": {
        description: null,
        mapicsDescription: "POWER TWR ASY-SUGAR MAXI-EII",
        customerReportsDescription: "Power Tower Cart, Galvanized, Lateral Move, High Profile, Hose Feed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395950": {
        description: null,
        mapicsDescription: "TOWER ASY-A100-MAXI",
        customerReportsDescription: "Power Tower Assy-2 Wheel Drive-A100-MAXI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395970": {
        description: null,
        mapicsDescription: "TOWER BASE-PIVOTING-LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395996": {
        description: null,
        mapicsDescription: "PROX SWITCH KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395988": {
        description: null,
        mapicsDescription: "BALLAST SUPPORT KIT-PIVOT-LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396007": {
        description: null,
        mapicsDescription: "ELBOW/RISER PIPE-6\"-PIVOT LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396008": {
        description: null,
        mapicsDescription: "ELBOW/RISER PIPE-8\"-PIVOT LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396577": {
        description: null,
        mapicsDescription: "JOINT-SPAN-DISC/WINCH-CNTR FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396576-G": {
        description: null,
        mapicsDescription: "TWR ASY-MAXI-CNTR FD-DROP SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394900": {
        description: null,
        mapicsDescription: "MASTER TWR-REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394910": {
        description: null,
        mapicsDescription: "MASTER TOWER-SUGAR-REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396037-G": {
        description: null,
        mapicsDescription: "PVT CTR-8\" RISER TO 6 5/8\"HOOK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396037SS": {
        description: null,
        mapicsDescription: "PVT CTR-8\"RISER TO 6 5/8\"HK-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396037-G",
    },
    "396030-G": {
        description: null,
        mapicsDescription: "PVT CTR-6-5/8\"MF-GLV-PIV-LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396030SS": {
        description: null,
        mapicsDescription: "PVT CTR-6-5/8\" MF-SS-PIV-LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396030-G",
    },
    "396031-G": {
        description: null,
        mapicsDescription: "PVT CTR-8\" MF-GALV-PIV-LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396031SS": {
        description: null,
        mapicsDescription: "PVT CTR-8\"MF-SS-PIV-LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396031-G",
    },
    "396035-G": {
        description: null,
        mapicsDescription: "PVT CTR-6\" RISER TO 8\"HOOK-GLV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396035SS": {
        description: null,
        mapicsDescription: "PVT CTR-6\" RISER TO 8\" HOOK-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396035-G",
    },
    "396032-G": {
        description: null,
        mapicsDescription: "PVT CTR-8 5/8\"MF-GLV-PIV-LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396032SS": {
        description: null,
        mapicsDescription: "PVT CTR-8 5/8\" MF-SS-PIV-LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396032-G",
    },
    "396036-G": {
        description: null,
        mapicsDescription: "PVT CTR-6\" RISER TO 8 5/8\"HOOK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396036SS": {
        description: null,
        mapicsDescription: "PVT CTR-6\"RISER TO 8 5/8\"HK-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396036-G",
    },
    "395812-G": {
        description: null,
        mapicsDescription: "PWR TWR-SECOND-DBL END FD-EII",
        customerReportsDescription: "Power Tower Assembly, Galvanized, Lateral Move, Secondary, Double End Feed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352046-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 380/80R38-STD GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352046TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 380/80R38 STD G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352047-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 380/80R38-1LH 1RH G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352047TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 380/80R38 1L 1R",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352048-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 380/80R38-2LH 1RH G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352048TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 380/80R38 2L 1R",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352093-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 320 / 85R24-STD GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352093TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 320/85R24 STD G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352094-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 320/85R24-1LH 1RH G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352094TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 320 /85R24 1L 1R",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352095-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 320/85R24-2LH 1RH G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352095TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 320/85R24 2L 1R",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352096TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 380/85R24 STD G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352097-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 380/85R24 1LH 1RH G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352097TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 380/85R24 1L 1R",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352098-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 380/85R24- 2LH 1RH G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352098TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 380/85R24 2L 1R",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352265-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 11.2 x 38-ND 2WL G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352265TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 11.2 X 38 ND 2WL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352266-G": {
        description: null,
        mapicsDescription: "WHL-TIRE 11.2 X 38-3WL ND G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352266TG": {
        description: null,
        mapicsDescription: "WHL-TIRE TUBE 11.2 X 38 ND 3WL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "392128": {
        description: null,
        mapicsDescription: "CD OPT-STD SPD-HEL6.5'-2WD MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392129": {
        description: "CD OPT-HI SPD-HEL 6.5'-2WD MAXI",
        mapicsDescription: "CD OPT-HI SPD-HEL6.5'-2WD MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396451": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT-STD SPD-4WD M",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396452": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT-HI SPD-4WD MX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395951": {
        description: null,
        mapicsDescription: "CD OPT-2WD A100 MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395974": {
        description: null,
        mapicsDescription: "CD OPTION-40:1-ROTATE CART",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395975": {
        description: null,
        mapicsDescription: "GEAR OPT-40:1-PIVOTING LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392156": {
        description: null,
        mapicsDescription: "HEL GEAR OPT STD SPD CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392132": {
        description: null,
        mapicsDescription: "CD OPT-STD SPD-HEL-2WD MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392133": {
        description: null,
        mapicsDescription: "CD OPT-HI SPD-HEL-2WD MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352187": {
        description: null,
        mapicsDescription: "4\" DRAG HOSE W/CPLRS-260'",
        customerReportsDescription: "Drag Hose, Soft with Couplers, 4\", 260 feet",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352191": {
        description: null,
        mapicsDescription: "4\" DRAG HOSE W/CPLRS 330'",
        customerReportsDescription: "Drag Hose, Soft with Couplers, 4\", 330 feet",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352186": {
        description: null,
        mapicsDescription: "4\" DRAG HOSE W/CPLRS-520'",
        customerReportsDescription: "Drag Hose, Soft with Couplers, 4\", 520 feet",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352189": {
        description: null,
        mapicsDescription: "5\" DRAG HOSE W/CPLRS-165'",
        customerReportsDescription: "Drag Hose, Soft with Couplers, 5\", 165 feet",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352192": {
        description: null,
        mapicsDescription: "5\" DRAG HOSE W/CPLRS 220'",
        customerReportsDescription: "Drag Hose, Soft with Couplers, 5\", 220 feet",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352188": {
        description: null,
        mapicsDescription: "5\" DRAG HOSE W/CPLRS-330'",
        customerReportsDescription: "Drag Hose, Soft with Couplers, 5\", 330 feet",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352199": {
        description: "COUPLER KIT-4\" HOSE-WHITE",
        mapicsDescription: "COUPLER KIT-4 IN HOSE-WHITE",
        customerReportsDescription: "Coupler Kit, 4\", Soft Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352201": {
        description: "COUPLER KIT-5\" HOSE-WHITE",
        mapicsDescription: "COUPLER KIT-5 IN HOSE-WHITE",
        customerReportsDescription: "Coupler Kit, 5\", Soft Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352217": {
        description: "COUPLER KIT-6\" HOSE",
        mapicsDescription: "COUPLER KIT-6 IN HOSE",
        customerReportsDescription: "Coupler Kit, 6\", Soft Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352200": {
        description: null,
        mapicsDescription: "COUPLER KIT-4 1/2\" DRAG HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395976": {
        description: null,
        mapicsDescription: "HOSE KIT-4\" DRAG HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395977": {
        description: null,
        mapicsDescription: "HOSE KIT-4.5\", 5\" DRAG HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395978": {
        description: null,
        mapicsDescription: "HOSE KIT-6\" DRAG HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395984": {
        description: null,
        mapicsDescription: "REVERSE ON HOSE KIT-4\" PIV MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395985": {
        description: null,
        mapicsDescription: "REVERSE ON HOSE KIT-4.5\" & 5\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395986": {
        description: null,
        mapicsDescription: "HOSE SLEEVE KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396000": {
        description: null,
        mapicsDescription: "CROSSPIPE/LONG RISER-6\" PL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396010": {
        description: null,
        mapicsDescription: "CROSSPIPE/LONG RISER-8\" PIV LT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352150": {
        description: null,
        mapicsDescription: "4 1/2\" DRAG HOSE, PER FT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352151": {
        description: null,
        mapicsDescription: "4 1/2\" DRAG HOSE W/CPLRS-330'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352152": {
        description: null,
        mapicsDescription: "4 1/2\" DRAG HOSE W/CPLRS-520'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396001": {
        description: null,
        mapicsDescription: "CROSSPIPE/SHORT RISER-6\" PL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396011": {
        description: null,
        mapicsDescription: "CROSSPIPE/SHORT RISER-8\"-PL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352158": {
        description: "HOSE-4\" DRAG-MAXI-PER FT",
        mapicsDescription: "HOSE-4 IN DRAG-MAXI-PER FT",
        customerReportsDescription: "Drag Hose, Soft, 4\", per foot",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352169": {
        description: "HOSE-5\" DRAG-MAXI-PER FT",
        mapicsDescription: "HOSE-5 IN DRAG-MAXI-PER FT",
        customerReportsDescription: "Drag Hose, Soft, 5\", per foot",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352210": {
        description: "HOSE-6\" DRAG-MAXI-PER FT",
        mapicsDescription: "HOSE-6 IN DRAG-MAXI-PER FT",
        customerReportsDescription: "Drag Hose, Soft, 6\", per foot",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395911": {
        description: null,
        mapicsDescription: "TWR TOP-6\" BUTTERFLY VLV-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395910",
    },
    "395933": {
        description: null,
        mapicsDescription: "TWR TOP-6\" BTERFLY VLV-MAXI-PL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395912PL",
    },
    "395912PL": {
        description: null,
        mapicsDescription: "TOWER TOP OPTION-S2065PL MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395910": {
        description: null,
        mapicsDescription: "TOWER TOP OPT-6 5/8\" MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395910SS": {
        description: null,
        mapicsDescription: "TOWER TOP OPT-6 5/8\" MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395910",
    },
    "396578": {
        description: null,
        mapicsDescription: "JOINT-SPAN-DISC-CNTR FD W/VLVS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396579",
    },
    "396044": {
        description: null,
        mapicsDescription: "PRIMING PUMP KIT-CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352533": {
        description: null,
        mapicsDescription: "BOX-LAT RELAY-CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396597": {
        description: null,
        mapicsDescription: "JOINT-6 5/8\" MAXI E2665G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395931": {
        description: null,
        mapicsDescription: "JOINT-6\" MAXI-ALUM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395930SS": {
        description: null,
        mapicsDescription: "JOINT-6 5/8\" MAXI-STNLS-EII",
        customerReportsDescription: "JOINT-6 5/8\" MAXI-Stainless Steel-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396597",
    },
    "395937-G": {
        description: null,
        mapicsDescription: "JOINT-MAXI-8 5/8\" EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395938-G": {
        description: "JOINT OPT-MAXI-8 5/8\" TO 6 5/8\"",
        mapicsDescription: "JOINT OPT-MAXI-8 5/8\" TO 6 5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395935": {
        description: null,
        mapicsDescription: "JOINT-MAXI-A80G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395936": {
        description: null,
        mapicsDescription: "JOINT-MAXI-A80G TO A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395938SS": {
        description: null,
        mapicsDescription: "JOINT-MAXI-8 5/8\" WYE-S2065G",
        customerReportsDescription: "JOINT OPT-MAXI-8 5/8\" TO 6 5/8\" S2065G",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395938-G",
    },
    "395932": {
        description: null,
        mapicsDescription: "JOINT-S2065PL CENTER FEED-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395938PL": {
        description: null,
        mapicsDescription: "JOINT-MAXI-8 5/8\" WYE-S2065PL",
        customerReportsDescription: "JOINT OPT-MAXI-8 5/8\" TO 6 5/8\" PL2065",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395938-G",
    },
    "396579": {
        description: null,
        mapicsDescription: "JOINT-SPAN-DISC-CNTR FD EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396597",
    },
    "395823": {
        description: null,
        mapicsDescription: "TWR BASE-4\"-4WD NON TOW MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395837-G": {
        description: null,
        mapicsDescription: "TOWER BASE-4WD MAXI-4\"-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395824": {
        description: null,
        mapicsDescription: "TWR BASE-5\"-4WD NON TOW MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395838-G": {
        description: null,
        mapicsDescription: "TOWER BASE-4WD MAXI-5\"-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395825": {
        description: null,
        mapicsDescription: "TWR BASE-6\"-4WD NON TOW MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395839-G": {
        description: null,
        mapicsDescription: "TOWER BASE-4WD MAXI-6\"-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395826": {
        description: null,
        mapicsDescription: "TWR BASE-8\"-4WD NON TOW MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395849-G": {
        description: null,
        mapicsDescription: "TOWER BASE-8\" 4WD MAXI-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395840-G": {
        description: null,
        mapicsDescription: "TOWER BASE OPT-MAXI-4\"-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395841-G": {
        description: null,
        mapicsDescription: "TOWER BASE-MAXI-4\" W/JK BRKTS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395842-G": {
        description: null,
        mapicsDescription: "TOWER BASE OPT-MAXI-5\" INLETS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395843-G": {
        description: null,
        mapicsDescription: "TOWER BASE-MAXI-5\" W/JK BRKTS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395844-G": {
        description: null,
        mapicsDescription: "TOWER BASE OPT-MAXI-6\" INLETS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395845-G": {
        description: null,
        mapicsDescription: "TOWER BASE-MAXI-6\" W/JK BRKTS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395953": {
        description: null,
        mapicsDescription: "TWR BASE OPT-6\"-A100 MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396002": {
        description: null,
        mapicsDescription: "SINGLE 6\" INLET-PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396003": {
        description: null,
        mapicsDescription: "DUAL 6\" INLETS-PIVOT LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396012": {
        description: "SINGLE 8\" INLET-PIVOTING LATERAL",
        mapicsDescription: "SINGLE 8\" INLET-PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396013": {
        description: "DUAL 8\" INLET-PIVOTING LATERAL",
        mapicsDescription: "DUAL 8\" INLETS-PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394901": {
        description: null,
        mapicsDescription: "TWR BASE-2WD-REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394902": {
        description: null,
        mapicsDescription: "TWR BASE-4WD-NT-REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394903": {
        description: null,
        mapicsDescription: "TWR BASE-4WD-TOW-REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395860-G": {
        description: null,
        mapicsDescription: "ELBOW OPTI-MAXI-W/O EB-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395850": {
        description: "WYE OPTION-13' TO 61' END BM",
        mapicsDescription: "WYE OPTION-13FT TO 61FT END BM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395851": {
        description: "WYE OPTION-85' & 105' END BM",
        mapicsDescription: "WYE OPTION-85FT & 105FT END BM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395865-G": {
        description: null,
        mapicsDescription: "ELBOW OPT-OUTER TWR-MAXI-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395827": {
        description: null,
        mapicsDescription: "ELBOW OPT-8 5/8\" MAXI W/O EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395973": {
        description: null,
        mapicsDescription: "JOINT OPT-6 5/8\" PIVOT-LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395921": {
        description: null,
        mapicsDescription: "WYE-W/6\" BUTTERFLY VALVES-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395920",
    },
    "395920": {
        description: null,
        mapicsDescription: "WYE-OPT-6 5/8\" WYE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395923": {
        description: null,
        mapicsDescription: "WYE OPT-8 5/8\" WYE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395922": {
        description: null,
        mapicsDescription: "WYE OPT-8\" WYE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395945": {
        description: null,
        mapicsDescription: "WYE-W/6\" BUTRFLY VLV-SUGAR LIN",
        customerReportsDescription: "WYE-w/6\" valves-Sugargator Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395944",
    },
    "395944": {
        description: null,
        mapicsDescription: "WYE-OPT-6 5/8\" SUGAR LINEAR",
        customerReportsDescription: "WYE-6 5/8\" Sugargator Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395921-14": {
        description: null,
        mapicsDescription: "WYE-14C-W/6\" BTTRFLY VLV-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395921",
    },
    "395920-14": {
        description: null,
        mapicsDescription: "WYE-OPT-6 5/8\" WYE-14C-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395920",
    },
    "395923-14": {
        description: null,
        mapicsDescription: "WYE OPT-8 5/8\" WYE-14C-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395923",
    },
    "395922-14": {
        description: null,
        mapicsDescription: "WYE-OPT-8\" WYE-14C-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395922",
    },
    "395945-14": {
        description: null,
        mapicsDescription: "WYE-W/6\" VALVES-14C--SUGAR LIN",
        customerReportsDescription: "WYE-w/6\" valves-14C-Sugargator Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395945",
    },
    "395944-14": {
        description: null,
        mapicsDescription: "WYE-6 5/8\"-14C-SUGAR LINEAR",
        customerReportsDescription: "WYE-6 5/8\"-14C-Sugargator Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "395944",
    },
    "392864": {
        description: null,
        mapicsDescription: "TOW KIT OPT-POWER TWR-MINI MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352212": {
        description: "TOW KIT ASY-194' SPAN-MAXI",
        mapicsDescription: "TOW KIT ASY-194FT SPAN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395954": {
        description: null,
        mapicsDescription: "TOW KIT-PWR TWR-A100 MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352209": {
        description: "TOW KIT ASY-156' SPAN-MAXI",
        mapicsDescription: "TOW KIT ASY-156FT SPAN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352211": {
        description: "TOW KIT ASY-175' SPAN-MAXI",
        mapicsDescription: "TOW KIT ASY-175FT SPAN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395987": {
        description: "TOW KIT-PIVOTING LATERAL",
        mapicsDescription: "TOW KIT-PIVOTING LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394907": {
        description: null,
        mapicsDescription: "TOW KIT-156' SPAN-REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394908": {
        description: null,
        mapicsDescription: "TOW KIT-175' SPAN-REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396040": {
        description: null,
        mapicsDescription: "TOWER ASY-CF200-MULTI SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396041": {
        description: null,
        mapicsDescription: "TOWER ASY-CF200-SINGLE SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396042": {
        description: null,
        mapicsDescription: "TOWER BASE-CF200 W/JACK BRKT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396043": {
        description: null,
        mapicsDescription: "TOWER BASE-CF200 W/O JACK BRKT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396045": {
        description: null,
        mapicsDescription: "TOW KIT-PWR TWR-CF200 SINGLE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396046": {
        description: null,
        mapicsDescription: "TOW KIT ASY-156FT SPAN CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396047": {
        description: null,
        mapicsDescription: "TOW KIT ASY-175FT SPAN CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356491": {
        description: null,
        mapicsDescription: "ANCHOR KIT-PIVOTING MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352197": {
        description: null,
        mapicsDescription: "PIVOT KIT-MAXIGATOR",
        customerReportsDescription: "Option, Pivoting Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395955": {
        description: null,
        mapicsDescription: "PUMP/SCREEN-15 HP,650 GPM-A100",
        customerReportsDescription: "Submersible 6\", Pump and Screen - A100 MAXI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358891": {
        description: null,
        mapicsDescription: "PUMP PANEL KIT 15HP-CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396050": {
        description: null,
        mapicsDescription: "CORNELL 15HP-4RB PUMP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358892": {
        description: null,
        mapicsDescription: "PUMP PANEL KIT 20/25HP-CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396051": {
        description: null,
        mapicsDescription: "CORNELL 20HP-4RB PUMP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396052": {
        description: null,
        mapicsDescription: "CORNELL 25HP-4RB PUMP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353350": {
        description: null,
        mapicsDescription: "HYDRAULIC LIFT OPTION-4WD MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353348": {
        description: null,
        mapicsDescription: "CYL OPT-3\"X 8\"-CEN LIFT LINEAR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352570": {
        description: null,
        mapicsDescription: "ELECTRIC DRAG CORD-PER FT",
        customerReportsDescription: "Drag Cable, Electric",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352195-G": {
        description: null,
        mapicsDescription: "HARDWARE KIT-DRAG CABLE-4WD MX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352198": {
        description: null,
        mapicsDescription: "HARDWARE KIT-DRAG CABLE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395989": {
        description: null,
        mapicsDescription: "DRAG CORD KIT-PIVOTING LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394906": {
        description: null,
        mapicsDescription: "DRAG CORD KIT-REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352131": {
        description: null,
        mapicsDescription: "REINKE GR OPT 2.5\" XHD NON TOW",
        customerReportsDescription: "Reinke Gear Opt 2.5\" XHD Non Tow",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352134": {
        description: null,
        mapicsDescription: "WHEEL GEAR-TNT-REINKE",
        customerReportsDescription: "Wheel Gears, Reinke, (TNT) Towable-Non-Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352147": {
        description: null,
        mapicsDescription: "WHEEL GEAR-TNT-REINKE-BRZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352134",
    },
    "352148": {
        description: "Wheel gear (4) UMC 760 Bronze",
        mapicsDescription: "WHEEL GEAR (4) UMC 760 BRONZE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352149": {
        description: null,
        mapicsDescription: "WHEEL GEAR-760 BRZ-2WD MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352131",
    },
    "352034": {
        description: null,
        mapicsDescription: "WHEEL GEAR-765 2WD MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352131",
    },
    "352039": {
        description: null,
        mapicsDescription: "WHEEL GEAR (4) 765",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352170": {
        description: null,
        mapicsDescription: "COUPLER OPT-5\" POLY PIPE-MAXI",
        customerReportsDescription: "Adaptor, Hard Hose, 5\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352171": {
        description: null,
        mapicsDescription: "COUPLER OPT-6\" POLY PIPE-MAXI",
        customerReportsDescription: "Adaptor, Hard Hose, 6\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352172": {
        description: null,
        mapicsDescription: "COUPLER OPT-8\" POLYPIPE-MAXI",
        customerReportsDescription: "Adaptor, Hard Hose, 8\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352173": {
        description: null,
        mapicsDescription: "COUPLER OPT-4\" POLY PIPE-MAXI",
        customerReportsDescription: "Coupler Opt-4\"Poly Pipe-Lat Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394904": {
        description: null,
        mapicsDescription: "COUPLER-6\" TO 4\" REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394905": {
        description: null,
        mapicsDescription: "COUPLER-6\" TO 5\" REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394909": {
        description: null,
        mapicsDescription: "COUPLER KIT-6\"REV ON POLY HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395980": {
        description: null,
        mapicsDescription: "POLY HOSE KIT-4\" PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395981": {
        description: null,
        mapicsDescription: "POLY HOSE KIT-5\" PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395982": {
        description: null,
        mapicsDescription: "POLY HOSE KIT-6\" PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351010": {
        description: null,
        mapicsDescription: "MAXI JCT BOX W/30 AMP PLUG",
        customerReportsDescription: "Maxi Junction Box W/30 Amp Plug",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351011": {
        description: null,
        mapicsDescription: "MAXI JCT BOX W/O PLUG",
        customerReportsDescription: "Maxi Junction Box W/O Plug",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395809": {
        description: null,
        mapicsDescription: "CENTER LIFT-PIVOTING LINEAR",
        customerReportsDescription: "Center Lift Pivoting Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353352": {
        description: null,
        mapicsDescription: "HYD PUMP-4WD LIFT KIT-MAXI",
        customerReportsDescription: "Hydraulic Pump-4WD Lift Kit-Maxi",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352242": {
        description: null,
        mapicsDescription: "TOW BAR-CEN LIFT LATERAL",
        customerReportsDescription: "Tow Bar Kit, Center Lift Pivoting Lateral",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352243": {
        description: null,
        mapicsDescription: "TOW BAR-156' CEN LIFT LATERAL",
        customerReportsDescription: "Tow Bar Kit, Center Lift Pivoting Lateral",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352244": {
        description: null,
        mapicsDescription: "TOW BAR-175' CEN LIFT-LATERAL",
        customerReportsDescription: "Tow Bar Kit, Center Lift Pivoting Lateral",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350816": {
        description: null,
        mapicsDescription: "PLC-VFD PANEL-PIVOTING LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350818": {
        description: null,
        mapicsDescription: "PENDANT RECEPTACLE KIT PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350821": {
        description: null,
        mapicsDescription: "PIV LAT STD MTR VFD KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350819": {
        description: null,
        mapicsDescription: "PIV LAT CART GPS VFD KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350801": {
        description: null,
        mapicsDescription: "MCP-RPM STANDARD",
        customerReportsDescription: "RPM Standard Main Control Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350802": {
        description: null,
        mapicsDescription: "MCP-RPM ADVANCED",
        customerReportsDescription: "RPM Advanced Main Control Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350801",
    },
    "350807": {
        description: null,
        mapicsDescription: "MCP-RPM LINEAR-STANDARD",
        customerReportsDescription: "MCP-RPM-Linear",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350805": {
        description: null,
        mapicsDescription: "MCP-RPM LINEAR-ADVANCED",
        customerReportsDescription: "MCP-RPM Advanced-Linear",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350807",
    },
    "350809": {
        description: null,
        mapicsDescription: "MCP-RPM PREFERRED TOUCH",
        customerReportsDescription: "RPM Preferred Touch Screen MCP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350801",
    },
    "350804": {
        description: null,
        mapicsDescription: "MCP-RPM STANDARD TWR A/R",
        customerReportsDescription: "RPM Standard MCP for Tower A/R",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350801",
    },
    "350807H": {
        description: null,
        mapicsDescription: "MCP-RPM LINEAR-STANDARD-45 AMP",
        customerReportsDescription: "MCP-RPM Standard, 45 amp - Linear",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350807",
    },
    "350805H": {
        description: null,
        mapicsDescription: "MCP-RPM LINEAR-ADV-45 AMP",
        customerReportsDescription: "MCP-RPM Advanced, 45 amp - Linear",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350807",
    },
    "350810": {
        description: null,
        mapicsDescription: "MCP-RPM LINEAR PREFERRED-TOUCH",
        customerReportsDescription: "RPM Preferred Touch Screen MCP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350807",
    },
    "350811": {
        description: null,
        mapicsDescription: "MCP-RPM-BASIC",
        customerReportsDescription: "RPM Basic Main Control Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350812": {
        description: null,
        mapicsDescription: "MCP-RPM-BASIC-TAR",
        customerReportsDescription: "RPM Basic MCP for Tower Auto Reverse",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350811",
    },
    "350810H": {
        description: null,
        mapicsDescription: "MCP-RPM PREFERRED TOUCH-45 AMP",
        customerReportsDescription: "RPM Preferred Touch Screen - 45 Amp MCP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350801",
    },
    "350814": {
        description: null,
        mapicsDescription: "MCP-RPM-A100 MAXI",
        customerReportsDescription: "RPM Main Control Panel-A100 MAXI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351014": {
        description: null,
        mapicsDescription: "MCP-RPM ADV + 4.3\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350801",
    },
    "351015": {
        description: null,
        mapicsDescription: "MCP-RPM CONNECT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350801",
    },
    "351015M": {
        description: "MANUAL OPR PVT/SAC W/CONNECT",
        mapicsDescription: "MANUAL OPR PVT/SAC W/CONNECT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350815": {
        description: null,
        mapicsDescription: "RPM TOUCH PIVOTING LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351016": {
        description: null,
        mapicsDescription: "SURGE PROTECTOR OPTION-PIVOT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351016L": {
        description: null,
        mapicsDescription: "SURGE PROTECTOR OPTION-LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351012": {
        description: null,
        mapicsDescription: "MCP SPAN CABLE DISC. OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351017": {
        description: null,
        mapicsDescription: "MCP-RPM LINEAR ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350807",
    },
    "350800": {
        description: null,
        mapicsDescription: "RPM STANDARD LATERAL MCP-TAR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350807",
    },
    "351017H": {
        description: null,
        mapicsDescription: "MCP-RPM LINEAR ADV+-45 AMP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "350807",
    },
    "358935": {
        description: null,
        mapicsDescription: "MOTOR RAIL ASY-REV2-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358936": {
        description: null,
        mapicsDescription: "LATERAL MOVE-REV2-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358937": {
        description: null,
        mapicsDescription: "POWER TOWER GUIDE-REV2-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358800": {
        description: null,
        mapicsDescription: "MCP-RPM STANDARD-30A-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358803": {
        description: null,
        mapicsDescription: "MCP-RPM STD-PAC-30A-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353413": {
        description: null,
        mapicsDescription: "RAIN GAUGE OPT-RAMS WTHR EII",
        customerReportsDescription: "Rain Gauge Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350956": {
        description: null,
        mapicsDescription: "RAIN GAUGE-RPM TOUCH CP KIT",
        customerReportsDescription: "Rain Gauge-RPM Touch CP Kit",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353407": {
        description: null,
        mapicsDescription: "FLOWMETER,8\"MCPROP-HRZ-PULS",
        customerReportsDescription: "Flowmeter Option, 8\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353407-10": {
        description: null,
        mapicsDescription: "FLOWMETER,10\"MCPROP-HRZ-PULS",
        customerReportsDescription: "Flowmeter Option, 10\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355321": {
        description: null,
        mapicsDescription: "FLOWMETER,8\"MCPROP-VRT-PULS",
        customerReportsDescription: "Flow Meter Option, 8\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355316": {
        description: "FLOWMETER-8\"OD-105\"WALL-VERT",
        mapicsDescription: "FLOWMETER,8\"MCPROP-VRT",
        customerReportsDescription: "Flow Meter Option, 8\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355317": {
        description: "FLOWMTR-RAMS 10\" OD 105\"W-VERT",
        mapicsDescription: "FLOWMETER,10\"MCPROP-VRT-PULS",
        customerReportsDescription: "Flow Meter Option, 10\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355318": {
        description: "FLOWMTR-10\" OD-105\"WALL-VERT",
        mapicsDescription: "FLOWMETER,10\"MCPROP-VRT",
        customerReportsDescription: "Flow Meter Option, 10\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355319": {
        description: null,
        mapicsDescription: "FLOWMETER,8\"MCPROP-HRZ",
        customerReportsDescription: "Flow Meter Option, 8\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355320": {
        description: null,
        mapicsDescription: "FLOWMETER,10\"MCPROP-HRZ",
        customerReportsDescription: "Flow Meter Option, 10\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353272": {
        description: null,
        mapicsDescription: "FLOWMETER,10\"MCMAG 3000W/PULSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353262": {
        description: null,
        mapicsDescription: "FLOWMETER,8\"MCMAG 3000 W/PULSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353252": {
        description: null,
        mapicsDescription: "FLOWMETER,6\"MCMAG 3000 W/PULSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353255": {
        description: null,
        mapicsDescription: "FLOWMETER,6\"MCPROP-VRT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353257": {
        description: null,
        mapicsDescription: "FLOWMETER,6\"MCPROP-VRT-PULS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353254": {
        description: null,
        mapicsDescription: "FLOWMETER,6\"MCPROP-HRZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353256": {
        description: null,
        mapicsDescription: "FLOWMETER,6\"MCPROP-HRZ-PULS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350941A": {
        description: "ANTENNA,450, 470,YAGI",
        mapicsDescription: "ANTENNA,450-470,YAGI",
        customerReportsDescription: "Antenna 450-470 Yagi",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350942": {
        description: null,
        mapicsDescription: "RADIO MODEM-RPM ADV",
        customerReportsDescription: "Radio Modem - RPM Advanced",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350947": {
        description: null,
        mapicsDescription: "RADIO MODEM-RPM TOUCH",
        customerReportsDescription: "Radio Modem-RPM Touch Screen Preferred",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350940A": {
        description: null,
        mapicsDescription: "RAMS TELEMETRY RADIO/ANTENNA",
        customerReportsDescription: "RAMS Telemetry Radio/Antenna",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350940": {
        description: null,
        mapicsDescription: "RAMS TELEMETRY BASE STATION",
        customerReportsDescription: "RAMS Telemetry Base Station",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350955": {
        description: null,
        mapicsDescription: "PHONE LINK-RPM TOUCH",
        customerReportsDescription: "Phone Link-RPM Touch Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350915": {
        description: null,
        mapicsDescription: "PHONE LINK-RPM ADV & PREF",
        customerReportsDescription: "Phone Link-RPM Adv/Pref",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350906": {
        description: null,
        mapicsDescription: "UL CERTIFICATION-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358938": {
        description: null,
        mapicsDescription: "UL CERTIFICATION-REV2-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350907": {
        description: null,
        mapicsDescription: "RESIDUAL CURRENT DEVICE-GFI",
        customerReportsDescription: "Residual Current Device or GFI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358910": {
        description: null,
        mapicsDescription: "GFI/REC OPTION-REV2-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350930": {
        description: null,
        mapicsDescription: "2WD KIT-MCP-RPM LINEAR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350931": {
        description: null,
        mapicsDescription: "4WD KIT-MCP-RPM LINEAR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350930H": {
        description: null,
        mapicsDescription: "2WD KIT-45 AMP-RPM LINEAR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350931H": {
        description: null,
        mapicsDescription: "4WD KIT-45 AMP-RPM LINEAR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350961": {
        description: null,
        mapicsDescription: "2WD KIT-MCP-A100 MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396015": {
        description: null,
        mapicsDescription: "MCP MTG KIT-HOSE DRAG-PIV MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350823": {
        description: null,
        mapicsDescription: "PIV LAT CART GUIDE MCP KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350825": {
        description: null,
        mapicsDescription: "PIV LAT MID GUIDE MCP KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395995": {
        description: null,
        mapicsDescription: "GPS GUIDE-PIVOT MAXI-NO BASE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350820": {
        description: null,
        mapicsDescription: "PIV LAT CART FURROW VFD KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352562": {
        description: "GROUND ROD KIT-EXTRA",
        mapicsDescription: "GROUND ROD KIT- EXTRA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353420": {
        description: null,
        mapicsDescription: "WIND VANE-RPM PREF",
        customerReportsDescription: "Wind Direction & Velocity Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350922": {
        description: null,
        mapicsDescription: "PIVOT JUNCTION BOX-RPM CP KIT",
        customerReportsDescription: "Pivot Junction Box-RPM CP Kit",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350922A": {
        description: null,
        mapicsDescription: "PIVOT JUNCTION BOX W/HARDWARE",
        customerReportsDescription: "Pivot Junction Box w/Hardware",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350922B": {
        description: null,
        mapicsDescription: "PIVOT JUNCTION BOX W/O RMT MTS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350932": {
        description: null,
        mapicsDescription: "GUIDANCE KIT-MCP-RPM LINEAR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350911": {
        description: null,
        mapicsDescription: "FROST SWITCH-RPM STD",
        customerReportsDescription: "Frost Switch-RPM Std",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350911L": {
        description: null,
        mapicsDescription: "FROST SWITCH-RPM STD-LATERAL",
        customerReportsDescription: "Frost Switch-RPM Std-Lateral",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358914": {
        description: null,
        mapicsDescription: "LOW TEMP SHUTDOWN-STD-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350905": {
        description: null,
        mapicsDescription: "AUX RECEPTACLE KIT",
        customerReportsDescription: "Auxiliary Receptacle Kit",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350905L": {
        description: null,
        mapicsDescription: "AUX RECEPTACLE KIT-LATERAL",
        customerReportsDescription: "Auxiliary Receptacle Kit-Lateral",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358908": {
        description: null,
        mapicsDescription: "CHEM INJECT ASY-20A-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358909": {
        description: null,
        mapicsDescription: "CHEM INJECT ASY-30A-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350670": {
        description: null,
        mapicsDescription: "TRANSDUCER OPT PREF TOUCH MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353103": {
        description: null,
        mapicsDescription: "PRES TRANSDUCER RISER",
        customerReportsDescription: "Digital Pressure Readout Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "357093": {
        description: "GPS CDW EOS KIT PSI TRANS",
        mapicsDescription: "GPS CDW EOS KIT PSI TRANS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350673": {
        description: null,
        mapicsDescription: "TRANSDUCER OPT ADV +/CONNECT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350670-25": {
        description: null,
        mapicsDescription: "TRANS 25 PSI OP PREF TOUCH MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350673-25": {
        description: null,
        mapicsDescription: "TRANS 25 PSI ADV +/CONNECT OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350964": {
        description: null,
        mapicsDescription: "VRI CONTROL PANEL OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350935": {
        description: null,
        mapicsDescription: "ELECT KIT-SHUT DOWN VALVE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350939": {
        description: null,
        mapicsDescription: "ELECT KIT-S.D. VALVE-RAMS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352563": {
        description: null,
        mapicsDescription: "GROUNDING BAR OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351027": {
        description: null,
        mapicsDescription: "10/4 SO CORD-BLACK-PER FT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351028": {
        description: null,
        mapicsDescription: "14/2 SO CORD-BLACK-PER FT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353214": {
        description: null,
        mapicsDescription: "RAMS 2000 PHONE DIALER OPT",
        customerReportsDescription: "Phone Dialer Option, RAMS 2000",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351004": {
        description: null,
        mapicsDescription: "CABLE-RSLVR SIGNAL-DIRECT BURY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351005": {
        description: null,
        mapicsDescription: "CABLE-RSLVR ARMOR-DIRECT BURY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352727": {
        description: null,
        mapicsDescription: "COLLECTOR REEL-10 RING",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352729": {
        description: null,
        mapicsDescription: "COLLECTOR REEL-10 RING-RAMS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352727",
    },
    "352728": {
        description: null,
        mapicsDescription: "COLLECTOR REEL-14 RING",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352727",
    },
    "352730": {
        description: null,
        mapicsDescription: "COLLECTOR REEL-14 RING-RAMS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352727",
    },
    "353442": {
        description: null,
        mapicsDescription: "ASY-COLLECTOR REEL-11 CIRCUIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "353720",
    },
    "352844": {
        description: null,
        mapicsDescription: "COLL REEL-10 RING-A/R RPM PNL",
        customerReportsDescription: "Collector Reel-10 Ring-for RPM Std and Twr A/R",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352727",
    },
    "353720": {
        description: null,
        mapicsDescription: "COLLECTOR REEL-11C-ICII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352726": {
        description: null,
        mapicsDescription: "NO COLLECTOR REEL OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352291": {
        description: null,
        mapicsDescription: "LOW VOLT RELAY-RAMS",
        customerReportsDescription: "Option, Low Voltage Shutdown",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352292": {
        description: null,
        mapicsDescription: "LOW VOLT RELAY-STD",
        customerReportsDescription: "Option, Low Voltage Shutdown",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392576": {
        description: null,
        mapicsDescription: "WIRE-MCP-15 1/2' STANDARD PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392566",
    },
    "392576-X": {
        description: "WIRE-MCP-11C X 15 1/2'-STD PVT",
        mapicsDescription: "WIRE-MCP-11C X15 1/2'-STD PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392566",
    },
    "392576-14": {
        description: null,
        mapicsDescription: "WIRE-RAMS-14C X 15.5'-STD PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392566",
    },
    "392575": {
        description: null,
        mapicsDescription: "WIRE-MCP-11'-LOW PROFILE PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392565",
    },
    "392575-X": {
        description: "WIRE-MCP-11C X 11'-LOW PRO PVT",
        mapicsDescription: "WIRE-MCP-11C X11'- LOW PRO PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392565",
    },
    "392575-14": {
        description: null,
        mapicsDescription: "WIRE-RAMS-14C X 10'-LP PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392565",
    },
    "392577": {
        description: "WIRE-MCP-19'-SG PIVOT",
        mapicsDescription: "WIRE-MCP-19FT-SG PIVOT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392567",
    },
    "392577-X": {
        description: "WIRE-MCP-11C X 19'-SUGAR PVT",
        mapicsDescription: "WIRE-MCP-11C X19'-SUGAR PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392567",
    },
    "392577-14": {
        description: null,
        mapicsDescription: "WIRE-RAMS-14C X 20'-SUGAR PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "392567",
    },
    "392566": {
        description: "WIRE, MCP #12, 15 1/2' STD PVT",
        mapicsDescription: "WIRE-MCP #12-15 1/2' STD PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392565": {
        description: "WIRE, MCP #12, 11', LOW PRO PVT",
        mapicsDescription: "WIRE-MCP #12-11'-LOW PRO PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392567": {
        description: "WIRE, MCP #12, 19', SG PVT",
        mapicsDescription: "WIRE-MCP #12-19FT-SG PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351322": {
        description: null,
        mapicsDescription: "KIT-CAM WHEEL & SW MTG HDWARE",
        customerReportsDescription: "Cam Wheel Switch Mount Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353133": {
        description: "ASY-SW MTG-CAM PLT STYL-6 5/8\"",
        mapicsDescription: "ASY-SW MTG-CAM PLT STYL-6 5/8",
        customerReportsDescription: "Cam Plate Switch Mount Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "351322",
    },
    "353134": {
        description: null,
        mapicsDescription: "ASY-SW MTG-CAM PLT STYL-8 5/8",
        customerReportsDescription: "Cam Plate Switch Mount Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "351322",
    },
    "353135": {
        description: null,
        mapicsDescription: "ASY-SW MTG-CAM PLT STYL-10",
        customerReportsDescription: "Cam Plate Switch Mount Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "351322",
    },
    "351323": {
        description: null,
        mapicsDescription: "KIT-CAM WHL & SWTCH HDWR E2045",
        customerReportsDescription: "Cam Wheel Switch Mount Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353172": {
        description: null,
        mapicsDescription: "ASY-SW MTG-CAM PLT TRIPOD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "351323",
    },
    "357027": {
        description: "TOWER BOX-RETRO MNT KIT 6 5/8\"",
        mapicsDescription: "TOWER BOX-RETRO MNT KIT 6-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350919": {
        description: null,
        mapicsDescription: "RPM ADV/PREF RESOLVER KIT",
        customerReportsDescription: "Resolver Kit-RPM Adv/Pref",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351026": {
        description: null,
        mapicsDescription: "RPM ADV PLUS RESOLVER OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "357007": {
        description: null,
        mapicsDescription: "GPS RESOLVER OPTION MAXI",
        customerReportsDescription: "GPS Resolver Option-Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "357007T": {
        description: null,
        mapicsDescription: "GPS RESOLVER-TOWABLE MAXI",
        customerReportsDescription: "GPS Resolver Option-Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352660": {
        description: null,
        mapicsDescription: "HRDWRE KIT-RESOLVER 6\" PVT CTR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350970": {
        description: null,
        mapicsDescription: "GPS CDW MCP KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350970A": {
        description: null,
        mapicsDescription: "GPS CDW EOS KIT",
        customerReportsDescription: "GPS End of System Control",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395992": {
        description: null,
        mapicsDescription: "GPS RESOLVER OPT-PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353122": {
        description: null,
        mapicsDescription: "AUTO STOP SWITCH ASY-E60",
        customerReportsDescription: "Auto Stop, Pivot Center Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352559": {
        description: null,
        mapicsDescription: "AUTO STOP ASY-KWIK TOW",
        customerReportsDescription: "Auto Stop, Pivot Center Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353115": {
        description: null,
        mapicsDescription: "ASY-AUTO STOP SW-CAM PLT STYLE",
        customerReportsDescription: "Cam Plate Switch Mount Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350910": {
        description: null,
        mapicsDescription: "AUTO STOP OVERRIDE-RPM STD/ADV",
        customerReportsDescription: "Auto Stop Override-RPM Std",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352845": {
        description: null,
        mapicsDescription: "AUTO REV SW ASY-10C RPM",
        customerReportsDescription: "Auto Reverse Switch Assy-10C RPM Std",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395814": {
        description: null,
        mapicsDescription: "CART A/R-OPT-REV ON HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395813": {
        description: null,
        mapicsDescription: "CART A/R OPT-RAMS-CANAL FD",
        customerReportsDescription: "Option, Auto Reverse",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350900": {
        description: null,
        mapicsDescription: "PAC OPTION-RPM STD",
        customerReportsDescription: "PAC Option-RPM MCP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350901": {
        description: null,
        mapicsDescription: "END GUN SHUTOFF SWITCH",
        customerReportsDescription: "End Gun Shutoff Overide",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358902": {
        description: null,
        mapicsDescription: "END GUN SW-1-REV2-STD-LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358903": {
        description: null,
        mapicsDescription: "END GUN SW-2-REV2-STD-LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350903": {
        description: null,
        mapicsDescription: "GENERATOR CONTROL SWITCH",
        customerReportsDescription: "Generator Control Switch",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351025": {
        description: null,
        mapicsDescription: "GENERATOR SWITCH-RPM ADV PLUS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350993": {
        description: null,
        mapicsDescription: "GENERATOR SWITCH-RPM TOUCH",
        customerReportsDescription: "Generator Switch-RPM Touch Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350963": {
        description: null,
        mapicsDescription: "GEN CONTROL SWITCH RPM BASIC",
        customerReportsDescription: "Generator Control Switch",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358906": {
        description: null,
        mapicsDescription: "GEN SW-NORMALLY CLSD-STD-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350912": {
        description: null,
        mapicsDescription: "RESTART-RPM STD",
        customerReportsDescription: "Power Auto Restart-RPM Std",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350946": {
        description: null,
        mapicsDescription: "RESTART-RPM STD SAC",
        customerReportsDescription: "Power Auto Restart-RPM Std-SAC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350904": {
        description: null,
        mapicsDescription: "CHEM/AUX CONTROL SWITCH",
        customerReportsDescription: "Chem/Aux Control Switch",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350904L": {
        description: null,
        mapicsDescription: "CHEM/AUX SWITCH-LATERAL",
        customerReportsDescription: "Chem/Aux Control Switch-Lateral",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358907": {
        description: null,
        mapicsDescription: "CHEM-AUX CONT SWITCH-REV2-STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350913": {
        description: null,
        mapicsDescription: "TEMPERATURE PROBE-RPM ADV/PREF",
        customerReportsDescription: "Temperature Probe-RPM Advanced",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350920": {
        description: null,
        mapicsDescription: "BWG TRANSMITTER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350924": {
        description: null,
        mapicsDescription: "SINGLE PHASE OPTION-RPM STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350925": {
        description: null,
        mapicsDescription: "SINGLE PHASE OPT-RPM ADV&PREF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350848": {
        description: null,
        mapicsDescription: "FUSE OPTION-35 AMP-CLASS J",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350951": {
        description: null,
        mapicsDescription: "FUSE OPTION 20 AMP-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350849": {
        description: null,
        mapicsDescription: "FUSE OPTION-45 AMP-CLASS J",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350953": {
        description: null,
        mapicsDescription: "FUSE OPTION 30 AMP-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358928": {
        description: null,
        mapicsDescription: "FUSE KIT 20A-REV2-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358929": {
        description: null,
        mapicsDescription: "FUSE KIT 30A-REV2-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350923": {
        description: null,
        mapicsDescription: "LARGE SERVICE WIRE ADAPTER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351000": {
        description: null,
        mapicsDescription: "BASE SELECTOR SWITCH-TOW MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350902UL": {
        description: null,
        mapicsDescription: "LIGHTNING ARRESTOR UL LISTED",
        customerReportsDescription: "Lightning Arrestor UL Listed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350902": {
        description: null,
        mapicsDescription: "LIGHTNING ARRESTOR",
        customerReportsDescription: "Lightning Arrestor",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350926": {
        description: null,
        mapicsDescription: "LIGHTNING ARRESTOR-1P",
        customerReportsDescription: "Lightning Arrestor - Single Phase",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358904UL": {
        description: null,
        mapicsDescription: "LIGHTNING ARRESTOR-3PH-UL-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350945": {
        description: null,
        mapicsDescription: "DIAGNOSTIC SWITCH ASY-PLC SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350937": {
        description: null,
        mapicsDescription: "ELECT KIT-CEN LIFT LINEAR",
        customerReportsDescription: "Electrical Kit-Center Lift Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350944": {
        description: null,
        mapicsDescription: "DRIVEN PWR TWR-KT PVT-MCP OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350938": {
        description: null,
        mapicsDescription: "LAMP KIT-BWG OR GPS GUIDE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350927": {
        description: null,
        mapicsDescription: "MANUAL-RPM BASIC-SPANISH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350928": {
        description: null,
        mapicsDescription: "MANUAL-RPM ADVANCE-SPANISH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350999": {
        description: null,
        mapicsDescription: "RPM BASIC MANUAL-RUSSIAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350998": {
        description: null,
        mapicsDescription: "RPM ADVANCED MANUAL-RUSSIAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350996": {
        description: null,
        mapicsDescription: "MANUAL-TOUCH SCREEN RUSSIAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350997": {
        description: null,
        mapicsDescription: "LATERAL MOVE MANUAL-RUSSIAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351002": {
        description: null,
        mapicsDescription: "MANUAL-TOUCH SCREEN SPANISH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350899": {
        description: null,
        mapicsDescription: "CARNET-CENTER PIVOT-SPANISH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350898": {
        description: null,
        mapicsDescription: "CARNET-LATERAL MOVE-SPANISH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355422": {
        description: null,
        mapicsDescription: "EN MANUAL OPR-PVT/SAC W/STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355421": {
        description: null,
        mapicsDescription: "SP MANUAL OPR-PVT/SAC W/STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355423": {
        description: null,
        mapicsDescription: "RU MANUAL OPR-PVT/SAC W/STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355424": {
        description: null,
        mapicsDescription: "EN MANUAL OPR-PVT/SAC W/ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355425": {
        description: null,
        mapicsDescription: "SP MANUAL OPR-PVT/SAC W/ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355426": {
        description: null,
        mapicsDescription: "RU MANUAL OPR-PVT/SAC W/ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355427": {
        description: null,
        mapicsDescription: "EN MANUAL OPR-PVT/SAC W/BASIC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355428": {
        description: null,
        mapicsDescription: "RU MANUAL OPR-PVT/SAC W/BASIC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355429": {
        description: null,
        mapicsDescription: "SP MANUAL OPR-PVT/SAC W/BASIC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355430": {
        description: null,
        mapicsDescription: "EN MANUAL OPR-PVT/SAC W/ADV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355431": {
        description: null,
        mapicsDescription: "EN MANUAL OPR-PVT/SAC W/TOUCH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355432": {
        description: null,
        mapicsDescription: "SP MANUAL OPR-PVT/SAC W/TOUCH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355433": {
        description: null,
        mapicsDescription: "RU MANUAL OPR-PVT/SAC W/TOUCH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355434": {
        description: null,
        mapicsDescription: "EN MANUAL LATERAL W/STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355437": {
        description: null,
        mapicsDescription: "SP MANUAL LATERAL W/STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355440": {
        description: null,
        mapicsDescription: "RU MANUAL LATERAL W/STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355435": {
        description: null,
        mapicsDescription: "EN MANUAL LATERAL W/ADV PLUS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355438": {
        description: null,
        mapicsDescription: "SP MANUAL LATERAL W/ADV PLUS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355441": {
        description: null,
        mapicsDescription: "RU MANUAL LATERAL W/ADV PLUS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355436": {
        description: null,
        mapicsDescription: "EN MANUAL LATERAL W/TOUCH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355439": {
        description: null,
        mapicsDescription: "SP MANUAL LATERAL W/TOUCH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355442": {
        description: null,
        mapicsDescription: "RU MANUAL LATERAL W/TOUCH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350960": {
        description: null,
        mapicsDescription: "SUBM. PUMP KIT-15 HP, 25 AMP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358924": {
        description: null,
        mapicsDescription: "CF200 PUMP CONTROL KIT-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "358932": {
        description: null,
        mapicsDescription: "PRIMING PUMP-REV2-30A-MCP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350991": {
        description: null,
        mapicsDescription: "PIVOT/LATERAL SWITCH-RPM MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350954": {
        description: null,
        mapicsDescription: "NON FUSE CARRIER OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350978": {
        description: null,
        mapicsDescription: "PRESS SW-RPM-BASIC-BARKSDALE",
        customerReportsDescription: "Low Pressure Safety Shutdown",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350958": {
        description: null,
        mapicsDescription: "PRESS SW-RPM-BARKSDALE",
        customerReportsDescription: "Low Pressure Safety Shutdown",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350908L": {
        description: null,
        mapicsDescription: "PRESS SWITCH-STD-LATERAL",
        customerReportsDescription: "Low Pressure Safety Shutdown-RPM Std/Adv-Lateral",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350968": {
        description: null,
        mapicsDescription: "PRESS SW-RPM ADV-BARKSDALE",
        customerReportsDescription: "Low Pressure Safety Shutdown",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350918L": {
        description: null,
        mapicsDescription: "PRESS SWITCH-ADV-LATERAL",
        customerReportsDescription: "Low Pressure Safety Shutdown-RPM Adv-Lateral",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350959": {
        description: null,
        mapicsDescription: "PRESS SW-RPM PREF-BARKSDALE",
        customerReportsDescription: "Low Pressure Safety Shutdown",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350909L": {
        description: null,
        mapicsDescription: "PRESS SWITCH-PREF-LATERAL",
        customerReportsDescription: "Low Pressure Safety Shutdown-RPM Pref-Lateral",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350652": {
        description: null,
        mapicsDescription: "LOW PRESS SW-RPM BASIC-4.5 PSI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350655": {
        description: null,
        mapicsDescription: "LOW PRESS SW-RPM-4.5 PSI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350653": {
        description: null,
        mapicsDescription: "LOW PRESS SW-RPM ADV-4.5 PSI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350958A": {
        description: null,
        mapicsDescription: "TUBING KIT PRESS SW 7.5",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350908B": {
        description: null,
        mapicsDescription: "PRESSURE SWITCH-RPM-KWIK TOW",
        customerReportsDescription: "Low Pressure Safety Shutdown",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350934": {
        description: null,
        mapicsDescription: "PRESSURE SW-RPM-SUGAR-LINEAR",
        customerReportsDescription: "Low Pressure Safety Shutdown",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350654": {
        description: null,
        mapicsDescription: "LOW PRESS SW-RPM PRF-4.5 PSI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358911": {
        description: null,
        mapicsDescription: "PRESS SW-RPM STD REV2-15PSI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358911A": {
        description: null,
        mapicsDescription: "PRESSURE DEVICE TUBING KIT-ALL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350948": {
        description: null,
        mapicsDescription: "PRESS START OPT-RPM STD",
        customerReportsDescription: "Pressure Auto Restart-RPM Std",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350979": {
        description: null,
        mapicsDescription: "AUTO-RESTART & PRESS-RST OPT",
        customerReportsDescription: "Power and Pressure Auto Restart-RPM Std",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350949": {
        description: "PRESS START -RPM STD SAC",
        mapicsDescription: "PRESS START - RPM STD SAC",
        customerReportsDescription: "Pressure Auto Restart-RPM Std-SAC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350976": {
        description: null,
        mapicsDescription: "RESTART-PRSS PWR RPM STD SAC",
        customerReportsDescription: "Power and Pressure Auto Restart-RPM Std-SAC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350966": {
        description: null,
        mapicsDescription: "PRESS START OPT-RPM STD A/R",
        customerReportsDescription: "Pressure Auto Restart-RPM Std-Auto Reverse",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350967": {
        description: "PRESS START -RPM STD A/R SAC",
        mapicsDescription: "PRESS START - RPM STD A/R SAC",
        customerReportsDescription: "Pressure Auto Restart-RPM Std-SAC-Auto Reverse",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350977": {
        description: null,
        mapicsDescription: "RSTRT-PRSS PWR RPM STD TAR SAC",
        customerReportsDescription: "Power and Pressure Auto Restart-RPM Std-SAC-Auto Reverse",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350943": {
        description: null,
        mapicsDescription: "RPM ADV PRESS START OPT",
        customerReportsDescription: "RPM Advanced-Pressure Restart option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350936": {
        description: null,
        mapicsDescription: "ELECT KIT-BP-SELF CLEAN SCREEN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352832": {
        description: null,
        mapicsDescription: "PRIMING KIT-MCP-RPM LINEAR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354056": {
        description: null,
        mapicsDescription: "SWITCH-TOWABLE BASE SEL KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350214": {
        description: null,
        mapicsDescription: "REINCLOUD HUMIDITY RC10D",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350840": {
        description: null,
        mapicsDescription: "SAT RC10DS PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350842": {
        description: null,
        mapicsDescription: "GSM RC10DG PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350844": {
        description: null,
        mapicsDescription: "GSM INTL RC10DGW PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350846": {
        description: null,
        mapicsDescription: "VZN RC10DV PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350833": {
        description: null,
        mapicsDescription: "VZN RC10DV ADV +",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350829": {
        description: null,
        mapicsDescription: "GSM RC10DG ADV +",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350831": {
        description: null,
        mapicsDescription: "GSM INTL RC10DGW ADV +",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350827": {
        description: null,
        mapicsDescription: "SAT RC10DS ADV +",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350858": {
        description: null,
        mapicsDescription: "ROGERS RC10D ADV +",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350861": {
        description: null,
        mapicsDescription: "ROGERS RC10D PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351032": {
        description: null,
        mapicsDescription: "EUROPE INT RC10D ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351031": {
        description: null,
        mapicsDescription: "A-PAC INT RC10D ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351037": {
        description: null,
        mapicsDescription: "AUS/CAN/MEX RC10D ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351034": {
        description: null,
        mapicsDescription: "EUROPE INT RC10D PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351033": {
        description: null,
        mapicsDescription: "RC10D-PREFERRED NZ/ASIA/ZA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351039": {
        description: null,
        mapicsDescription: "AUS/CAN/MEX RC10D PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351071": {
        description: null,
        mapicsDescription: "CASAMX INT RC10D ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351072": {
        description: null,
        mapicsDescription: "CASAMX INT RC10D PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351992": {
        description: "PLUG-WEEP HOLE MCP-5 PACK",
        mapicsDescription: "PLUG-WEEP HOLE MCP-5 PACK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351993": {
        description: "PLUG-WEEP HOLE TWR BOX-50 PACK",
        mapicsDescription: "PLUG-WEEP HOLE TWR BOX-50 PACK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396103-G": {
        description: "SPAN-118' LEPA 10\" EII GALV",
        mapicsDescription: "SPAN-118' LEPA 10\" EII GALV",
        customerReportsDescription: "Spans, 118', 10\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396915-G": {
        description: "SPAN-160' 57 SP 6\" EII GALV",
        mapicsDescription: "SPAN-160' 57 SP 6\" EII GALV",
        customerReportsDescription: "Spans, 160', 6\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396104-G": {
        description: null,
        mapicsDescription: "SPAN-120' LEPA 10\" EII GALV",
        customerReportsDescription: "Spans, 120', 10\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396267": {
        description: null,
        mapicsDescription: "SPAN-140' LEPA E2065",
        customerReportsDescription: "Spans, 140', 6 5/8\" OD, Galvanized Pipe, 40\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396105-G": {
        description: null,
        mapicsDescription: "SPAN-137' LEPA 10\" EII GALV",
        customerReportsDescription: "Spans, 137', 10\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396615-G": {
        description: "SPAN-160' 57\" SP 6\" CT EII GALV",
        mapicsDescription: "SPAN-160' 57 SP 6\" CT EII GALV",
        customerReportsDescription: "Spans, 160', 6\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396915-G",
    },
    "396203-G": {
        description: "SPAN-118' LEPA 8 5/8\" EII GALV",
        mapicsDescription: "SPAN-118' LEPA 8-5/8\" EII GALV",
        customerReportsDescription: "Spans, 118', 8 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396267X": {
        description: "SPAN-140' LEPA E2065 3/4\"R",
        mapicsDescription: "SPAN-140' LEPA E2065 3/4R",
        customerReportsDescription: "Spans, 140', 6 5/8\" OD, Galvanized Pipe, 40\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396267",
    },
    "396204-G": {
        description: "SPAN-137' LEPA 8 5/8\" EII GALV",
        mapicsDescription: "SPAN-137' LEPA 8-5/8\" EII GALV",
        customerReportsDescription: "Spans, 137', 8 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396215-G": {
        description: "SPAN-160' 57\" SP 6 5/8\" EII GAL",
        mapicsDescription: "SPAN-160' 57 SP 6-5/8\" EII GAL",
        customerReportsDescription: "Spans, 160', 6 5/8\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396205-G": {
        description: "SPAN-156' LEPA 8 5/8\" EII GALV",
        mapicsDescription: "SPAN-156' LEPA 8-5/8\" EII GALV",
        customerReportsDescription: "Spans, 156', 8 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396269": {
        description: null,
        mapicsDescription: "SPAN-140' LEPA E2060",
        customerReportsDescription: "Spans, 140', 6\" OD, Galvanized Pipe, 40\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396206-G": {
        description: "SPAN-160' LEPA 8 5/8\" EII GALV",
        mapicsDescription: "SPAN-160' LEPA 8-5/8\" EII GALV",
        customerReportsDescription: "Spans, 160', 8 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396515-G": {
        description: "SPAN-160' 57\" SP 6 5/8\" CT EII G",
        mapicsDescription: "SPAN-160' 57 SP 6-5/8 CT EII G",
        customerReportsDescription: "Spans, 160', 6 5/8\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396215-G",
    },
    "396269X": {
        description: "SPAN-140' LEPA E2060 3/4\"R",
        mapicsDescription: "SPAN-140' LEPA E2060 3/4R",
        customerReportsDescription: "Spans, 140', 6\" OD, Galvanized Pipe, 40\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396269",
    },
    "396428": {
        description: null,
        mapicsDescription: "SPAN-118' PL2065",
        customerReportsDescription: "Spans, 118', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396435": {
        description: null,
        mapicsDescription: "SPAN-140' LEPA E2085",
        customerReportsDescription: "Spans, 140', 8 5/8\" OD, Galvanized Pipe, 40\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396429": {
        description: null,
        mapicsDescription: "SPAN-137' PL2065",
        customerReportsDescription: "Spans, 137', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396430": {
        description: null,
        mapicsDescription: "SPAN-156' PL2065",
        customerReportsDescription: "Spans, 156', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396433": {
        description: "SPAN-160'-57\"SP-PL2065",
        mapicsDescription: "SPAN-160'-57SP-PL2065",
        customerReportsDescription: "Spans, 160', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396431": {
        description: null,
        mapicsDescription: "SPAN-175' PL2065",
        customerReportsDescription: "Spans, 175', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396432": {
        description: null,
        mapicsDescription: "SPAN-194' PL2065",
        customerReportsDescription: "Spans, 194', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397405": {
        description: "SPAN-160' 57\"SP E2665G",
        mapicsDescription: "SPAN-160' 57SP E2665G",
        customerReportsDescription: "Span(s), 160', 6 5/8\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397305": {
        description: "SPAN-160' 57\"SP E2660G",
        mapicsDescription: "SPAN-160' 57SP E2660G",
        customerReportsDescription: "Span(s), 160', 6\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396310-G": {
        description: "SPAN-118' 57\" SP 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-118' 57 SP6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 118', 6 5/8\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396311-G": {
        description: "SPAN-137' 57\"SP 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-137' 57SP 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 137', 6 5/8\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396312-G": {
        description: "SPAN-156' 57\"SP 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-156' 57SP 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 156', 6 5/8\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396313-G": {
        description: "SPAN-175' 57\"SP 6 5/8\"EII GALV",
        mapicsDescription: "SPAN-175' 57SP 6-5/8\"EII GALV",
        customerReportsDescription: "Spans, 175', 6 5/8\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396314-G": {
        description: "SPAN-194' 57\"SP 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-194' 57SP 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 194', 6 5/8\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396383-G": {
        description: "SPAN-118' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-118' LEPA 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 118', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396384-G": {
        description: "SPAN-137' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-137' LEPA 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 137', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396385-G": {
        description: "SPAN-156' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-156' LEPA 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 156', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396315-G": {
        description: "SPAN-160' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-160' LEPA 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 160', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396386-G": {
        description: "SPAN-175' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-175' LEPA 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 175', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396316-G": {
        description: "SPAN-180' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-180' LEPA 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 180', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396322-G": {
        description: "SPAN-186.7' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-186.7 LEPA 6-5/8 EII GALV",
        customerReportsDescription: "Spans, 186.7', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396387-G": {
        description: "SPAN-194' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-194' LEPA 6-5/8 EII GALV",
        customerReportsDescription: "Spans, 194', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396391-G": {
        description: "SPAN-204' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-204' LEPA 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 204', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396110-G": {
        description: "SPAN-118' 57\"SP 4 1/2\" EII GALV",
        mapicsDescription: "SPAN-118' 57SP 4-1/2\" EII GALV",
        customerReportsDescription: "Spans, 118', 4.5\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396111-G": {
        description: "SPAN-137' 57\"SP 4 1/2\" EII GALV",
        mapicsDescription: "SPAN-137' 57SP 4-1/2\" EII GALV",
        customerReportsDescription: "Spans, 137', 4.5\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396112-G": {
        description: "SPAN-156' 57\"SP 4 1/2\" EII GALV",
        mapicsDescription: "SPAN-156' 57SP 4-1/2\" EII GALV",
        customerReportsDescription: "Spans, 156', 4.5\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396113-G": {
        description: "SPAN-175' 57\"SP 4 1/2\" EII GALV",
        mapicsDescription: "SPAN-175' 57SP 4-1/2\" EII GALV",
        customerReportsDescription: "Spans, 175', 4.5\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396119": {
        description: "SPAN-194' 57\"SP 4 1/2\" EII GALV",
        mapicsDescription: "SPAN-194' 57SP 4-1/2\" EII GALV",
        customerReportsDescription: "Spans, 194', 4.5\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396115-G": {
        description: null,
        mapicsDescription: "SPAN-118' 114SP 4-1/2 EII GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396116-G": {
        description: null,
        mapicsDescription: "SPAN-137' 114SP 4-1/2 EII GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396117-G": {
        description: null,
        mapicsDescription: "SPAN-156' 114SP 4-1/2 EII GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396118-G": {
        description: null,
        mapicsDescription: "SPAN-175' 114SP 4-1/2 EII GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396120": {
        description: null,
        mapicsDescription: "SPAN-194' 114SP 4-1/2 EII GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396410-G": {
        description: "SPAN-118' 57\" SP 6\" EII GALV",
        mapicsDescription: "SPAN-118' 57 SP 6\" EII GALV",
        customerReportsDescription: "Spans, 118', 6\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396411-G": {
        description: "SPAN-137' 57\"SP 6\" EII GALV",
        mapicsDescription: "SPAN-137' 57SP 6\" EII GALV",
        customerReportsDescription: "Spans, 137', 6\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396412-G": {
        description: "SPAN-156' 57\"SP 6\" EII GALV",
        mapicsDescription: "SPAN-156' 57SP 6\" EII GALV",
        customerReportsDescription: "Spans, 156', 6\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396413-G": {
        description: "SPAN-175' 57\"SP 6\" EII GALV",
        mapicsDescription: "SPAN-175' 57SP 6\" EII GALV",
        customerReportsDescription: "Spans, 175', 6\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396414-G": {
        description: "SPAN-194' 57\"SP 6\" EII GALV",
        mapicsDescription: "SPAN-194' 57SP 6\" EII GALV",
        customerReportsDescription: "Spans, 194', 6\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396419-G": {
        description: "SPAN-213' 57\"SP 6\" EII GALV",
        mapicsDescription: "SPAN-213' 57SP 6\" EII GALV",
        customerReportsDescription: "Spans, 213', 6\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396483-G": {
        description: "SPAN-118' LEPA 6\" EII GALV",
        mapicsDescription: "SPAN-118' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 118', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396484-G": {
        description: null,
        mapicsDescription: "SPAN-137' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 137', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396485-G": {
        description: null,
        mapicsDescription: "SPAN-156' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 156', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396415-G": {
        description: null,
        mapicsDescription: "SPAN-160' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 160', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396486-G": {
        description: "SPAN-175' LEPA 6\" EII GALV",
        mapicsDescription: "SPAN-175' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 175', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396416-G": {
        description: null,
        mapicsDescription: "SPAN-180' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 180', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396422-G": {
        description: null,
        mapicsDescription: "SPAN-186.7' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 186.7', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396487-G": {
        description: null,
        mapicsDescription: "SPAN-194' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 194', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396491-G": {
        description: null,
        mapicsDescription: "SPAN-204' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 204', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396489-G": {
        description: null,
        mapicsDescription: "SPAN-213' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 213', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396510-G": {
        description: "SPAN-118' 57\" SP 6 5/8\" CT EII G",
        mapicsDescription: "SPAN-118' 57 SP 6-5/8 CT EII G",
        customerReportsDescription: "Spans, 118', 6 5/8\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397402": {
        description: "SPAN-118' 57\"SP E2665G",
        mapicsDescription: "SPAN-118' 57SP E2665G",
        customerReportsDescription: "Span(s), 118', 6 5/8\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396511-G": {
        description: "SPAN-137' 57\"SP 6 5/8\" CT EII G",
        mapicsDescription: "SPAN-137' 57SP 6-5/8\" CT EII G",
        customerReportsDescription: "Spans, 137', 6 5/8\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397403": {
        description: "SPAN-137' 57\"SP E2665G",
        mapicsDescription: "SPAN-137' 57SP E2665G",
        customerReportsDescription: "Span(s), 137', 6 5/8\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396512-G": {
        description: "SPAN-156' 57\"SP 6 5/8\" CT EII G",
        mapicsDescription: "SPAN-156' 57SP 6-5/8\" CT EII G",
        customerReportsDescription: "Spans, 156', 6 5/8\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397404": {
        description: "SPAN-156' 57\"SP E2665G",
        mapicsDescription: "SPAN-156' 57SP E2665G",
        customerReportsDescription: "Span(s), 156', 6 5/8\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396513-G": {
        description: "SPAN-175' 57\"SP 6 5/8\" CT EII G",
        mapicsDescription: "SPAN-175' 57SP 6-5/8\" CT EII G",
        customerReportsDescription: "Spans, 175', 6 5/8\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397406": {
        description: "SPAN-175' 57\"SP E2665G",
        mapicsDescription: "SPAN-175' 57SP E2665G",
        customerReportsDescription: "Span(s), 175', 6 5/8\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396514-G": {
        description: "SPAN-194' 57\"SP 6 5/8\" CT EII G",
        mapicsDescription: "SPAN-194' 57SP 6-5/8\" CT EII G",
        customerReportsDescription: "Spans, 194', 6 5/8\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397407": {
        description: "SPAN-194' 57\"SP E2665G",
        mapicsDescription: "SPAN-194' 57SP E2665G",
        customerReportsDescription: "Span(s), 194', 6 5/8\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396610-G": {
        description: "SPAN-118' 57\" SP 6\" CT EII GALV",
        mapicsDescription: "SPAN-118' 57 SP 6\" CT EII GALV",
        customerReportsDescription: "Spans, 118', 6\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397302": {
        description: "SPAN-118' 57\"SP E2660G",
        mapicsDescription: "SPAN-118' 57SP E2660G",
        customerReportsDescription: "Span(s), 118', 6\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396611-G": {
        description: "SPAN-137' 57\"SP 6\" CT EII GALV",
        mapicsDescription: "SPAN-137' 57SP 6\" CT EII GALV",
        customerReportsDescription: "Spans, 137', 6\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397303": {
        description: "SPAN-137' 57\"SP E2660G",
        mapicsDescription: "SPAN-137' 57SP E2660G",
        customerReportsDescription: "Span(s), 137', 6\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396612-G": {
        description: "SPAN-156' 57\"SP 6\" CT EII GALV",
        mapicsDescription: "SPAN-156' 57SP 6\" CT EII GALV",
        customerReportsDescription: "Spans, 156', 6\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397304": {
        description: "SPAN-156' 57\"SP E2660G",
        mapicsDescription: "SPAN-156' 57SP E2660G",
        customerReportsDescription: "Span(s), 156', 6\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396613-G": {
        description: "SPAN-175' 57\"SP 6\" CT EII GALV",
        mapicsDescription: "SPAN-175' 57SP 6\" CT EII GALV",
        customerReportsDescription: "Spans, 175', 6\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397306": {
        description: "SPAN-175' 57\"SP E2660G",
        mapicsDescription: "SPAN-175' 57SP E2660G",
        customerReportsDescription: "Span(s), 175', 6\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396614-G": {
        description: "SPAN-194' 57\"SP 6\" CT EII GALV",
        mapicsDescription: "SPAN-194' 57SP 6\" CT EII GALV",
        customerReportsDescription: "Spans, 194', 6\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397307": {
        description: "SPAN-194' 57\"SP E2660G",
        mapicsDescription: "SPAN-194' 57SP E2660G",
        customerReportsDescription: "Span(s), 194', 6\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396619-G": {
        description: "SPAN-213' 57\"SP 6\" CT EII GALV",
        mapicsDescription: "SPAN-213' 57SP 6\" CT EII GALV",
        customerReportsDescription: "Spans, 213', 6\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397308": {
        description: "SPAN-213' 57\"SP E2660G",
        mapicsDescription: "SPAN-213' 57SP E2660G",
        customerReportsDescription: "Span(s), 213', 6\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397003-G": {
        description: "SPAN ASY-118'-40\" SP-A80-G",
        mapicsDescription: "SPAN ASY-118 FT-40 SP-A80-G",
        customerReportsDescription: "Spans, 118', 8\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397004-G": {
        description: "SPAN ASY-137'-40\" SP-A80-G",
        mapicsDescription: "SPAN ASY-137 FT-40 SP-A80-G",
        customerReportsDescription: "Spans, 137', 8\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397008-G": {
        description: "SPAN ASY-156'-40\" SP-A80-G",
        mapicsDescription: "SPAN ASY-156 FT-40 SP-A80-G",
        customerReportsDescription: "Spans, 156', 8\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397009-G": {
        description: "SPAN ASY 160' 40\" SPCG A80G",
        mapicsDescription: "SPAN ASY 160 FT 40 SPCG A80G",
        customerReportsDescription: "Spans, 160', 8\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396811-G": {
        description: "SPAN ASY-140'-40\" SP-A60G",
        mapicsDescription: "SPAN ASY-140 FT-40 SP-A60G",
        customerReportsDescription: "Spans, 140', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396812-G": {
        description: "SPAN ASY-160'-40\" SP-A60G",
        mapicsDescription: "SPAN ASY-160 FT-40 SP-A60G",
        customerReportsDescription: "Spans, 160', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396713-G": {
        description: "SPAN ASY-118'-ALLUMI 4-LEPA",
        mapicsDescription: "SPAN ASY-118FT-ALLUMI 4-LEPA",
        customerReportsDescription: "Spans, 118', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396714-G": {
        description: "SPAN ASY-137'-ALUMI 4-LEPA",
        mapicsDescription: "SPAN ASY-137FT-ALUMI 4-LEPA",
        customerReportsDescription: "Spans, 137', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396716-G": {
        description: null,
        mapicsDescription: "SPAN-140'-LEPA-A4",
        customerReportsDescription: "Spans, 140', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396715-G": {
        description: "SPAN ASY-156'-ALUMI 4-LEPA",
        mapicsDescription: "SPAN ASY-156FT-ALUMI 4-LEPA",
        customerReportsDescription: "Spans, 156', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396717-G": {
        description: "SPAN ASY-160'-ALUMI 4-LEPA",
        mapicsDescription: "SPAN ASY-160FT-ALUMI 4-LEPA",
        customerReportsDescription: "Spans, 160', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396815-G": {
        description: "SPAN ASY-118'-A60G-LEPA GALV",
        mapicsDescription: "SPAN ASY-118FT-A60G-LEPA GALV",
        customerReportsDescription: "Spans, 118', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396816-G": {
        description: "SPAN ASY-137'-A60G-LEPA GALV",
        mapicsDescription: "SPAN ASY-137FT-A60G-LEPA GALV",
        customerReportsDescription: "Spans, 137', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396817-G": {
        description: "SPAN ASY-156'-A60G-LEPA GALV",
        mapicsDescription: "SPAN ASY-156FT-A60G-LEPA GALV",
        customerReportsDescription: "Spans, 156', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396543": {
        description: null,
        mapicsDescription: "SPAN-118' LEPA S2085",
        customerReportsDescription: "Spans, 118', 8 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396544": {
        description: null,
        mapicsDescription: "SPAN-137' LEPA S2085",
        customerReportsDescription: "Spans, 137', 8 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396545": {
        description: null,
        mapicsDescription: "SPAN-140' LEPA S2085",
        customerReportsDescription: "Spans, 140', 8 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396546": {
        description: null,
        mapicsDescription: "SPAN-156' LEPA S2085",
        customerReportsDescription: "Spans, 156', 8 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396253": {
        description: "SPAN-118' LEPA S2065",
        mapicsDescription: "SPAN-118' LEPA S2065",
        customerReportsDescription: "Spans, 118', 6 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396254": {
        description: "SPAN-137' LEPA S2065",
        mapicsDescription: "SPAN-137' LEPA S2065",
        customerReportsDescription: "Spans, 137', 6 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396266": {
        description: "SPAN-140' LEPA S2065",
        mapicsDescription: "SPAN-140' LEPA S2065",
        customerReportsDescription: "Spans, 140', 6 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396255": {
        description: null,
        mapicsDescription: "SPAN-156' LEPA S2065",
        customerReportsDescription: "Spans, 156', 6 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396388": {
        description: "SPAN-160' LEPA S2065",
        mapicsDescription: "SPAN-160' LEPA S2065",
        customerReportsDescription: "Spans, 160', 6 5/8\" dia., Stainless Steel Pipie, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396256": {
        description: null,
        mapicsDescription: "SPAN-175' LEPA S2065",
        customerReportsDescription: "Spans, 175', 6 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396547": {
        description: null,
        mapicsDescription: "SPAN-160' LEPA S2085",
        customerReportsDescription: "Spans, 160', 8 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396383X": {
        description: "SPAN-118' E2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-118' E2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 118', 6 5/8\"OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396384X": {
        description: "SPAN-137' E2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-137' E2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 137', 6 5/8\"OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396385X": {
        description: "SPAN-156' E2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-156' E2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 156', 6 5/8\"OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396315X": {
        description: "SPAN-160' E2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-160' E2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 160', 6 5/8\"OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396483X": {
        description: null,
        mapicsDescription: "SPAN-118' E2060 LEPA",
        customerReportsDescription: "Spans, 118', 6\" OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396484X": {
        description: "SPAN-137' E2060 LEPA 3/4\"R",
        mapicsDescription: "SPAN-137' E2060 LEPA 3/4R",
        customerReportsDescription: "Spans, 137', 6\" OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396485X": {
        description: "SPAN-156' E2060 LEPA 3/4\"R",
        mapicsDescription: "SPAN-156' E2060 LEPA 3/4R",
        customerReportsDescription: "Spans, 156', 6\" OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396415X": {
        description: "SPAN-160' E2060 LEPA 3/4\"R",
        mapicsDescription: "SPAN-160' E2060 LEPA 3/4R",
        customerReportsDescription: "Spans, 160', 6\" OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396486X": {
        description: "SPAN-175' E2060 LEPA 3/4\"R",
        mapicsDescription: "SPAN-175' E2060 LEPA 3/4R",
        customerReportsDescription: "Spans, 175', 6\" OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396310X": {
        description: "SPAN-118' E2065 3/4\"R",
        mapicsDescription: "SPAN-118' E2065 3/4R",
        customerReportsDescription: "Spans, 118', 6 5/8\"OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396311X": {
        description: "SPAN-137' E2065 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-137' E2065 57SP 3/4R",
        customerReportsDescription: "Spans, 137', 6 5/8\"OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396312X": {
        description: "SPAN-156' E2065 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-156' E2065 57SP 3/4R",
        customerReportsDescription: "Spans, 156', 6 5/8\"OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396215X": {
        description: "SPAN-160' E2065 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-160' E2065 57SP 3/4R",
        customerReportsDescription: "Spans, 160', 6 5/8\" OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396410X": {
        description: "SPAN-118' E2060 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-118' E2060 57SP 3/4R",
        customerReportsDescription: "Spans, 118', 6\" OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396411X": {
        description: "SPAN-137' E2060 57\"SP",
        mapicsDescription: "SPAN-137' E2060 57SP",
        customerReportsDescription: "Spans, 137', 6\" OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396412X": {
        description: "SPAN-156' E2060 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-156' E2060 57SP 3/4R",
        customerReportsDescription: "Spans, 156', 6\" OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396915X": {
        description: "SPAN-160' E2060 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-160' E2060 57SP 3/4R",
        customerReportsDescription: "Spans, 160', 6\" OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396413X": {
        description: "SPAN-175' E2060 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-175' E2060 57SP 3/4R",
        customerReportsDescription: "Spans, 175', 6\" OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396510X": {
        description: "SPAN-118' E2665 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-118' E2665 57SP 3/4R",
        customerReportsDescription: "Spans, 118', 6 5/8\"OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396511X": {
        description: "SPAN-137' E2665 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-137' E2665 57SP 3/4R",
        customerReportsDescription: "Spans, 137', 6 5/8\"OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396512X": {
        description: "SPAN-156' E2665 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-156' E2665 57SP 3/4R",
        customerReportsDescription: "Spans, 156', 6 5/8\"OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396515X": {
        description: "SPAN-160' E2665 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-160' E2665 57SP 3/4R",
        customerReportsDescription: "Spans, 160', 6 5/8\"OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396610X": {
        description: "SPAN-118' E2660 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-118' E2660 57SP 3/4R",
        customerReportsDescription: "Spans, 118', 6\" OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396611X": {
        description: "SPAN-137' E2660 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-137' E2660 57SP 3/4R",
        customerReportsDescription: "Spans, 137', 6\" OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396612X": {
        description: "SPAN-156' E2660 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-156' E2660 57SP 3/4R",
        customerReportsDescription: "Spans, 156', 6\" OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396615X": {
        description: "SPAN-160' E2660 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-160' E2660 57SP 3/4R",
        customerReportsDescription: "Spans, 160', 6\" OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396613X": {
        description: "SPAN-175' E2660 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-175' E2660 57SP 3/4R",
        customerReportsDescription: "Spans, 175', 6\" OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397402X": {
        description: "SPAN-118' E2665G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-118' E2665G 57SP 3/4R",
        customerReportsDescription: "Spans, 118', 6 5/8\"OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397403X": {
        description: "SPAN-137' E2665G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-137' E2665G 57SP 3/4R",
        customerReportsDescription: "Spans, 137', 6 5/8\"OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397404X": {
        description: "SPAN-156' E2665G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-156' E2665G 57SP 3/4R",
        customerReportsDescription: "Spans, 156', 6 5/8\"OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397405X": {
        description: "SPAN-160' E2665G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-160' E2665G 57SP 3/4R",
        customerReportsDescription: "Spans, 160', 6 5/8\"OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397302X": {
        description: "SPAN-118' E2660G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-118' E2660G 57SP 3/4R",
        customerReportsDescription: "Spans, 118', 6\" OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397303X": {
        description: "SPAN-137' E2660G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-137' E2660G 57SP 3/4R",
        customerReportsDescription: "Spans, 137', 6\" OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397304X": {
        description: "SPAN-156' E2660G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-156' E2660G 57SP 3/4R",
        customerReportsDescription: "Spans, 156', 6\" OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397305X": {
        description: "SPAN-160' E2660G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-160' E2660G 57SP 3/4R",
        customerReportsDescription: "Spans, 160', 6\" OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397306X": {
        description: "SPAN-175' E2660G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-175' E2660G 57SP 3/4R",
        customerReportsDescription: "Spans, 175', 6\" OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396253X": {
        description: "SPAN-118' S2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-118' S2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 118', 6 5/8\"OD, Stainless Steel Pipe, 40\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396254X": {
        description: "SPAN-137' S2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-137' S2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 137', 6 5/8\"OD, Stainless Steel Pipe, 40\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396266X": {
        description: "SPAN-140' S2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-140' S2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 140', 6 5/8\"OD, Stainless Steel Pipe, 40\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396255X": {
        description: "SPAN-156' S2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-156' S2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 156', 6 5/8\"OD, Stainless Steel Pipe, 40\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396388X": {
        description: "SPAN-160' S2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-160' S2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 160', 6 5/8\"OD, Stainless Steel Pipe, 40\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396428X": {
        description: "SPAN-118' PL2065 3/4\"R",
        mapicsDescription: "SPAN-118' PL2065 3/4R",
        customerReportsDescription: "Spans, HD 118', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396429X": {
        description: "SPAN-137' PL2065 3/4\"R",
        mapicsDescription: "SPAN-137' PL2065 3/4R",
        customerReportsDescription: "Spans, HD 137', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396430X": {
        description: "SPAN-156' PL2065 3/4\"R",
        mapicsDescription: "SPAN-156' PL2065 3/4R",
        customerReportsDescription: "Spans, HD 156', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396433X": {
        description: "SPAN-160'-57\"SP-PL2065 3/4\"R",
        mapicsDescription: "SPAN-160'-57SP-PL2065 3/4R",
        customerReportsDescription: "Spans, HD 160', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396440": {
        description: null,
        mapicsDescription: "SPAN-118' PL2085",
        customerReportsDescription: "Spans, 118', 8 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396441": {
        description: null,
        mapicsDescription: "SPAN-137' PL2085",
        customerReportsDescription: "Spans, 137', 8 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396442": {
        description: null,
        mapicsDescription: "SPAN-156' PL2085",
        customerReportsDescription: "Spans, 156', 8 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396443": {
        description: null,
        mapicsDescription: "SPAN-160' PL2085",
        customerReportsDescription: "Spans, 160', 8 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396102-G": {
        description: null,
        mapicsDescription: "SPAN-99' LEPA 10\" EII GALV",
        customerReportsDescription: "Spans, 99', 10\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396202-G": {
        description: "SPAN-99' LEPA 8 5/8\" EII GALV",
        mapicsDescription: "SPAN-99' LEPA 8-5/8\" EII GALV",
        customerReportsDescription: "Spans, 99', 8 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396152": {
        description: null,
        mapicsDescription: "SPAN-99' LEPA S2085",
        customerReportsDescription: "Spans, 99', 8 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396252": {
        description: "SPAN-99' LEPA S2065",
        mapicsDescription: "SPAN-99' LEPA S2065",
        customerReportsDescription: "Spans, 99', 6 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396252X": {
        description: "SPAN-99' S2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-99' S2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 99', 6 5/8\" OD, Stainless Steel Pipe, 40\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396382-G": {
        description: "SPAN-99' LEPA 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-99' LEPA 6-5/8 EII GALV",
        customerReportsDescription: "Spans, 99', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396382X": {
        description: "SPAN-99' E2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-99' E2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 99', 6 5/8\"OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396321-G": {
        description: "SPAN-99' 57\"SP 6 5/8\" EII GALV",
        mapicsDescription: "SPAN-99' 57SP 6-5/8\" EII GALV",
        customerReportsDescription: "Spans, 99', 6 5/8\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396321X": {
        description: "SPAN-99' E2065 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-99' E2065 57SP 3/4R",
        customerReportsDescription: "Spans, 99', 6 5/8\"OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396482-G": {
        description: null,
        mapicsDescription: "SPAN-99' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 99', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396482X": {
        description: "SPAN-99' E2060 LEPA 3/4\"R",
        mapicsDescription: "SPAN-99' E2060 LEPA 3/4R",
        customerReportsDescription: "Spans, 99', 6\" OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396421-G": {
        description: "SPAN-99' 57\"SP 6\" EII GALV",
        mapicsDescription: "SPAN-99' 57SP 6\" EII GALV",
        customerReportsDescription: "Spans, 99', 6\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396421X": {
        description: "SPAN-99' E2060 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-99' E2060 57SP 3/4R",
        customerReportsDescription: "Spans, 99', 6\" OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397401": {
        description: "SPAN-99' 57\"SP E2665G",
        mapicsDescription: "SPAN-99' 57SP E2665G",
        customerReportsDescription: "Span(s), 99', 6 5/8\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397401X": {
        description: "SPAN-99' E2665G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-99' E2665G 57SP 3/4R",
        customerReportsDescription: "Spans, 99', 6 5/8\"OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396521-G": {
        description: "SPAN-99' 57\"SP 6 5/8\" CT EII GAL",
        mapicsDescription: "SPAN-99' 57SP 6-5/8 CT EII GAL",
        customerReportsDescription: "Spans, 99', 6 5/8\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing, Galv.",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396521X": {
        description: "SPAN-99' E2665 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-99' E2665 57SP 3/4R",
        customerReportsDescription: "Spans, 99', 6 5/8\"OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397301": {
        description: "SPAN-99' 57\"SP E2660G",
        mapicsDescription: "SPAN-99' 57SP E2660G",
        customerReportsDescription: "Span(s), 99', 6\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397301X": {
        description: "SPAN-99' 57\"SP E2660G 3/4\"R",
        mapicsDescription: "SPAN-99' 57SP E2660G 3/4R",
        customerReportsDescription: "Spans, 99', 6\" OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396621-G": {
        description: "SPAN-99' 57\"SP 6\" CT EII GALV",
        mapicsDescription: "SPAN-99' 57SP 6\" CT EII GALV",
        customerReportsDescription: "Spans, 99', 6\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing, Galv",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396621X": {
        description: "SPAN-99' E2660 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-99' E2660 57SP 3/4R",
        customerReportsDescription: "Spans, 99', 6\" OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396712-G": {
        description: "SPAN ASY-99'-ALUMI 4-LEPA",
        mapicsDescription: "SPAN ASY-99FT-ALUMI 4-LEPA",
        customerReportsDescription: "Spans, 99', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396814-G": {
        description: "SPAN ASY-99'-A60G-LEPA GALV",
        mapicsDescription: "SPAN ASY-99FT-A60G-LEPA GALV",
        customerReportsDescription: "Spans, 99', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397002-G": {
        description: "99' SPAN-A80-G",
        mapicsDescription: "99 FOOT SPAN-A80-G",
        customerReportsDescription: "Spans, 99', 8\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396427": {
        description: null,
        mapicsDescription: "SPAN-99' PL2065",
        customerReportsDescription: "Spans, 99', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396427X": {
        description: "SPAN-99' PL2065 3/4\"R",
        mapicsDescription: "SPAN-99' PL2065 3/4R",
        customerReportsDescription: "Spans, HD 99', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396101-G": {
        description: null,
        mapicsDescription: "SPAN-80' LEPA 10\" EII GALV",
        customerReportsDescription: "Spans, 80', 10\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396201-G": {
        description: "SPAN-80' LEPA 8 5/8\" EII GALV",
        mapicsDescription: "SPAN-80' LEPA 8-5/8\" EII GALV",
        customerReportsDescription: "Spans, 80', 8 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396151": {
        description: null,
        mapicsDescription: "SPAN-80' LEPA S2085",
        customerReportsDescription: "Spans, 80', 8 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396251": {
        description: "SPAN-80' LEPA S2065",
        mapicsDescription: "SPAN-80' LEPA S2065",
        customerReportsDescription: "Spans, 80', 6 5/8\" dia., Stainless Steel Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396381-G",
    },
    "396251X": {
        description: "SPAN-80' S2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-80' S2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 80', 6 5/8\" OD, Stainless Steel Pipe, 40\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396381-G",
    },
    "396381-G": {
        description: "SPAN-80' LEPA 6 5/8' EII GALV",
        mapicsDescription: "SPAN-80' LEPA 6-5/8' EII GALV",
        customerReportsDescription: "Spans, 80', 6 5/8\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396381X": {
        description: "SPAN-80' E2065 LEPA 3/4\"R",
        mapicsDescription: "SPAN-80' E2065 LEPA 3/4R",
        customerReportsDescription: "Spans, 80', 6 5/8\"ID, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396381-G",
    },
    "396320-G": {
        description: "SPAN 80' 57\"SP 6 5/8\" EII GALV",
        mapicsDescription: "SPAN 80' 57SP 6-5/8 EII GALV",
        customerReportsDescription: "Spans, 80', 6 5/8\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396320X": {
        description: "SPAN 80' E2065 57\"SP 3/4\"R",
        mapicsDescription: "SPAN 80' E2065 57SP 3/4R",
        customerReportsDescription: "Spans, 80', 6 5/8\"OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396320-G",
    },
    "396481-G": {
        description: null,
        mapicsDescription: "SPAN-80' LEPA 6\" EII GALV",
        customerReportsDescription: "Spans, 80', 6\" dia., Galvanized Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396481X": {
        description: "SPAN-80' E2060 LEPA 3/4\"R",
        mapicsDescription: "SPAN-80' E2060 LEPA 3/4R",
        customerReportsDescription: "Spans, 80', 6\" OD, Galvanized Pipe, 40\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396481-G",
    },
    "396420-G": {
        description: "SPAN-80' 57\"SP 6\" EII GALV",
        mapicsDescription: "SPAN-80' 57SP 6\" EII GALV",
        customerReportsDescription: "Spans, 80', 6\" dia., Galvanized Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396420X": {
        description: "SPAN-80' E2060 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-80' E2060 57SP 3/4R",
        customerReportsDescription: "Spans, 80', 6\" OD, Galvanized Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396420-G",
    },
    "397400": {
        description: "SPAN-80' 57\"SP E2665G",
        mapicsDescription: "SPAN-80' 57SP E2665G",
        customerReportsDescription: "Span(s), 80', 6 5/8\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397400X": {
        description: "SPAN-80' E2665G 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-80' E2665G 57SP 3/4R",
        customerReportsDescription: "Spans, 80', 6 5/8\"OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "397400",
    },
    "396508-G": {
        description: "SPAN-80' 57\"SP 6 5/8\" CT EII G",
        mapicsDescription: "SPAN-80' 57SP 6-5/8\" CT EII G",
        customerReportsDescription: "Spans, 80', 6 5/8\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing, Galv.",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396508X": {
        description: "SPAN-80' E2665 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-80' E2665 57SP 3/4R",
        customerReportsDescription: "Spans, 80', 6 5/8\"OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396508-G",
    },
    "397300": {
        description: "SPAN-80' 57\"SP E2660G",
        mapicsDescription: "SPAN-80' 57SP E2660G",
        customerReportsDescription: "Span(s), 80', 6\" OD, Chromium-Nickel(CN+) Galv Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397300X": {
        description: "SPAN-80' 57\"SP E2660G 3/4\"R",
        mapicsDescription: "SPAN-80' 57SP E2660G 3/4R",
        customerReportsDescription: "Spans, 80', 6\" OD, CN+ Galvanized Pipe, 57\" Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "397300",
    },
    "396620-G": {
        description: "SPAN-80' 57\"SP 6\" CT EII GALV",
        mapicsDescription: "SPAN-80' 57SP 6\" CT EII GALV",
        customerReportsDescription: "Spans, 80', 6\" dia., Chromium-Nickel (CN+) Pipe, 57\" Outlet Spacing, Galv",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396620X": {
        description: "SPAN-80' E2660 57\"SP 3/4\"R",
        mapicsDescription: "SPAN-80' E2660 57SP 3/4R",
        customerReportsDescription: "Spans, 80', 6\" OD, CN+ Painted Pipe, 57\" Outlet Spacing, 3/4\" Rod",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396620-G",
    },
    "396711-G": {
        description: "SPAN ASSY-80'-ALUMI 4-LEPA",
        mapicsDescription: "SPAN ASSY-80FT-ALUMI 4-LEPA",
        customerReportsDescription: "Spans, 80', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396813-G": {
        description: "SPAN ASY-80'-A60G-LEPA GALV",
        mapicsDescription: "SPAN ASY-80FT-A60G-LEPA GALV",
        customerReportsDescription: "Spans, 80', 6\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397001-G": {
        description: "80' SPAN-A80-G",
        mapicsDescription: "80 FOOT SPAN-A80-G",
        customerReportsDescription: "Spans, 80', 8\" dia., Aluminum Pipe, 40\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396426": {
        description: null,
        mapicsDescription: "SPAN-80' PL2065",
        customerReportsDescription: "Spans, 80', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396426X": {
        description: "SPAN-80' PL2065 3/4\"R",
        mapicsDescription: "SPAN-80' PL2065 3/4R",
        customerReportsDescription: "Spans, HD 80', 6 5/8\" dia, Poly-Lined Galv Pipe, 57\" Outlet Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "396426",
    },
    "396126": {
        description: null,
        mapicsDescription: "JOINT-SPAN-E2100",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396127": {
        description: null,
        mapicsDescription: "JOINT-SPAN-E2100/E2085 GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396226": {
        description: null,
        mapicsDescription: "JOINT-SPAN-E2085",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396227": {
        description: null,
        mapicsDescription: "JOINT-SPAN-TRANS-E2085/E2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396228": {
        description: "SPN JNT FIRST TWR KT 8 5/8\" EII",
        mapicsDescription: "SPN JNT FIRST TWR KT 8 5/8 EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396229": {
        description: "SPN JT TRAN KT 8 5/8\"-6 5/8\" EII",
        mapicsDescription: "SPN JT TRAN KT 8 5/8-6 5/8 EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397035": {
        description: null,
        mapicsDescription: "JOINT-SPAN-E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397037": {
        description: null,
        mapicsDescription: "JOINT-SPAN-TRANS-E2665G/E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396598": {
        description: null,
        mapicsDescription: "SPAN JOINT1ST TKT E2665G W/CPG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396025SS": {
        description: "LAST TOWER TOP 6 5/8\" SS EII",
        mapicsDescription: "LAST TOWER TOP 6-5/8 SS EII",
        customerReportsDescription: "Last Tower Top 6 5/8\" Stainless Steel EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397036": {
        description: null,
        mapicsDescription: "LAST TWR TOP E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396595": {
        description: null,
        mapicsDescription: "LAST TWR TOP E2665G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396596": {
        description: null,
        mapicsDescription: "JOINT SPAN E2665G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396527": {
        description: null,
        mapicsDescription: "JOINT-SPAN-DISC-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396548": {
        description: "LAST TWR TOP 85'/105' EB E2665G",
        mapicsDescription: "LAST TWR TOP 85/105 EB E2665G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396726": {
        description: null,
        mapicsDescription: "SPAN JOINT-ALUMI 4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396727": {
        description: null,
        mapicsDescription: "LAST TOWER TOP OPTION-ALUMI 4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396728": {
        description: null,
        mapicsDescription: "SPAN JOINT-FIRST TWR KT-ALUM 4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396826": {
        description: null,
        mapicsDescription: "SPAN JOINT-A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396827": {
        description: null,
        mapicsDescription: "LAST TOWER TOP OPTION-A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396828": {
        description: null,
        mapicsDescription: "SPAN JOINT-#1 TWR KT-A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397026": {
        description: null,
        mapicsDescription: "SPAN JOINT-A80-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397027": {
        description: null,
        mapicsDescription: "SPAN JOINT-TRANSITION-A80-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397028": {
        description: null,
        mapicsDescription: "SPAN JOINT-#1 TWR KT-A80-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397029": {
        description: "SPN-JNT 1-TWR KT A80G/A60G",
        mapicsDescription: "SPN-JNT 1- TWR KT A80G/A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397106SS": {
        description: null,
        mapicsDescription: "SPAN JOINT-S2085G",
        customerReportsDescription: "Tower Top Joint - Stainless Steel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397108SS": {
        description: null,
        mapicsDescription: "JOINT-SPAN-TRANS-S2085/S2065",
        customerReportsDescription: "Tower Top Joint - Stainless Steel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397039": {
        description: null,
        mapicsDescription: "SPAN JOINT 1ST TWR KT E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397038": {
        description: null,
        mapicsDescription: "SPAN JOINT E2065/E2045 194'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396328SS": {
        description: null,
        mapicsDescription: "SPAN JOINT-1ST TWR-KWIKTW-S65G",
        customerReportsDescription: "Span Joint-1st Tower-KT-S65G Stainless Steel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396270": {
        description: null,
        mapicsDescription: "SPN JNT-E2085 TO A80-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396231-G": {
        description: null,
        mapicsDescription: "JOINT-8 5/8\" FLG TO 6 5/8\"HOOK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396232": {
        description: null,
        mapicsDescription: "JOINT TRANS-A80G/ALUM 4-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397109SS": {
        description: null,
        mapicsDescription: "JOINT-MAXI-S2085G/S2065G",
        customerReportsDescription: "Tower Top Joint - Stainless Steel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396233": {
        description: null,
        mapicsDescription: "JOINT TRANS-A80G/A60G-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396026SS": {
        description: "SPAN JOINT 6 5/8\" SS EII",
        mapicsDescription: "SPAN JOINT 6-5/8 SS EII",
        customerReportsDescription: "Span Joint 6 5/8\" Stainless Steel EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397040": {
        description: null,
        mapicsDescription: "JOINT SPAN 194 E2045 CN+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396217": {
        description: "SPN-JNT 10\" W/CPG EII",
        mapicsDescription: "SPN-JNT 10 W/CPG EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396240": {
        description: "SPAN JOINT 10\"/8 5/8\" EII GALV",
        mapicsDescription: "SPAN JOINT 10\"/8-5/8\" EII GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396212": {
        description: null,
        mapicsDescription: "SPAN JNT W/CPLG-30\" SPG E2085",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396213": {
        description: "SPAN JNT RED E2085/E2065, 30\"",
        mapicsDescription: "SPAN JNT RED E2085/E2065-30\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396241": {
        description: "SPN-JNT 1-TWR KT 8 5/8\" W/CG EII",
        mapicsDescription: "JOINT-S2085 HOOK TO A80G MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396242": {
        description: "SPN JT 8 5/8\"/6 5/8\" KT W/CP EII",
        mapicsDescription: "SPN JT 8-5/8/6-5/8 KT W/CP EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397041": {
        description: null,
        mapicsDescription: "LAST TWR ASM E2045 194'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397112SS": {
        description: null,
        mapicsDescription: "TRANS HOOK JNT ASY-S65PL/S85G",
        customerReportsDescription: "Tower Top Joint - Stainless Steel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396245-G": {
        description: null,
        mapicsDescription: "LAST TWR 85/105 W/CPG EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396734": {
        description: null,
        mapicsDescription: "LAST TWR TOP W/CPLR E2665G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396733": {
        description: "SPAN-JNT 6 5/8\" W/CPLR E2665G",
        mapicsDescription: "JOINT SPAN W/CPLR E2665G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396243": {
        description: null,
        mapicsDescription: "JOINT SPAN W/CPLR E2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396730": {
        description: null,
        mapicsDescription: "SPN-JNT W/CPG A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396731": {
        description: null,
        mapicsDescription: "LAST TWR TOP AL W/CPLG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396732": {
        description: null,
        mapicsDescription: "SPN-JT 1-TWR KT W/CPG A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396829": {
        description: null,
        mapicsDescription: "SPN-JNT W/CPG A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396830": {
        description: null,
        mapicsDescription: "LAST TWR TOP W/CPG A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396831": {
        description: null,
        mapicsDescription: "SPN-JNT 1-TWR KT W/CPG A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397030": {
        description: null,
        mapicsDescription: "SPN-JNT W/CPG A80-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397032": {
        description: null,
        mapicsDescription: "SPN-JNT W/CPG A80-G/A60-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397031": {
        description: null,
        mapicsDescription: "SPN-JT 1-TWR KT W/CPG A80-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397033": {
        description: null,
        mapicsDescription: "SP-JT 1-TWR KT W/CP A80G/A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396219": {
        description: null,
        mapicsDescription: "SPAN-118' 30SP E2085",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396242SS": {
        description: null,
        mapicsDescription: "SPN-JNT S85G/S65G W/CPG",
        customerReportsDescription: "Span Joint S85G/S65G Stainless Steel w/Coupling",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396220": {
        description: null,
        mapicsDescription: "SPN-JNT 1-TWR KT W/CPG S65G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396271": {
        description: null,
        mapicsDescription: "SPN JNT W/CPLG-E2085 TO A80-G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396246": {
        description: "JNT, MAXI, 2085, 2065 HOOK W/CPLG",
        mapicsDescription: "JNT-MAXI-2085-2065 HOOK W/CPLG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396247": {
        description: null,
        mapicsDescription: "JOINT-A80G/A4-MAXI W/CPLG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397111SS": {
        description: null,
        mapicsDescription: "SPN-JNT S65G/S85G W/CPG",
        customerReportsDescription: "Tower Top Joint - Stainless Steel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396248": {
        description: null,
        mapicsDescription: "JOINT-A80G/A60G-MAXI W/CPLG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397113SS": {
        description: null,
        mapicsDescription: "JOINT-MAXI-S2085/S2065PL",
        customerReportsDescription: "Tower Top Joint - Stainless Steel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396550": {
        description: null,
        mapicsDescription: "SPAN JOINT PL2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396551": {
        description: null,
        mapicsDescription: "LAST TWR TOP PL2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396027": {
        description: "SPN JNT W/CPG-6 5/8\" SS EII",
        mapicsDescription: "SPN JNT W/CPG-6 5/8 SS EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396028": {
        description: "SPN-JNT 1 TWR KT 6 5/8\" SS EII",
        mapicsDescription: "SPN-JNT 1 TWR KT 6 5/8 SS EII",
        customerReportsDescription: "Tower Top Joint - Stainless Steel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396029": {
        description: "SPN-JNT 1 TWR KT W/CG 6 5/8\" SS EII",
        mapicsDescription: "SPN-JNT 1 TWR KT W/CG6-5/8 SS EII",
        customerReportsDescription: "Tower Top Joint - Stainless Steel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396223": {
        description: null,
        mapicsDescription: "LAST TWR W/CPG SS EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396235-G": {
        description: null,
        mapicsDescription: "JOINT-2665G RCVR-A60G/A4 HOOK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396274": {
        description: null,
        mapicsDescription: "JOINT-2665G RCVR W/CPLG-A4 HK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396599": {
        description: null,
        mapicsDescription: "SPAN JNT E2665G REC/A4-A60G HK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396272": {
        description: null,
        mapicsDescription: "SPN JNT-S2085 TO A80G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396273": {
        description: null,
        mapicsDescription: "SPN JNT W/CPLG-S2085 TO A80G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396237SS": {
        description: null,
        mapicsDescription: "JOINT-S2065G RCVR-A60G HOOK",
        customerReportsDescription: "Span Joint-S2065G Stainless Steel to A60G Aluminum",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396276": {
        description: null,
        mapicsDescription: "JOINT-SPAN E2085 DBL ROD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396238-G": {
        description: null,
        mapicsDescription: "JOINT-2085G HOOK TO A80G-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396282": {
        description: null,
        mapicsDescription: "JNT-S2085 HOOK-A80G RCVR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396239-G": {
        description: null,
        mapicsDescription: "JOINT-2085G RED HOOK-E60 RCVR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396249": {
        description: null,
        mapicsDescription: "JNT-2085 RED HK-E60 RCVR W/CP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396234-G": {
        description: null,
        mapicsDescription: "JOINT-2060 HOOK-E60 RCVR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396278": {
        description: null,
        mapicsDescription: "JNT-2060 HOOK-E60 RCVR W/CPLG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396234": {
        description: null,
        mapicsDescription: "JOINT-2660 HOOK-E660 RCVR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396279": {
        description: null,
        mapicsDescription: "JNT 2660 HOOK-E660 RCVR W/CPLG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396280": {
        description: null,
        mapicsDescription: "JOINT-S2065 HOOK-S65 RECEIVER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396281": {
        description: null,
        mapicsDescription: "JNT-S2065 HOOK-E65 RCVR W/CPLG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396533": {
        description: null,
        mapicsDescription: "JOINT OPT-90 DEG PVT TWR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396135": {
        description: null,
        mapicsDescription: "JOINT-SPAN PL2085",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396136": {
        description: null,
        mapicsDescription: "JOINT-SPAN-TRANS-PL2085/PL2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396326": {
        description: null,
        mapicsDescription: "JOINT SPAN E2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396325": {
        description: null,
        mapicsDescription: "LAST TWR TOP E2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396340": {
        description: null,
        mapicsDescription: "LAST TWR TOP 85/105 E2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396244": {
        description: null,
        mapicsDescription: "LAST TWR 85/100 W/CPLR E2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396250-G": {
        description: null,
        mapicsDescription: "LAST TWR TOP W/CPLR E2065",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354505-G": {
        description: "SPAN KIT-4' EXTENSION-A60-G",
        mapicsDescription: "SPAN KIT-4FT EXTENSION-A60-G",
        customerReportsDescription: "Span Extension, 4', 6\" Aluminum Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354503-G": {
        description: "SPAN KIT-4' EXTENSION-E65",
        mapicsDescription: "SPAN KIT-4 FT EXTENSION-E65",
        customerReportsDescription: "Span Extension, 4', 6 5/8\" Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354503SS": {
        description: null,
        mapicsDescription: "SPAN KIT-4' EXTENSION-S65G",
        customerReportsDescription: "Span Extension, 4', 6 5/8\" Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354501-G": {
        description: "SPAN KIT-4' EXTENSION-E60",
        mapicsDescription: "SPAN KIT-4 FT EXTENSION-E60",
        customerReportsDescription: "Span Extension, 4', 6\" Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354504-G": {
        description: "SPAN KIT-4' EXTENSION-E665",
        mapicsDescription: "SPAN KIT-4 FT EXTENSION-E665",
        customerReportsDescription: "Span Extension, 4', 6 5/8\" Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354509": {
        description: "SPAN KIT-4' EXT-6 5/8\"-E2665G",
        mapicsDescription: "SPAN KIT-4' EXT-6 5/8-E2665G",
        customerReportsDescription: "Span Extension, 4', 6-5/8\" OD, Chromium-Nickel (CN+) Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354502-G": {
        description: "SPAN KIT-4' EXTENSION-E660",
        mapicsDescription: "SPAN KIT-4 FT EXTENSION-E660",
        customerReportsDescription: "Span Extension, 4', 6\" Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354508": {
        description: "SPAN KIT-4' EXT-6\"-E2660G",
        mapicsDescription: "SPAN KIT-4' EXT-6-E2660G",
        customerReportsDescription: "Span Extension, 4', 6\" OD, Chromium-Nickel (CN+) Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354516": {
        description: "SPAN KIT-4' EXT PL2065",
        mapicsDescription: "SPAN KIT-4 FT EXT PL2065",
        customerReportsDescription: "Span Extension, 4', 6 5/8\" Polylined Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351985": {
        description: null,
        mapicsDescription: "LOW PRESS DRAIN DIVERTING KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351973": {
        description: "DRAIN LOW PRESS 1 1/2\" PER TWR",
        mapicsDescription: "DRAIN LOW PRESS 1-1/2\" PER TWR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353515": {
        description: null,
        mapicsDescription: "WIRE ALIGN PKG SNGL SPAN MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396342": {
        description: null,
        mapicsDescription: "LONG SYSTEM ALIGNMENT-6\" SPAN",
        customerReportsDescription: "Long System Alignment Package",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396343": {
        description: "LONG SYSTEM ALIGN 6 5/8\" SPAN",
        mapicsDescription: "LONG SYSTEM ALIGN 6-5/8\" SPAN",
        customerReportsDescription: "Long System Alignment Package",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396344": {
        description: null,
        mapicsDescription: "LONG SYSTEM ALIGNMENT-8\" SPAN",
        customerReportsDescription: "Long System Alignment Package",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396345": {
        description: null,
        mapicsDescription: "LONG SYSTEM ALIGNMENT-10\" SPAN",
        customerReportsDescription: "Long System Alignment Package",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396346": {
        description: "LONG SYSTEM ALIGN 8 5/8\" SPANS",
        mapicsDescription: "LONG SYSTEM ALIGN 8-5/8\" SPANS",
        customerReportsDescription: "Long System Alignment Package",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396130": {
        description: "LONG SYSTEM ALIGN 4 1/2\" SPAN",
        mapicsDescription: "LONG SYSTEM ALIGN 4-1/2\" SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396342SS": {
        description: null,
        mapicsDescription: "LONG SYSTEM ALIGN-SS 6\" SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396343SS": {
        description: null,
        mapicsDescription: "LONG SYSTEM ALIGN-SS 6-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396344SS": {
        description: null,
        mapicsDescription: "LONG SYSTEM ALIGNMENT-SS 8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396345SS": {
        description: null,
        mapicsDescription: "LONG SYSTEM ALIGNMENT-SS 10\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396346SS": {
        description: null,
        mapicsDescription: "LONG SYSTEM ALIGN-SS 8-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396130SS": {
        description: null,
        mapicsDescription: "LONG SYSTEM ALIGN-SS 4-1/2\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396334": {
        description: null,
        mapicsDescription: "FLANGE BRACKET ASY-6\"-LSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396335": {
        description: "FLANGE BRACKET ASY 6 5/8\" LSA",
        mapicsDescription: "FLANGE BRACKET ASY 6-5/8\" LSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396336": {
        description: null,
        mapicsDescription: "FLANGE BRACKET ASSEMBLY-8\"-LSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396337": {
        description: null,
        mapicsDescription: "FLANGE BRACKET ASSY-10\"-LSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396339": {
        description: "FLANGE BRACKET ASM-8 5/8\" -LSA",
        mapicsDescription: "FLANGE BRACKET ASM-8-5/8\" -LSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396782-G": {
        description: null,
        mapicsDescription: "LONG. SUPP ROD OPT-END SPAN-A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396781-G": {
        description: null,
        mapicsDescription: "LONG SUPP ROD OPT-INT SPAN-A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352223": {
        description: "TOW CABLE-156' SPAN-REV TOW",
        mapicsDescription: "TOW CABLE-156FT SPAN-REV TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352219": {
        description: "TOW CABLE-175' SPAN-REV TOW",
        mapicsDescription: "TOW CABLE-175FT SPAN-REV TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352221": {
        description: "TOW CABLE-194' SPAN-REV TOW",
        mapicsDescription: "TOW CABLE-194FT SPAN-REV TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352226": {
        description: "TOW CABLE-204' SPAN-REV TOW",
        mapicsDescription: "TOW CABLE-204FT SPAN-REV TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352227": {
        description: "TOW CABLE-213' SPAN-REV TOW",
        mapicsDescription: "TOW CABLE-213FT SPAN-REV TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396536": {
        description: null,
        mapicsDescription: "JOINT-SPAN-DISC/WINCH-A/S-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392800": {
        description: null,
        mapicsDescription: "TOW ROD EXT KIT-4'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396584": {
        description: "SPAN STAB OPT 99'/118' EII",
        mapicsDescription: "SPAN STAB OPT 99/118FT EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396586": {
        description: "SPAN STAB OPT 137' EII",
        mapicsDescription: "SPAN STAB OPT 137FT EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396587": {
        description: "SPAN STAB OPT 156' EII",
        mapicsDescription: "SPAN STAB OPT 156FT EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396582": {
        description: "SPAN STAB OPT 160' EII",
        mapicsDescription: "SPAN STAB OPT 160FT EII",
        customerReportsDescription: "Truss Stabilizer Option, 160', EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396585": {
        description: "SPAN STAB OPT 175'/180' EII",
        mapicsDescription: "SPAN STAB OPT 175/180FT EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396583": {
        description: "SPAN STAB OPT 80' EII",
        mapicsDescription: "SPAN STAB OPT 80FT EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397208": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 118'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397212": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 137'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397216": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 156'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397220": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 160'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397223": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 175'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397227": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 180'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397228": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 186.5'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397229": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 194'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397232": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 204'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397233": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 213'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397209": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 118'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397213": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 137'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397217": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 156'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397221": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 160'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397224": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 175'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397230": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 194'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397234": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 213'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397251": {
        description: "CONDUIT CLIP PKG 118'-57\" E2045",
        mapicsDescription: "CONDUIT CLIP PKG 118'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397252": {
        description: "CONDUIT CLIP PKG 137'-57\" E2045",
        mapicsDescription: "CONDUIT CLIP PKG 137'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397253": {
        description: "CONDUIT CLIP PKG 156'-57\" E2045",
        mapicsDescription: "CONDUIT CLIP PKG 156'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397254": {
        description: "CONDUIT CLIP PKG 175'-57\" E2045",
        mapicsDescription: "CONDUIT CLIP PKG 175'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397273": {
        description: null,
        mapicsDescription: "CONDUIT CLP COUPLERS 118'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397274": {
        description: null,
        mapicsDescription: "CONDUIT CLP COUPLERS 137'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397275": {
        description: null,
        mapicsDescription: "CONDUIT CLP COUPLERS 156'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397276": {
        description: null,
        mapicsDescription: "CONDUIT CLP COUPLERS 160'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397277": {
        description: null,
        mapicsDescription: "CONDUIT CLP COUPLERS 175'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397278": {
        description: null,
        mapicsDescription: "CONDUIT CLP COUPLERS 194'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397279": {
        description: null,
        mapicsDescription: "CONDUIT CLP COUPLERS 213'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397255": {
        description: "CONDUIT CLIP PKG 194'-57\" E2045",
        mapicsDescription: "CONDUIT CLIP PKG 194'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397272": {
        description: null,
        mapicsDescription: "CONDUIT CLP COUPLERS 99'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397205": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 99'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397204": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 99'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397271": {
        description: null,
        mapicsDescription: "CONDUIT CLP COUPLERS 80'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397201": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 80'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397200": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 80'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397257": {
        description: null,
        mapicsDescription: "CONDUIT CLP PKG 137'-114 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397258": {
        description: null,
        mapicsDescription: "CONDUIT CLP PKG 156'-114 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397256": {
        description: null,
        mapicsDescription: "CONDUIT CLP PKG 118'-114 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397259": {
        description: null,
        mapicsDescription: "CONDUIT CLP PKG 175'-114 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397260": {
        description: null,
        mapicsDescription: "CONDUIT CLP PKG 194'-114 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397667": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 118'-114\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397668": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 137'-114\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397669": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 156'-114\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397670": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 175'-114\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397671": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 194'-114\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397604": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 118'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397606": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 137'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397608": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 156'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397610": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 160'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397612": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 175'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397614": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 180'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397615": {
        description: "CONDUIT TDC PKG 186.5'-LEPA",
        mapicsDescription: "CONDUIT TDC PKG 186.5'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397616": {
        description: null,
        mapicsDescription: "CONDUIT TDC CPLRS 194'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397618": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 204'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397619": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 213'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397605": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 118'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397607": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 137'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397609": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 156'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397611": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 160'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397613": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 175'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397617": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 194'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397620": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 213'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397632": {
        description: "CONDUIT TDC PKG 118'-57\" E2045",
        mapicsDescription: "CONDUIT TDC PKG 118'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397633": {
        description: "CONDUIT TDC PKG 137'-57\" E2045",
        mapicsDescription: "CONDUIT TDC PKG 137'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397634": {
        description: "CONDUIT TDC PKG 156'-57\" E2045",
        mapicsDescription: "CONDUIT TDC PKG 156'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397635": {
        description: "CONDUIT TDC PKG 175'-57\" E2045",
        mapicsDescription: "CONDUIT TDC PKG 175'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397641": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 118'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397642": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 137'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397643": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 156'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397644": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 160'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397645": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 175'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397646": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 194'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397647": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 213'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397636": {
        description: "CONDUIT TDC PKG 194'-57\" E2045",
        mapicsDescription: "CONDUIT TDC PKG 194'-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397640": {
        description: null,
        mapicsDescription: "CONDUIT TDC COUPLERS 99'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397603": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 99'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397602": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 99'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397639": {
        description: null,
        mapicsDescription: "CONDUIT TDC CPLRS 80'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397601": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 80'-57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397600": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 80'-LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397309": {
        description: "CONDUIT CLIP PKG 118’ 8-5/8\"",
        mapicsDescription: "CONDUIT CLIP PKG 80' 8-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397310": {
        description: "CONDUIT CLIP PKG 137' 8-5/8\"",
        mapicsDescription: "CONDUIT CLIP PKG 137' 8-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397311": {
        description: "CONDUIT CLIP PKG 156' 8-5/8\"",
        mapicsDescription: "CONDUIT CLIP PKG 156' 8-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397312": {
        description: "CONDUIT CLIP PKG 160' 8-5/8\"",
        mapicsDescription: "CONDUIT CLIP PKG 160' 8-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397202": {
        description: "CONDUIT CLIP PKG 80'   6&6-5/8",
        mapicsDescription: "CONDUIT CLIP PKG 80' 6&6-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397206": {
        description: "CONDUIT CLIP PKG 99'   6&6-5/8",
        mapicsDescription: "CONDUIT CLIP PKG 99' 6&6-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397210": {
        description: "CONDUIT CLIP PKG 118'  6&6-5/8",
        mapicsDescription: "CONDUIT CLIP PKG 118' 6&6-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397214": {
        description: "CONDUIT CLIP PKG 137' 6&6-5/8",
        mapicsDescription: "CONDUIT CLIP PKG 137' 6&6-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397218": {
        description: "CONDUIT CLIP PKG 156' 6&6-5/8",
        mapicsDescription: "CONDUIT CLIP PKG 156' 6&6-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397261": {
        description: "CONDUIT CLIP PKG 160' 6&6-5/8",
        mapicsDescription: "CONDUIT CLIP PKG 160' 6&6-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397225": {
        description: "CONDUIT CLIP PKG 175'  6&6-5/8",
        mapicsDescription: "CONDUIT CLIP PKG 175' 6&6-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397262": {
        description: "CONDUIT CLIP PKG 194' 6&6-5/8",
        mapicsDescription: "CONDUIT CLIP PKG 194' 6&6-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392601-H": {
        description: "SPAN CABLE-118' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-118FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392603-H": {
        description: "SPAN CABLE-120' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-120FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392604-H": {
        description: "SPAN CABLE-137' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-137FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392609-H": {
        description: "SPAN CABLE-140'-PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-140FT-PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392607-H": {
        description: "SPAN CABLE-156' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-156FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392610-H": {
        description: "SPAN CABLE-160' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-160FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392613-H": {
        description: "SPAN CABLE-175' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-175FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392616-H": {
        description: "SPAN CABLE-180' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-180FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392623-H": {
        description: "SPAN CABLE-186.7' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-186.7 PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392619-H": {
        description: "SPAN CABLE-194' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-194FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392622-H": {
        description: "SPAN CABLE-204' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-204FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392625-H": {
        description: "SPAN CBL # 8, 213' PVT SPAN, E",
        mapicsDescription: "SPAN CBL # 8-213' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392601": {
        description: "SPAN CABLE-118' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-118FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392603": {
        description: "SPAN CABLE-120' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-120FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392604": {
        description: "SPAN CABLE-137' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-137FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392609": {
        description: "SPAN CABLE-140'-PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-140FT-PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392607": {
        description: "SPAN CABLE-156' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-156FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392610": {
        description: "SPAN CABLE-160' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-160FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392613": {
        description: "SPAN CABLE-175' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-175FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392616": {
        description: "SPAN CABLE-180' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-180FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392623": {
        description: null,
        mapicsDescription: "SPAN CABLE-186.7' PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392619": {
        description: "SPAN CABLE-194' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-194FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392622": {
        description: "SPAN CABLE-204' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-204FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392625": {
        description: null,
        mapicsDescription: "SPAN CABLE-213' PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392601-X": {
        description: "SPAN CABLE-118' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-118 PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392603-X": {
        description: "SPAN CABLE-120' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-120 PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392604-X": {
        description: "SPAN CABLE-137' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-137 PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392609-X": {
        description: "SPAN CABLE-140'-PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-140-PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392607-X": {
        description: "SPAN CABLE-156' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-156 PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392610-X": {
        description: "SPAN CABLE-160' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-160 PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392613-X": {
        description: "SPAN CABLE-175' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-175 PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392616-X": {
        description: "SPAN CABLE-180' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-180 PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392623-X": {
        description: "SPAN CABLE-186.7' PVT SPAN-11C",
        mapicsDescription: "SPAN CABLE-186.7 PVT SPAN-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392619-X": {
        description: "SPAN CABLE-194' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-194 PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392622-X": {
        description: "SPAN CABLE-204' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-204 PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392625-X": {
        description: null,
        mapicsDescription: "SPAN CBL-213' PVT SPAN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395870": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-118' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395871": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-137' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395872": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-140' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395873": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-156' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395874": {
        description: null,
        mapicsDescription: "CABLE SPAN-14C-160' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395875": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-175' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395876": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-194' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395877": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-180' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395878": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-186.7 PIVOT-MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392602-H": {
        description: "SPAN CABLE-118' PIVOT SPAN-KT#8",
        mapicsDescription: "SPAN CABLE-118 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392605-H": {
        description: "SPAN CABLE-137' PIVOT SPAN-KT#8",
        mapicsDescription: "SPAN CABLE-137 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392612-H": {
        description: "SPAN CABLE-140'-PIVOT SPAN-KT#8",
        mapicsDescription: "SPAN CABLE-140-PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392608-H": {
        description: "SPAN CABLE-156' PIVOT SPAN-KT#8",
        mapicsDescription: "SPAN CABLE-156 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392611-H": {
        description: "SPAN CABLE-160' PIVOT SPAN-KT#8",
        mapicsDescription: "SPAN CABLE-160 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392614-H": {
        description: "SPAN CABLE-175' PIVOT SPAN-KT#8",
        mapicsDescription: "SPAN CABLE-175 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392617-H": {
        description: "SPAN CABLE-180' PIVOT SPAN-KT#8",
        mapicsDescription: "SPAN CABLE-180 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392618-H": {
        description: "SPAN CABLE-186.7' PIVOT SP-KT#8",
        mapicsDescription: "SPAN CABLE-186.7 PIVOT SP-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392620-H": {
        description: "SPAN CABLE-194' PIVOT SPAN-KT#8",
        mapicsDescription: "SPAN CABLE-194 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392624-H": {
        description: "SPAN CABLE-213' PIVOT SPAN-KT#8",
        mapicsDescription: "SPAN CABLE-213 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392602": {
        description: "SPAN CABLE-118' PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-118FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392605": {
        description: "SPAN CABLE-137' PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-137FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392612": {
        description: "SPAN CABLE-140'-PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-140FT-PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392608": {
        description: "SPAN CABLE-156' PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-156FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392611": {
        description: "SPAN CABLE-160' PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-160FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392614": {
        description: "SPAN CABLE-175' PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-175FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392617": {
        description: "SPAN CABLE-180' PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-180FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392618": {
        description: "SPAN CABLE-186.7' PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-186.7 PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392620": {
        description: "SPAN CABLE-194' PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-194FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392624": {
        description: "SPAN CABLE-213' PIVOT SPAN-KT",
        mapicsDescription: "SPAN CABLE-213FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392602-X": {
        description: "SPAN CABLE-118' PVT SPN-KT-11C",
        mapicsDescription: "SPAN CABLE-118 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392732-X": {
        description: "SPAN CABLE 11C 118' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 11C 118 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392605-X": {
        description: "SPAN CABLE-137' PVT SPN-KT-11C",
        mapicsDescription: "SPAN CABLE-137 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392612-X": {
        description: "SPAN CABLE-140' PVT SPN-KT-11C",
        mapicsDescription: "SPAN CABLE-140 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392733-X": {
        description: "SPAN CABLE 11C 137' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 11C 137 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392608-X": {
        description: "SPAN CABLE-156' PVT SPN-KT-11C",
        mapicsDescription: "SPAN CABLE-156 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392611-X": {
        description: "SPAN CABLE-160' PVT SPN-KT-11C",
        mapicsDescription: "SPAN CABLE-160 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392734-X": {
        description: "SPAN CABLE 11C 156' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 11C 156 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392735-X": {
        description: "SPAN CABLE 11C 160' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 11C 160 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392614-X": {
        description: "SPAN CABLE-175' PVT SPN-KT-11C",
        mapicsDescription: "SPAN CABLE-175 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392617-X": {
        description: "SPAN CABLE-180' PVT SPN-KT-11C",
        mapicsDescription: "SPAN CABLE-180 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392736-X": {
        description: "SPAN CABLE 11C 175' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 11C 175 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392618-X": {
        description: "SPAN CABLE-186.7' PIVSPNKT-11C",
        mapicsDescription: "SPAN CABLE-186.7 PIVSPNKT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392620-X": {
        description: "SPAN CABLE-194' PVT SPN-KT-11C",
        mapicsDescription: "SPAN CABLE-194 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392624-X": {
        description: "SPAN CABLE-213' PVT SPN-KT-11C",
        mapicsDescription: "SPAN CABLE-213 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392732-14": {
        description: "SPAN CABLE 14C 118' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 14C 118 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392733-14": {
        description: "SPAN CABLE 14C 137' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 14C 137 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392734-14": {
        description: "SPAN CABLE 14C 156' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 14C 156 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392735-14": {
        description: "SPAN CABLE 14C 160' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 14C 160 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392736-14": {
        description: "SPAN CABLE 14C 175' 1ST SUG/MAX",
        mapicsDescription: "SPAN CABLE 14C 175 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392652-H": {
        description: "SPAN CABLE-118' 1ST-CTR F-C F#8",
        mapicsDescription: "SPAN CABLE-118 1ST-CTR F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392653-H": {
        description: "SPAN CABLE-118' 1ST-END F-C F#8",
        mapicsDescription: "SPAN CABLE-118 1ST-END F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392655-H": {
        description: "SPAN CABLE-137' 1ST-CTR F-C F#8",
        mapicsDescription: "SPAN CABLE-137 1ST-CTR F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392699-H": {
        description: "SPAN CABLE-140'-1ST-CTR F-C F#8",
        mapicsDescription: "SPAN CABLE-140-1ST-CTR F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392656-H": {
        description: "SPAN CABLE-137' 1ST-END F-C F#8",
        mapicsDescription: "SPAN CABLE-137 1ST-END F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392700-H": {
        description: "SPAN CABLE-140' 1ST-END F-C F#8",
        mapicsDescription: "SPAN CABLE-140 1ST-END F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392658-H": {
        description: "SPAN CABLE-156' 1ST-CTR F-C F#8",
        mapicsDescription: "SPAN CABLE-156 1ST-CTR F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392661-H": {
        description: "SPAN CABLE-160' 1ST-CTR F-C F#8",
        mapicsDescription: "SPAN CABLE-160 1ST-CTR F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392659-H": {
        description: "SPAN CABLE-156' 1ST-END F-C F#8",
        mapicsDescription: "SPAN CABLE-156 1ST-END F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392662-H": {
        description: "SPAN CABLE-160' 1ST-END F-C F#8",
        mapicsDescription: "SPAN CABLE-160 1ST-END F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392652": {
        description: "SPAN CABLE-118' 1ST-CTR FD-C FD",
        mapicsDescription: "SPAN CABLE-118 1ST-CTR FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392653": {
        description: "SPAN CABLE-118' 1ST-END FD-C FD",
        mapicsDescription: "SPAN CABLE-118 1ST-END FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392655": {
        description: "SPAN CABLE-137' 1ST-CTR FD-C FD",
        mapicsDescription: "SPAN CABLE-137 1ST-CTR FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392699": {
        description: "SPAN CABLE-140' 1ST-CTR FD-C FD",
        mapicsDescription: "SPAN CABLE-140 1ST-CTR FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392656": {
        description: "SPAN CABLE-137' 1ST-END FD-C FD",
        mapicsDescription: "SPAN CABLE-137 1ST-END FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392700": {
        description: "SPAN CABLE-140' 1ST-END FD-C FD",
        mapicsDescription: "SPAN CABLE-140 1ST-END FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392658": {
        description: "SPAN CABLE-156' 1ST-CTR FD-C FD",
        mapicsDescription: "SPAN CABLE-156 1ST-CTR FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392661": {
        description: "SPAN CABLE-160' 1ST-CTR FD-C FD",
        mapicsDescription: "SPAN CABLE-160 1ST-CTR FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392659": {
        description: "SPAN CABLE-156' 1ST-END FD-C FD",
        mapicsDescription: "SPAN CABLE-156 1ST-END FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392662": {
        description: "SPAN CABLE-160' 1ST-END FD-C FD",
        mapicsDescription: "SPAN CABLE-160 1ST-END FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392653-X": {
        description: null,
        mapicsDescription: "WIRE 11C-118' 1ST-END FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392656-X": {
        description: null,
        mapicsDescription: "WIRE 11C-137' 1ST-END FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392700-X": {
        description: null,
        mapicsDescription: "WIRE 11C-140' 1ST-END FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392659-X": {
        description: null,
        mapicsDescription: "WIRE 11C-156' 1ST-END FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392662-X": {
        description: null,
        mapicsDescription: "WIRE 11C-160' 1ST-END FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392652-X": {
        description: null,
        mapicsDescription: "WIRE-11C-118' -1ST-CEN FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392651-X": {
        description: "SPAN CABLE-118' 1ST-END FD-MAXI",
        mapicsDescription: "SPAN CABLE-118 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392655-X": {
        description: null,
        mapicsDescription: "WIRE-11C-137' -1ST-CEN FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392699-X": {
        description: null,
        mapicsDescription: "WIRE-11C-140' -1ST-CEN FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392654-X": {
        description: "SPAN CABLE-137' 1ST-END FD-MAXI",
        mapicsDescription: "SPAN CABLE-137 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392701-X": {
        description: "SPAN CABLE-140' 1ST-END FD-MAXI",
        mapicsDescription: "SPAN CABLE-140 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392658-X": {
        description: null,
        mapicsDescription: "WIRE-11C-156' -1ST-CEN FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392661-X": {
        description: null,
        mapicsDescription: "WIRE-11C-160' -1ST-CEN FD CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392657-X": {
        description: "SPAN CABLE-156' 1ST-END FD-MAXI",
        mapicsDescription: "SPAN CABLE-156 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392660-X": {
        description: "SPAN CABLE-160' 1ST-END FD-MAXI",
        mapicsDescription: "SPAN CABLE-160 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392663-X": {
        description: "SPAN CABLE-175' 1ST-END FD-MAXI",
        mapicsDescription: "SPAN CABLE-175 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392666-X": {
        description: "SPAN CABLE-180' 1ST-END FD-MAXI",
        mapicsDescription: "SPAN CABLE-180 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392675-X": {
        description: "WIRE-11C-186.7' 1ST-ND FD-MAXI",
        mapicsDescription: "WIRE-11C-186.7 1ST-ND FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392669-X": {
        description: "SPAN CABLE-194' 1ST-END FD-MAXI",
        mapicsDescription: "SPAN CABLE-194 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392652-14": {
        description: "WIRE-14C-118'-1ST-CTR FD-CANAL",
        mapicsDescription: "WIRE-14C-118-1ST-CTR FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392651-14": {
        description: null,
        mapicsDescription: "WIRE 14C-118' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392653-14": {
        description: null,
        mapicsDescription: "WIRE-14C-118' -1ST END FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392655-14": {
        description: null,
        mapicsDescription: "WIRE-14C-137' -1ST-CEN FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392699-14": {
        description: null,
        mapicsDescription: "WIRE-14C-140' -1ST-CEN FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392654-14": {
        description: null,
        mapicsDescription: "WIRE 14C-137' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392701-14": {
        description: null,
        mapicsDescription: "WIRE 14C-140' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392656-14": {
        description: null,
        mapicsDescription: "WIRE-14C-137' -1ST-END FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392700-14": {
        description: null,
        mapicsDescription: "WIRE-14C-140' -1ST-END FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392658-14": {
        description: null,
        mapicsDescription: "WIRE-14C-156' -1ST-CEN FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392661-14": {
        description: null,
        mapicsDescription: "WIRE-14C-160' -1ST-CEN FD CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392657-14": {
        description: null,
        mapicsDescription: "WIRE 14C-156' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392659-14": {
        description: null,
        mapicsDescription: "WIRE-14C-156' -1ST-END FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392660-14": {
        description: null,
        mapicsDescription: "WIRE 14C-160' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392662-14": {
        description: null,
        mapicsDescription: "WIRE-14C-160' -1ST-END FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392663-14": {
        description: null,
        mapicsDescription: "WIRE 14C-175' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392666-14": {
        description: null,
        mapicsDescription: "WIRE 14C-180' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392675-14": {
        description: null,
        mapicsDescription: "WIRE-14C-186.7'-1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392669-14": {
        description: null,
        mapicsDescription: "WIRE 14C-194' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392628-H": {
        description: "SPAN CABLE-118' DISC SPAN-#8",
        mapicsDescription: "SPAN CABLE-118FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392631-H": {
        description: "SPAN CABLE-137' DISC SPAN-#8",
        mapicsDescription: "SPAN CABLE-137FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392698-H": {
        description: "SPAN CABLE-140' DISC SPAN-#8",
        mapicsDescription: "SPAN CABLE-140FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392634-H": {
        description: "SPAN CABLE-156' DISC SPAN-#8",
        mapicsDescription: "SPAN CABLE-156FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392637-H": {
        description: "SPAN CABLE-160' DISC SPAN-#8",
        mapicsDescription: "SPAN CABLE-160FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392640-H": {
        description: "SPAN CABLE-175' DISC SPAN-#8",
        mapicsDescription: "SPAN CABLE-175FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392643-H": {
        description: "SPAN CABLE-180' DISC SPAN-#8",
        mapicsDescription: "SPAN CABLE-180FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392697-H": {
        description: "SPAN CABLE-186.7' DISC SPAN #8",
        mapicsDescription: "SPAN CABLE-186.7 DISC SPAN #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392646-H": {
        description: "SPAN CABLE-194' DISC SPAN-#8",
        mapicsDescription: "SPAN CABLE-194FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392645-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-204 DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392636-H": {
        description: "SPAN CABLE-213' DISC SPAN-#8",
        mapicsDescription: "SPAN CABLE-213FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392628": {
        description: "SPAN CABLE-118' DISC SPAN",
        mapicsDescription: "SPAN CABLE-118FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392631": {
        description: "SPAN CABLE-137' DISC SPAN",
        mapicsDescription: "SPAN CABLE-137FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392698": {
        description: "SPAN CABLE-140' DISC SPAN",
        mapicsDescription: "SPAN CABLE-140FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392634": {
        description: "SPAN CABLE-156' DISC SPAN",
        mapicsDescription: "SPAN CABLE-156FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392637": {
        description: "SPAN CABLE-160' DISC SPAN",
        mapicsDescription: "SPAN CABLE-160FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392640": {
        description: "SPAN CABLE-175' DISC SPAN",
        mapicsDescription: "SPAN CABLE-175FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392643": {
        description: "SPAN CABLE-180' DISC SPAN",
        mapicsDescription: "SPAN CABLE-180FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392697": {
        description: "SPAN CABLE-186.7' DISC SPAN",
        mapicsDescription: "SPAN CABLE-186.7 DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392646": {
        description: "SPAN CABLE-194' DISC SPAN",
        mapicsDescription: "SPAN CABLE-194FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392645": {
        description: "SPAN CABLE-204' DISC SPAN",
        mapicsDescription: "SPAN CABLE-204FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392636": {
        description: "SPAN CABLE-213' DISC SPAN",
        mapicsDescription: "SPAN CABLE-213FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392745": {
        description: null,
        mapicsDescription: "SPAN CABLE-118' DISC H-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392746": {
        description: null,
        mapicsDescription: "SPAN CABLE-137' DISC H-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392747": {
        description: null,
        mapicsDescription: "SPAN CABLE-140' DISC H-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392748": {
        description: null,
        mapicsDescription: "SPAN CABLE-156' DISC H-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392749": {
        description: null,
        mapicsDescription: "SPAN CABLE-160' DISC H-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392750": {
        description: null,
        mapicsDescription: "SPAN CABLE-175' DISC H-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392751": {
        description: null,
        mapicsDescription: "SPAN CABLE-180' DISC H-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392752": {
        description: null,
        mapicsDescription: "SPAN CABLE-186.7' DISC H-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392753": {
        description: null,
        mapicsDescription: "SPAN CABLE-194' DISC H-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392626-H": {
        description: "SPAN CABLE-118' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-118 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392627-H": {
        description: "SPAN CABLE-120' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-120 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392676-H": {
        description: "SPAN CABLE-118' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-118FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392629-H": {
        description: "SPAN CABLE-137' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-137 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392630-H": {
        description: "SPAN CABLE-140' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-140 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392679-H": {
        description: "SPAN CABLE-137' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-137FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392680-H": {
        description: "SPAN CABLE-140' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-140FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392632-H": {
        description: "SPAN CABLE-156' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-156 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392635-H": {
        description: "SPAN CABLE-160' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-160 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392682-H": {
        description: "SPAN CABLE-156' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-156FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392685-H": {
        description: "SPAN CABLE-160' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-160FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392638-H": {
        description: "SPAN CABLE-175' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-175 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392641-H": {
        description: "SPAN CABLE-180' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-180 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392688-H": {
        description: "SPAN CABLE-175' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-175FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392691-H": {
        description: "SPAN CABLE-180' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-180FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392642-H": {
        description: "SPAN CABLE-186.7' INT/END SP-#8",
        mapicsDescription: "SPAN CABLE-186.7 INT/END SP-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392692-H": {
        description: "SPAN CABLE-186.7' LAST SAC #8",
        mapicsDescription: "SPAN CABLE-186.7 LAST SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392644-H": {
        description: "SPAN CABLE-194' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-194 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392694-H": {
        description: "SPAN CABLE-194' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-194FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392647-H": {
        description: "SPAN CABLE-204' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-204 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392649-H": {
        description: "SPAN CABLE-213' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-213 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392626": {
        description: "SPAN CABLE-118' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-118FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392627": {
        description: "SPAN CABLE-120' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-120FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392676": {
        description: "SPAN CABLE-118' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-118FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392629": {
        description: "SPAN CABLE-137' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-137FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392630": {
        description: "SPAN CABLE-140' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-140FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392679": {
        description: "SPAN CABLE-137' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-137FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392680": {
        description: "SPAN CABLE-140' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-140FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392632": {
        description: "SPAN CABLE-156' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-156FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392635": {
        description: "SPAN CABLE-160' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-160FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392682": {
        description: "SPAN CABLE-156' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-156FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392685": {
        description: "SPAN CABLE-160' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-160FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392638": {
        description: "SPAN CABLE-175' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-175FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392641": {
        description: "SPAN CABLE-180' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-180FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392688": {
        description: "SPAN CABLE-175' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-175FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392691": {
        description: "SPAN CABLE-180' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-180FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392642": {
        description: "SPAN CABLE-186.7' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-186.7 INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392692": {
        description: "SPAN CABLE-186.7' LAST SAC SPAN",
        mapicsDescription: "SPAN CABLE-186.7 LAST SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392644": {
        description: "SPAN CABLE-194' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-194FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392694": {
        description: "SPAN CABLE-194' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-194FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392647": {
        description: "SPAN CABLE-204' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-204FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392649": {
        description: "SPAN CABLE-213' INT/END SPN-E",
        mapicsDescription: "SPAN CABLE-213FT INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392626-X": {
        description: "SPAN CABLE-118' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-118 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392627-X": {
        description: "SPAN CABLE-120' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-120 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392676-X": {
        description: "SPAN CABLE-118' LAST-SAC 11C",
        mapicsDescription: "SPAN CABLE-118FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392629-X": {
        description: "SPAN CABLE-137' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-137 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392630-X": {
        description: "SPAN CABLE-140' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-140 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392679-X": {
        description: "SPAN CABLE-137' LAST-SAC 11C",
        mapicsDescription: "SPAN CABLE-137FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392680-X": {
        description: "SPAN CABLE-140' LAST-SAC 11C",
        mapicsDescription: "SPAN CABLE-140FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392632-X": {
        description: "SPAN CABLE-156' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-156 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392635-X": {
        description: "SPAN CABLE-160' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-160 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392682-X": {
        description: "SPAN CABLE-156' LAST-SAC 11C",
        mapicsDescription: "SPAN CABLE-156FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392685-X": {
        description: "SPAN CABLE-160' LAST-SAC 11C",
        mapicsDescription: "SPAN CABLE-160FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392638-X": {
        description: "SPAN CABLE-175' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-175 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392641-X": {
        description: "SPAN CABLE-180' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-180 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392688-X": {
        description: "SPAN CABLE-175' LAST-SAC 11C",
        mapicsDescription: "SPAN CABLE-175FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392691-X": {
        description: "SPAN CABLE-180' LAST-SAC 11C",
        mapicsDescription: "SPAN CABLE-180FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392642-X": {
        description: "SPAN CABLE-186.7' INT/END 11C",
        mapicsDescription: "SPAN CABLE-186.7 INT/END 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392692-X": {
        description: "SPAN CABLE-186.7' LAST SAC 11C",
        mapicsDescription: "SPAN CABLE-186.7 LAST SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392644-X": {
        description: "SPAN CABLE-194' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-194 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392694-X": {
        description: "SPAN CABLE-194' LAST-SAC 11C",
        mapicsDescription: "SPAN CABLE-194FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392647-X": {
        description: "SPAN CABLE-204' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-204 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392649-X": {
        description: "SPAN CABLE-213' INT/END-E-11C",
        mapicsDescription: "SPAN CABLE-213 INT/END-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392626-14": {
        description: null,
        mapicsDescription: "WIRE-14C-118' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392627-14": {
        description: null,
        mapicsDescription: "WIRE-14C-120' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392629-14": {
        description: null,
        mapicsDescription: "WIRE-14C-137' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392630-14": {
        description: null,
        mapicsDescription: "WIRE-14C-140' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392632-14": {
        description: null,
        mapicsDescription: "WIRE-14C-156' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392635-14": {
        description: null,
        mapicsDescription: "WIRE-14C-160' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392638-14": {
        description: null,
        mapicsDescription: "WIRE-14C-175' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392641-14": {
        description: null,
        mapicsDescription: "WIRE-14C-180' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392642-14": {
        description: null,
        mapicsDescription: "WIRE-14C-186.7' INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392644-14": {
        description: null,
        mapicsDescription: "WIRE-14C-194' -INC/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392647-14": {
        description: null,
        mapicsDescription: "WIRE-14C-204' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392649-14": {
        description: null,
        mapicsDescription: "WIRE-14C-213'-INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392501": {
        description: "SPAN CABLE/12, 118' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-118' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392503": {
        description: "SPAN CABLE/12, 120' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-120' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392504": {
        description: "SPAN CABLE/12, 137' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-137' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392509": {
        description: "SPAN CABLE/12, 140' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-140' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392507": {
        description: "SPAN CABLE/12, 156' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-156' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392510": {
        description: "SPAN CABLE/12, 160' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-160' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392513": {
        description: "SPAN CABLE/12, 175' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-175' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392516": {
        description: "SPAN CABLE/12, 180' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-180' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392523": {
        description: "SPAN CBLE/12, 186.7' PVT SPAN, E",
        mapicsDescription: "SPAN CBLE/12-186.7' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392519": {
        description: "SPAN CABLE/12, 194' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-194' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392522": {
        description: "SPAN CABLE/12, 204' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-204' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392525": {
        description: "SPAN CABLE/12, 213' PVT SPAN, E",
        mapicsDescription: "SPAN CABLE/12-213' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392502": {
        description: "SPAN CABLE/12, 118' PVT SPAN, KT",
        mapicsDescription: "SPAN CABLE/12-118' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392505": {
        description: "SPAN CABLE/12, 137' PVT SPAN, KT",
        mapicsDescription: "SPAN CABLE/12-137' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392512": {
        description: "SPAN CABLE/12, 140' PVT SPAN, KT",
        mapicsDescription: "SPAN CABLE/12-140' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392508": {
        description: "SPAN CABLE/12, 156' PVT SPAN, KT",
        mapicsDescription: "SPAN CABLE/12-156' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392511": {
        description: "SPAN CABLE/12, 160' PVT SPAN, KT",
        mapicsDescription: "SPAN CABLE/12-160' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392514": {
        description: "SPAN CABLE/12, 175' PVT SPAN, KT",
        mapicsDescription: "SPAN CABLE/12-175' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392517": {
        description: "SPAN CABLE/12, 180' PVT SPAN, KT",
        mapicsDescription: "SPAN CABLE/12-180' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392518": {
        description: "SPAN CBL/12, 186.7' PVT SPAN, KT",
        mapicsDescription: "SPAN CBL/12-186.7' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392520": {
        description: "SPAN CABLE/12, 194' PVT SPAN, KT",
        mapicsDescription: "SPAN CABLE/12-194' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392524": {
        description: "SPAN CABLE/12, 213' PVT SPAN, KT",
        mapicsDescription: "SPAN CABLE/12-213' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392526": {
        description: "SPAN CBL/12, 118' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-118' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392527": {
        description: "SPAN CBL/12, 120' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-120' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392550": {
        description: "SPN CBL/12, 118' LAST, SAC, SPAN",
        mapicsDescription: "SPN CBL/12-118' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392529": {
        description: "SPAN CBL/12, 137' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-137' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392530": {
        description: "SPAN CBL/12, 140' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-140' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392551": {
        description: "SPN CBL/12, 137' LAST, SAC, SPAN",
        mapicsDescription: "SPN CBL/12-137' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392552": {
        description: "SPN CBL/12, 140' LAST, SAC, SPAN",
        mapicsDescription: "SPN CBL/12-140' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392532": {
        description: "SPAN CBL/12, 156' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-156' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392535": {
        description: "SPAN CBL/12, 160' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-160' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392553": {
        description: "SPN CBL/12, 156' LAST, SAC, SPAN",
        mapicsDescription: "SPN CBL/12-156' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392554": {
        description: "SPN CBL/12, 160' LAST, SAC, SPAN",
        mapicsDescription: "SPN CBL/12-160' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392538": {
        description: "SPAN CBL/12, 175' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-175' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392541": {
        description: "SPAN CBL/12, 180' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-180' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392555": {
        description: "SPN CBL/12, 175' LAST, SAC, SPAN",
        mapicsDescription: "SPN CBL/12-175' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392556": {
        description: "SPN CBL/12, 180' LAST, SAC, SPAN",
        mapicsDescription: "SPN CBL/12-180' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392542": {
        description: "SPN CBL/12, 186.7' INT/END SPN, E",
        mapicsDescription: "SPN CBL/12-186.7 INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392557": {
        description: "SPN CBL/12, 186.7' LAST, SAC, SPAN",
        mapicsDescription: "SPN CBL/12-186.7 LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392544": {
        description: "SPAN CBL/12, 194' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-194' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392558": {
        description: "SPN CBL/12, 194' LAST, SAC, SPAN",
        mapicsDescription: "SPN CBL/12-194' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392547": {
        description: "SPAN CBL/12, 204' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-204' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392549": {
        description: "SPAN CBL/12, 213' INT/END SPN, E",
        mapicsDescription: "SPAN CBL/12-213' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392572": {
        description: "SPAN CABLE-99' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-99 FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392574": {
        description: "SPAN CABLE-99' INT/END SPAN-E",
        mapicsDescription: "SPAN CABLE-99FT INT/END SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352641": {
        description: "SPAN CABLE-99' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-99FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392572-H": {
        description: "SPAN CABLE-99' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-99 FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392572-X": {
        description: "SPAN CABLE-99' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-99 FT PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392574-H": {
        description: "SPAN CABLE-99' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-99 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392574-X": {
        description: "SPAN CABLE-99' INT/END SP-E-11C",
        mapicsDescription: "SPAN CABLE-99 INT/END SP-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352641-H": {
        description: "SPAN CABLE-99' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-99FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392731-X": {
        description: null,
        mapicsDescription: "SPAN CABLE 11C 99' 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392571": {
        description: "SPAN CABLE-80' PIVOT SPAN-E",
        mapicsDescription: "SPAN CABLE-80 FT PIVOT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392573": {
        description: "SPAN CABLE-80' INT/END SPAN-E",
        mapicsDescription: "SPAN CABLE-80FT INT/END SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352640": {
        description: "SPAN CABLE-80' LAST-SAC SPAN",
        mapicsDescription: "SPAN CABLE-80FT LAST-SAC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392571-H": {
        description: "SPAN CABLE-80' PIVOT SP-#8-E",
        mapicsDescription: "SPAN CABLE-80 FT PIVOT SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392571-X": {
        description: "SPAN CABLE-80' PVT SPN-E-11C",
        mapicsDescription: "SPAN CABLE-80 FT PVT SPN-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392573-H": {
        description: "SPAN CABLE-80' INT/END SP-#8-E",
        mapicsDescription: "SPAN CABLE-80 INT/END SP-#8-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392573-X": {
        description: "SPAN CABLE-80' INT/END SP-E-11C",
        mapicsDescription: "SPAN CABLE-80 INT/END SP-E-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352640-H": {
        description: "SPAN CABLE-80' LAST-SAC #8",
        mapicsDescription: "SPAN CABLE-80FT LAST-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392730-X": {
        description: null,
        mapicsDescription: "SPAN CABLE 11C 80' 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392639": {
        description: null,
        mapicsDescription: "SPAN CABLE-80FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392703": {
        description: null,
        mapicsDescription: "SPAN CABLE/12-80' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392704": {
        description: null,
        mapicsDescription: "SPAN CABLE/12-99' PVT SPAN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392705": {
        description: null,
        mapicsDescription: "SPAN CABLE/12-80' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392706": {
        description: null,
        mapicsDescription: "SPAN CABLE/12-99' PVT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392707": {
        description: null,
        mapicsDescription: "SPAN CBL/12-80' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392708": {
        description: null,
        mapicsDescription: "SPAN CBL/12-99' INT/END SPN-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392709": {
        description: null,
        mapicsDescription: "SPAN CBL/12-80' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392710": {
        description: null,
        mapicsDescription: "SPN CBL/12-99' LAST-SAC-SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392711": {
        description: null,
        mapicsDescription: "SPAN CABLE-80FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392712": {
        description: null,
        mapicsDescription: "SPAN CABLE-99FT PIVOT SPAN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392715": {
        description: null,
        mapicsDescription: "SPAN CABLE-80 1ST-CTR FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392717": {
        description: null,
        mapicsDescription: "SPAN CABLE-80 1ST-END FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392718": {
        description: null,
        mapicsDescription: "SPAN CABLE-99 1ST-END FD-C FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392721": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-80' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392722": {
        description: null,
        mapicsDescription: "SPAN CABLE-14C-99' PIVOT-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392573-14": {
        description: null,
        mapicsDescription: "WIRE-14C-80' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392574-14": {
        description: null,
        mapicsDescription: "WIRE-14C-99' -INT/END SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392633-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-99FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392639-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-80FT DISC SPAN-#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392702-X": {
        description: null,
        mapicsDescription: "WIRE-11C 80' 1ST MAXI-97.5",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392711-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-80 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392711-X": {
        description: null,
        mapicsDescription: "SPAN CABLE-80 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392712-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-99 PIVOT SPAN-KT#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392712-X": {
        description: null,
        mapicsDescription: "SPAN CABLE-99 PVT SPN-KT-11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392713-14": {
        description: null,
        mapicsDescription: "WIRE 14C-80' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392713-X": {
        description: null,
        mapicsDescription: "SPAN CABLE-80 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392714-14": {
        description: null,
        mapicsDescription: "WIRE 14C-99' -1ST HOSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392714-X": {
        description: null,
        mapicsDescription: "SPAN CABLE-99 1ST-END FD-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392715-14": {
        description: null,
        mapicsDescription: "WIRE-14C-80 -1ST-CTR FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392715-X": {
        description: null,
        mapicsDescription: "WIRE-11C-80' -1ST-CEN FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392716-14": {
        description: null,
        mapicsDescription: "WIRE-14C-99 -1ST-CTR FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392716-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-99 1ST-CTR F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392716-X": {
        description: null,
        mapicsDescription: "WIRE-11C-99' -1ST-CEN FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392717-14": {
        description: null,
        mapicsDescription: "WIRE-14C-80' -1ST END FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392717-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-80 1ST-END F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392717-X": {
        description: null,
        mapicsDescription: "WIRE-11C-80' -1ST-END FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392718-14": {
        description: null,
        mapicsDescription: "WIRE-14C-99' -1ST END FD-CANL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392718-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-99 1ST-END F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392718-X": {
        description: null,
        mapicsDescription: "WIRE-11C-99' -1ST-END FD-CANAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392725-X": {
        description: null,
        mapicsDescription: "SPAN CABLE-80FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392730-14": {
        description: null,
        mapicsDescription: "SPAN CABLE 14C 80 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392731-14": {
        description: null,
        mapicsDescription: "SPAN CABLE 14C 99 1ST SUG/MAX",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392633": {
        description: null,
        mapicsDescription: "SPAN CABLE-99FT DISC SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392726-X": {
        description: null,
        mapicsDescription: "SPAN CABLE-99FT LAST-SAC 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392715-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-80 1ST-CTR F-C F#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393622": {
        description: null,
        mapicsDescription: "WIRE 10C-156' MSAC-166.5'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393623": {
        description: null,
        mapicsDescription: "WIRE 10C-175' MSSAC-185.5'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392689-12": {
        description: null,
        mapicsDescription: "SPAN CABLE-213 PVT NO COLL #12",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392689": {
        description: null,
        mapicsDescription: "SPAN CABLE-213 PVT NO COLL 10C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392689-H": {
        description: null,
        mapicsDescription: "SPAN CABLE-213 PVT NO COLL #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392689-X": {
        description: null,
        mapicsDescription: "SPAN CABLE-213 PVT NO COLL 11C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392964-14": {
        description: null,
        mapicsDescription: "WIRE-14C 80' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392965-14": {
        description: null,
        mapicsDescription: "WIRE-14C 99' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393966-14": {
        description: null,
        mapicsDescription: "WIRE-14C 140' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392967-14": {
        description: null,
        mapicsDescription: "WIRE-14C 118' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392972-14": {
        description: null,
        mapicsDescription: "WIRE-14C 137' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392973-14": {
        description: null,
        mapicsDescription: "WIRE-14C 156' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392998-14": {
        description: null,
        mapicsDescription: "WIRE-14C 175' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392999-14": {
        description: null,
        mapicsDescription: "WIRE-14C 194' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393000-14": {
        description: null,
        mapicsDescription: "WIRE-14C 160' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393003-14": {
        description: null,
        mapicsDescription: "WIRE-14C 180' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393004-14": {
        description: null,
        mapicsDescription: "WIRE-14C 186-7' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392963-14": {
        description: null,
        mapicsDescription: "WIRE-14C 204' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392962-14": {
        description: null,
        mapicsDescription: "WIRE-14C 213' 1ST CF200",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396350": {
        description: "END BOOM-3'-E65/665/60/660",
        mapicsDescription: "END BOOM-3FT-E65/665/60/660",
        customerReportsDescription: "No End Boom",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396375-G": {
        description: null,
        mapicsDescription: "END BOOM-85'/213' EII GALV",
        customerReportsDescription: "End Boom, 85', for 213' Span, Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396366-G": {
        description: "END BOOM-13'-E65/60-LEPA-GALV",
        mapicsDescription: "END BOOM-13FT-E65/60-LEPA-GALV",
        customerReportsDescription: "End Boom, 13', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396575-G": {
        description: null,
        mapicsDescription: "END BOOM-85'/213' EII CT",
        customerReportsDescription: "End Boom, 85', for 213' Span, (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396358-G": {
        description: "END BOOM-13'-57\"SP-E65/60-GALV",
        mapicsDescription: "END BOOM-13FT-57SP-E65/60-GALV",
        customerReportsDescription: "End Boom, 13', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396476": {
        description: "END BOOM-3'-PL2065",
        mapicsDescription: "END BOOM-3FT-PL2065",
        customerReportsDescription: "No End Boom",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396367-G": {
        description: "END BOOM-23'-E65/60-LEPA-GALV",
        mapicsDescription: "END BOOM-23FT-E65/60-LEPA-GALV",
        customerReportsDescription: "End Boom, 23', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396472": {
        description: "END BOOM-23'-57\"SP PL2065",
        mapicsDescription: "END BOOM-23FT-57SP PL2065",
        customerReportsDescription: "End Boom, 23', 6 5/8\" Polylined Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396359-G": {
        description: "END BOOM-23'-57\"SP-E65/60-GALV",
        mapicsDescription: "END BOOM-23FT-57SP-E65/60-GALV",
        customerReportsDescription: "End Boom, 23', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396458": {
        description: "END BOOM-51'-57\"SP-E2045",
        mapicsDescription: "END BOOM-51FT-57SP-E2045",
        customerReportsDescription: "End Boom, 51', Galvanized Pipe, E2045",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396368-G": {
        description: "END BOOM-32'-E65/60-LEPA-GALV",
        mapicsDescription: "END BOOM-32FT-E65/60-LEPA-GALV",
        customerReportsDescription: "End Boom, 32', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396473": {
        description: "END BOOM-42'-57\"SP PL2065",
        mapicsDescription: "END BOOM-42FT-57SP PL2065",
        customerReportsDescription: "End Boom, 42', 6 5/8\" Polylined Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396360-G": {
        description: "END BOOM-32'-57\"SP-E65/60-GALV",
        mapicsDescription: "END BOOM-32FT-57SP-E65/60-GALV",
        customerReportsDescription: "End Boom, 32', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396459": {
        description: "END BOOM-61'-57\"SP-E2045",
        mapicsDescription: "END BOOM-61FT-57SP-E2045",
        customerReportsDescription: "End Boom, 61', Galvanized Pipe, E2045",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396369-G": {
        description: "END BOOM-42'-E65/60-LEPA-GALV",
        mapicsDescription: "END BOOM-42FT-E65/60-LEPA-GALV",
        customerReportsDescription: "End Boom, 42', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396361-G": {
        description: "END BOOM-42'-57\"SP-E65/60-GALV",
        mapicsDescription: "END BOOM-42FT-57SP-E65/60-GALV",
        customerReportsDescription: "End Boom, 42', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396370-G": {
        description: "END BOOM-51'-E65/60-LEPA-GALV",
        mapicsDescription: "END BOOM-51FT-E65/60-LEPA-GALV",
        customerReportsDescription: "End Boom, 51', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396362-G": {
        description: "END BOOM-51'-57\"SP-E65/60-GALV",
        mapicsDescription: "END BOOM-51FT-57SP-E65/60-GALV",
        customerReportsDescription: "End Boom, 51', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396371-G": {
        description: "END BOOM-61'-E65/60-LEPA-GALV",
        mapicsDescription: "END BOOM-61FT-E65/60-LEPA-GALV",
        customerReportsDescription: "End Boom, 61', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396363-G": {
        description: "END BOOM-61'-57\"SP-E65/60-GALV",
        mapicsDescription: "END BOOM-61FT-57SP-E65/60-GALV",
        customerReportsDescription: "End Boom, 61', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396357-G": {
        description: "END BOOM-85' 6\"/6 5/8\" EII GALV",
        mapicsDescription: "END BOOM-85' 6/6-5/8 EII GALV",
        customerReportsDescription: "End Boom, 85', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396556": {
        description: null,
        mapicsDescription: "END BOOM-85'/175' GALV EII",
        customerReportsDescription: "End Boom, 85', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396372-G": {
        description: "END BOOM-105' 6\"/6 5/8\" EII GALV",
        mapicsDescription: "END BOOM-105' 6/6-5/8 EII GALV",
        customerReportsDescription: "End Boom, 105', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396558-G": {
        description: "END BOOM-13'-57\"SP-E665/660-GA",
        mapicsDescription: "END BOOM-13FT-57SP-E665/660-GA",
        customerReportsDescription: "End Boom, 13', Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396559-G": {
        description: "END BOOM-23'-57\"SP-E665/660-GA",
        mapicsDescription: "END BOOM-23FT-57SP-E665/660-GA",
        customerReportsDescription: "End Boom, 23', Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396560-G": {
        description: "END BOOM-32'-57\"SP-E665/660-GA",
        mapicsDescription: "END BOOM-32FT-57SP-E665/660-GA",
        customerReportsDescription: "End Boom, 32', Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397503": {
        description: "END BOOM 32' 57\"SP E2660G",
        mapicsDescription: "END BOOM 32FT 57SP E2660G",
        customerReportsDescription: "End Boom, 32', Chromium-Nickel (CN+) Galvanized Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396561-G": {
        description: "END BOOM-42'-57\"SP-E665/660-GA",
        mapicsDescription: "END BOOM-42FT-57SP-E665/660-GA",
        customerReportsDescription: "End Boom, 42', Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397504": {
        description: "END BOOM 42' 57\"SP E2660G",
        mapicsDescription: "END BOOM 42FT 57SP E2660G",
        customerReportsDescription: "End Boom, 42', Chromium-Nickel (CN+) Galvanized Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396562-G": {
        description: "END BOOM-51'-57\"SP-E665/660-GA",
        mapicsDescription: "END BOOM-51FT-57SP-E665/660-GA",
        customerReportsDescription: "End Boom, 51', Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397505": {
        description: "END BOOM 51' 57\"SP E2660G",
        mapicsDescription: "END BOOM 51FT 57SP E2660G",
        customerReportsDescription: "End Boom, 51', Chromium-Nickel (CN+) Galvanized Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396563-G": {
        description: "END BOOM-61'-57\"SP-E665/660-GA",
        mapicsDescription: "END BOOM-61FT-57SP-E665/660-GA",
        customerReportsDescription: "End Boom, 61', Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397506": {
        description: "END BOOM 61' 57\"SP E2660G",
        mapicsDescription: "END BOOM 61FT 57SP E2660G",
        customerReportsDescription: "End Boom, 61', Chromium-Nickel (CN+) Galvanized Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396557-G": {
        description: null,
        mapicsDescription: "END BOOM-85'/194' PAINT EII",
        customerReportsDescription: "End Boom, 85', Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396555": {
        description: null,
        mapicsDescription: "END BOOM-85'/175' PAINTED EII",
        customerReportsDescription: "End Boom, 85', Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396571-G": {
        description: "END BOOM-105' 6\"/6 5/8\" CT EII G",
        mapicsDescription: "END BOOM-105' 6/6-5/8 CT EII G",
        customerReportsDescription: "End Boom, 105', Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396750": {
        description: "END BOOM-3'-A4/A60G",
        mapicsDescription: "END BOOM-3 FT-A4/A-60-G",
        customerReportsDescription: "No End Boom",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396757-G": {
        description: "END BOOM-13'-A4/A60G-G",
        mapicsDescription: "END BOOM-13 FT-A4/A60G-G",
        customerReportsDescription: "End Boom, 13', Aluminum Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396758-G": {
        description: "END BOOM-23'-A4/A60G-G",
        mapicsDescription: "END BOOM-23 FT-A4/A60G-G",
        customerReportsDescription: "End Boom, 23', Aluminum Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396759-G": {
        description: "END BOOM-32'-A4/A60G-G",
        mapicsDescription: "END BOOM-32 FT-A4/A60G-G",
        customerReportsDescription: "End Boom, 32', Aluminum Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396760-G": {
        description: "END BOOM-42'-A4/A60G-G",
        mapicsDescription: "END BOOM-42 FT-A4/A60G-G",
        customerReportsDescription: "End Boom, 42', Aluminum Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396761-G": {
        description: "END BOOM-51'-A4/A60G-G",
        mapicsDescription: "END BOOM-51 FT-A4/A60G-G",
        customerReportsDescription: "End Boom, 51', Aluminum Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396366SS": {
        description: "ENDBOOM 13' LEPA E65 STNLSS",
        mapicsDescription: "ENDBOOM 13FT LEPA E65 STNLSS",
        customerReportsDescription: "End Boom, 13', Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396367SS": {
        description: "END BOOM 23' LEPA E65 STNLSS",
        mapicsDescription: "END BOOM 23FT LEPA E65 STNLSS",
        customerReportsDescription: "End Boom, 23', Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396368SS": {
        description: "END BOOM 32' LEPA E65 STNLSS",
        mapicsDescription: "END BOOM 32FT LEPA E65 STNLSS",
        customerReportsDescription: "End Boom, 32', Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396369SS": {
        description: "END BOOM 42' LEPA E65 STNLSS",
        mapicsDescription: "END BOOM 42FT LEPA E65 STNLSS",
        customerReportsDescription: "End Boom, 42', Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396370SS": {
        description: "END BOOM 51' LEPA E65 STNLSS",
        mapicsDescription: "END BOOM 51FT LEPA E65 STNLSS",
        customerReportsDescription: "End Boom, 51', Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396371SS": {
        description: "END BOOM 61' LEPA E65 STNLSS",
        mapicsDescription: "END BOOM 61FT LEPA E65 STNLSS",
        customerReportsDescription: "End Boom, 61', Stainless Steel Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396351": {
        description: "END BOOM-3'-E2045",
        mapicsDescription: "END BOOM-3FT-E2045",
        customerReportsDescription: "No End Boom",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396352": {
        description: "END BOOM-13'-57\"SP E2045",
        mapicsDescription: "END BOOM-13FT-57SP E2045",
        customerReportsDescription: "End Boom, 13' Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396353": {
        description: "END BOOM-23'-57\"SP-E2045",
        mapicsDescription: "END BOOM-23FT-57SP-E2045",
        customerReportsDescription: "End Boom, 23' Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396354": {
        description: "END BOOM-32'-57\"SP-E2045",
        mapicsDescription: "END BOOM-32FT-57SP-E2045",
        customerReportsDescription: "End Boom, 32' Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396355": {
        description: "END BOOM-42'-57\"SP-E2045",
        mapicsDescription: "END BOOM-42FT-57SP-E2045",
        customerReportsDescription: "End Boom, 42' Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396396": {
        description: null,
        mapicsDescription: "END BOOM-23FT-114SP-E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396394": {
        description: null,
        mapicsDescription: "END BOOM-32FT-114SP-E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396395": {
        description: null,
        mapicsDescription: "END BOOM-42FT-114SP-E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396463": {
        description: null,
        mapicsDescription: "END BOOM-51FT-114SP E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396460": {
        description: null,
        mapicsDescription: "END BOOM-61FT-114SP/E2045 194'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396479": {
        description: null,
        mapicsDescription: "END BOOM-61FT-114SP/E2045LTT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396454": {
        description: "END BOOM-32'-57\"SP/E2045 194'",
        mapicsDescription: "END BOOM-32FT-57SP/E2045 194'",
        customerReportsDescription: "End Boom, 32', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396455": {
        description: "END BOOM-42'-57\"SP/E2045 194'",
        mapicsDescription: "END BOOM-42FT-57SP/E2045 194'",
        customerReportsDescription: "End Boom, 42', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396456": {
        description: "END BOOM-51'-57\"SP/E2045 194'",
        mapicsDescription: "END BOOM-51FT-57SP/E2045 194'",
        customerReportsDescription: "End Boom, 51', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396457": {
        description: "END BOOM-61'-57\"SP/E2045 194'",
        mapicsDescription: "END BOOM-61FT-57SP/E2045 194'",
        customerReportsDescription: "End Boom, 61', Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396635": {
        description: null,
        mapicsDescription: "END BOOM-61FT-114SP GALV EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396477": {
        description: null,
        mapicsDescription: "END BOOM-51FT-114SP-LTT-E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351930": {
        description: null,
        mapicsDescription: "NO END GUN OPTION-E60",
        customerReportsDescription: "No End Gun",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351931": {
        description: null,
        mapicsDescription: "NELSON P85A END GUNS-2-W/8RN",
        customerReportsDescription: "End Guns, 2 Nelson P85A's",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351933": {
        description: null,
        mapicsDescription: "NELSON P85A END GUN-1-W/8RN",
        customerReportsDescription: "End Gun, Nelson P85A",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351754": {
        description: null,
        mapicsDescription: "NELSON SR100 EG-18DEG-TPR NOZZ",
        customerReportsDescription: "End Gun, Nelson SR100 with Tapered Nozzle",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353125": {
        description: null,
        mapicsDescription: "NELSON SR100NV EG-18DEG-TPR NZ",
        customerReportsDescription: "End Gun, Nelson SRNV100 with Tapered Nozzle",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351756": {
        description: null,
        mapicsDescription: "NELSON SR75 EG-18 DEG",
        customerReportsDescription: "End Gun, Nelson SR75",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351550": {
        description: null,
        mapicsDescription: "SIME END GUN WING 18 DEG",
        customerReportsDescription: "Sime Wing End Gun 18 deg",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351928": {
        description: null,
        mapicsDescription: "KOMET END GUN TWIN MAX 18 DEG",
        customerReportsDescription: "End Gun, KOMET Twin MAX",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353128": {
        description: null,
        mapicsDescription: "SR100/800P CTRL VLV-NO BOOST",
        customerReportsDescription: "SR100 w/800P Control Valve Less Solenoid",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353129": {
        description: null,
        mapicsDescription: "SR75/800P CTRL VLV-NO BOOST",
        customerReportsDescription: "SR75 w/800P Control Valve Less Solenoid",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353128SS": {
        description: null,
        mapicsDescription: "SR100/800P CTRL VLV-NO BST-SS",
        customerReportsDescription: "SR100 w/800P Control Valve-SS-Less Solenoid",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353129SS": {
        description: null,
        mapicsDescription: "SR75/800P CTRL VLV-NO BST-SS",
        customerReportsDescription: "SR75 w/800P Control Valve-SS-Less Solenoid",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353124": {
        description: null,
        mapicsDescription: "SR100/800P CNTRL VLVE W/NOZZLE",
        customerReportsDescription: "End Gun, Nelson SR100 with 800 Valve, Automatic Controls",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353126": {
        description: null,
        mapicsDescription: "VALVE-CTRL SR75/800 W/NOZZLE",
        customerReportsDescription: "End Gun, Nelson SR75 with 800 Valve, Automatic Controls",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350177": {
        description: null,
        mapicsDescription: "NELSON R55i VT NOZZLE DOWN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350175": {
        description: null,
        mapicsDescription: "NELSON R55 VT NOZZLE UP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350180": {
        description: null,
        mapicsDescription: "R75 NELSON GOLD 25-40 PSI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350181": {
        description: null,
        mapicsDescription: "R75 NELSON GREY 40-60 PSI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351927": {
        description: null,
        mapicsDescription: "KOMET END GUN TWIN 101 ULT 18D",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353145": {
        description: null,
        mapicsDescription: "NELSON P85A EG-2-W/8RN FM BP",
        customerReportsDescription: "End Guns, 2 Nelson P85A's",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353146": {
        description: null,
        mapicsDescription: "NELSON P85A EG-1-W/8RN FM BP",
        customerReportsDescription: "End Gun, Nelson P85A",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353153": {
        description: null,
        mapicsDescription: "SR100/800P W/NOZZLE FM BP",
        customerReportsDescription: "End Gun, Nelson SR100 with 800 Valve, Automatic Controls",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353154": {
        description: null,
        mapicsDescription: "SR75/800P W/NOZZLE FM BP",
        customerReportsDescription: "End Gun, Nelson SR75 with 800 Valve, Automatic Controls",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350189": {
        description: null,
        mapicsDescription: "SR100/1000P CTRL VLV W/NOZZLE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350190": {
        description: null,
        mapicsDescription: "SR100/1000P CTRL VLV-NO BOOST",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350190SS": {
        description: null,
        mapicsDescription: "SR100/1000P CTRL VLV-NO BST-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350191": {
        description: null,
        mapicsDescription: "SR75/1000P CTRL VLV-NO BOOST",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350191SS": {
        description: null,
        mapicsDescription: "SR75/1000P CTRL VLV-NO BST-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350196": {
        description: null,
        mapicsDescription: "SR100/1000P W/NOZZLE FM BP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350197": {
        description: null,
        mapicsDescription: "SR75/1000P W/NOZZLE FM BP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353125DP": {
        description: "NELSON SRNV100DP EG-18DEG",
        mapicsDescription: "NELSON SRNV100DP EG-18DEG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351931-D": {
        description: null,
        mapicsDescription: "DIFFUSER ASY FOR 2-NELSON-P85A",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351933-D": {
        description: null,
        mapicsDescription: "DIFFUSER ASY FOR 1-NELSON-P85A",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351932": {
        description: null,
        mapicsDescription: "MANUAL VALVE ASY-FOR END GUN",
        customerReportsDescription: "End Gun Control, Manual",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353121": {
        description: null,
        mapicsDescription: "2\" ELECT DIAPH VALVE ASSY",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Diaphragm Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353127": {
        description: null,
        mapicsDescription: "2\" ELECT DIAPH VALVE W/BP MNT",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Diaphragm Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353136": {
        description: null,
        mapicsDescription: "800P CTRL VLV-NO SOLENOID",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Sleeve Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353136SS": {
        description: null,
        mapicsDescription: "800P CTRL VLV-NO SOLENOID-SS",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Sleeve Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353127SS": {
        description: "2\" ELECT DIAPH VALVE W/BP-SS",
        mapicsDescription: "2\"ELECT DIAPH VALVE W/BP-SS",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Diaphragm Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353130": {
        description: null,
        mapicsDescription: "800P CTRL VLV W/SOLENOID",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Sleeve Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353130SS": {
        description: null,
        mapicsDescription: "800P CTRL VLV W/SOLENOID-SS",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Sleeve Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353147": {
        description: null,
        mapicsDescription: "800P CV-NO SOL-FM BP-P85",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Sleeve Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353147SS": {
        description: null,
        mapicsDescription: "800P CV-NO SOL-FM BP-P85-SS",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Sleeve Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353150": {
        description: null,
        mapicsDescription: "2\" AQUAMATIC VLV-FM BP-P85",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Diaphragm Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353150SS": {
        description: null,
        mapicsDescription: "2\" AQUAMATIC VLV-FM BP-P85-SS",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Diaphragm Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353160": {
        description: null,
        mapicsDescription: "REINKE 2\" VALVE W/BP",
        customerReportsDescription: "Reinke Valve for use w/BP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353160SS": {
        description: null,
        mapicsDescription: "REINKE 2\" VALVE W/BP SS",
        customerReportsDescription: "Reinke Valve for use w/BP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353161": {
        description: null,
        mapicsDescription: "REINKE 2\" VALVE NO BP",
        customerReportsDescription: "Reinke Valve with Solenoid",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353162": {
        description: null,
        mapicsDescription: "REINKE 2\" VALVE-FM BP",
        customerReportsDescription: "Reinke Valve for use w/BP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353162SS": {
        description: null,
        mapicsDescription: "REINKE 2\" VALVE-FM BP-SS",
        customerReportsDescription: "Reinke Valve for use w/BP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353149": {
        description: null,
        mapicsDescription: "2\" AQUAMATIC VLV-FM BP",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Diaphragm Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353148": {
        description: null,
        mapicsDescription: "800P CV-NO SOL-FM BP",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Sleeve Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353148SS": {
        description: null,
        mapicsDescription: "800P CV-NO SOL-FM BP-SS",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Sleeve Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353149SS": {
        description: null,
        mapicsDescription: "2\" AQUAMATIC VLV-FM BP-SS",
        customerReportsDescription: "End Gun Control, Automatic, 2\" Diaphragm Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350192": {
        description: null,
        mapicsDescription: "1000P CTRL VLV W/SOLENOID",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350192SS": {
        description: null,
        mapicsDescription: "1000P CTRL VLV W/SOLENOID-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350193": {
        description: null,
        mapicsDescription: "1000P CTRL VLV-NO SOLENOID",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350193SS": {
        description: null,
        mapicsDescription: "1000P CTRL VLV-NO SOLENOID-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350194": {
        description: null,
        mapicsDescription: "1000P CV-NO SOL-FM BP-P85",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350194SS": {
        description: null,
        mapicsDescription: "1000P CV-NO SOL-FM BP-P85-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350195": {
        description: null,
        mapicsDescription: "1000P CV-NO SOL-FM BP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350195SS": {
        description: null,
        mapicsDescription: "1000P CV-NO SOL-FM BP-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353132": {
        description: null,
        mapicsDescription: "ELECT END GUN SW ASY-K T& MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353131": {
        description: null,
        mapicsDescription: "ELECT END GUN SW ASY-STD PIVOT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352837": {
        description: null,
        mapicsDescription: "ASY END GUN SW-CAM PLT STYLE",
        customerReportsDescription: "Cam Plate Switch Mount Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353141": {
        description: "2\" ELBOW W/DRAIN-SR100 W/O BP",
        mapicsDescription: "2IN ELBOW W/DRAIN-SR100 W/O BP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353142": {
        description: "2\" BP ELBOW MOUNT-SR100 W/B P",
        mapicsDescription: "2IN BP ELBOW MOUNT-SR100 W/B P",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353141SS": {
        description: "2\" ELL W/DRN-SR100 W/O BP-SS",
        mapicsDescription: "2\" ELL W/DRN-SR100 W/O BP-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353142SS": {
        description: "ELBOW-MOUNT 2\" BP SR100 W/B P",
        mapicsDescription: "ELBOW-MOUNT 2IN BP SR100 W/B P",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353117": {
        description: null,
        mapicsDescription: "MOUNT R55i 2\"X3\" W/ELBOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353159": {
        description: null,
        mapicsDescription: "MNT SECONDARY END SPRNKLR SNGL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353118": {
        description: null,
        mapicsDescription: "MNT R55i NO VLV 3\" BOOM W/GUN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353157": {
        description: null,
        mapicsDescription: "MNT SECONDRY GUN-W/PRIMARY GUN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353158": {
        description: null,
        mapicsDescription: "MOUNT SECOND GUN 18\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353171": {
        description: null,
        mapicsDescription: "FLANGED ELBOW 2\" W/HDWR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351743": {
        description: "END GUN MNT-85' EB-W/O BP",
        mapicsDescription: "END GUN MNT-85 FT EB-W/O BP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351742": {
        description: null,
        mapicsDescription: "END GUN MNT-3X2 ADAPTER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351742SS": {
        description: null,
        mapicsDescription: "ENG GUN MNT 3X2 ADPTR W/O BPSS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353171SS": {
        description: null,
        mapicsDescription: "FLANGED ELBOW 2\" W/HDWR SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353152": {
        description: null,
        mapicsDescription: "NOZ VALVE/NO VALVE-FM BP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353152SS": {
        description: null,
        mapicsDescription: "NOZ VALVE/NO VALVE-FM BP-SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353114": {
        description: "MNT SEC GUN-W/PRIMRY GUN 4-1/2",
        mapicsDescription: "MNT SEC GUN-W/PRIMRY GUN 4-1/2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392580": {
        description: "END GUN TUBING KIT-3' EB",
        mapicsDescription: "END GUN TUBING KIT-3 FT EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392582": {
        description: "END GUN TUBING KIT-13' EB",
        mapicsDescription: "END GUN TUBING KIT-13 FT EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392584": {
        description: "END GUN TUBING KIT-23' EB",
        mapicsDescription: "END GUN TUBING KIT-23 FT EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392586": {
        description: "END GUN TUBING KIT-32' EB",
        mapicsDescription: "END GUN TUBING KIT-32 FT EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392588": {
        description: "END GUN TUBING KIT-42' EB",
        mapicsDescription: "END GUN TUBING KIT-42 FT EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392590": {
        description: "END GUN TUBING KIT-51' EB",
        mapicsDescription: "END GUN TUBING KIT-51 FT EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392592": {
        description: "END GUN TUBING KIT-61' EB",
        mapicsDescription: "END GUN TUBING KIT-61 FT EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392596": {
        description: "END GUN TUBING KT-85' EB-M&S",
        mapicsDescription: "END GUN TUBING KT-85 FT EB-M&S",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392594": {
        description: "END GUN TUBING KIT-85' EB",
        mapicsDescription: "END GUN TUBING KIT-85 FT EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392597": {
        description: null,
        mapicsDescription: "END GUN TUBING KIT-105 EB-M&SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392595": {
        description: "END GUN TUBING KIT-105' EB",
        mapicsDescription: "END GUN TUBING KIT-105 FT EB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351765": {
        description: "5HP BOOST PUMP ASY W/3' BOOM",
        mapicsDescription: "5HP BOOST PUMP ASY W/3 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351767": {
        description: "5HP BOOSTER PUMP ASY-13' BOOM",
        mapicsDescription: "5HP BOOSTER PUMP ASY-13FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351768": {
        description: "5HP BOOSTER PUMP ASY-23' BOOM",
        mapicsDescription: "5HP BOOSTER PUMP ASY-23FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351769": {
        description: "5HP BOOSTER PUMP ASY-32' BOOM",
        mapicsDescription: "5HP BOOSTER PUMP ASY-32FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351770": {
        description: "5HP BOOSTER PUMP ASY-42' BOOM",
        mapicsDescription: "5HP BOOSTER PUMP ASY-42FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351771": {
        description: "5HP BOOSTER PUMP ASY-51' BOOM",
        mapicsDescription: "5HP BOOSTER PUMP ASY-51FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351772": {
        description: "5HP BOOSTER PUMP ASY-61' BOOM",
        mapicsDescription: "5HP BOOSTER PUMP ASY-61FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351744P": {
        description: "2HP BOOST PUMP ASY W/3' BOOM",
        mapicsDescription: "2HP BOOST PUMP ASY W/3 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351745P": {
        description: "2 HP BSTR PUMP ASY-13' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-13 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351746P": {
        description: "2 HP BSTR PUMP ASY-23' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-23 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351747P": {
        description: "2 HP BSTR PUMP ASY-32' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-32 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351748P": {
        description: "2 HP BSTR PUMP ASY-42' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-42 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351749P": {
        description: "2 HP BSTR PUMP ASY-51' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-51 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351750P": {
        description: "2 HP BSTR PUMP ASY-61' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-61 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351761P": {
        description: "2 HP BSTR PUMP ASY-85' SAC",
        mapicsDescription: "2 HP BSTR PUMP ASY-85 FT SAC",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351751P": {
        description: "2 HP BSTR PUMP ASY-85' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-85 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351762P": {
        description: "2 HP BSTR PUMP ASY-105' SSAC",
        mapicsDescription: "2 HP BSTR PUMP ASY-105 FT SSAC",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351752P": {
        description: "2 HP BSTR PUMP ASY-105' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-105 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351765SS": {
        description: null,
        mapicsDescription: "5HP BST PMP ASY W/3' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351767SS": {
        description: null,
        mapicsDescription: "5HP BST PMP ASY-13' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351768SS": {
        description: null,
        mapicsDescription: "5HP BST PMP ASY-23' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351769SS": {
        description: null,
        mapicsDescription: "5HP BST PMP ASY-32' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351770SS": {
        description: null,
        mapicsDescription: "5HP BST PMP ASY-42' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351771SS": {
        description: null,
        mapicsDescription: "5HP BST PMP ASY-51' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351772SS": {
        description: null,
        mapicsDescription: "5HP BST PMP ASY-61' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351744SSP": {
        description: null,
        mapicsDescription: "2HP BST PMP ASY W/3' BOOM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351745SSP": {
        description: null,
        mapicsDescription: "2 HP BST PMP ASY-13' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351746SSP": {
        description: null,
        mapicsDescription: "2 HP BST PMP ASY-23' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351747SSP": {
        description: null,
        mapicsDescription: "2 HP BST PMP ASY-32' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351748SSP": {
        description: null,
        mapicsDescription: "2 HP BST PMP ASY-42' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351749SSP": {
        description: null,
        mapicsDescription: "2 HP BST PMP ASY-51' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351750SSP": {
        description: null,
        mapicsDescription: "2 HP BST PMP ASY-61' BM-SS",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp, Stainless Steel Assembly",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351775": {
        description: "5 HP BSTR PUMP ASY-85' BOOM",
        mapicsDescription: "5 HP BSTR PUMP ASY-85 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351777": {
        description: "5 HP BSTR PUMP ASY-105' BOOM",
        mapicsDescription: "5 HP BSTR PUMP ASY-105 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351763": {
        description: "5 HP BSTR PUMP ASY-85' SAC",
        mapicsDescription: "5 HP BSTR PUMP ASY-85 FT SAC",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351764": {
        description: "5 HP BSTR PUMP ASY-105' SAC",
        mapicsDescription: "5 HP BSTR PUMP ASY-105 FT SAC",
        customerReportsDescription: "Booster Pump, End Gun, 5 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351744F": {
        description: "2HP BOOST PUMP ASY W/3' BOOM",
        mapicsDescription: "2HP BOOST PUMP ASY W/3 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351745F": {
        description: "2 HP BSTR PUMP ASY-13' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-13 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351746F": {
        description: "2 HP BSTR PUMP ASY-23' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-23 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351747F": {
        description: "2 HP BSTR PUMP ASY-32' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-32 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351748F": {
        description: "2 HP BSTR PUMP ASY-42' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-42 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351749F": {
        description: "2 HP BSTR PUMP ASY-51' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-51 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351750F": {
        description: "2 HP BSTR PUMP ASY-61' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-61 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351761F": {
        description: "2 HP BSTR PUMP ASY-85' SAC",
        mapicsDescription: "2 HP BSTR PUMP ASY-85 FT SAC",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351751F": {
        description: "2 HP BSTR PUMP ASY-85' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-85 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351762F": {
        description: "2 HP BSTR PUMP ASY-105' SSAC",
        mapicsDescription: "2 HP BSTR PUMP ASY-105 FT SSAC",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351752F": {
        description: "2 HP BSTR PUMP ASY-105' BOOM",
        mapicsDescription: "2 HP BSTR PUMP ASY-105 FT BOOM",
        customerReportsDescription: "Booster Pump, End Gun, 2 Hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351946": {
        description: null,
        mapicsDescription: "5 HP BSTR PUMP ASY-51 FT MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351948": {
        description: null,
        mapicsDescription: "5 HP BSTR PUMP ASY-61 FT MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351945": {
        description: null,
        mapicsDescription: "2 HP BSTR PUMP ASY-51 FT MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351947": {
        description: null,
        mapicsDescription: "2 HP BSTR PUMP ASY-61 FT MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351761E": {
        description: null,
        mapicsDescription: "2 HP BSTR PUMP ASY-85 FT ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351762E": {
        description: null,
        mapicsDescription: "2 HP BSTR PMP ASY-105 FT ESSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351763E": {
        description: null,
        mapicsDescription: "5 HP BSTR PUMP ASY-85 FT ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351764E": {
        description: null,
        mapicsDescription: "5 HP BSTR PMP ASY-105 FT ESSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351766": {
        description: null,
        mapicsDescription: "2 HP BSTR PUMP ASY-85' SAC SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351795": {
        description: null,
        mapicsDescription: "2HP BSTR PUMP ASY-105' SSAC SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351773": {
        description: null,
        mapicsDescription: "5HP BSTR PUMP ASY-85' SAC SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351796": {
        description: null,
        mapicsDescription: "5HP BSTR PUMP ASY-105' SSAC SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394548": {
        description: null,
        mapicsDescription: "SIGN-W/BRACKET REINKE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394848": {
        description: null,
        mapicsDescription: "SIGN-W/BRACKET REINKE GPS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394948": {
        description: null,
        mapicsDescription: "SIGN-W/BRACKET REINKE ALUM RF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352995": {
        description: null,
        mapicsDescription: "BOX-CTRL 2HP BP PS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352996": {
        description: null,
        mapicsDescription: "BOX-CTRL 2HP BP PS UL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352997": {
        description: null,
        mapicsDescription: "BOX-CTRL 5HP BP PS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352998": {
        description: null,
        mapicsDescription: "BOX-CTRL 5HP BP PS UL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351106": {
        description: null,
        mapicsDescription: "S BOX RPM BP 2HP OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351107": {
        description: null,
        mapicsDescription: "S BOX RPM BP 5HP OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "357041": {
        description: null,
        mapicsDescription: "STRAINER-LAST TOWER-END BOOM",
        customerReportsDescription: "Strainer - Last Tower Top, Flange Mount",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351741": {
        description: null,
        mapicsDescription: "KIT-VERT FLG MT BP W/800/1000P",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351740": {
        description: null,
        mapicsDescription: "KIT-VERT FLANGE MNT BOOST PUMP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352508-E": {
        description: null,
        mapicsDescription: "END LIGHT ASSEMBLY-ELECT/ALUMI",
        customerReportsDescription: "Light Assembly, End Tower, Standard",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352509-E": {
        description: null,
        mapicsDescription: "STROBE LIGHT ASY-END TOWER-EA",
        customerReportsDescription: "Light Assembly, End Tower, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352575": {
        description: null,
        mapicsDescription: "STROBE LIGHT ASY-END TWR-SAC",
        customerReportsDescription: "Light Assembly, End Tower, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352574": {
        description: null,
        mapicsDescription: "END TWR LIGHT-SWING ARM CORNER",
        customerReportsDescription: "Light Assembly, End Tower, Standard",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352508-R": {
        description: null,
        mapicsDescription: "PIVOT LIGHT ASY-CONST ON-RAMS",
        customerReportsDescription: "Light Assembly, Pivot Center, Standard",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352509-R": {
        description: null,
        mapicsDescription: "STROBE LIGHT ASY-CONST ON-RAMS",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352757-C": {
        description: null,
        mapicsDescription: "BULB-CLEAR STROBE-120V SOCKET",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352757-A": {
        description: null,
        mapicsDescription: "BULB-AMBER STROBE-120V SOCKET",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352757-B": {
        description: null,
        mapicsDescription: "BULB-BLUE STROBE -120V SOCKET",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352757-G": {
        description: null,
        mapicsDescription: "BULB-GREEN STROBE-120V SOCKET",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352757-R": {
        description: null,
        mapicsDescription: "BULB-RED STROBE-120V SOCKET",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352990-C": {
        description: null,
        mapicsDescription: "PVT LGT ASSY-STROBE-400ECP-CLR",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352990-A": {
        description: null,
        mapicsDescription: "PVT LGT ASSY-STROBE-400ECP-AMB",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352990-B": {
        description: null,
        mapicsDescription: "PVT LGT ASSY-STROBE-400ECP-BLU",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352990-G": {
        description: null,
        mapicsDescription: "PVT LGT ASSY-STROBE-400ECP-GRN",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352990-R": {
        description: null,
        mapicsDescription: "PVT LGT ASSY-STROBE-400ECP-RED",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352989-C": {
        description: null,
        mapicsDescription: "PVT LGT-STROBE-400ECP-20-CLR",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352989-A": {
        description: null,
        mapicsDescription: "PVT LGT-STROBE-400ECP-20-AMB",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352989-B": {
        description: null,
        mapicsDescription: "PVT LGT-STROBE-400ECP-20-BLU",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352989-G": {
        description: null,
        mapicsDescription: "PVT LGT-STROBE-400ECP-20-GRN",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352989-R": {
        description: null,
        mapicsDescription: "PVT LGT-STROBE-400ECP-20-RED",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352986-A": {
        description: null,
        mapicsDescription: "END-STROBE-CONST ON 400ECP-AMB",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352986-B": {
        description: null,
        mapicsDescription: "END-STROBE-CONST ON 400ECP-BLU",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352986-C": {
        description: null,
        mapicsDescription: "END-STROBE-CONST ON 400ECP-CLR",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352986-G": {
        description: null,
        mapicsDescription: "END-STROBE-CONST ON 400ECP-GRN",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352986-R": {
        description: null,
        mapicsDescription: "END-STROBE-CONST ON 400ECP-RED",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397264": {
        description: null,
        mapicsDescription: "CONDUIT PKG 32/42' EB-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397265": {
        description: null,
        mapicsDescription: "CONDUIT PKG 51/61' EB-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397280": {
        description: "CONDUIT CLP CPLRS 32/42' EB 57\"",
        mapicsDescription: "CONDUIT CLP CPLRS 32/42' EB 57",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397281": {
        description: "CONDUIT CLP CPLRS 51/61' EB 57\"",
        mapicsDescription: "CONDUIT CLP CPLRS 51/61' EB 57",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397296": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 85' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397297": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 105' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397283": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 13' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397284": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 13' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397285": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 23' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397286": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 23' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397288": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 32' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397290": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 42' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397292": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 51' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397294": {
        description: null,
        mapicsDescription: "CONDUIT CLIP CPLRS 61' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397289": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 32' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397291": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 42' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397293": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 51' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397295": {
        description: null,
        mapicsDescription: "CONDUIT CLIP PKG 61' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397267": {
        description: null,
        mapicsDescription: "CONDUIT PKG 32' EB-114 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397268": {
        description: null,
        mapicsDescription: "CONDUIT PKG 42' EB-114 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397269": {
        description: null,
        mapicsDescription: "CONDUIT PKG 51' EB-114 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397270": {
        description: null,
        mapicsDescription: "CONDUIT PKG 61' EB-114 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397672": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 32/42 EB 114\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397673": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 51/61 EB 114\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397637": {
        description: "CONDUIT TDC 32'/42' EB-57\" E2045",
        mapicsDescription: "CONDUIT TDC 32/42' EB-57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397638": {
        description: "CONDUIT TDC 51'/61' EB 57\" E2045",
        mapicsDescription: "CONDUIT TDC 51/61' EB 57 E2045",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397648": {
        description: "CONDUIT TDC CPLRS 32'/42' EB 57\"",
        mapicsDescription: "CONDUIT TDC CPLRS 32/42' EB 57",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397649": {
        description: "CONDUIT TDC CPLRS 51'/61' EB 57\"",
        mapicsDescription: "CONDUIT TDC CPLRS 51/61' EB 57",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397664": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 85' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397665": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 105' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397651": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 13' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397652": {
        description: null,
        mapicsDescription: "CONDUIT TDC CPLRS 13' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397653": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 23' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397654": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 23' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397656": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 32' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397658": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 42' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397660": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 51' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397662": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 61' EB LEPA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397657": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 32' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397659": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 42' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397661": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 51' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "397663": {
        description: null,
        mapicsDescription: "CONDUIT TDC PKG 61' EB 57\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351758": {
        description: "WIRE-10C X 25'-PWR TWR BST PUMP",
        mapicsDescription: "WIRE-10C X25'-PWR TWR BST PUMP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352232-G": {
        description: null,
        mapicsDescription: "FUR. GUIDE OPT-TWR BOX STYLE-A",
        customerReportsDescription: "Guidance, Lateral Move, Furrow",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352231-G": {
        description: null,
        mapicsDescription: "FURROW GUIDANCE-MAXI-EII-STD",
        customerReportsDescription: "Guidance, Lateral Move, Furrow",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395956": {
        description: null,
        mapicsDescription: "GUIDE OPT-PIPE FURROW-A100MAXI",
        customerReportsDescription: "Power Tower Furrow Guidance-A100 MAXI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352234-G": {
        description: null,
        mapicsDescription: "FURROW GUIDE-MAXI-EII-LOW PRO",
        customerReportsDescription: "Guidance, Lateral Move, Furrow",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352235-G": {
        description: null,
        mapicsDescription: "FURROW GUIDE-MAXI-EII-SUGAR",
        customerReportsDescription: "Guidance, Lateral Move, Fence",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352204-G": {
        description: null,
        mapicsDescription: "MASTER TOWER GUIDANCE-MAXI-GAL",
        customerReportsDescription: "Guidance, Lateral Move, Furrow",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395808": {
        description: null,
        mapicsDescription: "FURROW G.-CEN LIFT PVT LINEAR",
        customerReportsDescription: "Guidance, Lateral Move, Furrow",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395993": {
        description: null,
        mapicsDescription: "PIPE FURROW GUIDE-PIV-LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396004": {
        description: null,
        mapicsDescription: "FURROW WHEEL GUIDANCE-PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352811": {
        description: null,
        mapicsDescription: "GUIDANCE-FURROW-HOSE DG-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352815": {
        description: null,
        mapicsDescription: "GUIDANCE-FUR/PIPE-HOSE DG-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352820L": {
        description: null,
        mapicsDescription: "GUIDE-FURROW-MID SYS-LP-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352820": {
        description: null,
        mapicsDescription: "GUIDE-FURROW-MID SYS-STD-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352820S": {
        description: null,
        mapicsDescription: "GUIDE-FURROW-MID SYS-SG-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352826": {
        description: null,
        mapicsDescription: "GUIDE-FURROW-MID SYS-A4-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352224": {
        description: null,
        mapicsDescription: "ELEC WINCH OPT-GUIDE ARMS-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352225",
    },
    "352225": {
        description: null,
        mapicsDescription: "MAN WINCH OPT-GUIDE ARMS-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392815": {
        description: null,
        mapicsDescription: "CABLE GUID-1/4\" CBL-CF-MAXI",
        customerReportsDescription: "Guidance, Lateral Move, Cable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392816": {
        description: null,
        mapicsDescription: "CABLE GUIDE-1/4\" CBL-HOSE-MAXI",
        customerReportsDescription: "Guidance, Lateral Move, Cable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392813": {
        description: null,
        mapicsDescription: "CABLE GUIDANCE-RPM CANAL FEED",
        customerReportsDescription: "Guidance, Lateral Move, Cable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392814": {
        description: null,
        mapicsDescription: "CABLE GUIDANCE-RPM HOSE DRAG",
        customerReportsDescription: "Guidance, Lateral Move, Cable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392817": {
        description: null,
        mapicsDescription: "CABLE POST OPT-1/4\" CABLE-30'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392818": {
        description: "GUIDE CABLE-1/4\"CABLE-300'",
        mapicsDescription: "GUIDE CABLE-1/4\"CABLE-300 FT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392820-G": {
        description: null,
        mapicsDescription: "CABLE TRIP OPT-CABLE GUIDE-C F",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352207": {
        description: null,
        mapicsDescription: "BURIED WIRE GUIDE OPTION-MAXI",
        customerReportsDescription: "Guidance, Lateral Move, Buried Wire",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352215": {
        description: null,
        mapicsDescription: "BURIED WIRE GUIDE OPT-2WD MAXI",
        customerReportsDescription: "Guidance, Lateral Move, Buried Wire",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352216": {
        description: null,
        mapicsDescription: "BURIED WIRE GUIDE OPT-4WD MAXI",
        customerReportsDescription: "Guidance, Lateral Move, Buried Wire",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352208": {
        description: null,
        mapicsDescription: "BWG-LINEAR-W/SOLAR TRANSMITTER",
        customerReportsDescription: "Guidance, Lateral Move, Buried Wire with Solar Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351719": {
        description: null,
        mapicsDescription: "BURIED WIRE GUIDE OPT-MAXI A4",
        customerReportsDescription: "Buried Wire Guidance - Maxi A4",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351720": {
        description: null,
        mapicsDescription: "BWG-MAXI A4 W/SOLAR TRANSMITER",
        customerReportsDescription: "Buried Wire Guidance-A4, w/Solar Transmitter",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352715": {
        description: null,
        mapicsDescription: "GDE WIRE-14/1 SHLD-SAC 10K SP",
        customerReportsDescription: "Guidance Wire, Perimeter, 14/1, Shielded",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352717": {
        description: null,
        mapicsDescription: "GDE WIR-14/1 NON SD-SAC 10K SP",
        customerReportsDescription: "Guidance Wire, Perimeter, 14/1, Non-Shielded",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352233": {
        description: null,
        mapicsDescription: "FENCE CABLE GUIDE OPT-MAXI",
        customerReportsDescription: "Guidance, Lateral Move, Fence",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357009": {
        description: null,
        mapicsDescription: "GPS GUIDANCE OPT-LINEAR",
        customerReportsDescription: "GPS Guidance Option-Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353999": {
        description: null,
        mapicsDescription: "MAXI GPS BASE-FREEWAVE-TRIMBLE",
        customerReportsDescription: "GPS Guidance Base - Freewave Trimble - Maxigator EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357011": {
        description: null,
        mapicsDescription: "GPS GUID MAXI-TRIMBLE-NO RADIO",
        customerReportsDescription: "GPS Guidance Option-No Base-Lateral Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351717": {
        description: null,
        mapicsDescription: "GPS GUIDANCE OPT-LINEAR A4",
        customerReportsDescription: "GPS Guidance Opt-A4 Lat Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351718": {
        description: null,
        mapicsDescription: "GPS MAXI-TRIMBLE-NO RADIO-A4",
        customerReportsDescription: "GPS Guide Opt-NO Base-A4 Lat Move",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353998": {
        description: null,
        mapicsDescription: "GPS GUID MAXI-FREEWAVE-TRIMBLE",
        customerReportsDescription: "GPS Guidance - Freewave Trimble - Maxigator EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353997": {
        description: null,
        mapicsDescription: "GPS GUIDE MAXI-FRWV-TRIMBLE-A4",
        customerReportsDescription: "GPS Guidance - Freewave Trimble - Maxigator A4",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357078": {
        description: null,
        mapicsDescription: "AUSTRALIAN C-TICK LABELING",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354010": {
        description: null,
        mapicsDescription: "GPS GUIDE LATERAL-W/O RADIO-NZ",
        customerReportsDescription: "GPS Guidance - Less Radio - Maxigator Eii",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354011": {
        description: null,
        mapicsDescription: "GPS GUIDE MAXI-A4-W/O RADIO-NZ",
        customerReportsDescription: "GPS Guidance - Less Radio - Maxigator A4",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354012": {
        description: null,
        mapicsDescription: "GPS BASE-FREEWAVE W/O RADIO-NZ",
        customerReportsDescription: "GPS Guidance Base - Less Radio - Maxigator EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395994": {
        description: null,
        mapicsDescription: "GPS GUIDE OPTION-PIV MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395994NZ": {
        description: null,
        mapicsDescription: "GPS GUIDE-W/O RADIO-PIV MAX-NZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352818": {
        description: null,
        mapicsDescription: "GUIDANCE-GPS-CART-FRWV-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352816": {
        description: null,
        mapicsDescription: "GUIDANCE-GPS-CART-SCMBLD-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352824": {
        description: null,
        mapicsDescription: "GUIDE-GPS-MID SYS-FRWV-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352823": {
        description: null,
        mapicsDescription: "GUIDE-GPS-MID SYS-SCMBLD-REV2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395807": {
        description: null,
        mapicsDescription: "ADPTR-FUR G-SUGAR-CEN LIFT LIN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394761": {
        description: null,
        mapicsDescription: "TOWER ASSY-ALUMI 4-NT",
        customerReportsDescription: "Tower Assembly, Aluminum, Standard Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394762": {
        description: null,
        mapicsDescription: "TOWER ASSY-ALUMI 4-TOW",
        customerReportsDescription: "Tower Assembly, Aluminum, Standard Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394771-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-NON TOW-A60 G-GALV",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394772-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-TOWABLE-A60 G-GALV",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394777-G": {
        description: null,
        mapicsDescription: "TOWER ASY NT A80G SUGARGATOR",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394361-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-NT-STD EII-GALV",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394778-G": {
        description: null,
        mapicsDescription: "TOWER ASY TOW A80G SUGARGATOR",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, High Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394362-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-TOW-STD EII-GALV",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394773-G": {
        description: null,
        mapicsDescription: "TOWER ASY LOW PROFILE NT A60G",
        customerReportsDescription: "Tower Assembly, Galvanized, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394161-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-NT-HD EII-GALV",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, Standard Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394775-G": {
        description: null,
        mapicsDescription: "TOWER ASY NT A60G SUGARGATOR",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, High Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394162-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-TOW-HD EII-GALV",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, Standard Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394774-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-LP TOW-A60 G-GALV",
        customerReportsDescription: "Tower Assembly, Galvanized, Low Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394363-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-LP-NT-EII GALV",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, Low Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394364-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-LP-TOW-EII GALV",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, Low Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394385": {
        description: null,
        mapicsDescription: "TWR NT SG NO/TRUSS-PIPE ST EII",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394776-G": {
        description: null,
        mapicsDescription: "TOWER ASY TOW A60G SUGARGATOR",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, High Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394386": {
        description: null,
        mapicsDescription: "TWR TOW SG NO/TRUSS-PIPE EII",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, High Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394365-G": {
        description: null,
        mapicsDescription: "TOWER NT SG EII GALV",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, High Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394960": {
        description: null,
        mapicsDescription: "ASY-H TWR & HINGE ASY-UHMW",
        customerReportsDescription: "Tower Assembly, Special-UHMW, SAC, Heavy Duty, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394366-G": {
        description: "TOWER TOW SG 6\"/6 5/8\" EII GALV",
        mapicsDescription: "TOWER TOW SG 6/6-5/8 EII GALV",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, High Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394864-G": {
        description: null,
        mapicsDescription: "TWR ASY-INT-E2085-REV TOW-EII",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, Standard Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394861-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-REV TOW-KT/MAXI-EII",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, Reverse Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394866-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-INT-E2060 HD-REV T",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, Standard Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394863-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-LAST-REV TOW-MAXI-E",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, Reverse Towable, End Tower",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394862-G": {
        description: null,
        mapicsDescription: "TOWER ASSY-LAST-REV TOW-KT-EII",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, Reverse Towable, End Tower",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394261-G": {
        description: null,
        mapicsDescription: "TWR ASY-HD-NT-A80G GALV",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, Standard Profile",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394262-G": {
        description: null,
        mapicsDescription: "TWR ASY-HD-TOW-A80G GALV",
        customerReportsDescription: "Tower Assembly, Heavy Duty, Galvanized, Standard Profile, Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394358": {
        description: null,
        mapicsDescription: "TOWER ASY-3 WHEEL-STD-EII",
        customerReportsDescription: "Three-Wheel Tower-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394359": {
        description: null,
        mapicsDescription: "TOWER ASY-3 WHEEL-HD-EII",
        customerReportsDescription: "Three-Wheel Tower-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394357": {
        description: null,
        mapicsDescription: "HINGE-UHMC-HTWR-3WHL DRV-EII",
        customerReportsDescription: "Hinge Tower(UHMC)-3 Wheel Drive-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394965-G": {
        description: null,
        mapicsDescription: "A60G/A4 SYS-E60 H TOWER UHMW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394970": {
        description: null,
        mapicsDescription: "H TWR SAC UHMW HNG EII GALV SG",
        customerReportsDescription: "Tower Assembly, Special-UHMW, SAC, Heavy Duty, Galvanized-SG",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394355": {
        description: null,
        mapicsDescription: "H-TWR-UHMW-3 WHL FLX-EII",
        customerReportsDescription: "Flexible Three-Wheel H-Tower-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394356": {
        description: null,
        mapicsDescription: "TOWER ASY-3 WHL FLX-EII",
        customerReportsDescription: "Flexible Three-Wheel Tower-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394327": {
        description: null,
        mapicsDescription: "TOWER ASSY-NT-E2045",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, E2045",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394328": {
        description: null,
        mapicsDescription: "TOWER ASSY-TOW-E2045",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, Towable, E2045",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394330": {
        description: null,
        mapicsDescription: "TWR ASY-LAST-REV TOW-KT-E2045",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, Reverse Towable, End Tower",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394329": {
        description: null,
        mapicsDescription: "TWR ASSY-REV TOW-KT/MAXI-E2045",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, Reverse Towable, E2045",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394331": {
        description: null,
        mapicsDescription: "TWR ASY-LAST-REV TOW-MAX-E2045",
        customerReportsDescription: "Tower Assembly, Galvanized, Standard Profile, Reverse Towable, End Tower",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394267": {
        description: null,
        mapicsDescription: "TOWER SUPERGATOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352465": {
        description: null,
        mapicsDescription: "COVER KIT-UMC PS CENTER DRIVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352466": {
        description: null,
        mapicsDescription: "COVER KIT-UMC PS4 CENTER DRIVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352276-G": {
        description: null,
        mapicsDescription: "TIRE OPT/TOWER-16.9X24-LH-GALV",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352276TG": {
        description: "WHL-TIRE W/TUBE-16.9X24-LH G",
        mapicsDescription: "WHL-TIRE W/TUBE-16.9X24- LH G",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Tires with Tubes, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352280TG": {
        description: null,
        mapicsDescription: "TIRE-TUBE OPT/TWR 13.6X38 LH G",
        customerReportsDescription: "Tire Option, 13.6 x 38 New Tires with Tube, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352277-G": {
        description: "TIRE OPT/TWR-11.2X38\"-GALVANIZE",
        mapicsDescription: "TIRE OPT/TWR-11.2X38-GALVANIZE",
        customerReportsDescription: "Tire Option, 11.2 x 38 New Tires-Tubeless, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352277TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-11.2X38-STD-G",
        customerReportsDescription: "Tire Option, 11.2 x 38 New Tires with Tubes, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352273-G": {
        description: "TIRE & WHEEL-11.2X38\"-1LH,1RH-G",
        mapicsDescription: "TIRE & WHEEL-11.2X38-1LH,1RH-G",
        customerReportsDescription: "Tire Option, 11.2 x 38 New Tires-Tubeless, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352273TG": {
        description: null,
        mapicsDescription: "WHL-TIRE W/TUBE-11.2X38-L&R G",
        customerReportsDescription: "Tire Option, 11.2 x 38 New Tires with Tubes, Galvanized Wheels, 1 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352061": {
        description: null,
        mapicsDescription: "TIRE/WHEEL ASY-RECAPS-2LH1RH-G",
        customerReportsDescription: "Tire Option, 11 x 22.5 Recap Tires-Tubeless, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352063": {
        description: "TIRE/WHEEL, 11.2X24, 2LH, 1RH, G",
        mapicsDescription: "TIRE/WHEEL-11.2X24-2LH, 1RH-G",
        customerReportsDescription: "Tire Option, 11.2 x 24 New Tires-Tubeless, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352065": {
        description: "TIRE W/TUBE, 11.2X24, 2L & 1R G",
        mapicsDescription: "TIRE W/TUBE-11.2X24-2L & 1R G",
        customerReportsDescription: "Tire Option, 11.2 x 24 New Tires with Tubes, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352067": {
        description: "TIRE OPT/TWR, 14.9X24, 2LH&1RH, G",
        mapicsDescription: "TIRE OPT/TWR-14.9X24-2LH&1RH-G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Tires-Tubeless, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352069": {
        description: "TIRE W/TUBE, 14.9X24, 2L & 1R G",
        mapicsDescription: "TIRE W/TUBE-14.9X24-2L & 1R G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Tires with Tubes, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352071": {
        description: null,
        mapicsDescription: "TIRE OPT-3WD-14.9X24 TURF-TT-G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Turf Tires, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352073": {
        description: null,
        mapicsDescription: "TIRE W/TUBE-3WD-14.9X24-TURF G",
        customerReportsDescription: "Tire Option, 14.9 x 24 New Turf Tires with Tube, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352075": {
        description: "TIRE OPT/TWR, 16.9X24, 2LH&1RH, G",
        mapicsDescription: "TIRE OPT/TWR-16.9X24-2LH&1RH-G",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Tires, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352077": {
        description: "TIRE W/TUBE, 16.9X24, 2L & 1R G",
        mapicsDescription: "TIRE W/TUBE-16.9X24-2L & 1R G",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Tires with Tubes, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352079": {
        description: null,
        mapicsDescription: "TIRE OPT-3WD-16.9X24 TURF-GALV",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Turf Tires, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352081": {
        description: null,
        mapicsDescription: "TIRE W/TUBE-3WD-16.9X24-TURF G",
        customerReportsDescription: "Tire Option, 16.9 x 24 New Turf Tires with Tubes, Galvanized Wheels, 2 Fwd, 1 Rev",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352089": {
        description: "TIRE W/TUBE, 13.6X38, 2L & 1R G",
        mapicsDescription: "TIRE W/TUBE-13.6X38-2L & 1R G",
        customerReportsDescription: "Tire Option, 13.6 x 38 New Tires with Tubes, Galvanized Wheels, 2 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352083": {
        description: "TIRE & WHEEL, 11.2X38, 2LH,1RH, G",
        mapicsDescription: "TIRE & WHEEL-11.2X38-2LH,1RH-G",
        customerReportsDescription: "Tire Option, 11.2 x 38 New Tires, Galvanized Wheels, 2 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352085": {
        description: "TIRE W/TUBE, 11.2X38, 2L & 1R G",
        mapicsDescription: "TIRE W/TUBE-11.2X38-2L & 1R G",
        customerReportsDescription: "Tire Option, 11.2 x 38 New Tires with Tubes, Galvanized Wheels, 2 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352061TG": {
        description: null,
        mapicsDescription: "TIRE-WHL-TUBE-RECAPS-2LH1RH-G",
        customerReportsDescription: "Tire Option, 11 x 22.5 Recap Tires-w/Tubes, Galvanized Wheels, 2 Forward, 1 Reversed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352247": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR11.2X2416L MACH II",
        customerReportsDescription: "Tire Option, 11.2 x 24 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352248": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR14.9X2416L MACH II",
        customerReportsDescription: "Tire Option, 14.9 x 24 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352249": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR11.2X3820L MACH II",
        customerReportsDescription: "Tire Option, 11.2 x 38 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352250": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR11X22.512L MACH II",
        customerReportsDescription: "Tire Option, 11 x 22.5 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352056": {
        description: null,
        mapicsDescription: "TIRE OP/3W-11.2X24 16L MACH II",
        customerReportsDescription: "Tire Option, 11.2 x 24 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352057": {
        description: null,
        mapicsDescription: "TIRE OPT/3WD14.9X2416L MACH II",
        customerReportsDescription: "Tire Option, 14.9 x 24 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352058": {
        description: null,
        mapicsDescription: "TIRE OPT/3WD11.2X3820L MACH II",
        customerReportsDescription: "Tire Option, 11.2 x 38 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352059": {
        description: null,
        mapicsDescription: "TIRE OPT/3WD11X22.512L MACH II",
        customerReportsDescription: "Tire Option, 11 x 22.5 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352254": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR13.6X3824L MACH II",
        customerReportsDescription: "Tire Option, 13.6 x 38 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352055": {
        description: null,
        mapicsDescription: "TIRE OP/3W-13.6X38 24L MACH II",
        customerReportsDescription: "Tire Option, 13.6 x 38 Mach II Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352252": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR11.2X3812L MACH II",
        customerReportsDescription: "Tire Option, 11.2 x 38 Mach II, 12 Lug Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352253": {
        description: null,
        mapicsDescription: "TIRE OPT/3WD11.2X3812L MACH II",
        customerReportsDescription: "Tire Option, 11.2 x 38 Mach II, 12 Lug Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352255": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR 11.2X24 ST RHINO",
        customerReportsDescription: "Tire Option, 11.2 x 24 RhinoGator Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352054": {
        description: null,
        mapicsDescription: "TIRE OPT/3W-11.2X24 ST RHINO",
        customerReportsDescription: "Tire Option, 11.2 x 24 RhinoGator Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352256": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR 11.2X38 DT RHINO",
        customerReportsDescription: "Tire Option, 11.2 x 38 RhinoGator Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352052": {
        description: null,
        mapicsDescription: "TIRE OPT/3W 11.2X38 DT RHINO",
        customerReportsDescription: "Tire Option, 11.2 x 38 RhinoGator Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352257": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR 14.9X24 ST RHINO",
        customerReportsDescription: "Tire Option, 14.9 x 24 RhinoGator Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352053": {
        description: null,
        mapicsDescription: "TIRE OPT/3W 14.9X24 ST RHINO",
        customerReportsDescription: "Tire Option, 14.9 x 24 RhinoGator Tires, Galvanized Wheels",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352267": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR-SHARK HTRAC-2 WHL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270-G",
    },
    "352268": {
        description: null,
        mapicsDescription: "TIRE OPT/TWR-SHARK HTRAC-3 WHL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352061",
    },
    "352106": {
        description: null,
        mapicsDescription: "OVR HD TWR A/R-STD TWR-RAM-EII",
        customerReportsDescription: "Auto Reverse, Tower Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352105": {
        description: null,
        mapicsDescription: "OVR HD TWR A/R-LO PRO-RAMS-EII",
        customerReportsDescription: "Auto Reverse, Tower Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352120": {
        description: null,
        mapicsDescription: "OVR HD TAR-203.5'/H-RAMS-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352108": {
        description: null,
        mapicsDescription: "OVR HD TWR A/R-RAMS-SUGAR-EII",
        customerReportsDescription: "Auto Reverse, Tower Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352123": {
        description: null,
        mapicsDescription: "OVR HD TWR A/R-RAMS-SUPERGATOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352127": {
        description: null,
        mapicsDescription: "REINKE GEAR OPTION -NON TOW",
        customerReportsDescription: "Wheel Gears, Reinke, Non-Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352135": {
        description: null,
        mapicsDescription: "WHL GEAR OPT-NON TOW-AGRITRAC",
        customerReportsDescription: "Wheel Gears, Reinke, Special, Agri-TRAC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352136": {
        description: null,
        mapicsDescription: "REINKE GEAR OPT-3WD-NON TOW",
        customerReportsDescription: "Wheel Gears, Reinke 3 Wheel Drive, Non-Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352140": {
        description: null,
        mapicsDescription: "REINKE GEAR OPTION-MOD DUTY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352137": {
        description: null,
        mapicsDescription: "REINKE GEAR OPT-HD 3WD-NON TOW",
        customerReportsDescription: "Wheel Gears HD, Reinke 3 Wheel Drive, Non-Towable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352153": {
        description: null,
        mapicsDescription: "REINKE GEAR 1016-NON TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352127",
    },
    "352154": {
        description: null,
        mapicsDescription: "REINKE GEAR 1016-3WD-NON TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352136",
    },
    "352127HDC": {
        description: null,
        mapicsDescription: "REINKE GEAR OPTION-NON TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: null,
        upgradeToPartNumber: null,
    },
    "352136HDC": {
        description: null,
        mapicsDescription: "description required",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: null,
        upgradeToPartNumber: null,
    },
    "352155": {
        description: null,
        mapicsDescription: "REINKE GEAR 1016-NON TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352127",
    },
    "352156": {
        description: null,
        mapicsDescription: "REINKE GEAR 1016-3WD-NON TOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352136",
    },
    "352141": {
        description: null,
        mapicsDescription: "TOW HUB OPTION",
        customerReportsDescription: "Towable Option, Wheel Gear Hub",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352045": {
        description: null,
        mapicsDescription: "HIGH TORQUE COUP OPT/TWR-NT-A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352042": {
        description: null,
        mapicsDescription: "HI TQ CPLR-3 WHL TWR BASE-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352025": {
        description: null,
        mapicsDescription: "U-JOINTS-PER TOWER-ALUM-4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352041": {
        description: null,
        mapicsDescription: "HIGH TORQUE COUP/TWR-E-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352022": {
        description: null,
        mapicsDescription: "U-JOINTS-3 WHEEL TWR BASE-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352021": {
        description: null,
        mapicsDescription: "U-JOINTS-STD-PER TOWER-E-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352043": {
        description: null,
        mapicsDescription: "HIGH TORQUE COUP/TWR-NT-LP-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352023": {
        description: null,
        mapicsDescription: "U-JOINTS LP PER TOWER EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352027": {
        description: null,
        mapicsDescription: "HTC/DRVSHFT TOW SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352028": {
        description: null,
        mapicsDescription: "STL-COUPLER/DRVSHFT SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352441": {
        description: null,
        mapicsDescription: "HIGH TORQUE COUP OPT/TWR-N T-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352421": {
        description: null,
        mapicsDescription: "U-JOINTS-STD-PER TOWER-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352443": {
        description: null,
        mapicsDescription: "HIGH TORQUE COUP OPT/TWR-NT-LP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352423": {
        description: null,
        mapicsDescription: "U-JOINTS-LOW-PROF-PER TOWER-E",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352427": {
        description: null,
        mapicsDescription: "HI TORQUE COUP/TWR-TOW-SGRGATR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352428": {
        description: null,
        mapicsDescription: "U JOINTS-PER TOWER-SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352012": {
        description: null,
        mapicsDescription: "C-X COUPLER/TWR-E-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352013": {
        description: null,
        mapicsDescription: "C-X COUPLER/3 WHL-RGD BASE-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352014": {
        description: null,
        mapicsDescription: "C-X COUPLER/TWR-NT-LP-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352015": {
        description: null,
        mapicsDescription: "C-X COUPLER OPT/TWR-NT-A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352031": {
        description: null,
        mapicsDescription: "C-X COUPLER/TWR-TOW-SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352032": {
        description: null,
        mapicsDescription: "C-X COUPLER OPT/TWR-NT-E65",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352033": {
        description: null,
        mapicsDescription: "C-X COUPLER/TWR-TOW-LP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352010": {
        description: null,
        mapicsDescription: "C-X COUPLER/DRVSHFT TOW SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352260": {
        description: null,
        mapicsDescription: "UMC GEAR-60 TO 1 RATIO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352240": {
        description: null,
        mapicsDescription: "UMC GEAR-40 TO 1 RATIO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352220": {
        description: null,
        mapicsDescription: "UMC GEAR-30 TO 1 RATIO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352110": {
        description: null,
        mapicsDescription: "KIT-OVR HD TWR A/S-STD TWR-EII",
        customerReportsDescription: "Auto Stop, Tower Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352109": {
        description: null,
        mapicsDescription: "TWR A/S-LO PRO-COMPLETE-EII",
        customerReportsDescription: "Auto Stop, Tower Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352111": {
        description: null,
        mapicsDescription: "KIT-OVR HD TWR A/S-203.5' SPAN",
        customerReportsDescription: "Auto Stop, Tower Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352112": {
        description: null,
        mapicsDescription: "KIT-OVR HD TWR A/S-SUGAR-EII",
        customerReportsDescription: "Auto Stop, Tower Option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352113": {
        description: null,
        mapicsDescription: "KIT-OVR HD TWR A/S-PIVOT TOWER",
        customerReportsDescription: "Option, 10 Deg Wrap Around Span Joint",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352121": {
        description: null,
        mapicsDescription: "AUTO REVERSE-KIT-90 DEG-TOWER",
        customerReportsDescription: "Option, 90 Deg Wrap Around Span Joint",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352122": {
        description: null,
        mapicsDescription: "OVR HD TAR-SUG 90 PVT TWR",
        customerReportsDescription: "Option, 90 Deg Wrap Around Span Joint-SG",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352615": {
        description: null,
        mapicsDescription: "CONTROL LINE-BOOSTER PUMP-OPT",
        customerReportsDescription: "Control Line Booster Pump Option, Swing Arm Corner System",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352655": {
        description: null,
        mapicsDescription: "S BOX RPM CONTROL LINE-BP OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352104": {
        description: null,
        mapicsDescription: "KIT-OVR HD TWR A/S-SUPERGATOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352516": {
        description: null,
        mapicsDescription: "OVR HD TWR A/S LO PRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352517": {
        description: null,
        mapicsDescription: "OVR HD TWR A/S-STD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352518": {
        description: null,
        mapicsDescription: "OVR HD TWR A/S-SUGARGATOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352519": {
        description: null,
        mapicsDescription: "OVR HD TWR A/S-SUPERGATOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352911": {
        description: null,
        mapicsDescription: "AIR COMP OPT-JENNY-TWR MNT",
        customerReportsDescription: "Air Compressor Option - Jenny - Tower Mount",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352520": {
        description: null,
        mapicsDescription: "OVR HD TWR A/S-STD PIVOT TOWER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352686": {
        description: null,
        mapicsDescription: "TOWER BOX-A-SINGLE PHASE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352687": {
        description: null,
        mapicsDescription: "TOWER BOX-B-SINGLE PHASE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352681-U": {
        description: null,
        mapicsDescription: "TOWER BOX-A-E60-US MOTOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352688": {
        description: null,
        mapicsDescription: "TOWER BOX-C-SINGLE PHASE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352773-U": {
        description: null,
        mapicsDescription: "TOWER BOX-G/A-GPS GUIDANCE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352742-U": {
        description: null,
        mapicsDescription: "TOWER BOX-A-NO OL-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352740": {
        description: null,
        mapicsDescription: "MOD B-BOX-2 SPAN MAXI US MTR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352555-U": {
        description: null,
        mapicsDescription: "TWR BOX-G/A-BWG-MAXI-US MOTORS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352553-U": {
        description: null,
        mapicsDescription: "BOX-G/A-BURIED WIRE MAXI-CSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352767-U": {
        description: null,
        mapicsDescription: "BOX-TWR A/C NO OL MAXI UL/CUL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352747-RH": {
        description: null,
        mapicsDescription: "TOWER BOX-A/C-RH-MAXI-UL/CUL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352556-U": {
        description: null,
        mapicsDescription: "TOWER BOX-G/A MAXI-US MOTORS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352877-U": {
        description: null,
        mapicsDescription: "A-BOX-PIVOT TOWER OPT-CSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352878-U": {
        description: null,
        mapicsDescription: "A-BOX-90 DEG PIVOT TWR OPT-CSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352774-U": {
        description: null,
        mapicsDescription: "TOWER BOX-G/A MAXI-L OL-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353100-U": {
        description: null,
        mapicsDescription: "A/C BOX PIVOT NO OL UL/CUL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352581-U": {
        description: null,
        mapicsDescription: "TOWER BOX-A-1ST MAXI-US MOTORS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352751-U": {
        description: null,
        mapicsDescription: "TOWER BOX-A-NO OL-#1MAX-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352682-U": {
        description: null,
        mapicsDescription: "TOWER BOX-B-E60-US MOTOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352743-U": {
        description: null,
        mapicsDescription: "TOWER BOX-B-NO OL-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352755-U": {
        description: null,
        mapicsDescription: "TOWER BOX-G/B-MAXI-US MOTORS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352775-U": {
        description: null,
        mapicsDescription: "TOWER BOX-G/B MAXI-L OL-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352684-U": {
        description: null,
        mapicsDescription: "TOWER BOX-C-E60-US MOTOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352744-U": {
        description: null,
        mapicsDescription: "TOWER BOX-C-NO OL-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352564-U": {
        description: null,
        mapicsDescription: "TOWER BOX-C-MAXI-US MOTORS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352753-U": {
        description: null,
        mapicsDescription: "TOWER BOX-C-NO OL-MAXI-UL/C UL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352756-U": {
        description: null,
        mapicsDescription: "TOWER BOX-G/C-MAXI-US MOTORS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352778-U": {
        description: null,
        mapicsDescription: "TOWER BOX-G/C MAXI-L OL-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352585": {
        description: null,
        mapicsDescription: "TOWER BOX-C-MAXI-DOUBLE END FD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352585-H": {
        description: null,
        mapicsDescription: "TWR BOX-C-MAXI-DBL END FD-HDOL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352779": {
        description: null,
        mapicsDescription: "T BOX-STD-MOD C-DEF-MAX-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352779-H": {
        description: null,
        mapicsDescription: "T BOX-H D-MOD C-DEF-MAX-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352762-U": {
        description: null,
        mapicsDescription: "TOWER BOX-H SAC-NO OL-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352725": {
        description: null,
        mapicsDescription: "C BOX SINGLE SPAN MAXI CSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395869-U": {
        description: null,
        mapicsDescription: "BOX-TWR A-W/O OL-PVT MX-UL/CUL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395868-U": {
        description: null,
        mapicsDescription: "BOX-TWR A-W/O OL-PVT MX-UL/CUL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352790-U": {
        description: null,
        mapicsDescription: "BOX-H TWR-PLC-NO OL-UL C/UL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395620": {
        description: null,
        mapicsDescription: "S-BOX RPM PANEL MOUNT/HARDWARE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395621": {
        description: null,
        mapicsDescription: "TWR BOX-RAIL MOUNT 4-BOX TWRS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357400": {
        description: null,
        mapicsDescription: "ENGINE DRIVE-1SP-CTR-PVT-EII",
        customerReportsDescription: "Engine Drive, Mini Pivot",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392478": {
        description: "HELICAL GR OPT LO SPD A4",
        mapicsDescription: "HELICAL GR OPT LO SPD A-4",
        customerReportsDescription: "Helical Center Drive-Lo Speed-A4",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392479": {
        description: "HELICAL GEAR OPT STD SPD A4",
        mapicsDescription: "HELICAL GEAR OPT STD SPD A-4",
        customerReportsDescription: "Helical Center Drive-Std Speed-A4",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392480": {
        description: "HELICAL GEAR OPT HI SPD A4",
        mapicsDescription: "HELICAL GEAR OPT HI SPD A-4",
        customerReportsDescription: "Helical Center Drive-Hi Speed-A4",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392487": {
        description: null,
        mapicsDescription: "HELICAL GEAR OP LOW SPD EII",
        customerReportsDescription: "Helical Center Drive-Lo Speed-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392488": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT STD SPD EII",
        customerReportsDescription: "Helical Center Drive-Std Speed-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392489": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT HI SPD EII",
        customerReportsDescription: "Helical Center Drive-Hi Speed-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392484": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT LOW SPD EIILP",
        customerReportsDescription: "Helical Center Drive-Lo Speed-LP-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392485": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT STD SPD EIILP",
        customerReportsDescription: "Helical Center Drive-Std Speed-LP-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392486": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT HI SPD EIILP",
        customerReportsDescription: "Helical Center Drive-Hi Speed-LP-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392475": {
        description: null,
        mapicsDescription: "HELICAL GR OPT LOW SPD EIISG",
        customerReportsDescription: "Helical Center Drive-Lo Speed-SG-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392476": {
        description: null,
        mapicsDescription: "HELICAL GR OPT STD SPD EIISG",
        customerReportsDescription: "Helical Center Drive-Std Speed-SG-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392477": {
        description: null,
        mapicsDescription: "HELICAL GR OPT HI SPD EIISG",
        customerReportsDescription: "Helical Center Drive-Hi Speed-SG-EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392481": {
        description: "HELICAL GR OPT LO SPD 203.5'/H",
        mapicsDescription: "HELICAL GR OPT LO SPD 203.5/H",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392482": {
        description: "HELICAL GR OPT STD SPD 203.5'/H",
        mapicsDescription: "HELICAL GR OPT STD SPD 203.5/H",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352513": {
        description: null,
        mapicsDescription: "MOTOR/GR OPT-1HP-1PH-60:1-EII",
        customerReportsDescription: "Center Drive, 80:1 Ratio, Single Phase",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352514": {
        description: null,
        mapicsDescription: "MOTOR/GR OPT-1PH-60:1-LP-EII",
        customerReportsDescription: "Center Drive, 80:1 Ratio, Single Phase",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392490": {
        description: null,
        mapicsDescription: "MOTOR-STD HEL-25' W/O THERM",
        customerReportsDescription: "Helical Center Drive-Std Speed-25'-No Therm OL",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392491": {
        description: null,
        mapicsDescription: "MOTOR-HI HEL-25' W/O THERM",
        customerReportsDescription: "Helical Center Drive-Hi Speed-25'-No Therm OL",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392477HDC": {
        description: null,
        mapicsDescription: "HELICAL GR OPT HI SPD EIISG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392480HDC": {
        description: "HELICAL GEAR OPT HI SPD A-4",
        mapicsDescription: "HELICAL GEAR OPT HI SPD A-4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392486HDC": {
        description: "HELICAL GEAR OPT HI SPD EIILP",
        mapicsDescription: "HELICAL GEAR OPT HI SPD EIILP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392489HDC": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT HI SPD EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392444": {
        description: null,
        mapicsDescription: "HELICAL GR OPT LOW EII SUPGTR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392445": {
        description: null,
        mapicsDescription: "HELICAL GR OPT STD EII SUPGTR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392446": {
        description: null,
        mapicsDescription: "HELICAL GR OPT HI EII SUPGTR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392446HDC": {
        description: null,
        mapicsDescription: "description required",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: null,
        upgradeToPartNumber: null,
    },
    "392476HDC": {
        description: null,
        mapicsDescription: "HELICAL GR OPT STD SPD EIISG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: null,
        upgradeToPartNumber: null,
    },
    "392479HDC": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT STD SPD A-4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: null,
        upgradeToPartNumber: null,
    },
    "392482HDC": {
        description: null,
        mapicsDescription: "HELICAL GR OPT STD SPD 203.5/H",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: null,
        upgradeToPartNumber: null,
    },
    "392485HDC": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT STD SPD EIILP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: null,
        upgradeToPartNumber: null,
    },
    "392488HDC": {
        description: null,
        mapicsDescription: "HELICAL GEAR OPT STD SPD EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: null,
        upgradeToPartNumber: null,
    },
    "392445HDC": {
        description: null,
        mapicsDescription: "description required",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: null,
        upgradeToPartNumber: null,
    },
    "352290": {
        description: "AGRI-TRAC 11 X 22.5-PER TWR",
        mapicsDescription: "AGRI-TRAC 11 X 22.5-PER TWR",
        customerReportsDescription: "Agri-Trac 11 x 22.5 per tower",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Upgrade,
        upgradeToPartNumber: "352270",
    },
    "352857": {
        description: null,
        mapicsDescription: "BUCK/BOOST TRANS-15 KVA-EII",
        customerReportsDescription: "Buck & Boost Transformer-15 KVA",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352856": {
        description: null,
        mapicsDescription: "BUCK/BOOST TRANS-22.5 KVA-EII",
        customerReportsDescription: "Buck & Boost Transformer-22.5 KVA",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352360": {
        description: null,
        mapicsDescription: "FUSE KIT-6 AMP F/5HP BP-1/TWR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395800": {
        description: null,
        mapicsDescription: "CABLE/BRACE/STIFF-A60G-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "395830": {
        description: null,
        mapicsDescription: "CABLE/BRACE/STIFF PKG-MAXI/A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396347": {
        description: null,
        mapicsDescription: "WIRE ALIGN-CONTROL BOX-6\" SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396348": {
        description: null,
        mapicsDescription: "WIRE ALIGN-CTRL BOX-6 5/8\"SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396349": {
        description: null,
        mapicsDescription: "WIRE ALIGN-CTRL BOX-8\" SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396341": {
        description: null,
        mapicsDescription: "WIRE ALIGN-CTRL BOX-8 5/8\" SPN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396131": {
        description: "WIRE ALIGN-CTRL BOX-4 1/2\"SPAN",
        mapicsDescription: "WIRE ALIGN-CTRL BOX-4-1/2\"SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396347SS": {
        description: null,
        mapicsDescription: "WIRE ALIGN-SS-CTRL BOX-6\" SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396348SS": {
        description: null,
        mapicsDescription: "WIRE ALIGN-SS-CTRL BOX-6 5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396349SS": {
        description: null,
        mapicsDescription: "WIRE ALIGN-SS-CTRL BOX-8\" SPAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396341SS": {
        description: null,
        mapicsDescription: "WIRE ALIGN-SS-CTRL BOX-8 5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396131SS": {
        description: null,
        mapicsDescription: "WIRE ALIGN-SS-CTRL BOX-4-1/2\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396333": {
        description: "CABLE OPT-3/32\" X 40' LSA",
        mapicsDescription: "CABLE OPT-3/32 X 40' LSA",
        customerReportsDescription: "Cable Opt-3/32\" X 40' LSA",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396333SS": {
        description: null,
        mapicsDescription: "CABLE-SS-3/32 X 40' LSA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396338": {
        description: null,
        mapicsDescription: "G-BOX MNT OPT-WIRE ALIGN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394373": {
        description: "STIFFENER STABILIZER OPT 6 5/8\"",
        mapicsDescription: "STIFFENER STAB OPT 6\" / 6-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394374": {
        description: "STIFFENER STABILIZER OPT 8 5/8\"",
        mapicsDescription: "STIFFENER STABILIZER OPT 8-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395801": {
        description: null,
        mapicsDescription: "STIFF/STAB OPT-A80G-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395904": {
        description: "STIFF-STAB OPT-A60G-A4",
        mapicsDescription: "STIFF-STAB OPT-A60G, A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394377": {
        description: null,
        mapicsDescription: "STIFFENER TRUSS-PIPE OPT EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394378": {
        description: null,
        mapicsDescription: "STIFF OPT-TRUSS-PIPE-A60G/A80G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395900": {
        description: null,
        mapicsDescription: "ROD OPT-TRUSS TO PIPE-A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395901": {
        description: null,
        mapicsDescription: "ROD OPT-TRUSS TO PIPE-A60G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395902": {
        description: null,
        mapicsDescription: "ROD OPT-TRUSS TO PIPE-A80G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395903": {
        description: null,
        mapicsDescription: "STIFF OPT-TRUSS TO PIPE OPT-A4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394376": {
        description: null,
        mapicsDescription: "TOWER CROSS BRACE OPTION EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356143": {
        description: null,
        mapicsDescription: "GUARD-STD MTR LD-CATTLE",
        customerReportsDescription: "Motor Lead Cattle Guard-Std",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356241": {
        description: null,
        mapicsDescription: "GUARD-LP MTR LD-CATTLE",
        customerReportsDescription: "Motor Lead Cattle Guard-LP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354360": {
        description: null,
        mapicsDescription: "GUARD-SG MTR LD-CATTLE",
        customerReportsDescription: "Motor Lead Cattle Guard-SG",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394350-G": {
        description: null,
        mapicsDescription: "STEP-TOWER BASE ACCESS-ASSY",
        customerReportsDescription: "Step Option, Tower Base Access",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "394959": {
        description: null,
        mapicsDescription: "RETRO E60 SYS-EII H TWR UHMW",
        customerReportsDescription: "Retro E60 Tower Assembly, Special-UHMW, SAC, Heavy Duty, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352620": {
        description: null,
        mapicsDescription: "RETRO KIT-SAC-H TWR-E80/100",
        customerReportsDescription: "Retro E80/100/6580/680 Tower Assembly, SAC, Heavy Duty, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352622": {
        description: null,
        mapicsDescription: "RETRO-A3 SYS-E60 H TWR ROLLER",
        customerReportsDescription: "Retro A3-E60 Tower Assembly, Roller, SAC, Heavy Duty, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352617": {
        description: "RETRO A4 TWR -E60 H TWR UHMW",
        mapicsDescription: "RETRO A4 TWR - E60 H TWR UHMW",
        customerReportsDescription: "Retro A4-E60 Tower Assembly, Special-UHMW, SAC, Heavy Duty, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394968": {
        description: null,
        mapicsDescription: "RETRO EII SYS-EII H TWR UHMW",
        customerReportsDescription: "Retro EII Tower Assembly, Special-UHMW, SAC, Heavy Duty, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394957": {
        description: null,
        mapicsDescription: "RETRO-VAL H TWR UHMW",
        customerReportsDescription: "Retro Valley Tower Assembly, Special-UHMW, SAC, Heavy Duty, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394958": {
        description: null,
        mapicsDescription: "RETRO-ZIM H TWR UHMW",
        customerReportsDescription: "Retro Zimmatic Tower Assembly, Special-UHMW, SAC, Heavy Duty, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394956": {
        description: null,
        mapicsDescription: "RETRO-VAL H TWR LAST PIPE DROP",
        customerReportsDescription: "Retro Valley Tower w/Drop Assembly, Special-UHMW, SAC, Heavy Duty, Galvanized",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352609": {
        description: "RETRO-SAC H-TWR EII",
        mapicsDescription: "RETRO-SAC H-TWR EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352619": {
        description: "RETRO-SAC H-TWR EII",
        mapicsDescription: "RETRO KIT-SAC-H TWR-E60",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352690": {
        description: null,
        mapicsDescription: "TOWER BOX KT-RETRO-REINKE SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352691": {
        description: null,
        mapicsDescription: "TOWER BX KT-RETRO-SAC V & Z",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352624": {
        description: "SPAN WIRE 80' SPAN-RETROFIT-SAC",
        mapicsDescription: "SPAN WIRE 80 SPAN-RETROFIT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352624-H": {
        description: "SPAN WIRE-80' SP-RETRO-SAC #8",
        mapicsDescription: "SPAN WIRE-80FT SP-RETRO-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352636": {
        description: "SPAN WIRE-83' LAST SPAN A3/SAC",
        mapicsDescription: "SPAN WIRE-83F LAST SPAN A3/SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352636-H": {
        description: "SPAN WIRE-83' LAST-A3/SAC #8",
        mapicsDescription: "SPAN WIRE-83FT LAST-A3/SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352625": {
        description: "SPAN WIRE 99' SPAN-RETROFIT-SAC",
        mapicsDescription: "SPAN WIRE 99 SPAN-RETROFIT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352625-H": {
        description: "SPAN WIRE-99' SP-RETRO-SAC #8",
        mapicsDescription: "SPAN WIRE-99FT SP-RETRO-SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352637": {
        description: "SPAN WIRE-103' LST SPAN A3/SAC",
        mapicsDescription: "SPAN WIRE-103F LST SPAN A3/SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352637-H": {
        description: "SPAN WIRE-103' LAST-A3/SAC #8",
        mapicsDescription: "SPAN WIRE-103FT LAST-A3/SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352626": {
        description: "SPAN WIRE 118' SPN-RETROFIT-SAC",
        mapicsDescription: "SPAN WIRE 118 SPN-RETROFIT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352626-H": {
        description: "SPAN WIRE-118' SP-RETRO-SAC#8",
        mapicsDescription: "SPAN WIRE-118FT SP-RETRO-SAC#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352638": {
        description: "SPAN WIRE-123' LST SPAN A3/SAC",
        mapicsDescription: "SPAN WIRE-123F LST SPAN A3/SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352638-H": {
        description: "SPAN WIRE-123' LAST-A3/SAC #8",
        mapicsDescription: "SPAN WIRE-123FT LAST-A3/SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352633": {
        description: "SPAN WIRE-124' SPN RETRFIT-SAC",
        mapicsDescription: "SPAN WIRE-124 SPN RETRFIT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352633-H": {
        description: "SPAN WIRE-124' SP-RETRO-SAC#8",
        mapicsDescription: "SPAN WIRE-124FT SP-RETRO-SAC#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352627": {
        description: "SPAN WIRE 137' SPN-RETROFIT-SAC",
        mapicsDescription: "SPAN WIRE 137 SPN-RETROFIT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352627-H": {
        description: "SPAN WIRE-137' SP-RETRO-SAC#8",
        mapicsDescription: "SPAN WIRE-137FT SP-RETRO-SAC#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352639": {
        description: "SPAN WIRE-143' LST SPAN A3/SAC",
        mapicsDescription: "SPAN WIRE-143F LST SPAN A3/SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352639-H": {
        description: "SPAN WIRE-143' LAST-A3/SAC #8",
        mapicsDescription: "SPAN WIRE-143FT LAST-A3/SAC #8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352634": {
        description: "SPAN WIRE-144' SPN RETRFIT-SAC",
        mapicsDescription: "SPAN WIRE-144 SPN RETRFIT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352634-H": {
        description: "SPAN WIRE-144' SP-RETRO-SAC#8",
        mapicsDescription: "SPAN WIRE-144FT SP-RETRO-SAC#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352628": {
        description: "SPAN WIRE 156' SPN-RETROFIT-SAC",
        mapicsDescription: "SPAN WIRE 156 SPN-RETROFIT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352628-H": {
        description: "SPAN WIRE-156' SP-RETRO-SAC#8",
        mapicsDescription: "SPAN WIRE-156FT SP-RETRO-SAC#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352632": {
        description: "SPAN WIRE-164' SPN RETROFIT-SAC",
        mapicsDescription: "SPAN WIRE-164 SPN RETROFIT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352632-H": {
        description: "SPAN WIRE-164' SP-RETRO-SAC#8",
        mapicsDescription: "SPAN WIRE-164FT SP-RETRO-SAC#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352629": {
        description: "SPAN WIRE 175' SPN-RETROFIT-SAC",
        mapicsDescription: "SPAN WIRE 175 SPN-RETROFIT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352629-H": {
        description: "SPAN WIRE-175' SP-RETRO-SAC#8",
        mapicsDescription: "SPAN WIRE-175FT SP-RETRO-SAC#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352635": {
        description: "SPAN WIRE 194' SPN RETROFT-SAC",
        mapicsDescription: "SPAN WIRE 194 SPN RETROFT-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352635-H": {
        description: "SPAN WIRE-194' SP-RETRO-SAC#8",
        mapicsDescription: "SPAN WIRE-194FT SP-RETRO-SAC#8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352762-H": {
        description: null,
        mapicsDescription: "TOWER BOX-H SAC-H D-UL/C",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352790-H": {
        description: null,
        mapicsDescription: "BOX-H TWR-PLC-HD OL-UL C/UL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392471": {
        description: null,
        mapicsDescription: "HELICAL GR OPT STD SPD SAC",
        customerReportsDescription: "Helical Center Drive-Std Speed-SAC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392472": {
        description: null,
        mapicsDescription: "HELICAL GR OPT HI SPD SAC",
        customerReportsDescription: "Helical Center Drive-Hi Speed-SAC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392494": {
        description: null,
        mapicsDescription: "GEARDRV-SBX-SG SAC HI SPD EII",
        customerReportsDescription: "Geardrive, S-Box, SG SAC, High Speed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392474": {
        description: null,
        mapicsDescription: "GEARDRV-PLC-SG SAC HI SPD EII",
        customerReportsDescription: "Geardrive, PLC, SG SAC, High Speed, EII",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392470": {
        description: null,
        mapicsDescription: "HELICAL GR PLC SAC STD SPD",
        customerReportsDescription: "Helical Center Drive-Std Speed-SAC-PLC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392469": {
        description: null,
        mapicsDescription: "HELICAL GR PLC SAC HI SPD",
        customerReportsDescription: "Helical Center Drive-Hi Speed-SAC-AccuCorner",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392493": {
        description: null,
        mapicsDescription: "GEARDRV-SBX-SG SAC STD SPD EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392473": {
        description: null,
        mapicsDescription: "GEARDRV-PLC-SG SAC STD SPD EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392483": {
        description: null,
        mapicsDescription: "HELICAL GR HI SPD SAC ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392495": {
        description: null,
        mapicsDescription: "HELICAL GR STD SPD SAC ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392496": {
        description: null,
        mapicsDescription: "GEARDRV-SG SAC HI SPD EII ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392497": {
        description: null,
        mapicsDescription: "GEARDRV-SG SAC STD SPD EII ESA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396937": {
        description: null,
        mapicsDescription: "SAC E2060 W/S-TWR SG",
        customerReportsDescription: "SAC Span and Boom w/S-Tower E2060-SG - Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396938": {
        description: null,
        mapicsDescription: "SAC E2660 W/S-TWR SG",
        customerReportsDescription: "SAC Span and Boom w/S-Tower E2660-SG - Painted CN+ Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396942": {
        description: null,
        mapicsDescription: "SAC-E2660G W/S-TWR SG",
        customerReportsDescription: "SAC Span and Boom w/S-Tower E2660G-SG - Galvanized CN+ Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396939": {
        description: null,
        mapicsDescription: "SSAC E2060 W/S-TWR SG",
        customerReportsDescription: "SSAC Span and Boom w/S-Tower E2060-SG - Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396940": {
        description: null,
        mapicsDescription: "SSAC E2660 W/S-TWR SG",
        customerReportsDescription: "SSAC Span and Boom w/S-Tower E2660-SG - Painted CN+ Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396941": {
        description: null,
        mapicsDescription: "SSAC E2660G W/S-TWR SG",
        customerReportsDescription: "SSAC Span and Boom w/S-Tower E2660G-SG - Galvanized CN+ Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396923-G": {
        description: "SAC-194'/85'-57\" 6\"-EII-S TWR-G",
        mapicsDescription: "SAC-194/85-57- 6-EII-S TWR-G",
        customerReportsDescription: "Swing Arm Corner, 279', 57\" Outlet Spacing, Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396924-G": {
        description: "SAC-194'/85'-57\" 6\"-CT-EII-S TWR-G",
        mapicsDescription: "SAC-194/85-57-6-CT-EII-S TWR-G",
        customerReportsDescription: "Swing Arm Corner, 279', 57\" Outlet Spacing, Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397409": {
        description: "SAC-194'/85'-57\" STWR E2660G",
        mapicsDescription: "SAC-194/85-57 STWR E2660G",
        customerReportsDescription: "Swing Arm Corner, 279', Chromium-Nickel (CN+) Galvanized Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396931-G": {
        description: "SSAC-213'/105'-57\" 6\"-EII-STWR-G",
        mapicsDescription: "SSAC-213/105-57-6-EII-STWR-G",
        customerReportsDescription: "Super Swing Arm Corner, 318', 57\" Outlet Spacing, Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396932-G": {
        description: "SSAC-213'/105'-57\" 6\"-CT-EII-STWR",
        mapicsDescription: "SSAC-213/105-57-6-CT-EII-STWR",
        customerReportsDescription: "Super Swing Arm Corner, 318', 57\" Outlet Spacing, Chromium-Nickel (CN+) Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "397408": {
        description: "SSAC-213'/105'-57\" STWR E2660G",
        mapicsDescription: "SSAC-213/105-57 STWR E2660G",
        customerReportsDescription: "Super Swing Arm Corner, 318', Chromium-Nickel (CN+) Galvanized Pipe, 57\" Spacing",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396950": {
        description: null,
        mapicsDescription: "MSAC-156/51-E2060",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396951": {
        description: null,
        mapicsDescription: "MSAC-156/51-E2660",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396952": {
        description: null,
        mapicsDescription: "MSAC-156/51-E2660G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396953": {
        description: null,
        mapicsDescription: "MSSAC-175/61-E2060",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396954": {
        description: null,
        mapicsDescription: "MSSAC-175/61-E2660",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "396955": {
        description: null,
        mapicsDescription: "MSSAC-175/61-E2660G",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394354": {
        description: "H-TWR-3 WHL FLX-EII",
        mapicsDescription: "H-TWR-3 WHL FLX-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394360": {
        description: "H-TWR SAC-3 WHEEL DRIVE-EII",
        mapicsDescription: "H-TWR SAC-3 WHEEL DRIVE-EII",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394961-G": {
        description: "H-TWR SAC 6 / 6-5/8 EII GALV",
        mapicsDescription: "H-TWR SAC 6 / 6-5/8 EII GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "394969": {
        description: "H TWR EII RLR HINGE GALV SG",
        mapicsDescription: "H TWR EII RLR HINGE GALV SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352957-H": {
        description: null,
        mapicsDescription: "S-BOX-PLC-UL/C-RPM-SG HD OL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352958-H": {
        description: null,
        mapicsDescription: "S-BOX PLC-RPM-AIR-SG HD OL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352960-H": {
        description: null,
        mapicsDescription: "S-BOX PLC-RPM-GPS-SG HD OL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352961-H": {
        description: null,
        mapicsDescription: "S-BOX PLC-RPM-AIR-GPS-SG HD OL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352940-H": {
        description: null,
        mapicsDescription: "S-BOX-PLC-UL/C-RPM HD OL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352950-H": {
        description: null,
        mapicsDescription: "S-BOX PLC-UL/C-RPM-GPS HD OL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352941-H": {
        description: null,
        mapicsDescription: "S-BOX PLC-UL/C-RPM-AIR HD OL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352951-H": {
        description: null,
        mapicsDescription: "S-BOX PLC-RPM-AIR-GPS HD OL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352847A": {
        description: null,
        mapicsDescription: "BOX-S SAC-UL/C-RPM-AIR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352847": {
        description: null,
        mapicsDescription: "BOX-S SAC-UL/C-RPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351108": {
        description: null,
        mapicsDescription: "S BOX RPM AIR/BP RELAY OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351111": {
        description: null,
        mapicsDescription: "S-BOX RPM ESAC OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351112": {
        description: null,
        mapicsDescription: "S BOX RPM ESAC SLAVE OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351104": {
        description: null,
        mapicsDescription: "S-BOX RPM GPS GUIDANCE NO OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352910": {
        description: null,
        mapicsDescription: "AIR COMP-OPT-JENNY-SAC",
        customerReportsDescription: "Option, Air Operated Control Valves",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352936": {
        description: null,
        mapicsDescription: "AIR COMP-OPT-JENNY- ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352614": {
        description: null,
        mapicsDescription: "CONTROL LINE BOOST PUMP F/ESP",
        customerReportsDescription: "Control Line Booster Pump Option for ESP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352130": {
        description: null,
        mapicsDescription: "GEAR OPT-NT-UMC 760 SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352144": {
        description: null,
        mapicsDescription: "GEAR OP UMC 760 W/EXT SHFT SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352145": {
        description: null,
        mapicsDescription: "GEAR OPT-UMC 760 SAC BRONZE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352146": {
        description: null,
        mapicsDescription: "GEAR OPT-760 SAC BZ EXT SHAFT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352016": {
        description: null,
        mapicsDescription: "GEAR OPT-NT-760 C-X CPLR SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352018": {
        description: null,
        mapicsDescription: "GEAR OPT 760 EXT SHFT C-X SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352019": {
        description: null,
        mapicsDescription: "GEAR OPT-UMC 760 SAC BRNZ C-X",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352020": {
        description: null,
        mapicsDescription: "GEAR OP-760 SAC BZ EXT-SFT C-X",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352036": {
        description: null,
        mapicsDescription: "GEAR OPT-NT-765 C-X PLR SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352038": {
        description: null,
        mapicsDescription: "GEAR OPT-NT 765 SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352716": {
        description: null,
        mapicsDescription: "GDE WIRE-14/2 SHLD-SAC 1350 SP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352718": {
        description: null,
        mapicsDescription: "GDE WIR-14/2 NON SD-SAC 1350SP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392740": {
        description: "WIRE 10C-194'-SAC-205'-MB",
        mapicsDescription: "WIRE 10C-194-SAC-205FT-MB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392743": {
        description: "WIRE 14C-194' SAC SPAN-205'-MB",
        mapicsDescription: "WIRE 14C-194 SAC SPAN-205FT-MB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392741": {
        description: "WIRE 10C-213'-SSAC SPN-224'-MB",
        mapicsDescription: "WIRE 10C-213-SSAC SPN-224FT-MB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392742": {
        description: "WIRE 14C-213' SSAC SPN-224'-MB",
        mapicsDescription: "WIRE 14C-213 SSAC SPN-224FT-MB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393624": {
        description: null,
        mapicsDescription: "WIRE 10C-194' SAC-203'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393625": {
        description: null,
        mapicsDescription: "WIRE 10C-213' SSAC-222'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393622-X": {
        description: null,
        mapicsDescription: "WIRE 11C-156' MSAC-166.5'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393623-X": {
        description: null,
        mapicsDescription: "WIRE 11C-175' MSSAC-185.5'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393624-X": {
        description: null,
        mapicsDescription: "WIRE 11C-194' SAC-203'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393625-X": {
        description: null,
        mapicsDescription: "WIRE 11C-213' SWG SSAC-222'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393622-14": {
        description: null,
        mapicsDescription: "WIRE-14C-156' MSAC-166.5'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393623-14": {
        description: null,
        mapicsDescription: "WIRE-14C-175' MSAC-185.5'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393624-14": {
        description: null,
        mapicsDescription: "WIRE-14C-194' SAC-203'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "393625-14": {
        description: null,
        mapicsDescription: "WIRE-14C-213' SAC-222'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354245": {
        description: "34 SPRINKLER CTRL HARNESS-SSAC",
        mapicsDescription: "34-SPRINKLER CTRL HARNESS-SSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354244": {
        description: "30 SPRINKLER CTRL HARNESS-SAC",
        mapicsDescription: "30-SPRINKLER CTRL HARNESS-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354242": {
        description: null,
        mapicsDescription: "HARNESS-SPR CTRL-SAC-PLC-ES",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354243": {
        description: null,
        mapicsDescription: "HARNESS-SPR CTRL-SSAC-PLC-ES",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354240": {
        description: null,
        mapicsDescription: "HARNESS-SPR CTRL-SAC-57 SPG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354241": {
        description: null,
        mapicsDescription: "HARNESS-SPR CTRL-SSAC-57 SPG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354259": {
        description: "34 SPRNKLR RNKE CTRL HRNS-SSAC",
        mapicsDescription: "34-SPRNKLR RNKE CTRL HRNS-SSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354254": {
        description: "30 SPRINKLR RNKE CTRL HRNS-SAC",
        mapicsDescription: "30-SPRINKLR RNKE CTRL HRNS-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354257": {
        description: null,
        mapicsDescription: "HRNS-RNKE SPR CTRL-SAC-PLC-ES",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354258": {
        description: null,
        mapicsDescription: "HRNS-RNKE SPR CTRL-SSAC-PLC-ES",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354255": {
        description: "HRNS-RNKE SPR CTRL-SAC-57\"",
        mapicsDescription: "HRNS-RNKE SPR CTRL-SAC-57",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354256": {
        description: "HRNS-RNKE SPR CTRL-SSAC-57\"",
        mapicsDescription: "HRNS-RNKE SPR CTRL-SSAC-57",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354221": {
        description: null,
        mapicsDescription: "HARNESS-EWP CTRL-AQUA-SSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354220": {
        description: null,
        mapicsDescription: "HARNESS-EWP CTRL-AQUA-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354223": {
        description: null,
        mapicsDescription: "HARNESS-EWP CTRL-REINKE-SSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354222": {
        description: null,
        mapicsDescription: "HARNESS-EWP CTRL-REINKE-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356885": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT 12 ZONE ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356886": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT 12 ZONE ESSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356887": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT 6 ZONE ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356888": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT 6 ZONE ESSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356889": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT 6 ZONE E-MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356890": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT 6 ZONE E-MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356891": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT 12 ZONE E-MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356892": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT 12 ZONE E-MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356877": {
        description: null,
        mapicsDescription: "CLAMP KT-12 ZN U-PIPE G ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356879": {
        description: null,
        mapicsDescription: "CLAMP KT-12 ZN U-PIPE G ESSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356881": {
        description: null,
        mapicsDescription: "CLAMP KT-6 ZN U-PIPE G ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356883": {
        description: null,
        mapicsDescription: "CLAMP KT-6 ZN U-PIPE G ESSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356878": {
        description: null,
        mapicsDescription: "CLAMP KT-12 ZN U-PIPE P ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356880": {
        description: null,
        mapicsDescription: "CLAMP KT-12 ZN U-PIPE P ESSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356882": {
        description: null,
        mapicsDescription: "CLAMP KT-6 ZN U-PIPE P ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "356884": {
        description: null,
        mapicsDescription: "CLAMP KT-6 ZN U-PIPE P ESSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354261": {
        description: null,
        mapicsDescription: "HARNESS-SPR CTRL-AQUA-MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354260": {
        description: null,
        mapicsDescription: "HARNESS-SPR CTRL-AQUA-MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354263": {
        description: null,
        mapicsDescription: "HARNESS-SPR CTRL-REINKE-MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354262": {
        description: null,
        mapicsDescription: "HARNESS-SPR CTRL-REINKE-MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354265": {
        description: null,
        mapicsDescription: "HARNESS-EWP CTRL-AQUA-MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354264": {
        description: null,
        mapicsDescription: "HARNESS-EWP CTRL-AQUA-MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354267": {
        description: null,
        mapicsDescription: "HARNESS-EWP CTRL-REINKE-MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354266": {
        description: null,
        mapicsDescription: "HARNESS-EWP CTRL-REINKE-MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354268": {
        description: null,
        mapicsDescription: "HARNESS-31-SPR CTRL-MSAC KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354269": {
        description: null,
        mapicsDescription: "HARNESS-37-SPR CTRL-MSSAC KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354270": {
        description: null,
        mapicsDescription: "HARNESS-47-SPR CTRL-SAC KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354271": {
        description: null,
        mapicsDescription: "HARNESS-55-SPR CTRL-SSAC KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354273": {
        description: null,
        mapicsDescription: "HARNESS-22-SPR CTRL-MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354274": {
        description: null,
        mapicsDescription: "HARNESS-25-SPR CTRL-MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354275": {
        description: null,
        mapicsDescription: "HARNESS-30-SPR CTRL-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354276": {
        description: null,
        mapicsDescription: "HARNESS-34-SPR CTRL-SSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351998": {
        description: null,
        mapicsDescription: "HARNESS-31-POLY SPR CTRL-MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351999": {
        description: null,
        mapicsDescription: "HARNESS-37-POLY SPR CTRL-MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352000": {
        description: null,
        mapicsDescription: "HARNESS-47-POLY SPR CTRL-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352001": {
        description: null,
        mapicsDescription: "HARNESS-55-POLY SPR CTRL-SSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352002": {
        description: null,
        mapicsDescription: "ADD A SPR CTRL POLY VALVE KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354277": {
        description: null,
        mapicsDescription: "HARNESS-22-SPR CTRL POLY-MSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354279": {
        description: null,
        mapicsDescription: "HARNESS-25-POLY SPR CTRL-MSSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354283": {
        description: null,
        mapicsDescription: "HARNESS-30-POLY CTRL-SAC 114",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354282": {
        description: null,
        mapicsDescription: "HARNESS-30-POLY SPR CTRL-SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354281": {
        description: null,
        mapicsDescription: "HARNESS-34-POLY SPR CTRL-SSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "354280": {
        description: null,
        mapicsDescription: "HARNESS-34-POLY SPR CTRL-SSAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352361": {
        description: null,
        mapicsDescription: "FUSE KIT-6 AMP F/5HP BP-S TWR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353371": {
        description: null,
        mapicsDescription: "BOX-SAC VALVE AIR-ESP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353373": {
        description: null,
        mapicsDescription: "BOX-SAC VALVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353375": {
        description: null,
        mapicsDescription: "BOX-VALVE-SAC ENH WTR PKG+ESP",
        customerReportsDescription: "SAC Enhanced Water Package used w/ESP",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353374": {
        description: null,
        mapicsDescription: "BOX-VALVE-SAC ENH WTR PKG",
        customerReportsDescription: "SAC Enhanced Water Package",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351105": {
        description: null,
        mapicsDescription: "S BOX RPM AIR COMPRESSOR OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353376": {
        description: null,
        mapicsDescription: "BOX-VALVE-VRI SAC KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353509": {
        description: null,
        mapicsDescription: "BOX-SAC VALVE W/OUT ENDGUN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353518": {
        description: null,
        mapicsDescription: "BOX-SAC VALVE ESP ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353530": {
        description: null,
        mapicsDescription: "BOX-SAC VALVE ESAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352851": {
        description: null,
        mapicsDescription: "KIT-SAC HARDWARE SG-ESP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352850": {
        description: null,
        mapicsDescription: "KIT-SAC HARDWARE SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352849": {
        description: null,
        mapicsDescription: "KIT-SAC HARDWARE ESP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352848": {
        description: null,
        mapicsDescription: "KIT-SAC HARDWARE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352854": {
        description: null,
        mapicsDescription: "SAC TWR HARDWARE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352855": {
        description: null,
        mapicsDescription: "SAC TWR HARDWARE ESP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352858": {
        description: null,
        mapicsDescription: "SAC TWR HARDWARE SG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352859": {
        description: null,
        mapicsDescription: "SAC TWR HARDWARE SG ESP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353378": {
        description: null,
        mapicsDescription: "S-BOX GUIDANCE OPTION-BWG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357055": {
        description: null,
        mapicsDescription: "S-BOX GUIDANCE-TRIMBLE L1/L2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351110": {
        description: null,
        mapicsDescription: "S BOX RPM GPS SCRAMBLED NO OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352363": {
        description: null,
        mapicsDescription: "S-BOX OPTION-LEADING ARM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352853": {
        description: null,
        mapicsDescription: "S BOX RPM STEER BOX/HDW LEAD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352852": {
        description: null,
        mapicsDescription: "S BOX RPM STEER/LIMIT BOX/HDW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353239": {
        description: null,
        mapicsDescription: "SAC STEER U-GROUND",
        customerReportsDescription: "Guidance, Swing Arm Corner System, Buried Wire",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357040": {
        description: null,
        mapicsDescription: "SAC STEER GPS ALL SACS EII",
        customerReportsDescription: "Guidance, Swing Arm Corner System, GPS",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357042": {
        description: null,
        mapicsDescription: "SAC GPS ANT OPTION-TRIMBLE",
        customerReportsDescription: "SAC GPS Antennae Option-Trimble",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357002": {
        description: null,
        mapicsDescription: "GPS BASE OPTION",
        customerReportsDescription: "Dual Frequency GPS Base",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355414": {
        description: "RADIO/ANT KIT -S BOX -GPS",
        mapicsDescription: "RADIO/ANT KIT - S BOX - GPS",
        customerReportsDescription: "Radio Antenna Kit for SAC GPS option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355417": {
        description: null,
        mapicsDescription: "RAD/ANT SAC GPS BASE FGR-2",
        customerReportsDescription: "Radio Antenna Kit for SAC GPS Freewave Base option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355418": {
        description: null,
        mapicsDescription: "RADIO KIT-S BOX-GPS FREEWAVE",
        customerReportsDescription: "Radio Kit for S-Box GPS Freewave",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355417NZ": {
        description: null,
        mapicsDescription: "RAD/ANT SAC GPS BASE-NO RADIO",
        customerReportsDescription: "Radio Antenna Kit for SAC GPS (less Radio) option",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355418NZ": {
        description: null,
        mapicsDescription: "RADIO KIT-S BOX-GPS-NO RADIO",
        customerReportsDescription: "Radio Kit for S-Box GPS (less Radio)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351109": {
        description: null,
        mapicsDescription: "S BOX RPM RADIO GPS GUID OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351109A": {
        description: null,
        mapicsDescription: "S BOX RPM RADIO HARDWARE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351109NZ": {
        description: null,
        mapicsDescription: "S BOX RPM NO RADIO GPS GUIDOPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357001": {
        description: null,
        mapicsDescription: "GPS BASE OPTION-RADIO ONLY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "355420": {
        description: null,
        mapicsDescription: "RADIO KIT SAC MINI MM2",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "357020": {
        description: null,
        mapicsDescription: "CABLE 2 PAIR GPS SAC-2000' SPL",
        customerReportsDescription: "Cable w/2 pair for SAC GPS guidance - 2000'",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "353745": {
        description: "SIGN-REINKE 24 \"X 32\"-PVT CTR",
        mapicsDescription: "SIGN-REINKE 24 X 32-PVT CTR",
        customerReportsDescription: "Reinke Sign at Pivot Center",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352251": {
        description: null,
        mapicsDescription: "TOWER AS/AR BARRICADES(1 ASSY)",
        customerReportsDescription: "Barricade, Tower Auto Stop/Auto Reverse, Movable",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352246": {
        description: null,
        mapicsDescription: "TRAILING TIRE PLATE OPT-1 EACH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352163": {
        description: null,
        mapicsDescription: "SLEEVE VALVE-6\"WTR SHUTDN-MAXI",
        customerReportsDescription: "Sleeve Valve, Water Shutdown Safety",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352164": {
        description: null,
        mapicsDescription: "SLEEVE VALVE-6\"WTR SHUTDOWN-KT",
        customerReportsDescription: "Sleeve Valve, Water Shutdown Safety",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352167": {
        description: null,
        mapicsDescription: "VALVE-6\" WTR SHUTDN-MAXI-SG",
        customerReportsDescription: "Sleeve Valve, Water Shutdown Safety",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352165": {
        description: null,
        mapicsDescription: "VALVE-SLEEVE-W/PRESS SW-6\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352168": {
        description: null,
        mapicsDescription: "VALVE-SLEEVE-W/PRESS SW-8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395957": {
        description: null,
        mapicsDescription: "JACK OPT-POWER TOWER-A100 MAXI",
        customerReportsDescription: "Manual Jack Kit-Power Tower-A100 MAXI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355301": {
        description: null,
        mapicsDescription: "MANUAL JACK KIT-COMPLETE-TOWER",
        customerReportsDescription: "Tower Jacks, Manually Operated",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355302": {
        description: null,
        mapicsDescription: "JACK FOOT KIT-SNGL SPAN MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351500": {
        description: null,
        mapicsDescription: "SPARE BOLTS PKG-IRRIG SYSTEMS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351499": {
        description: "SPARE BOLTS 2045 PKG -IRR SYS",
        mapicsDescription: "SPARE BOLTS 2045 PKG - IRR SYS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352114": {
        description: null,
        mapicsDescription: "BARRICADE-SG-A/S-A/R-EII",
        customerReportsDescription: "Barricade, Tower Auto Stop/Auto Reverse, Stationary",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352115": {
        description: null,
        mapicsDescription: "BARRICADE-STANDARD-A/S-A/R-EII",
        customerReportsDescription: "Barricade, Tower Auto Stop/Auto Reverse, Stationary",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "391002": {
        description: null,
        mapicsDescription: "TOW BOX W/HARDWARE-4 X 8 CONN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "391002-R": {
        description: null,
        mapicsDescription: "TOW BOX W/HARDWARE-4 X 8 CONN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392615": {
        description: null,
        mapicsDescription: "10 COND WIRE X 40' W/4X8 PLUG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392615-R": {
        description: null,
        mapicsDescription: "14 COND WIRE X 40' W/4X8 PLUG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351013": {
        description: null,
        mapicsDescription: "GENERATOR ASY-10 KW-LIMA",
        customerReportsDescription: "Generator, Lima, 10 Kw, with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351045": {
        description: "GENERATOR ASY-12 1/2 KW-LIMA",
        mapicsDescription: "GENERATOR ASY-12-1/2 KW-LIMA",
        customerReportsDescription: "Generator, Lima, 12.5 Kw, with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351044": {
        description: null,
        mapicsDescription: "GENERATOR ASY-15 KW-LIMA",
        customerReportsDescription: "Generator, Lima, 15 Kw, with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351058": {
        description: null,
        mapicsDescription: "GENERATOR ASY-20 KW-LIMA",
        customerReportsDescription: "Generator, Lima, 20 Kw, with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351059": {
        description: null,
        mapicsDescription: "GENERATOR ASY-25 KW-LIMA",
        customerReportsDescription: "Generator, Lima, 25 Kw, with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351061": {
        description: null,
        mapicsDescription: "GENERATOR ASY-30 KW-LIMA",
        customerReportsDescription: "Generator, Lima, 30 Kw, with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395990": {
        description: null,
        mapicsDescription: "GEN SET MTG KIT-PIVOT-LATERAL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392896": {
        description: null,
        mapicsDescription: "GENERATOR ASY-10KW-MECC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "392897": {
        description: null,
        mapicsDescription: "GENERATOR ASY-15KW-MECC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352312": {
        description: "KUBOTA V1505, 10KW GEN KT",
        mapicsDescription: "KUBOTA/LIMA 10KW-MURPHY-KT",
        customerReportsDescription: "Kubota V1505 with Generator, Lima, 10 Kw with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353052": {
        description: "KUBOTA-10 KW-18 HP-PIVOT",
        mapicsDescription: "KUBOTA/LIMA 10KW-MURPHY-PIVOT",
        customerReportsDescription: "Kubota Gen Set-10 KW, 18 HP-PIVOT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352322": {
        description: "KUBOTA V1505, 10KW, 17.9 HP, MAXI",
        mapicsDescription: "KUBOTA/LIMA 10KW-MURPHY-MAXI",
        customerReportsDescription: "Kubota V1505 with Generator, Lima, 10 Kw with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352372": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 10KW-MURPHY-4WPM",
        customerReportsDescription: "Kubota V1505 with Generator, Lima10 Kw with fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352312M": {
        description: "KUBOTA 18 HP/MECC ALTE 10K-KT",
        mapicsDescription: "KUBOTA/MECC 10KW-MURPHY-KT",
        customerReportsDescription: "Kubota Gen Set 18 HP/MECC ALTE 10K-KT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353052M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 10KW-MURPHY-PIVOT",
        customerReportsDescription: "Kubota Gen Set 18 HP/MECC ALTE 10KW-PIVOT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352322M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 10KW-MURPHY-MAXI",
        customerReportsDescription: "Kubota Gen Set 18 HP/MECC ALTE 10KW-MAXI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352372M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 10KW-MURPHY-4WPM",
        customerReportsDescription: "Kubota Gen Set 18 HP/MECC ALTE 10KW-4WPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352314": {
        description: "KUBOTA V2203, 15 KW,27 HP, KT",
        mapicsDescription: "KUBOTA/LIMA 15KW-MURPHY-KT",
        customerReportsDescription: "Kubota V2203 with Generator, Lima, 15 Kw with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352394": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-KT",
        customerReportsDescription: "Kubota Gen Set-15KW (27 HP) W/DSE-KT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353054": {
        description: "KUBOTA-15 KW-27 HP-PIVOT",
        mapicsDescription: "KUBOTA/LIMA 15 KW-MURPHY-PIVOT",
        customerReportsDescription: "Kubota Gen Set-15 KW, 27 HP-PIVOT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353064": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 15KW-DSE-PIVOT",
        customerReportsDescription: "Kubota Gen Set 15KW (27HP) W/DSE-PIVOT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352324": {
        description: "KUBOTA V2203, 15 KW, 27 HP, MAXI",
        mapicsDescription: "KUBOTA/LIMA 15KW-MURPHY-MAXI",
        customerReportsDescription: "Kubota V2203 with Generator, Lima, 15 Kw with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352404": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-MAXI",
        customerReportsDescription: "Kubota, 27 HP w/Fused 15 Kw Lima Mac Generator & Digital Engine Control Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352374": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 15KW-MURPHY-4WPM",
        customerReportsDescription: "Kubota V2203 with Generator, Lima 15 Kw with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352414": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-4WPM",
        customerReportsDescription: "Kubota Gen Set 15KW (27HP) W/DSE-4WPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352314M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 15KW-MURPHY-KT",
        customerReportsDescription: "Kubota Gen Set 27 HP/MECC ALTE 15KW-KT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352394M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 15KW-DSE-KT",
        customerReportsDescription: "Kubota Gen Set 27HP/MECC ALTE 15KW-DSE-KT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353054M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-25KW-MURPHY-PIVOT",
        customerReportsDescription: "Kubota Gen Set 27 HP/MECC ALTE 15KW-PIVOT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353064M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 15KW-DSE-PIVOT",
        customerReportsDescription: "Kubota Gen Set 27HP/MECC ALTE 15KW-DSE-PVT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352324M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 15KW-MURPHY-MAXI",
        customerReportsDescription: "Kubota Gen Set 27 HP/MECC ALTE 15 KW-MAXI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352404M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 15KW-DSE-MAXI",
        customerReportsDescription: "Kubota Gen Set 27HP/MECC ALTE 15KW-DSE-MAX",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352374M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 15KW-MURPHY-4WPM",
        customerReportsDescription: "Kubota Gen Set 27 HP/MECC ALTE 15KW-4WPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352414M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 15KW-DSE-4WPM",
        customerReportsDescription: "Kubota Gen Set 27HP/MECC ALTE 15KW-DSE-4WPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353056": {
        description: "KUBOTA-25 KW-40 HP-PIVOT",
        mapicsDescription: "KUBOTA/LIMA 25 KW-MURPHY-PVT",
        customerReportsDescription: "Kubota Gen Set-25 KW, 40 HP-PIVOT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353066": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-25KW-DSE-PIVOT",
        customerReportsDescription: "Kubota Gen Set 25KW (40HP) W/DSE-PIVOT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352326": {
        description: "KUBOTA V3300, 25 KW, 40 HP, MAXI",
        mapicsDescription: "KUBOTA/LIMA 25KW-MURPHY-MAXI",
        customerReportsDescription: "Kubota V3300 with Generator, Lima, 25 Kw with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352406": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-25KW-DSE-MAXI",
        customerReportsDescription: "Kubota, 40 HP w/Fused 25 Kw Lima Mac Generator & Digital Engine Control Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352376": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 25KW-MURPHY-4WPM",
        customerReportsDescription: "Kubota V3300 with Generator, Lima 25 Kw with Fused Panel",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352416": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-25KW-DSE-4WPM",
        customerReportsDescription: "Kubota Gen Set 25KW (40HP) W/DSE-4WPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352310M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 5KW-MURPHY-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353056M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-25KW-MURPHY-PIVOT",
        customerReportsDescription: "Kubota Gen Set 40 HP/MECC ALTE 25KW-PIVOT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353066M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-25KW-DSE-PIVOT",
        customerReportsDescription: "Kubota Gen Set 40HP/MECC ALTE 25KW-DSE-PVT",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352326M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 25KW-MURPHY-MAXI",
        customerReportsDescription: "Kubota Gen Set 40 HP/MECC ALTE 25 KW-MAXI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352406M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-25KW-DSE-MAXI",
        customerReportsDescription: "Kubota Gen Set 40HP/MECC ALTE 25KW-DSE-MAX",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352376M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-25KW-MURPHY-4WPM",
        customerReportsDescription: "Kubota Gen Set 40 HP/MECC ALTE 25KW-4WPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352416M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-25KW-DSE-4WPM",
        customerReportsDescription: "Kubota Gen Set 40HP/MECC ALTE 25KW-DSE-4WPM",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352311M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 7.5KW-MURPHY-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352320M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 5KW-MURPHY-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352321M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 7.5KW-MURPHY-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352370M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 5KW-MURPHY-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353050M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 5KW-MURPHY-PIVOT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353051M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 7.5KW-MURPHY-PIVOT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352327M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-30KW-MURPHY-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352377M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-30KW-MURPHY-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353058M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-30KW-MURPHY-PIV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352315M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-5KW-DSE-OPEN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352316M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-7.5KW-DSE-OPEN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352317": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-10KW-DSE-OPEN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352317M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-10KW-DSE-OPEN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352327": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-30KW-MURPHY-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352328M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 5KW-DSE-OPEN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352329M": {
        description: null,
        mapicsDescription: "KUBOTA/MEC 7.5KW-DSE-OPEN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352330": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 10KW-DSE-OPEN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352330M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 10KW-DSE-OPEN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352331": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-10KW-DSE-ENCL-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352331M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-10KW-DSE-ENCL-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352371M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 7.5KW-MURPHY-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352377": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-30KW-MURPHY-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352378M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 5KW-DSE-OPEN-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352379M": {
        description: null,
        mapicsDescription: "KUBOTA/MEC 7.5KW-DSE-OPEN-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352395M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-5KW-DSE-ENCL-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352396M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-7.5KW-DSE-ENCL-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352397": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-10KW-DSE-ENCL-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352397M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-10KW-DSE-ENCL-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352408M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-5KW-DSE-ENCL-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352409M": {
        description: null,
        mapicsDescription: "KUBOTA/MEC-7.5KW-DSE-ENCL-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352417": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 10KW-DSE-OPEN-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352417M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 10KW-DSE-OPEN-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352418M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-5KW-DSE-ENCL-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352419M": {
        description: null,
        mapicsDescription: "KUBOTA/MEC-7.5KW-DSE-ENCL-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352420": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-10KW-DSE-ENCL-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352420M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-10KW-DSE-ENCL-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353057M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-5KW-DSE-OPEN-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353058": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-30KW-MURPHY-PIVOT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353059M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-5KW-DSE-ENCL-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353067M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 7.5KW-DSE-OPEN-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353068M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-7.5KW-DSE-ENCL-PIV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353069": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 10KW-DSE-OPEN-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353069M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 10KW-DSE-OPEN-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353070": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-18KW-DSE-OPEN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353071": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-18KW-DSE-ENCL-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353072": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-27KW-DSE-OPEN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353073": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-27KW-DSE-ENCL-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353074": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-18KW-DSE-OPEN-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353075": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-18KW-DSE-OPEN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353076": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-18KW-DSE-OPEN-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353077": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-18KW-DSE-ENCL-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353078": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-18KW-DSE-ENCL-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353079": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-18KW-DSE-ENCL-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353080": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-10KW-DSE-ENCL-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353080M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-10KW-DSE-ENCL-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353081": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-27KW-DSE-OPEN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353082": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-27KW-DSE-OPEN-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353083": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-27KW-DSE-OPEN-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353084": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-27KW-DSE-ENCL-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353085": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-27KW-DSE-ENCL-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353086": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-27KW-DSE-ENCL-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353087": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-25KW-DSE-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353087M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-25KW-DSE-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353088": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 25KW-MURPHY-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353088M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC 25KW-MURPHY-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353089": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 30KW-DSE-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353089M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-30KW-DSE-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353090": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-30KW-MURPHY-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353090M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-30KW-MURPHY-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353091": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 30KW-DSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353091M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-30KW-DSE-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353092": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA 30KW-DSE-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353092M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-30KW-DSE-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353093": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-30KW-DSE-PIVOT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353093M": {
        description: null,
        mapicsDescription: "KUBOTA/MECC-30KW-DSE-PIV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353032": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-OPEN-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353036": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-ENCL-KT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353033": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-OPEN-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353037": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-ENCL-MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353034": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-OPEN-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353038": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-ENCL-4WPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353035": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-OPEN-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353039": {
        description: null,
        mapicsDescription: "KUBOTA/LIMA-15KW-DSE-ENCL-PVT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351030": {
        description: null,
        mapicsDescription: "GENERATOR SLIDE RAIL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352157": {
        description: "FUEL TANK ASY-80 GALLON-KWIK TOW",
        mapicsDescription: "FUEL TANK ASY-80 GAL-KWIK TOW",
        customerReportsDescription: "Fuel Tank, 80 Gallon",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352206": {
        description: "FUEL TANK ASY-80 GALLON-MAXI",
        mapicsDescription: "FUEL TANK ASY-80 GAL-MAXI",
        customerReportsDescription: "Fuel Tank, 80 Gallon",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352161": {
        description: "FUEL TANK ASY-80 GALLON-4WD MAXI",
        mapicsDescription: "FUEL TANK ASY-80 GAL-4WD MAXI",
        customerReportsDescription: "Fuel Tank, 80 Gallon",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396017": {
        description: null,
        mapicsDescription: "MTG KIT-CHEM TANK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352508": {
        description: null,
        mapicsDescription: "PIVOT LIGHT ASSY-ELECTRO/ALUMI",
        customerReportsDescription: "Light Assembly, Pivot Center, Standard",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352561": {
        description: null,
        mapicsDescription: "PIVOT LIGHT ASY-KWIK TOW&MAXI",
        customerReportsDescription: "Light Assembly, Pivot Center, Standard",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352509": {
        description: null,
        mapicsDescription: "STROBE LIGHT ASY-PIVOT-EA",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352558": {
        description: null,
        mapicsDescription: "STROBE LIGHT ASY-PIVOT-KT&MAXI",
        customerReportsDescription: "Light Assembly, Pivot Center, Strobe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354555": {
        description: "VALVE-FLUSH-3'-61'-END BOOM",
        mapicsDescription: "VALVE-FLUSH-3'~61'-END BOOM",
        customerReportsDescription: "Flush Valve, End Boom",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354556": {
        description: "VALVE-FLUSH-85'/105'-END BOOM",
        mapicsDescription: "VALVE-FLUSH-85'~105'-END BOOM",
        customerReportsDescription: "Flush Valve, End Boom",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352798": {
        description: null,
        mapicsDescription: "INSTALL-DOC WARR US/CANADA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352798SS": {
        description: null,
        mapicsDescription: "INSTALL-DOC WAR STNLS US/CAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352798-I": {
        description: null,
        mapicsDescription: "INSTALL-DOC WAR INTERNAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352799": {
        description: null,
        mapicsDescription: "PACKET WARR SAC RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352798-A": {
        description: null,
        mapicsDescription: "INSTALL-DOC WAR ALUM US/CAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352798PL": {
        description: null,
        mapicsDescription: "INSTALL-DOC WAR POLY US/CAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352798PLI": {
        description: null,
        mapicsDescription: "INSTALL-DOC WAR POLY INT",
        customerReportsDescription: "Installation Warranty Label, Poly-International",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "392819": {
        description: null,
        mapicsDescription: "DRAIN VALVE OPT-CANAL FEED",
        customerReportsDescription: "Drain Valve Option-Canal Feed",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "396018": {
        description: null,
        mapicsDescription: "AUX STEP-FUEL TANK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395991": {
        description: null,
        mapicsDescription: "FUEL TANK MTG KIT-80G-PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395999": {
        description: null,
        mapicsDescription: "BARRICADE OPT-PROX SWITCH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351781": {
        description: "3/4\" X 48\" SCH 40 DROP W/U-PIPE",
        mapicsDescription: "3/4X48 SCH 40 DROP W/U-PIPE",
        customerReportsDescription: "Drops, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351782": {
        description: "3/4\" X 54\" SCH 40 DROP W/U-PIPE",
        mapicsDescription: "3/4X54 SCH 40 DROP W/U-PIPE",
        customerReportsDescription: "Drops, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351783": {
        description: "3/4\" X 60\" SCH 40 DROP W/U-PIPE",
        mapicsDescription: "3/4X60 SCH 40 DROP W/U-PIPE",
        customerReportsDescription: "Drops, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351778": {
        description: "3/4\" X 66\" SCH 40 DROP W/U-PIPE",
        mapicsDescription: "3/4 X 66 SCH 40 DROP W/U-PIPE",
        customerReportsDescription: "Drops, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351779": {
        description: "3/4\" X 72\" SCH 40 DROP W/U-PIPE",
        mapicsDescription: "3/4X72 SCH 40 DROP W/U-PIPE",
        customerReportsDescription: "Drops, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351780": {
        description: "3/4\" X 78\" SCH 40 DROP W/U-PIPE",
        mapicsDescription: "3/4X78 SCH 40 DROP W/U-PIPE",
        customerReportsDescription: "Drops, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351784": {
        description: "3/4\" X 84\" SCH 40 DROP W/U-PIPE",
        mapicsDescription: "3/4 X 84 SCH 40 DROP W/U-PIPE",
        customerReportsDescription: "Drops, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351851": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-42\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-42",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351852": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-48\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-48",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351853": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-54\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-54",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351854": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-60\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-60",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351855": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-66\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-66",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351856": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-72\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-72",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351857": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-78\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-78",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351858": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-84\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-84",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351859": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-90\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-90",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351860": {
        description: "DROP OPT-SCH80 PVC-WO/GOOSE-96\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GOOSE-96",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351861": {
        description: "DROP OPT-SCH80 PVC-WO/GSE-102\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GSE-102",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351862": {
        description: "DROP OPT-SCH80 PVC-WO/GSE-108\"",
        mapicsDescription: "DROP OPT-SCH80 PVC-WO/GSE-108",
        customerReportsDescription: "Drops, PVC, Schedule 80",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351870": {
        description: "DRP-3/4\" X 36\" BLK PLAS. WO/U-PIPE",
        mapicsDescription: "DRP-3/4X36 BLK PLAS. WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351871": {
        description: "DRP-3/4\" X 42\" BLK PLAS. WO/U-PIPE",
        mapicsDescription: "DRP-3/4X42 BLK PLAS. WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351872": {
        description: "DRP-3/4\" X 48\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X48 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351873": {
        description: "DRP-3/4\" X 54\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X54 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351874": {
        description: "DRP-3/4\" X 60\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X60 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351875": {
        description: "DRP-3/4\" X 66\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X66 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351876": {
        description: "DRP-3/4\" X 72\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X72 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351877": {
        description: "DRP-3/4\" X 78\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X78 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351878": {
        description: "DRP-3/4\" X 84\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X84 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351879": {
        description: "DRP-3/4\" X 90\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X90 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351880": {
        description: "DRP-3/4\" X 96\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X96 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351881": {
        description: "DRP-3/4\" X 102\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X102 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351882": {
        description: "DRP-3/4\" X 108\" BLK PLAS WO/U-PIPE",
        mapicsDescription: "DRP-3/4X108 BLK PLAS WO/U-PIPE",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351892": {
        description: "DROP-3/4\" X 48\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP-3/4X48TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351893": {
        description: "DROP-3/4\" X 54\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP-3/4X54TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351894": {
        description: "DROP-3/4\" X 60\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP-3/4X60TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351895": {
        description: "DROP-3/4\" X 66\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP-3/4X66TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351896": {
        description: "DROP-3/4\" X 72\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP-3/4X72TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351897": {
        description: "DROP-3/4\" X 78\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP-3/4X78TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351898": {
        description: "DROP-3/4\" X 84\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP-3/4X84TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351899": {
        description: "DROP-3/4\" X 90\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP-3/4X90TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351900": {
        description: "DROP-3/4\" X 96\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP-3/4X96TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351901": {
        description: "DROP 3/4\" X 102\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X102TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351902": {
        description: "DROP 3/4\" X 108\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X108TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351903": {
        description: "DROP 3/4\" X 114\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X114TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351904": {
        description: "DROP 3/4\" X 120\" TUBE/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X120TUBE/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351905": {
        description: null,
        mapicsDescription: "DROP-3/4\" X 126\" MXM TUBE/HOSE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351906": {
        description: "DROP-3/4\" X132\" M X M TUBE/HOSE",
        mapicsDescription: "DROP-3/4\"X132\" M X M TUBE/HOSE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351907": {
        description: "DROP-3/4\" X138\" M X M TUBE/HOSE",
        mapicsDescription: "DROP-3/4\"X138\" M X M TUBE/HOSE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351908": {
        description: "DROP-3/4\" X144\" M X M TUBE/HOSE",
        mapicsDescription: "DROP-3/4\"X144\" M X M TUBE/HOSE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351909": {
        description: "DROP-3/4\" X150\" M X M TUBE/HOSE",
        mapicsDescription: "DROP-3/4\"X150\" M X M TUBE/HOSE",
        customerReportsDescription: "Drops, Combo, Tube with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351883": {
        description: "DRP-3/4\" X114\" BLK PLAS WO/U-PP",
        mapicsDescription: "DRP-3/4X114\" BLK PLAS WO/U-PP",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351884": {
        description: "DRP-3/4\" X120\" BLK PLAS WO/U-PP",
        mapicsDescription: "DRP-3/4X120\" BLK PLAS WO/U-PP",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351885": {
        description: "DROP-3/4\" X 126\" M X M BLK PLAS",
        mapicsDescription: "DROP-3/4 X 126\" M X M BLK PLAS",
        customerReportsDescription: "Drops, Black Polyethylene Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352004": {
        description: "3/4\" X 90\" SCH 40 DROP W/U-PIPE",
        mapicsDescription: "3/4 X 90 SCH 40 DROP W/U-PIPE",
        customerReportsDescription: "Drops, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352005": {
        description: "3/4\" X 96\" SCH 40 DROP W/U-PIPE",
        mapicsDescription: "3/4 X 96 SCH 40 DROP W/U-PIPE",
        customerReportsDescription: "Drops, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351600": {
        description: "DROP 3/4\" X 42\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X42 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351601": {
        description: "DROP 3/4\" X 48\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X48 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351602": {
        description: "DROP 3/4\" X 54\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X54 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351603": {
        description: "DROP 3/4\" X 60\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X60 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351604": {
        description: "DROP 3/4\" X 66\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X66 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351605": {
        description: "DROP 3/4\" X 72\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X72 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351606": {
        description: "DROP 3/4\" X 78\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X78 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351607": {
        description: "DROP 3/4\" X 84\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X84 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351608": {
        description: "DROP 3/4\" X 90\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X90 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351609": {
        description: "DROP 3/4\" X 96\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP 3/4X96 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351610": {
        description: "DROP 3/4\" X 102\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X102 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351611": {
        description: "DROP 3/4\" X 108\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X108 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351612": {
        description: "DROP 3/4\" X 114\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X114 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351613": {
        description: "DROP 3/4\" X 120'\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X120 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351614": {
        description: "DROP 3/4\" X 126\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X126 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351615": {
        description: "DROP 3/4\" X 132\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X132 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351616": {
        description: "DROP 3/4\" X 138\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X138 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351617": {
        description: "DROP 3/4\" X 144\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X144 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351618": {
        description: "DROP 3/4\" X 150\" PVC/HOSE W/O UPIPE",
        mapicsDescription: "DROP3/4X150 PVC/HOSE W/O UPIPE",
        customerReportsDescription: "Drops, Combo, PVC with Hose",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351949": {
        description: null,
        mapicsDescription: "DROPS-RIGID HOSE 84\" 3\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351950": {
        description: null,
        mapicsDescription: "DROPS-RIGID HOSE 84\" 4-1/2\" IA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351951": {
        description: null,
        mapicsDescription: "DROPS-RIGID HOSE 84\" 6\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351952": {
        description: null,
        mapicsDescription: "DROP-RIGID HOSE 84\" 6-5/8\" IAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351953": {
        description: null,
        mapicsDescription: "DROPS-RIGID HOSE 84\" 8\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351954": {
        description: null,
        mapicsDescription: "DROPS-RIGID HOSE 84\" 8-5/8\" IA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351955": {
        description: null,
        mapicsDescription: "DROPS-RIGID HOSE 84\" 10\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351810": {
        description: null,
        mapicsDescription: "3/4\" SCH 80PVC GOOSENECK MXF",
        customerReportsDescription: "Hose Drops, Flexible with Fittings",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351786": {
        description: "U PIPE-3/4\" SCH 40 GALV-M X F",
        mapicsDescription: "U PIPE-3/4 SCH 40 GALV-M X F",
        customerReportsDescription: "Hose Drops, Flexible with Fittings",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "391610": {
        description: null,
        mapicsDescription: "GOOSENECK-3/4 MXM-SGL 180-SEN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "391611": {
        description: null,
        mapicsDescription: "GOOSENECK-3/4 MXM-SGL 180-NEL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356893": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT/COUPLER 3\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356894": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT/COUPLER 4-1/2\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356895": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT/COUPLER 6\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356896": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT/COUPLER 6-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356897": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT/COUPLER 8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356898": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT/COUPLER 8-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356899": {
        description: null,
        mapicsDescription: "DROP CLAMP KIT/COUPLER 10\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356863": {
        description: null,
        mapicsDescription: "CLAMP KIT W/O U-PIPE GALV 3\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356865": {
        description: null,
        mapicsDescription: "CLAMP KIT W/O U-PIPE 4 1/2\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356867": {
        description: null,
        mapicsDescription: "CLAMP KIT W/O U-PIPE 6\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356869": {
        description: null,
        mapicsDescription: "	CLAMP KIT W/O U-PIPE 6-5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356871": {
        description: null,
        mapicsDescription: "CLAMP KIT W/O U-PIPE 8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356873": {
        description: null,
        mapicsDescription: "CLAMP KIT W/O U-PIPE 8 5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "356875": {
        description: null,
        mapicsDescription: "CLAMP KIT W/O U-PIPE 10\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351830": {
        description: "3/4\" PLASTIC COUPLING-GRAY",
        mapicsDescription: "3/4-INCH PLASTIC COUPLING-GRAY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351823": {
        description: "3/4\" PLASTIC COUPLING-BLK",
        mapicsDescription: "3/4-INCH PLASTIC COUPLING-BLK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351835": {
        description: "3/4\" COUPLING-GALV",
        mapicsDescription: "3/4-INCH COUPLING-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351828": {
        description: "REINF CLAMP FOR 7' GALV DROP",
        mapicsDescription: "REINF CLAMP-7FT DROP 6/6-5/8",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351867": {
        description: null,
        mapicsDescription: "REINF CLAMP-7FT GALV DROP 3\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351868": {
        description: null,
        mapicsDescription: "REINF CLAMP-7FT GALV DROP 4-1/2\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351785": {
        description: "U PIPE-3/4\" SCH 40 GALV-M X M",
        mapicsDescription: "U PIPE-3/4 SCH 40 GALV-M X M",
        customerReportsDescription: "Hose Drops, Flexible with Fittings",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351791": {
        description: "U PIPE-3/4\" SCH 40 GALV-M X HB",
        mapicsDescription: "U PIPE-3/4 SCH 40 GALV-M X HB",
        customerReportsDescription: "Hose Drops, Flexible with Fittings",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351809": {
        description: "12-FURROW ARM-3/4\" MXM ALUM",
        mapicsDescription: "12-FURROW ARM-3/4 MXM ALUM",
        customerReportsDescription: "Hose Drops, Flexible with Fittings",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351792": {
        description: "12\" FURROW ARM-3/4\" SCH40G-MXHB",
        mapicsDescription: "12\" FURROW ARM-3/4 SCH40G-MXHB",
        customerReportsDescription: "Hose Drops, Flexible with Fittings",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351793": {
        description: "20\" FURROW ARM-3/4\" SCH40G-MXHB",
        mapicsDescription: "20\" FURROW ARM-3/4 SCH40G-MXHB",
        customerReportsDescription: "Hose Drops, Flexible with Fittings",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351838": {
        description: null,
        mapicsDescription: "GOOSENECK SCH80 M X HB PVC",
        customerReportsDescription: "Hose Drops, Flexible with Fittings",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351811": {
        description: "GOOSENECK-3/4\" MXM 0.154W PVC",
        mapicsDescription: "GOOSENECK-3/4 MXM 0.154W PVC",
        customerReportsDescription: "Hose Drops, Flexible with Fittings",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351812": {
        description: null,
        mapicsDescription: "FURROW ARM-12 MXHB 0.154W PVC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "391609": {
        description: null,
        mapicsDescription: "GOOSENECK-3/4MNPTX3/4B-SGL-180",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "391608": {
        description: null,
        mapicsDescription: "GOOSENCK3/4MNPTX3/4MNPT-SGL125",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "391607": {
        description: null,
        mapicsDescription: "GOOSENECK-3/4MNPTX3/4B-SGL-125",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351913": {
        description: null,
        mapicsDescription: "GOOSENCK3/4MNPTX3/4MNPT-DBL125",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351912": {
        description: null,
        mapicsDescription: "GOOSENCK3/4MNPT X 3/4B-DBL125",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "391612": {
        description: null,
        mapicsDescription: "GOOSENCK 3/4MNPT X BARB-SNG180",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351799": {
        description: null,
        mapicsDescription: "ADAPTER-FEM X HOSE BARB-BLACK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351797": {
        description: null,
        mapicsDescription: "ADAPTER-MALE X HOSE BARB-GRAY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351798": {
        description: null,
        mapicsDescription: "ADAPTER-MALE X HOSE BARB-BLACK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351824": {
        description: "3/4\" X CLOSE NIPPLE-PLSTC",
        mapicsDescription: "RISER 3/4 X CLOSE MXM PVC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351836": {
        description: "3/4\" CLOSE NIPPLE-BLK PLAS",
        mapicsDescription: "3/4-INCH CLOSE NIPPLE-BLK PLAS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351834": {
        description: "3/4\" CLOSE NIPPLE-GALV",
        mapicsDescription: "3/4-INCH CLOSE NIPPLE-GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351847": {
        description: null,
        mapicsDescription: "ADAPTER M X HB-NELSON",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351848": {
        description: null,
        mapicsDescription: "ADAPTER F X HB-NELSON",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358650": {
        description: null,
        mapicsDescription: "NIPPLE PIPE 3/4X2 KPT KOMET X1",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351794": {
        description: "18\" DROP-3/4\" SCH 40 GALV-M X M",
        mapicsDescription: "18\" DROP-3/4 SCH 40 GALV-M X M",
        customerReportsDescription: "Weight, Hose Drop, Galvanized, 18\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351794SS": {
        description: null,
        mapicsDescription: "18\" DROP-3/4 SCH 40 SS-M X M",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351820": {
        description: null,
        mapicsDescription: "3/4-INCH CLOSE NIPPLE-NELSON",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352300": {
        description: null,
        mapicsDescription: "RISER 3/4 X 2-1/2 MXM GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352300SS": {
        description: null,
        mapicsDescription: "RISER 3/4 X 2-1/2 MXM SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352305": {
        description: null,
        mapicsDescription: "RISER 3/4 X 10 MXM GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352305SS": {
        description: null,
        mapicsDescription: "RISER 3/4 X 10 MXM SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352304": {
        description: null,
        mapicsDescription: "RISER 3/4 X 18 MXF GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352304SS": {
        description: null,
        mapicsDescription: "RISER 3/4 X 18 MXF SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352301": {
        description: null,
        mapicsDescription: "RISER 3/4 X 4 MXM GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352301SS": {
        description: null,
        mapicsDescription: "RISER 3/4 X 4 MXM SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358319": {
        description: "RISER 3/4\" X 3 1/2\" MXF",
        mapicsDescription: "RISER 3/4\" X 3-1/2\" MXF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "358319SS": {
        description: null,
        mapicsDescription: "RISER 3/4\" X 3-1/2\" MXF SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352303": {
        description: null,
        mapicsDescription: "RISER 3/4 X 10 MXF GALV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352303SS": {
        description: null,
        mapicsDescription: "RISER 3/4 X 10 MXF SS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351826": {
        description: null,
        mapicsDescription: "SCREW CLAMP FOR 3/4\" IPS HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351825": {
        description: null,
        mapicsDescription: "PINCH CLAMP FOR 3/4\" IPS HOSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351925": {
        description: null,
        mapicsDescription: "PINCH CLAMP FOR 3/4\" SURELINE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351926": {
        description: null,
        mapicsDescription: "SCREW CLAMP FOR 3/4\" SURELINE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351789": {
        description: "18\" DROP-3/4\" SCH 40 GALV-M XHB",
        mapicsDescription: "18\" DROP-3/4 SCH 40 GALV-M XHB",
        customerReportsDescription: "Weight, Hose Drop, Galvanized, 18\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351790": {
        description: "24\" DROP-3/4\" SCH 40 GALV-M XHB",
        mapicsDescription: "24\" DROP-3/4 SCH 40 GALV-M XHB",
        customerReportsDescription: "Weight, Hose Drop, Galvanized, 24\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350185": {
        description: "WEIGHT-BLK PLAS-2 lb-SLIP-9102C",
        mapicsDescription: "WEIGHT-BLK PLAS-2#-SLIP-9102C",
        customerReportsDescription: "Weight 2 lb Slip Black",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350186": {
        description: "WEIGHT-BLK PLAS-2 LB-FXF-9102CT",
        mapicsDescription: "WEIGHT-BLK PLAS-2#-FXF-9102CT",
        customerReportsDescription: "Weight 2 LB FxF Thrd Black",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351596": {
        description: null,
        mapicsDescription: "WEIGHT-COIL 1.25 LB NELSON",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351598": {
        description: null,
        mapicsDescription: "WEIGHT-COIL 1 LB NELSON",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351595": {
        description: null,
        mapicsDescription: "WEIGHT-DROP .85 LB NEL SLIM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351808": {
        description: null,
        mapicsDescription: "ADAPTER-HOSE DRAG-HD3000",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351806": {
        description: null,
        mapicsDescription: "ADAPTER LDN X HB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351805": {
        description: null,
        mapicsDescription: "ADAPTER SUPER SPRAY X HB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352185": {
        description: null,
        mapicsDescription: "DRAG SOCK",
        customerReportsDescription: "Drag Sock, Hose Drop",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351776B": {
        description: "HOSE-3/4\" PVC IPS 250' BLUE",
        mapicsDescription: "HOSE-3/4 PVC IPS 250' BLUE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351776": {
        description: null,
        mapicsDescription: "HOSE-3/4 PVC IPS 250'BLK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351738B": {
        description: null,
        mapicsDescription: "HOSE-3/4 SURELINE 250' BLUE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3501195": {
        description: null,
        mapicsDescription: "SENN ONE WEIGHT",
        customerReportsDescription: "Weight, Hose Drop, Senninger, Universal",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351590": {
        description: null,
        mapicsDescription: "WEIGHT-DROP 1 LB NELSON",
        customerReportsDescription: "Weight, Hose Drop, Nelson, 1 LB",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351591": {
        description: null,
        mapicsDescription: "WEIGHT-3/4# LDN SENN #LDNWGT3",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351592": {
        description: null,
        mapicsDescription: "WEIGHT-1# LDN SENN #LDNWGT4",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351593": {
        description: null,
        mapicsDescription: "MAG WEIGHT SLIP SENN MAGWGTSLP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351594": {
        description: null,
        mapicsDescription: "MAG WEIGHT THRD SENN MAGWGTBRB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351597": {
        description: null,
        mapicsDescription: "UNIV MAG WEIGHT SENN UNIMAGWGT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519405": {
        description: "SENNINGER 25 PSI MF REG",
        mapicsDescription: "SENNINGER 25# MF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 25 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519406": {
        description: "SENNINGER 30 PSI MF REG",
        mapicsDescription: "SENNINGER 30# MF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 30 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519407": {
        description: "SENNINGER 40 PSI MF REG",
        mapicsDescription: "SENNINGER 40# MF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 40 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519381": {
        description: "NELSON BLUE 6 PSI LFST REG",
        mapicsDescription: "NELSON BLUE 6# LFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 6 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519382": {
        description: "NELSON BLUE 10 PSI LFST REG",
        mapicsDescription: "NELSON BLUE 10# LFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 10 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519383": {
        description: "NELSON BLUE 15 PSI LFST REG",
        mapicsDescription: "NELSON BLUE 15# LFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 15 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519384": {
        description: "NELSON BLUE 20 PSI LFST REG",
        mapicsDescription: "NELSON BLUE 20# LFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 20 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519385": {
        description: "NELSON BLUE 25 PSI LFST REG",
        mapicsDescription: "NELSON BLUE 25# LFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 25 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519369": {
        description: "NELSON REG 30 PSI LF BLUE FXST 3/4\"",
        mapicsDescription: "NELSON REG 30#LF BLUE FXST 3/4",
        customerReportsDescription: "Nelson Pressure Regulator, 30 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519351": {
        description: "NELSON BLUE 6 PSI HFST REG",
        mapicsDescription: "NELSON BLUE 6# HFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 6 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519352": {
        description: "NELSON BLUE 10 PSI HFST REG",
        mapicsDescription: "NELSON BLUE 10# HFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 10 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519353": {
        description: "NELSON BLUE 15 PSI HFST REG",
        mapicsDescription: "NELSON BLUE 15 PSI HFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 15 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519354": {
        description: "NELSON BLUE 20 PSI HFST REG",
        mapicsDescription: "NELSON BLUE 20# HFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 20 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519355": {
        description: "NELSON BLUE 25 PSI HFST REG",
        mapicsDescription: "NELSON BLUE 25# HFST REG",
        customerReportsDescription: "Pressure Regulators, Nelson 25 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519370": {
        description: "NELSON REG 30 PSI HF BLUE FXST 3/4\"",
        mapicsDescription: "NELSON REG 30#HF BLUE FXST 3/4",
        customerReportsDescription: "Nelson Pressure Regulator, 30 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519371": {
        description: "NELSON BLUE 6 PSI LF REG",
        mapicsDescription: "NELSON BLUE 6# LF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 6 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519372": {
        description: "NELSON BLUE 10 PSI LF REG",
        mapicsDescription: "NELSON BLUE 10# LF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 10 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519340": {
        description: "NELSON ALLFLO 10 PSI FXF REG",
        mapicsDescription: "NELSON ALLFLO 10# FXF",
        customerReportsDescription: "Pressure Regulators, Nelson 10 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519341": {
        description: "NELSON ALLFLO 15 PSI FXF REG",
        mapicsDescription: "NELSON ALLFLO 15# FXF",
        customerReportsDescription: "Pressure Regulators, Nelson 15 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519373": {
        description: "NELSON BLUE 15 PSI LF REG",
        mapicsDescription: "NELSON BLUE 15# LF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 15 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519374": {
        description: "NELSON BLUE 20 PSI LF REG",
        mapicsDescription: "NELSON BLUE 20# LF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 20 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519375": {
        description: "NELSON BLUE 25 PSI LF REG",
        mapicsDescription: "NELSON BLUE 25# LF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 25 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519376": {
        description: "NELSON BLUE 30 PSI LF REG",
        mapicsDescription: "NELSON BLUE 30# LF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 30 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519361": {
        description: "NELSON BLUE 6 PSI HF REG",
        mapicsDescription: "NELSON BLUE 6 PSI HF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 6 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519362": {
        description: "NELSON BLUE 10 PSI HF REG",
        mapicsDescription: "NELSON BLUE 10# HF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 10 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519363": {
        description: "NELSON BLUE 15 PSI HF REG",
        mapicsDescription: "NELSON BLUE 15# HF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 15 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519364": {
        description: "NELSON BLUE 20 PSI HF REG",
        mapicsDescription: "NELSON BLUE 20# HF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 20 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519365": {
        description: "NELSON BLUE 25 PSI HF REG",
        mapicsDescription: "NELSON BLUE 25# HF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 25 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519366": {
        description: "NELSON BLUE 30 PSI HF REG",
        mapicsDescription: "NELSON BLUE 30# HF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 30 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519367": {
        description: "NELSON BLUE 40 PSI HF REG",
        mapicsDescription: "NELSON BLUE 40# HF REG",
        customerReportsDescription: "Pressure Regulators, Nelson 40 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519391": {
        description: "SENNINGER 6 PSI LF REG",
        mapicsDescription: "SENNINGER 6# LF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 6 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519392": {
        description: "SENNINGER 10 PSI LF REG",
        mapicsDescription: "SENNINGER 10# LF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 10 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519393": {
        description: "SENNINGER 15 PSI LF REG",
        mapicsDescription: "SENNINGER 15# LF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 15 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519394": {
        description: "SENNINGER 20 PSI LF REG",
        mapicsDescription: "SENNINGER 20# LF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 20 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519401": {
        description: "SENNINGER 6 PSI MF REG",
        mapicsDescription: "SENNINGER 6# MF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 6 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519402": {
        description: "SENNINGER 10 PSI MF REG",
        mapicsDescription: "SENNINGER 10# MF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 10 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519403": {
        description: "SENNINGER 15 PSI MF REG",
        mapicsDescription: "SENNINGER 15# MF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 15 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519404": {
        description: "SENNINGER 20 PSI MF REG",
        mapicsDescription: "SENNINGER 20# MF REG",
        customerReportsDescription: "Pressure Regulators, Senninger 20 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519414": {
        description: "SENNINGER 20 PSI PSR REG",
        mapicsDescription: "SENNINGER 20# PSR REG",
        customerReportsDescription: "Pressure Regulators, Senninger 20 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519416": {
        description: null,
        mapicsDescription: "SENNINGER 10# PSR-2 REG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519415": {
        description: null,
        mapicsDescription: "SENNINGER 6# PSR-2 REG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519417": {
        description: null,
        mapicsDescription: "SENNINGER 15# PSR-2 REG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519345": {
        description: null,
        mapicsDescription: "NELSON ALLFLO 10# FXST",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519346": {
        description: null,
        mapicsDescription: "NELSON ALLFLO 15# FXST",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519347": {
        description: null,
        mapicsDescription: "NELSON ALLFLO 20# FXST",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519421": {
        description: null,
        mapicsDescription: "KOMET 6# KPR-X REG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519422": {
        description: null,
        mapicsDescription: "KOMET 10# KPR-X REG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519423": {
        description: null,
        mapicsDescription: "KOMET 15# KPR-X REG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519424": {
        description: null,
        mapicsDescription: "KOMET 20# KPR-X REG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3519342": {
        description: null,
        mapicsDescription: "NELSON ALLFLO 20# FXF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3501350": {
        description: null,
        mapicsDescription: "KOMET TWISTER STD ANG W/ WT",
        customerReportsDescription: "Sprinklers, Komet, Twister",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501550": {
        description: null,
        mapicsDescription: "NELSON A3000 D4 MAROON",
        customerReportsDescription: "Sprinklers, Nelson Accelerator (A3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501562": {
        description: null,
        mapicsDescription: "NELSON R3000 D8 PLATE ORANGE",
        customerReportsDescription: "Sprinklers, Nelson Rotator (R3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501564": {
        description: null,
        mapicsDescription: "NELSON R3000 U6 PLATE WHITE",
        customerReportsDescription: "Sprinklers, Nelson Rotator (R3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501320": {
        description: null,
        mapicsDescription: "SENN XIWOB BLUE 610 UP3",
        customerReportsDescription: "Sprinklers, Senninger, Xi-Wob UP3",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501321": {
        description: null,
        mapicsDescription: "SENN XIWOB GRAY 910 UP3",
        customerReportsDescription: "Sprinklers, Senninger, Xi-Wob UP3",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501322": {
        description: null,
        mapicsDescription: "SENN XIWOB BLACK 615 UP3",
        customerReportsDescription: "Sprinklers, Senninger, Xi-Wob UP3",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501196": {
        description: null,
        mapicsDescription: "SENN XCEL WOBBLER MA WHITE",
        customerReportsDescription: "Sprinklers, Senninger, Xi-Wob",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501323": {
        description: null,
        mapicsDescription: "SENN XIWOB 605 WHITE UP3",
        customerReportsDescription: "Sprinklers, Senninger, Xi-Wob UP3",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501551": {
        description: null,
        mapicsDescription: "NELSON A3000 D6 GOLD",
        customerReportsDescription: "Sprinklers, Nelson Accelerator (A3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501573": {
        description: null,
        mapicsDescription: "NELSON 03000 STD BLACK W/WT",
        customerReportsDescription: "Sprinklers, Nelson Orbitor (O3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501574": {
        description: null,
        mapicsDescription: "NELSON 03000 LOW BLUE W/WT",
        customerReportsDescription: "Sprinklers, Nelson Orbitor (O3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501552": {
        description: null,
        mapicsDescription: "NELSON A3000 D6 NAVY",
        customerReportsDescription: "Sprinklers, Nelson Accelerator (A3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501575": {
        description: null,
        mapicsDescription: "NELSON 03000 SM DRP PUR W/WT",
        customerReportsDescription: "Sprinklers, Nelson Orbitor (O3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501480": {
        description: null,
        mapicsDescription: "NELSON O3030 FX UP TOP WHITE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501512": {
        description: null,
        mapicsDescription: "NELSON O3030 FX D-SA-P BLACK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501351": {
        description: null,
        mapicsDescription: "KOMET TWISTER LW ANG W/ WT",
        customerReportsDescription: "Sprinklers, Komet, Twister",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501352": {
        description: null,
        mapicsDescription: "KOMET TWISTER UL LW ANG W/ WT",
        customerReportsDescription: "Sprinklers, Komet, Twister",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501353": {
        description: null,
        mapicsDescription: "KOMET TWISTER UP TOP WHITE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501327": {
        description: null,
        mapicsDescription: "SENN XCEL WOBBLER UP3 TOP",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501360": {
        description: null,
        mapicsDescription: "KOMET SPRAY BLUE,MED,CC X CHM",
        customerReportsDescription: "Sprinklers, Komet, Sprays",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501361": {
        description: null,
        mapicsDescription: "KOMET SPRAY BLK,MED,FL X CHM",
        customerReportsDescription: "Sprinklers, Komet, Sprays",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501362": {
        description: null,
        mapicsDescription: "KOMET SPRAY YEL,FINE,FL X CHM",
        customerReportsDescription: "Sprinklers, Komet, Sprays",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501363": {
        description: null,
        mapicsDescription: "KOMET SPRAY GRY,DEEP FL X CHM",
        customerReportsDescription: "Sprinklers, Komet, Sprays",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501103": {
        description: null,
        mapicsDescription: "SENNINGER 3012 IMPACT",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501104": {
        description: null,
        mapicsDescription: "SENNINGER 4012 IMPACT",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501105": {
        description: null,
        mapicsDescription: "SENNINGER 5012 IMPACT",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501107": {
        description: null,
        mapicsDescription: "SENNINGER 7012 IMPACT",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501123": {
        description: null,
        mapicsDescription: "SENNINGER 3006 IMPACT",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501124": {
        description: null,
        mapicsDescription: "SENNINGER 4006 IMPACT",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501125": {
        description: null,
        mapicsDescription: "SENNINGER 5006 IMPACT",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501126": {
        description: null,
        mapicsDescription: "SENNINGER 5006H2 IMPACT",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501133": {
        description: null,
        mapicsDescription: "SENNINGER 3006 IMPACT-RED VANE",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501134": {
        description: null,
        mapicsDescription: "SENNINGER 4006 IMPACT-RED VANE",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501135": {
        description: null,
        mapicsDescription: "SENNINGER 5006 IMPACT-RED VANE",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501136": {
        description: null,
        mapicsDescription: "SENNINGER 5006H2 IMPACT-RED VN",
        customerReportsDescription: "Sprinklers, Senninger, Plastic Impacts",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501541": {
        description: null,
        mapicsDescription: "NELSON R3000 U4 BLUE",
        customerReportsDescription: "Sprinklers, Nelson Rotator (R3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501542": {
        description: null,
        mapicsDescription: "NELSON R3000 D4 GREEN",
        customerReportsDescription: "Sprinklers, Nelson Rotator (R3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501560": {
        description: null,
        mapicsDescription: "NELSON R3000 D6 RED",
        customerReportsDescription: "Sprinklers, Nelson Rotator (R3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501546": {
        description: null,
        mapicsDescription: "NELSON TRB3000 U4 PLATE BLUE",
        customerReportsDescription: "Sprinklers, Nelson Rotator (R3000), Trash Buster",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501547": {
        description: null,
        mapicsDescription: "NELSON TBR3000 D4 PLATE GREEN",
        customerReportsDescription: "Sprinklers, Nelson Rotator (R3000), Trash Buster",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501563": {
        description: null,
        mapicsDescription: "NELSON R3000 D10 PLATE BROWN",
        customerReportsDescription: "Sprinklers, Nelson Rotator (R3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501566": {
        description: null,
        mapicsDescription: "NELSON R3000 D10 OLIVE",
        customerReportsDescription: "Sprinklers, Nelson Rotator (R3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501581": {
        description: null,
        mapicsDescription: "NELSON S3000 D6 RED",
        customerReportsDescription: "Sprinklers, Nelson Spinner (S3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501582": {
        description: null,
        mapicsDescription: "NELSON S3000 D6 PURPLE",
        customerReportsDescription: "Sprinklers, Nelson Spinner (S3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501583": {
        description: null,
        mapicsDescription: "NELSON S3000 D8 YELLOW",
        customerReportsDescription: "Sprinklers, Nelson Spinner (S3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501521": {
        description: null,
        mapicsDescription: "NELSON D3000 GRAY,FLAT,COARSE",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501522": {
        description: "NELSON D3000 BLACK-FLAT-MED.",
        mapicsDescription: "NELSON D3000 BLACK, FLAT, MED.",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501523": {
        description: null,
        mapicsDescription: "NELSON D3000 BLUE,CONCAVE,MED",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501524": {
        description: null,
        mapicsDescription: "NELSON D3000 PURPL,CONVEX,FINE",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501525": {
        description: "NELSON D3000 YELLOW-FL-FINE",
        mapicsDescription: "NELSON D3000 YELLOW, FL, FINE",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501526": {
        description: null,
        mapicsDescription: "NELSON D3000 GREEN,CC,FINE",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501527": {
        description: null,
        mapicsDescription: "NELSON D3000 TURQ,FLAT,SMOOTH",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501528": {
        description: null,
        mapicsDescription: "D3000-SPRAY GRAY X RED",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501529": {
        description: null,
        mapicsDescription: "D3000-SPRAY BLUE X RED",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501530": {
        description: null,
        mapicsDescription: "D3000-SPRAY BLUE X GREEN",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501536": {
        description: null,
        mapicsDescription: "NELSON TBD3000 CNCV,FINE,GR,PD",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000) Trash Buster",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501535": {
        description: null,
        mapicsDescription: "NELSON TBD3000 FLT,FINE,YEL,PD",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000) Trash Buster",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501520": {
        description: null,
        mapicsDescription: "NELSON D3000 ORANGE FLAT CRSE",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501519": {
        description: null,
        mapicsDescription: "NELSON D3000 BROWN CONCAVE MED",
        customerReportsDescription: "Sprinklers, Nelson Spray (D3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3519419": {
        description: null,
        mapicsDescription: "NELSON D3000 W/BUBBLE-WIDE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501198": {
        description: null,
        mapicsDescription: "I-WOB2 LOW ANG 6 GRV WHT UP3",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501197": {
        description: null,
        mapicsDescription: "I-WOB2 STD ANG 9 GRV BLK UP3",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501199": {
        description: null,
        mapicsDescription: "I-WOB2 LOW ANG 9 GRV BLUE UP3",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501200": {
        description: null,
        mapicsDescription: "I-WOB2 STD ANG GRV GRAY UP3",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501325": {
        description: null,
        mapicsDescription: "SENN LDN MCC/BUBBLE/SHROUD UP3",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501326": {
        description: null,
        mapicsDescription: "SENN LDN CC/BUBBLE/SHROUD UP3",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501309": {
        description: null,
        mapicsDescription: "SENN LDN MCC PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501310": {
        description: null,
        mapicsDescription: "SENN LDN CC PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501311": {
        description: null,
        mapicsDescription: "SENN LDN CC/FL PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501312": {
        description: null,
        mapicsDescription: "SENN LDN CC/CC/FL PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501313": {
        description: null,
        mapicsDescription: "SENN LDN MFL PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501314": {
        description: null,
        mapicsDescription: "SENN LDN FL PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501315": {
        description: null,
        mapicsDescription: "SEN LDN MINI BLUE/BUBBLER UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501316": {
        description: null,
        mapicsDescription: "SEN LDN SINGLE BLUE/BUBLR UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501317": {
        description: null,
        mapicsDescription: "SENN LDN COTTON X BUBBLER UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501301": {
        description: null,
        mapicsDescription: "SENN LDN MCC X CORN PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501302": {
        description: null,
        mapicsDescription: "SENN LDN CC X CORN PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501303": {
        description: null,
        mapicsDescription: "SENN LDN CC/FL X CORN PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501304": {
        description: null,
        mapicsDescription: "SENN LDN CC/CC/FL X CORN UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501305": {
        description: null,
        mapicsDescription: "SENN LDN MCC X COTTON PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501306": {
        description: null,
        mapicsDescription: "SENN LDN CC X COTTON PAD UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501307": {
        description: null,
        mapicsDescription: "SENN LDN CC/FL X COTTON UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501308": {
        description: null,
        mapicsDescription: "SENN LDN CC/CC/FL X COTTON UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501171": {
        description: null,
        mapicsDescription: "SENN S-SPRAY UP3 BLUE,CC,DEEP",
        customerReportsDescription: "Sprinklers, Senninger, Super Spray",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501172": {
        description: "SENN S-SPRAY UP3 BLUE,CC-MED",
        mapicsDescription: "SENN S-SPRAY UP3 BLUE,CC, MED",
        customerReportsDescription: "Sprinklers, Senninger, Super Spray",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501173": {
        description: null,
        mapicsDescription: "SENN S-SPRAY UP3 BLK,FL,COARSE",
        customerReportsDescription: "Sprinklers, Senninger, Super Spray",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501174": {
        description: null,
        mapicsDescription: "SENN S-SPRAY UP3 BLK,FL,DEEP",
        customerReportsDescription: "Sprinklers, Senninger, Super Spray",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501175": {
        description: null,
        mapicsDescription: "SENN S-SPRAY UP3 BLK,FL,MED",
        customerReportsDescription: "Sprinklers, Senninger, Super Spray",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501176": {
        description: null,
        mapicsDescription: "SENN S-SPRAY UP3 BLK,FL,SMOOTH",
        customerReportsDescription: "Sprinklers, Senninger, Super Spray",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501177": {
        description: null,
        mapicsDescription: "SENN S-SPRAY UP3 MINI COTTON",
        customerReportsDescription: "Sprinklers, Senninger, Super Spray",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501178": {
        description: null,
        mapicsDescription: "SENN S-SPRAY UP3 COTTON PAD",
        customerReportsDescription: "Sprinklers, Senninger, Super Spray",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351845": {
        description: null,
        mapicsDescription: "PLUG 3/4\" NPT PLASTIC",
        customerReportsDescription: "Plug 3/4\" NPT-Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501318": {
        description: null,
        mapicsDescription: "SENN LDN MINI BLK/BUBBLER UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501319": {
        description: null,
        mapicsDescription: "SENN LDN SINGLE BLK/BUBLR UP3",
        customerReportsDescription: "Sprinklers, Senninger, Low-Drift Nozzle (LDN UP3)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501324": {
        description: null,
        mapicsDescription: "SEN LDN DG 24 BLK/BUBBLER UP3",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "999999": {
        description: null,
        mapicsDescription: "Dummy",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501492": {
        description: null,
        mapicsDescription: "NELSON A3030 D4 MAROON",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501486": {
        description: null,
        mapicsDescription: "NELSON R3030 D8 ORANGE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501482": {
        description: null,
        mapicsDescription: "NELSON R3030 U6 WHITE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501493": {
        description: null,
        mapicsDescription: "NELSON A3030 D6 CAP/PLT GOLD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501495": {
        description: null,
        mapicsDescription: "NELSON O3030 STD BLACK W/WT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501496": {
        description: null,
        mapicsDescription: "NELSON O3030 LOW BLUE W/WT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501494": {
        description: null,
        mapicsDescription: "NELSON A3030 U6 CAP/PLT NAVY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501497": {
        description: null,
        mapicsDescription: "NELSON O3030 SM DRP PUR W/WT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501483": {
        description: null,
        mapicsDescription: "NELSON R3030 U4 BLUE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501485": {
        description: null,
        mapicsDescription: "NELSON R3030 D4 GREEN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501487": {
        description: null,
        mapicsDescription: "NELSON R3030 D6 RED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501484": {
        description: null,
        mapicsDescription: "NELSON R3030 MULT-TRAJ BROWN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501478": {
        description: null,
        mapicsDescription: "NELSON R3030 D10 OLIVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501489": {
        description: null,
        mapicsDescription: "NELSON S3030 D6 RED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501488": {
        description: null,
        mapicsDescription: "NELSON S3030 D6 PURPLE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501490": {
        description: null,
        mapicsDescription: "NELSON S3030 D8 YELLOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501498": {
        description: null,
        mapicsDescription: "NELSON D3030 GRAY,FLAT,COARSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501502": {
        description: null,
        mapicsDescription: "NELSON D3030 BLACK, FLAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501499": {
        description: null,
        mapicsDescription: "NELSON D3030 BLUE,CONCAVE,MED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501504": {
        description: null,
        mapicsDescription: "NELSON D3030 PURPL,CONVEX,FINE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501506": {
        description: null,
        mapicsDescription: "NELSON D3030 YELLOW,FLAT,FINE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501501": {
        description: null,
        mapicsDescription: "NELSON D3030 GRN,FINE,CONCAVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501505": {
        description: null,
        mapicsDescription: "NELSON D3030 TURQ,FLAT,SMOOTH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501507": {
        description: null,
        mapicsDescription: "NELSON D3030 GRAY X RED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501508": {
        description: null,
        mapicsDescription: "NELSON D3030 BLUE X RED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501509": {
        description: null,
        mapicsDescription: "NELSON D3030 BLUE X GRN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501503": {
        description: null,
        mapicsDescription: "NELSON D3030 ORNG,FLAT,CONCAVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501500": {
        description: null,
        mapicsDescription: "NELSON D3030 BROWN,CONCAVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3519420": {
        description: null,
        mapicsDescription: "NELSON D3030 W/BUBBLE-WIDE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501599": {
        description: null,
        mapicsDescription: "NELSON A3030M D4 MAROON",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501600": {
        description: null,
        mapicsDescription: "NELSON A3030M D6 CAP/PLT GOLD",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501601": {
        description: null,
        mapicsDescription: "NELSON D3030M GRAY,FLAT,COARSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501605": {
        description: null,
        mapicsDescription: "NELSON D3030M BLACK, FLAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501602": {
        description: null,
        mapicsDescription: "NELSON D3030M BLUE,CONCAVE,MED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501607": {
        description: null,
        mapicsDescription: "NELSON D3030MPURPL,CONVEX,FINE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501604": {
        description: null,
        mapicsDescription: "NELSON D3030M GRN,FINE,CONCAVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501608": {
        description: null,
        mapicsDescription: "NELSON D3030M TURQ,FLAT,SMOOTH",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501610": {
        description: null,
        mapicsDescription: "NELSON D3030M GRAY X RED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501611": {
        description: null,
        mapicsDescription: "NELSON D3030M BLUE X RED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501612": {
        description: null,
        mapicsDescription: "NELSON D3030M BLUE X GRN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501606": {
        description: null,
        mapicsDescription: "NELSON D303MORNG,FLAT,CONCAVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501603": {
        description: null,
        mapicsDescription: "NELSON D3030M BROWN,CONCAVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3519430": {
        description: null,
        mapicsDescription: "NELSON D3030M W/BUBBLE-WIDE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351815": {
        description: null,
        mapicsDescription: "PIPE JNT COMPOUND 4 OZ TRU-BLU",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351730": {
        description: null,
        mapicsDescription: "VALVE 3/4\" BRASS BALL MXF",
        customerReportsDescription: "Ball Valves, Brass",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351733": {
        description: null,
        mapicsDescription: "MAN SHUTOFF VALVE-3/4\" W/O REG",
        customerReportsDescription: "Ball Valves, Brass",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351729": {
        description: null,
        mapicsDescription: "BALL VALVE ASY 3/4\"",
        customerReportsDescription: "Ball Valves, Brass",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352184": {
        description: null,
        mapicsDescription: "BALL VALVE-3/4\" MXF-PLASTIC",
        customerReportsDescription: "Ball Valves, Plastic",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352182": {
        description: null,
        mapicsDescription: "BALL VALVE-3/4\" MXF PLAS-SENN",
        customerReportsDescription: "Ball Valves, Plastic, Senninger",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351800": {
        description: null,
        mapicsDescription: "BOOM BACK-W/4 1/2\" CLMP-14'",
        customerReportsDescription: "Boom Backs, Span Pipe, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351801": {
        description: null,
        mapicsDescription: "BOOM BACK-W/6\" CLMP-14'",
        customerReportsDescription: "Boom Backs, Span Pipe, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351802": {
        description: null,
        mapicsDescription: "BOOM BACK-W/6&6 5/8\" CLMP-14'",
        customerReportsDescription: "Boom Backs, Span Pipe, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351803": {
        description: null,
        mapicsDescription: "BOOM BACK-W/8/8-5/8\" CLMP-14'",
        customerReportsDescription: "Boom Backs, Span Pipe, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351804": {
        description: null,
        mapicsDescription: "BOOM BACK-W/10\" CLMP-14'",
        customerReportsDescription: "Boom Backs, Span Pipe, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351842": {
        description: null,
        mapicsDescription: "BOOM BACK PVC-W/4 1/2\" CLAMP",
        customerReportsDescription: "Boom Backs, Span Pipe, PVC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351843": {
        description: null,
        mapicsDescription: "BOOM BACK PVC-W/6\" CLAMP",
        customerReportsDescription: "Boom Backs, Span Pipe, PVC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351839": {
        description: null,
        mapicsDescription: "BOOM BACK PVC-W/6 5/8\" CLAMP",
        customerReportsDescription: "Boom Backs,  Span Pipe, PVC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351840": {
        description: null,
        mapicsDescription: "BOOM BACK PVC-W/8/8-5/8 CLAMP",
        customerReportsDescription: "Boom Backs,  Span Pipe, PVC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351846": {
        description: null,
        mapicsDescription: "BOOM BACK PVC-W/10\" CLAMP",
        customerReportsDescription: "Boom Backs, Span Pipe, PVC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351710": {
        description: null,
        mapicsDescription: "BOOMBACK-TOWER-LEG-PVC",
        customerReportsDescription: "Boomback, Tower Leg with PVC Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351712": {
        description: null,
        mapicsDescription: "BOOMBACK-TOWER-LEG-GALV",
        customerReportsDescription: "Boomback, Tower Leg with Galvanized Pipe",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351722": {
        description: "BOOMBACK-4.5\" PIPE-14'-OH VALVE",
        mapicsDescription: "BOOMBACK-4.5\"PIPE-14'-OH VALVE",
        customerReportsDescription: "Boom Backs, (two each), Span Pipe, with Direction Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351723": {
        description: "BOOMBACK-6\" PIPE-14'-OH VALVE",
        mapicsDescription: "BOOMBACK-6\"PIPE-14'-OH VALVE",
        customerReportsDescription: "Boom Backs, (two each), Span Pipe, with Direction Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351724": {
        description: "BOOMBK-6 5/8\" PIPE-14'-OH VALVE",
        mapicsDescription: "BOOMBK-6&6 5/8\"PIPE-14'-OH VAL",
        customerReportsDescription: "Boom Backs, (two each), Span Pipe, with Direction Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351725": {
        description: "BOOMBACK-8\" PIPE-14'-OH VALVE",
        mapicsDescription: "BOOMBACK-8/8-5/8\"-14'-OH VALVE",
        customerReportsDescription: "Boom Backs, (two each), Span Pipe, with Direction Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351726": {
        description: "BOOMBACK-10\" PIPE-14'-OH VALVE",
        mapicsDescription: "BOOMBACK-10\"PIPE-14'-OH VALVE",
        customerReportsDescription: "Boombacks, (two each), Span Pipe, with Direction Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351850": {
        description: null,
        mapicsDescription: "BOOM BACK-W/3\" CLMP-14'",
        customerReportsDescription: "Boom Backs, Span Pipe, Galvanized, Schedule 40",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351841": {
        description: null,
        mapicsDescription: "BOOM BACK PVC-W/3\" CLAMP",
        customerReportsDescription: "Boom Backs,  Span Pipe, PVC",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351721": {
        description: "BOOMBACK-3\" PIPE-14'-OH VALVE",
        mapicsDescription: "BOOMBACK-3\"PIPE-14'-OH VALVE",
        customerReportsDescription: "Boom Backs, (two each), Span Pipe, with Direction Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351715": {
        description: null,
        mapicsDescription: "TWR LEG BOOMBACK-PVC A4",
        customerReportsDescription: "Boom Backs, (two each), Span Pipe, with Direction Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351716": {
        description: null,
        mapicsDescription: "TWR LEG BOOMBACK-GALV A4",
        customerReportsDescription: "Boom Backs, (two each), Span Pipe, with Direction Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351707": {
        description: null,
        mapicsDescription: "TWR LEG BOOMBACK-PVC-SUGAR",
        customerReportsDescription: "Tower Leg Boom Back-PVC-SG",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351708": {
        description: null,
        mapicsDescription: "TWR LEG BOOMBACK-GALV-SUGAR",
        customerReportsDescription: "Tower Leg Boom Back-Galv-SG",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351890": {
        description: null,
        mapicsDescription: "HOSE BOOMS 15' 3\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351966": {
        description: null,
        mapicsDescription: "HOSE BOOMS 15' 4-1/2\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351967": {
        description: null,
        mapicsDescription: "HOSE BOOMS 15' 6\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351968": {
        description: null,
        mapicsDescription: "HOSE BOOMS 15' 6-5/8\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351969": {
        description: null,
        mapicsDescription: "HOSE BOOMS 15' 8\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351970": {
        description: null,
        mapicsDescription: "HOSE BOOMS 15' 8-5/8\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351971": {
        description: null,
        mapicsDescription: "HOSE BOOMS 15' 10\" IACO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350109": {
        description: null,
        mapicsDescription: "SPRAY-PART CIRCLE-PCD3000",
        customerReportsDescription: "Sprinklers, Part Circle, Nelson Spray, (PCD3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350108": {
        description: null,
        mapicsDescription: "SPINNER-PART CIRCLE-PCS3000",
        customerReportsDescription: "Sprinklers, Part Circle, Nelson Spinner, (PCS3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350107": {
        description: null,
        mapicsDescription: "SPRAY FAN SENN 180",
        customerReportsDescription: "Sprinklers, Part Circle, Senninger Fan Spray",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501300": {
        description: null,
        mapicsDescription: "SENN LDN PC PAD UP3",
        customerReportsDescription: "Sprinklers, Part Circle, Senninger Fan Spray UP3",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350110": {
        description: "CAP/BDY ASY PCR3000B 10843, 001",
        mapicsDescription: "CAP/BDY ASY PCR3000B 10843-001",
        customerReportsDescription: "Sprinklers, Part Circle, Nelson Rotator, (PCR3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350111": {
        description: "CAP/BDY ASY PCR3000W 10843, 002",
        mapicsDescription: "CAP/BDY ASY PCR3000W 10843-002",
        customerReportsDescription: "Sprinklers, Part Circle, Nelson Rotator, (PCR3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350112": {
        description: "CAP/BDY ASY PCR3000T 10843, 003",
        mapicsDescription: "CAP/BDY ASY PCR3000T 10843-003",
        customerReportsDescription: "Sprinklers, Part Circle, Nelson Rotator, (PCR3000)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501364": {
        description: null,
        mapicsDescription: "KOMET PC SPRAY BLUE,CC",
        customerReportsDescription: "Sprinklers, Part Circle, Komet, Sprays",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350113": {
        description: null,
        mapicsDescription: "NELSON PC-S3030 #12650 SPINNER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350114": {
        description: null,
        mapicsDescription: "NELSON PC-R3030 #12651-001 BLK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350115": {
        description: null,
        mapicsDescription: "NELSON PC-R3030 #12651-002 WHT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350116": {
        description: null,
        mapicsDescription: "NELSON PC-R3030 #12651-003 TAN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350117": {
        description: null,
        mapicsDescription: "SPRAY-PART CIRCLE-PCD3030",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501354": {
        description: null,
        mapicsDescription: "KOMET TWISTER PC 180 GREY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "352183": {
        description: null,
        mapicsDescription: "DUAL NOZZLE CLIP-W/1 3TN NOZ",
        customerReportsDescription: "Dual Nozzle Clip",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3501340": {
        description: null,
        mapicsDescription: "UP3 DUAL NOZZLE CARRIER",
        customerReportsDescription: "Nozzle Carrier, Senninger, With UP3 Nozzle",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352181": {
        description: null,
        mapicsDescription: "TRIPLE NOZZLE CLIP W/2 3TN NOZ",
        customerReportsDescription: "Triple Nozzle Clip w/2-3TN Nozzles, Nelson 3000 Series",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3501365": {
        description: null,
        mapicsDescription: "KOMET KFRN DUAL NOZZLE",
        customerReportsDescription: "2nd Nozzle for Dual Flow Rates",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3501479": {
        description: null,
        mapicsDescription: "DUAL NOZZLE CLIP W/1 3NV NOZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3501341": {
        description: null,
        mapicsDescription: "DUAL NOZZLE W/1 UP3 NOZZLE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3501342": {
        description: null,
        mapicsDescription: "TRIPLE NOZZLE W/2 UP3 NOZZLE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351831": {
        description: "WATER PRESSURE GAUGE 0-30 PSI",
        mapicsDescription: "WATER PRESSURE GAUGE 0-30#",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351832": {
        description: "WATER PRESSURE GAUGE 0-100 PSI",
        mapicsDescription: "WATER PRESSURE GAUGE 0-100#",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351833": {
        description: "WATER PRESSURE GAUGE 0-200 PSI",
        mapicsDescription: "WATER PRESSURE GAUGE 0-200#",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351821": {
        description: "WATER PRESSURE GAUGE 0-60 PSI",
        mapicsDescription: "WATER PRESSURE GAUGE 0-60#",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351558": {
        description: "SENN-PRES DROP 0-30 PSI FP",
        mapicsDescription: "SENN-PRES DROP 0-30#FP",
        customerReportsDescription: "Drop Mount, End Pressure Gauge, 0-30 PSI",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355257": {
        description: null,
        mapicsDescription: "WRAP SPAN CONTROL VALVE",
        customerReportsDescription: "Control Valve Assembly for Wrap Span",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395998": {
        description: null,
        mapicsDescription: "VALVE BOX-DUAL SPRKLR-PIV LAT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351980": {
        description: "3/4\" AQUAMATIC VALVE W/3/8\" TEE",
        mapicsDescription: "3/4\" AQUAMATIC VALVE W/3/8\"TEE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351987": {
        description: null,
        mapicsDescription: "3/4\" REINKE VALVE W/TEE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351997": {
        description: null,
        mapicsDescription: "3/4\" POLY VALVE W/TEE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351981": {
        description: null,
        mapicsDescription: "CONTROL LINE BOOST VALVE ASSY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351989": {
        description: null,
        mapicsDescription: "CONTROL LINE BOOST RNK VLV ASY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351972": {
        description: null,
        mapicsDescription: "CONTROL LINE BOOST POLY VLV AS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351982": {
        description: null,
        mapicsDescription: "CONTROL LINE DRAIN VALVE ASSY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351983": {
        description: null,
        mapicsDescription: "STRAINER/VALVE W/ELBOW-SAC-ESP",
        customerReportsDescription: "Energy Saving Package (ESP)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351986": {
        description: null,
        mapicsDescription: "STRNR/REINKE VALVE W/ELBOW",
        customerReportsDescription: "Energy Saving Package (ESP)",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352003": {
        description: null,
        mapicsDescription: "STRNR/POLY VALVE W/ELBOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351984": {
        description: null,
        mapicsDescription: "TUBING-3/8\" OD X 250' W/UNION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355243": {
        description: null,
        mapicsDescription: "ASY-BOOMBACK VALVE CONTROL",
        customerReportsDescription: "Control Valve Assembly for Boombacks",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355255": {
        description: null,
        mapicsDescription: "ASY BOOMBACK VALVE-END",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355245": {
        description: null,
        mapicsDescription: "ASY BOOMBACK VALVE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355315": {
        description: null,
        mapicsDescription: "TOWER LEG BOOMBACK VALUE ASY",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "3501591": {
        description: null,
        mapicsDescription: "NELSON PLATE GRAY,FLAT,COARSE",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501592": {
        description: null,
        mapicsDescription: "NELSON PLATE BLACK,FLAT,MED",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501593": {
        description: "NELSON PLATE BLUE,CONCAVE-MED",
        mapicsDescription: "NELSON PLATE BLUE,CONCAVE, MED",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501594": {
        description: null,
        mapicsDescription: "NELSON PLATE PURPL,CONVEX,FINE",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501595": {
        description: null,
        mapicsDescription: "NELSON PLATE YELLOW,FLAT,FINE",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501596": {
        description: null,
        mapicsDescription: "NELSON PLATE GREEN,CC,FINE",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501597": {
        description: null,
        mapicsDescription: "NELSON PLATE TURQ,FLAT,SMOOTH",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501598": {
        description: "NELSON PLATE RED-CHEM",
        mapicsDescription: "NELSON PLATE RED, CHEM",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501590": {
        description: null,
        mapicsDescription: "NELSON PLATE ORANGE FL/CV CRSE",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501589": {
        description: null,
        mapicsDescription: "NELSON PLATE BROWN MULTI MED",
        customerReportsDescription: "Secondary Spray Plate, Nelson",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351942": {
        description: "CLIP-BUBBLER D3000-NEL#10577",
        mapicsDescription: "CLIP, BUBBLER D3000-NEL#10577",
        customerReportsDescription: "Bubbler Clip for D3000 Spray Body",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501554": {
        description: null,
        mapicsDescription: "CAP/MOTOR/PLATE-GOLD-A3030",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501555": {
        description: null,
        mapicsDescription: "CAP/MOTOR/PLATE-MAROON-A3030",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501588": {
        description: null,
        mapicsDescription: "CAP ONLY NELSON-D3030",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501511": {
        description: null,
        mapicsDescription: "NELSON-CONVERTER #11472",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501510": {
        description: null,
        mapicsDescription: "NELSON 3000 ADAPTER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351849": {
        description: null,
        mapicsDescription: "ADAPTER ST X HB-NELSON",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "3501545": {
        description: null,
        mapicsDescription: "NELSON 3000FC",
        customerReportsDescription: "Nozzle, Nelson, Flow Control",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "351911": {
        description: null,
        mapicsDescription: "SLING-HOSE-3/4\" TR ROD SENN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351910": {
        description: null,
        mapicsDescription: "SLING-HOSE-5/8\" TR ROD SENN",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351917": {
        description: null,
        mapicsDescription: "SLING-HOSE-3/4\" TR ROD KOMET",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351915": {
        description: null,
        mapicsDescription: "SLING-HOSE-5/8\" TR ROD KOMET",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-34-111030-0": {
        description: null,
        mapicsDescription: "Simplex 30 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-34-111055-0": {
        description: null,
        mapicsDescription: "Simplex 55 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-34-111110-0": {
        description: null,
        mapicsDescription: "Simplex 110 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-34-114150-0": {
        description: null,
        mapicsDescription: "Simplex 150 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-34-141330-0": {
        description: null,
        mapicsDescription: "Duplex 30x30 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-34-141430-0": {
        description: null,
        mapicsDescription: "Duplex 4x30 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-34-144885-0": {
        description: null,
        mapicsDescription: "Duplex 85x85 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "887-34-212902-0": {
        description: "20 gallon insectigator 3ph",
        mapicsDescription: "20 gallon insectigator 3ph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "887-34-312902-0": {
        description: "30 gallon insectigator 3ph",
        mapicsDescription: "30 gallon insectigator 3ph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-34-712005-0": {
        description: null,
        mapicsDescription: "Simplex 70 chem 3ph 5.6 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-34-712018-0": {
        description: null,
        mapicsDescription: "Simplex 70 chem 3ph 18 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-34-112005-0": {
        description: null,
        mapicsDescription: "Simplex 110 chem 3ph 5.6 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-34-112018-0": {
        description: null,
        mapicsDescription: "Simplex 110 chem 3ph 18 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-34-212018-0": {
        description: null,
        mapicsDescription: "Simplex 200 chem 3ph 18 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-34-212030-0": {
        description: null,
        mapicsDescription: "Simplex 200 chem 3ph 30 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-34-712430-0": {
        description: null,
        mapicsDescription: "Duplex 70 chem 3ph 4x30 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-34-112430-0": {
        description: null,
        mapicsDescription: "Duplex 110 chem 3ph 4x30 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-34-212430-0": {
        description: null,
        mapicsDescription: "Duplex 200 chem 3ph 4x30 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-54-111030-0": {
        description: null,
        mapicsDescription: "Simplex 30 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-54-111055-0": {
        description: null,
        mapicsDescription: "Simplex 55 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-54-111110-0": {
        description: null,
        mapicsDescription: "Simplex 110 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-54-114150-0": {
        description: null,
        mapicsDescription: "Simplex 150 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-54-141330-0": {
        description: null,
        mapicsDescription: "Duplex 30x30 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-54-141430-0": {
        description: null,
        mapicsDescription: "Duplex 4x30 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "882-54-144885-0": {
        description: null,
        mapicsDescription: "Duplex 85x85 gph 3ph complete",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "887-54-212902-0": {
        description: null,
        mapicsDescription: "20 gallon insectigator 3ph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "887-54-312902-0": {
        description: null,
        mapicsDescription: "30 gallon insectigator 3ph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-54-712005-0": {
        description: null,
        mapicsDescription: "Simplex 70 chem 3ph 5.6 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-54-712018-0": {
        description: null,
        mapicsDescription: "Simplex 70 chem 3ph 18 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-54-112005-0": {
        description: null,
        mapicsDescription: "Simplex 110 chem 3ph 5.6 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-54-112018-0": {
        description: null,
        mapicsDescription: "Simplex 110 chem 3ph 18 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-54-212018-0": {
        description: null,
        mapicsDescription: "Simplex 200 chem 3ph 18 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-54-212030-0": {
        description: null,
        mapicsDescription: "Simplex 200 chem 3ph 30 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-54-712430-0": {
        description: null,
        mapicsDescription: "Duplex 70 chem 3ph 4x30 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-54-112430-0": {
        description: null,
        mapicsDescription: "Duplex 110 chem 3ph 4x30 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "888-54-212430-0": {
        description: null,
        mapicsDescription: "Duplex 200 chem 3ph 4x30 gph",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "113657": {
        description: null,
        mapicsDescription: "VALVE 800 CNTRL A4B5C2D3E50H2",
        customerReportsDescription: "Control Valve, Nelson 800, 4\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "113658": {
        description: null,
        mapicsDescription: "VALVE 800 CNTRL A6B5C2D3E50H2",
        customerReportsDescription: "Control Valve, Nelson 800, 6\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "113659": {
        description: null,
        mapicsDescription: "VALVE 800 CNTRL A8B5C2D3E50H2",
        customerReportsDescription: "Control Valve, Nelson 800, 8\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "115343": {
        description: null,
        mapicsDescription: "VALVE-6\" 800 SERIES CONTROL",
        customerReportsDescription: "Control Valve, Nelson 800, 6\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "113951": {
        description: null,
        mapicsDescription: "VALVE-AIR CNTR 2\" NPT ACV200P",
        customerReportsDescription: "Air Relief Valve, Nelson ACV200P",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "A4B5": {
        description: null,
        mapicsDescription: "Nelson 800 Series 4\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "A6B5": {
        description: null,
        mapicsDescription: "Nelson 800 Series 6\" Cast Iron",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "A8B5": {
        description: null,
        mapicsDescription: "Nelson 800 Series 8\" Cast Iron",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "C1": {
        description: null,
        mapicsDescription: "Low pressure (50 PSI) sleeve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "C2": {
        description: null,
        mapicsDescription: "High pressure (30-200 PSI) sleeve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "C3": {
        description: null,
        mapicsDescription: "Medium pressure (18-80 PSI) sleeve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D1": {
        description: null,
        mapicsDescription: "Manual on/off",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D2": {
        description: "5-50 PSI Pressure reducing valve",
        mapicsDescription: "5-50 PSI Pressure reducing valve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D3": {
        description: "10-120 PSI Pressure reducing valve",
        mapicsDescription: "10-120 PSI Pressure reducing valve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D4": {
        description: "25-200 PSI Pressure reducing valve",
        mapicsDescription: "25-200 PSI Pressure reducing valve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D6": {
        description: null,
        mapicsDescription: "Sleeve exhaust",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D18": {
        description: null,
        mapicsDescription: "Rate-of-flow valve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E1": {
        description: null,
        mapicsDescription: "No Solenoid",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E20": {
        description: "12-24 VDC latch solenoid -0.5 amp max",
        mapicsDescription: "12-24 VDC latch solenoid -0.5 amp max",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E21": {
        description: "12 VDC solenoid -0.6 amp max",
        mapicsDescription: "12 VDC solenoid -0.6 amp max",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E30": {
        description: "24 VDC solenoid -0.3 amp max",
        mapicsDescription: "24 VDC solenoid -0.3 amp max",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E40": {
        description: "24 VAC solenoid -0.3 amp inrush current",
        mapicsDescription: "24 VAC solenoid -0.3 amp inrush current",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E41": {
        description: "24 VAC solenoid -1.1 amp inrush heavy duty",
        mapicsDescription: "24 VAC solenoid -1.1 amp inrush heavy duty",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E42": {
        description: "24 VAC solenoid -3.6 amp inrush current",
        mapicsDescription: "24 VAC solenoid -3.6 amp inrush current",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E50": {
        description: null,
        mapicsDescription: "120 VAC solenoid 1/16\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E52": {
        description: null,
        mapicsDescription: "120 VAC solenoid 1/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E80": {
        description: null,
        mapicsDescription: "Hydraulic remote control",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "A4B10": {
        description: null,
        mapicsDescription: "Nelson 800 Series 4\" Aluminum",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "H2": {
        description: null,
        mapicsDescription: "External filter",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "H3": {
        description: null,
        mapicsDescription: "Internal filter",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "A4B24": {
        description: "Nelson 800 Series 4\" Alum-6\" Adapter",
        mapicsDescription: "Nelson 800 Series 4\" Alum-6\" Adapter",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "A6B10": {
        description: null,
        mapicsDescription: "Nelson 800 Series 6\" Aluminum",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "A6B24": {
        description: null,
        mapicsDescription: "Nelson 800 Series 6\" Alum-8\" Adapter",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "A6B22": {
        description: null,
        mapicsDescription: "Nelson 800 Series 6\" Cast Iron-8\" Adapter",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "A8B10": {
        description: null,
        mapicsDescription: "Nelson 800 Series 8\" Aluminum",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D01": {
        description: null,
        mapicsDescription: "NO Pressure Control",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D02": {
        description: null,
        mapicsDescription: "5-50 PSI Pressure reducing valve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D03": {
        description: null,
        mapicsDescription: "10-120 PSI Pressure reducing valve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D04": {
        description: null,
        mapicsDescription: "25-200 PSI Pressure reducing valve",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D18-1": {
        description: null,
        mapicsDescription: "Rate-of-flow valve, 3\" Pipe, 120-400 GPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D18-2": {
        description: null,
        mapicsDescription: "Rate-of-flow valve, 4\" Pipe, 200-550 GPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D18-3": {
        description: null,
        mapicsDescription: "Rate-of-flow valve, 6\" Pipe, 350-1000 GPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D18-4": {
        description: null,
        mapicsDescription: "Rate-of-flow valve, 6\" Pipe, 500-2000 GPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D18-5": {
        description: null,
        mapicsDescription: "Rate-of-flow valve, 8\" Pipe, 500-1550 GPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "D18-6": {
        description: null,
        mapicsDescription: "Rate-of-flow valve, 8\" Pipe, 900-3800 GPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "E01": {
        description: null,
        mapicsDescription: "Manual Selector Only (No Solenoid)",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "H4": {
        description: null,
        mapicsDescription: "External filter (w/Tubing Protection)",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "H5": {
        description: null,
        mapicsDescription: "Internal filter (w/Tubing Protection)",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119100": {
        description: "SPRINKLER LUBE 1200, 35 LB PAIL",
        mapicsDescription: "SPRINKLER LUBE 1200 35 LB PAIL",
        customerReportsDescription: "Sprinkler Lube 1200, 35lb Pail",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119101": {
        description: "SPRINKLER LUBE 1200, 120 LB KEG",
        mapicsDescription: "SPRINKLER LUBE 1200 120 LB KEG",
        customerReportsDescription: "Sprinkler Lube 1200, 120lb Keg",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119102": {
        description: "SPRINKLER LUBE 1200, 400 LB DRUM",
        mapicsDescription: "SPRINKLER LUBE 1200 400LB DRUM",
        customerReportsDescription: "Sprinkler Lube 1200, 400lb Drum",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119080": {
        description: "SPRINKLER LUBE 220, 2/17.5 LB",
        mapicsDescription: "SPRINKLER LUBE 220-2/17.5 LB",
        customerReportsDescription: "Sprinkler Lube 220, 2/17.5lb",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119082": {
        description: "SPRINKLER LUBE 220, 220 LB",
        mapicsDescription: "SPRINKLER LUBE 220-220 LB",
        customerReportsDescription: "Sprinkler Lube 220, 220lb",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119083": {
        description: "SPRINKLER LUBE 220, 400 LB",
        mapicsDescription: "SPRINKLER LUBE 220-400 LB",
        customerReportsDescription: "Sprinkler Lube 220, 400lb",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119095": {
        description: "PUMP-LUBE MANUAL 5 GALLON PAIL",
        mapicsDescription: "PUMP-LUBE MANUAL 5GAL PAIL",
        customerReportsDescription: "Pneumatic Lube Pump, 5 Gallon Pail",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119096": {
        description: "PUMP-LUBE MANUAL 16 GALLON DRUM",
        mapicsDescription: "PUMP-LUBE MANUAL 16GAL DRUM",
        customerReportsDescription: "Manual Lube Pump, 16 Gallon Drum",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119092": {
        description: "PUMP-SPK LUBE-120 lb-W/O REEL",
        mapicsDescription: "PUMP-SPK LUBE-120#-W/O REEL",
        customerReportsDescription: "Pump w/o Reel-Sprinkler Lube-120lb Keg",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119090": {
        description: "PUMP-SPK LUBE-400 lb-W/O REEL",
        mapicsDescription: "PUMP-SPK LUBE-400#-W/O REEL",
        customerReportsDescription: "Pump w/o Reel-Sprinkler Lube-400lb Drum",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119091": {
        description: "PUMP-SPK LUBE-120 lb-W/REEL",
        mapicsDescription: "PUMP-SPK LUBE-120#-W/REEL",
        customerReportsDescription: "Pump w/Reel-Sprinkler Lube-120lb Keg",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119089": {
        description: "PUMP-SPK LUBE-400 lb-W/ REEL",
        mapicsDescription: "PUMP-SPK LUBE-400#-W/ REEL",
        customerReportsDescription: "Pump w/Reel-Sprinkler Lube-400lb Drum",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119112": {
        description: "FLTR-6\"-475 GPM-3/32\" CLEMONS",
        mapicsDescription: "FLTR- 6\"-475 GPM-3/32 CLEMONS",
        customerReportsDescription: "Clemons Filter, 6\", 475 GPM, 3/32\" Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119113": {
        description: "FLTR-8\"-700 GPM-3/32\" CLEMONS",
        mapicsDescription: "FLTR- 8\"-700 GPM-3/32 CLEMONS",
        customerReportsDescription: "Clemons Filter, 8\", 700 GPM, 3/32\" Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119114": {
        description: "FLTR-8\"-1200 GPM-3/32\" CLEMONS",
        mapicsDescription: "FLTR- 8\"-1200 GPM-3/32 CLEMONS",
        customerReportsDescription: "Clemons Filter, 8\", 1200 GPM, 3/32\" Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119115": {
        description: "FLTR-12\"-2000 GPM-3/32\" CLEMONS",
        mapicsDescription: "FLTR- 12\"-2000GPM-3/32 CLEMONS",
        customerReportsDescription: "Clemons Filter, 12\", 2000 GPM, 3/32\" Screen",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119105": {
        description: "VALVE-CHEM CV4RL-4\"",
        mapicsDescription: "VALVE-CHEM CV4RL-4",
        customerReportsDescription: "Chemigation Check Valve, 4\" CV4RL",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119106": {
        description: null,
        mapicsDescription: "VALVE-CHEM CV6RL-6\"",
        customerReportsDescription: "Chemigation Check Valve, 6\" CV6RL",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119107": {
        description: "VALVE-CHEM CV8RL-8\"",
        mapicsDescription: "VALVE-CHEM CV8RL-8",
        customerReportsDescription: "Chemigation Check Valve, 8\" CV8RL",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119108": {
        description: "VALVE-CHEM CV10RL-10\"",
        mapicsDescription: "VALVE-CHEM CV10RL-10\"",
        customerReportsDescription: "Chemigation Check Valve, 10\" CV10RL",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119109": {
        description: "VALVE-CHEM CV12RL-12\"",
        mapicsDescription: "VALVE-CHEM CV12RL-12",
        customerReportsDescription: "Chemigation Check Valve, 12\" CV12RL",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119136": {
        description: null,
        mapicsDescription: "GASKET-6\" FLG CHECK VLV",
        customerReportsDescription: "Flange Gasket 6\" - Check Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119138": {
        description: null,
        mapicsDescription: "GASKET-8\" FLG CHECK VLV",
        customerReportsDescription: "Flange Gasket 8\" - Check Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119139": {
        description: "GASKET-10\" X 3/16\" 2PLY CI",
        mapicsDescription: "GASKET-10\" X 3/16\" 2PLY CI",
        customerReportsDescription: "Flange Gasket 10\" - Check Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "129119": {
        description: "FLANGE-CHECK VLV-6 1/16\" ID",
        mapicsDescription: "FLANGE-CHECK VLV-6-1/16 ID",
        customerReportsDescription: "Flange 6 1/16\" ID - Check Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "129120": {
        description: "FLANGE-8\" OD X 6\" ID-CHECK VLV",
        mapicsDescription: "FLANGE-8 OD X 6 ID-CHECK VLV",
        customerReportsDescription: "Flange 8\" OD x 6\" ID - Check Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "119124": {
        description: "FLANGE-8 1/16\" ID X 13.5\" OD 8 HOLE",
        mapicsDescription: "FLANGE-8-1/16IDX13.5 OD 8 HOLE",
        customerReportsDescription: "Flange 8 1/16\" ID x 13.5\" OD, 8-Hole - Check Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "149104-G": {
        description: "ADAPTER-8 x 6-CHECK VALVE",
        mapicsDescription: "ADAPTER-8 X 6-CHECK VALVE",
        customerReportsDescription: "Adapter Flange, 8\" x 6\" - Check Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "149105": {
        description: null,
        mapicsDescription: "ADAPTER-8\" DRESSER COUP",
        customerReportsDescription: "Adapter Flange, 8\" Dresser Coupler - Check Valve",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "149001": {
        description: null,
        mapicsDescription: "EXCHANGE-HEAT-24\"",
        customerReportsDescription: "Heat Exchanger, 24\" Unit, 40hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "149002": {
        description: null,
        mapicsDescription: "EXCHANGE-HEAT-30\"",
        customerReportsDescription: "Heat Exchanger 30\" Unit, 75hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "149003": {
        description: null,
        mapicsDescription: "EXCHANGE-HEAT-40\"",
        customerReportsDescription: "Heat Exchanger 40\" Unit, 95hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "149004": {
        description: null,
        mapicsDescription: "EXCHANGE-HEAT-48\"",
        customerReportsDescription: "Heat Exchanger 48\" Unit, 135hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "149005": {
        description: null,
        mapicsDescription: "EXCHANGE-HEAT-60\"",
        customerReportsDescription: "Heat Exchanger 60\" Unit, 200hp",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "149031": {
        description: "FITTING-PKG PIPE 1 1/4\" HE",
        mapicsDescription: "FITTING-PKG PIPE 1 1/4\" HE",
        customerReportsDescription: "Pipe Fitting Package, 1.25\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "149032": {
        description: "FITTING-PKG PIPE 1 1/2\" HE",
        mapicsDescription: "FITTING-PKG PIPE 1 1/2\" HE",
        customerReportsDescription: "Pipe Fitting Package, 1.5\"",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350701": {
        description: null,
        mapicsDescription: "TRAC-AUDIBLE CABLE THEFT ALARM",
        customerReportsDescription: "Audible Cable Theft Alarm",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350941": {
        description: null,
        mapicsDescription: "RADIO MODEM-RPM PREF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353985": {
        description: "ANTENNA, OMNI 8\" 430, 470MHZ",
        mapicsDescription: "ANTENNA-8\" OMNI 12 CBL 450MHZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350980": {
        description: null,
        mapicsDescription: "RADIO MODEM-900 MHZ BASE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350981": {
        description: null,
        mapicsDescription: "TELEMETRY KIT-RPM TCH 900MHZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350982": {
        description: null,
        mapicsDescription: "TELEMETRY KIT-RPM ADV 900MHZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350983": {
        description: null,
        mapicsDescription: "TELEMETRY KIT-RPM RAMS 900MHZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350995": {
        description: null,
        mapicsDescription: "ANTENNA-OMNI BASE 6' 900MHZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350985": {
        description: null,
        mapicsDescription: "ANTENNA-12\" OMNI 12 CBL 900MHZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350984": {
        description: null,
        mapicsDescription: "ANTENNA-YAGI 15 CBL 900MHZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350994": {
        description: null,
        mapicsDescription: "TELEMETRY KIT-900 MHZ REPEATER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350987": {
        description: null,
        mapicsDescription: "ANTENNA-6' OMNI 15CBL RPTR 900",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350988": {
        description: null,
        mapicsDescription: "ANTENNA-6' OMNI 40' CBL 900MHZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350992": {
        description: null,
        mapicsDescription: "ANTENNA-RUBBER DUCK 900MHZ",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350989": {
        description: null,
        mapicsDescription: "ANTENNA MOUNT-PIVOT TOWER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355258": {
        description: null,
        mapicsDescription: "TUBE OPT-1/4\" PER 1000'-VRI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355260": {
        description: null,
        mapicsDescription: "TUBE OPT-1/4\" PER 250'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "355259": {
        description: null,
        mapicsDescription: "TUBE OPT-3/8\" PER 250'",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351990": {
        description: "3/4\" REINKE VLV-1/4\" STRAIGHT",
        mapicsDescription: "3/4\" REINKE VLV-1/4 STRAIGHT",
        customerReportsDescription: "Reinke 3/4\" Valve - 1/4\" Straight",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351991": {
        description: "3/4\" REINKE VLV-1/4\" TEE",
        mapicsDescription: "3/4\" REINKE VLV-1/4 TEE",
        customerReportsDescription: "Reinke 3/4\" Valve - 1/4\" Tee",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351995": {
        description: null,
        mapicsDescription: "3/4\" POLY VALVE-1/4 STRAIGHT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351994": {
        description: null,
        mapicsDescription: "3/4\" POLY VALVE-1/4 TEE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352927": {
        description: "VALVE BOX OPTION-6\" VALVE-VRI",
        mapicsDescription: "VALVE BOX OPTION-6 VALVE-VRI",
        customerReportsDescription: "Control Valve Box Option - 6 VRI Zones",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "352927GPS": {
        description: null,
        mapicsDescription: "VALVE BOX OPT-VRI-GPS",
        customerReportsDescription: "Control Valve Box Option-GPS-6 VRI Zones",
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353977": {
        description: "VALVE BOX 6 VRIGPS RBST TRANS",
        mapicsDescription: "VALVE BOX 6 VRIGPS RBST TRANS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "357025": {
        description: null,
        mapicsDescription: "TOWER BOX-RETRO MNT KIT-4 1/2\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "357026": {
        description: null,
        mapicsDescription: "TOWER BOX-RETRO MNT KIT-6\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "357028": {
        description: null,
        mapicsDescription: "TOWER BOX-RETRO MNT KIT-8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "357029": {
        description: null,
        mapicsDescription: "TOWER BOX-RETRO MNT KIT-8 5/8\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "357030": {
        description: null,
        mapicsDescription: "TOWER BOX-RETRO MNT KIT-10\"",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353253": {
        description: null,
        mapicsDescription: "PULSE CABLE-16'-MCCROMETER",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: true,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353259": {
        description: null,
        mapicsDescription: "MCCROMETER 6\" FLOW STRAIGHTENR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353269": {
        description: null,
        mapicsDescription: "MCCROMETER 8\" FLOW STRAIGHTENR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353251": {
        description: null,
        mapicsDescription: "FLOW METER-SEAMETRICS 6\" PULSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353261": {
        description: null,
        mapicsDescription: "FLOWMETR-SEAMETRICS 8\" W/PULSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "353271": {
        description: null,
        mapicsDescription: "FLOWMTR-SEAMETRICS 10\" W/PULSE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351539": {
        description: null,
        mapicsDescription: "RCOM IND SPAN MOUNT KIT RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350882": {
        description: null,
        mapicsDescription: "RC10I RAIN GAUGE-SPAN MOUNT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351540": {
        description: null,
        mapicsDescription: "RCOM IND PIV CEN MNT KIT RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350886": {
        description: null,
        mapicsDescription: "RAIN GAUGE OPT-MCP-RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350862": {
        description: null,
        mapicsDescription: "REINCLOUD IND GSM RC10IG",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350860": {
        description: null,
        mapicsDescription: "REINCLOUD IND SAT RC10IS",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350864": {
        description: null,
        mapicsDescription: "REINCLOUD IND GSM RC10IGW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350212": {
        description: null,
        mapicsDescription: "REINCLOUD IND HUMIDITY RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350216": {
        description: null,
        mapicsDescription: "REINCLOUD IND POWER RELAY OPT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350852": {
        description: null,
        mapicsDescription: "REINCLOUD IND PRS SW 20PSI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350872": {
        description: null,
        mapicsDescription: "REINCLOUD IND PSI SW RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350874": {
        description: null,
        mapicsDescription: "REINCLOUD IND PSI TRANS RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350876": {
        description: null,
        mapicsDescription: "REINCLOUD 25 PSI TRANS RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350880": {
        description: null,
        mapicsDescription: "RC10I START RELAY OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350881": {
        description: null,
        mapicsDescription: "RC10I SPEED RELAY OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350883": {
        description: null,
        mapicsDescription: "RC10I CHEM RELAY OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350884": {
        description: null,
        mapicsDescription: "RC10I END GUN/AUX RELAY OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350885": {
        description: null,
        mapicsDescription: "RC10I PUMP RELAY OPTION",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350891": {
        description: null,
        mapicsDescription: "RC10I START RELAY KIT RPM SAC",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350893": {
        description: null,
        mapicsDescription: "KIT-WEATHER STN-MCP-RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350894": {
        description: null,
        mapicsDescription: "HARDWARE-WIND/RAIN-MCP-RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350803": {
        description: null,
        mapicsDescription: "MCP-RPM PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350806": {
        description: null,
        mapicsDescription: "MCP-RPM LINEAR PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350808": {
        description: null,
        mapicsDescription: "MAIN CNTRL PNL BOX-EMPTY-RPM",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Standard,
        upgradeToPartNumber: null,
    },
    "350892": {
        description: null,
        mapicsDescription: "RCOM IND NAV GPS CBL MCP-RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350895": {
        description: null,
        mapicsDescription: "KIT-INSTALL EXISTING EOS CABLE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350890": {
        description: null,
        mapicsDescription: "RCOM IND NAV GPS CBL EOS-RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350870": {
        description: null,
        mapicsDescription: "REINCLOUD IND GPS OPTION RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350866": {
        description: null,
        mapicsDescription: "REINCLOUD IND VZN RC10IV",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350215": {
        description: null,
        mapicsDescription: "RCOM HUMIDITY RETRO RC10D",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350841": {
        description: null,
        mapicsDescription: "RETRO SAT RC10DS PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350847": {
        description: null,
        mapicsDescription: "RETRO VZN RC10DV PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350843": {
        description: null,
        mapicsDescription: "RETRO GSM RC10DG PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350845": {
        description: null,
        mapicsDescription: "RTRO GSM INT RC10DGW PREFERRED",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350834": {
        description: null,
        mapicsDescription: "RETRO VZN RC10DV ADV +",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350830": {
        description: null,
        mapicsDescription: "RETRO GSM RC10DG ADV +",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350828": {
        description: null,
        mapicsDescription: "RETRO SAT RC10DS ADV +",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350832": {
        description: null,
        mapicsDescription: "RETRO GSM INTL RC10DGW ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351042": {
        description: null,
        mapicsDescription: "REINCLOUD IND EUROPE RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351041": {
        description: null,
        mapicsDescription: "REINCLOUD IND A-PAC RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350878": {
        description: null,
        mapicsDescription: "REINCLD IND AUS/CAN/MEX RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351024": {
        description: null,
        mapicsDescription: "RETRO EUROPE INT RC10D ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351023": {
        description: null,
        mapicsDescription: "RETRO A-PAC INT RC10D ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351007": {
        description: null,
        mapicsDescription: "RETRO AUS/CAN/MEX RC10D ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351036": {
        description: null,
        mapicsDescription: "RETRO EU INT RC10D PREF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351035": {
        description: null,
        mapicsDescription: "RC10D-PREF NZ/ASIA/ZA RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351038": {
        description: null,
        mapicsDescription: "RETRO AUS/CAN/MEX RC10D PREF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351043": {
        description: null,
        mapicsDescription: "REINCLOUD IND CASAMX RC10I",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351070": {
        description: null,
        mapicsDescription: "RETRO CASAMX INT RC10D ADV+",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351073": {
        description: null,
        mapicsDescription: "RETRO CASAMX INT RC10D PREF",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "395997": {
        description: null,
        mapicsDescription: "CONTROL LINE-BP-PIVOTING MAXI",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351975": {
        description: null,
        mapicsDescription: "STRAINER W/STRAIGHT CONNECTOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351977": {
        description: null,
        mapicsDescription: "3/4\" REINKE VALVE W/3/8\" ELBOW",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "354290": {
        description: null,
        mapicsDescription: "3/4\" POLY VALVE W/TEE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350660": {
        description: null,
        mapicsDescription: "CROPX2.5PROBE-CATM1H-AT&T-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350662": {
        description: null,
        mapicsDescription: "CROPX3.5PROBE-CATM1H-AT&T-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350664": {
        description: null,
        mapicsDescription: "CROPX2.5PROBE-CAT1T-TELIT-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350665": {
        description: null,
        mapicsDescription: "CROPX3.5PROBE-CAT1T-TELIT-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350656": {
        description: null,
        mapicsDescription: "CROPX2.5PROBE-CAT1H-VIAERO-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350657": {
        description: null,
        mapicsDescription: "CROPX3.5PROBE-CAT1H-VIAERO-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350681": {
        description: null,
        mapicsDescription: "CROPX2.5PROBE-CATM1V-VRIZN-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350685": {
        description: null,
        mapicsDescription: "CROPX3.5PROBE-CATM1V-VRIZN-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350630": {
        description: null,
        mapicsDescription: "CROPX2.5PROBE-CATM1T-CA/BR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350631": {
        description: null,
        mapicsDescription: "CROPX3.5PROBE-CATM1T-CA/BR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350691": {
        description: null,
        mapicsDescription: "CROPXV4PROBE-CATM1H-AT&T-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350693": {
        description: null,
        mapicsDescription: "CROPXV4PROBE-CATM1V-VRIZN-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350694": {
        description: null,
        mapicsDescription: "CROPXV4PROBE-CAT1H-VIEARO-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350690": {
        description: null,
        mapicsDescription: "CROPXV4PROBE-CATM1T-CA/BR-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350692": {
        description: null,
        mapicsDescription: "CROPXV4PROBE-CAT1T-TELIT-SUB",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350680": {
        description: null,
        mapicsDescription: "CROPX PROBELESS IRR SCHEDULING",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350684": {
        description: null,
        mapicsDescription: "CROPX V2.5 INSTALL KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350688": {
        description: null,
        mapicsDescription: "CROPX V3.5 INSTALL KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350703": {
        description: null,
        mapicsDescription: "CROPX V4 INSTALLATION KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350706": {
        description: null,
        mapicsDescription: "CROPX V4 BATTERY PACK",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350629": {
        description: null,
        mapicsDescription: "CROPX ANTENNA EXTENSION 3M CBL",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350704": {
        description: null,
        mapicsDescription: "CROPX V4 ANTENNA CABLE",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "350705": {
        description: null,
        mapicsDescription: "CROPX V4 OMNI ANTENNA",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "160809": {
        description: null,
        mapicsDescription: "FACE PLATE RPM PREF TOUCH RET",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "160802": {
        description: null,
        mapicsDescription: "FACE PLATE-RPM ADV-RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "160804": {
        description: null,
        mapicsDescription: "FACE PLATE-RPM STD TAR-RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "160970": {
        description: null,
        mapicsDescription: "GPS CDW MCP KIT- RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "160912": {
        description: null,
        mapicsDescription: "RESTART-RPM STD-RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "160946": {
        description: null,
        mapicsDescription: "RESTART-RPM STD SAC-RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "161014": {
        description: null,
        mapicsDescription: "FACEPLATE ADV + 4.3\" RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "161015": {
        description: null,
        mapicsDescription: "FACEPLATE CONNECT RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "141016": {
        description: "SURGE PROTECTOR OPTION-PIVOT",
        mapicsDescription: "SURGE PROTECTOR OPTION-PIVOT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "161020": {
        description: null,
        mapicsDescription: "RETRO SURGE PROTECTOR",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "351541": {
        description: null,
        mapicsDescription: "RC10I LATERAL MCP MOUNT KIT",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
    "167025": {
        description: null,
        mapicsDescription: "GPS RESOLVER OPTION MAXI RETRO",
        customerReportsDescription: null,
        roeSuppressOnCustomerReports: false,
        proposalSection: ProposalSection.Option,
        upgradeToPartNumber: null,
    },
};

export default partsCatalog;
