import {
    BaseInletSizes,
    ICircleCenterPivot as CenterPivot,
    CenterPivotTypes,
    CollectorRingTypes,
    IControlPanel as ControlPanel,
    Countries,
    ElectricalFrequencies,
    EndTowerPositioningTypes,
    FlowmeterModels,
    FlowmeterTypes,
    GuidanceLocations,
    GuidanceTypes,
    HoseFeedTypes,
    ISystemBase,
    InletTypes,
    Languages,
    PanelLocations,
    PanelModels,
    PressureTransducerOptions,
    Pumps,
    SwitchMountTypes,
    SystemTypes,
    TowerHeights
} from 'rdptypes/project/ISystemBase.AutoGenerated';
import schema from "../data/partsPackages/2024060101/poSchema";
import CSection2 from "./CSection2";
import { MaxAmpsFor10GWire, MaxAmpsFor12GWire } from "./CommonConstants";
import { IntakeSizes } from "./ControlPanelFunctions";
import { RaiseError, errOther } from "./Errors";
import * as hff from "./HoseFeedFunctions";
import * as ohf from "./OtherHelpers";
import PartsAssembler from "./PartsAssembler";
import { WarningLevels } from "./QuoteClass";
import * as sidef from "./SideFunctions";
import * as sysf from "./SystemFunctions";
import { strLanguageText } from "./TranslationsLib";
import { CartAutoReverseOK, ChemAuxControlOK, CollectorReelOK, EndGunShutoffOverrideOK, EndTowerPositioningOK, FlowmeterModelOK, FlowmeterPulseOK, FlowmeterTypeOK, FrostControlOK, GFIOK, GenStartOK, GroundingBarOK, HorizontalFlowtubeWithFS100OK, HorizontalFlowtubeWithVanesOK, InjectorPumpOK, InjectorPumpReceptacle30AmpOK, LargeServiceWireKitOK, LightningArrestorOK, LowVoltageSafetyOK, PACTimerOK, PanelModelOK, PanelULOK, PivotAutoReverseOK, PivotAutoStopOK, PressureRestartOK, PressureSwitchOK, PressureTransducer0to25PSIOk, RainGaugeOK, RemotePanelLessMountOK, RemotePanelOK, SwitchMountOK, TemperatureProbeOK, TowerULOK, WindGaugeOK } from './Valids.MainControlPanel';
import { CollectorReelICCIOptionCountOk } from "./Valids.Valids";
import { CollectorReelICIIOptionCountOkDto } from './Valids.dto.Valids';
export class ControlPanelClass extends CSection2 {
    public DataOK = (PA: PartsAssembler, WithWarnings: boolean = false, DependencyCheckInProgress: boolean = false): void => {
        if (!this.FieldSet.DataValid()) return;
        /*if (FieldSet.Value === FieldSetStates.Invalid) {
        return true;
        }
        if (!(FieldSet.ConfigurationValid())) {
        this.Quote.Warnings.Add(strLanguageText("sltControlPanelSection"));
        return false;
        }*/
        let bIsTowable = this.Quote.IsTowable();
        let bIsA100 = this.Quote.IsA100_CF200();
        let bPivotingLateral: boolean;
        let eBaseInletSize: BaseInletSizes;
        let b230V: boolean = sysf.Is230V(this.Quote.System);
        let eSystemType: SystemTypes = this.Quote.System.SystemProperties.SystemType;
        let ePivotType: CenterPivotTypes;
        let dPivotPercent: number;
        let eHoseFeedType: HoseFeedTypes;
        let bFlowTubeKitAllowed: boolean = sysf.FlowTubeKitAllowed(this.Quote.System);
        let bIsInterational: boolean = ohf.IsInternational(this.Quote.System.DealerProperties);
        let eCountry: Countries = this.Quote.System.DealerProperties.Country;
        let bIsMaxigator: boolean = sysf.IsMaxigator(this.Quote.System);
        let bIsCenterFeed: boolean = sysf.IsCenterFeed(this.Quote.System);
        switch (eSystemType) {
            case SystemTypes.CenterPivot:
                ePivotType = this.Quote.System.Circle.CenterPivot.CenterPivotType;
                dPivotPercent = ohf.PercentOfCircle(this.Quote.System);
                break;
            case SystemTypes.HoseFeedMaxigator:
                eHoseFeedType = this.Quote.System.Lateral.HoseFeed.HoseFeedType;
                eBaseInletSize = this.Quote.System.Lateral.HoseFeed.BaseInletSize;
                bPivotingLateral = this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral;
                break;
        }
        let cp: ControlPanel = this.Quote.System.ControlPanel;
        if (cp.SourceVoltage < cp.MinVoltage) {
            this.Quote.Warnings.Add(strLanguageText("sltMCPVoltageTooLow"));
        }
        let IsVoltageValid: boolean = true;
        if (sysf.Is230V(this.Quote.System)) {
            if (cp.SourceVoltage < 208 || cp.MinVoltage < 208) {
                IsVoltageValid = false;
            }
            else if (cp.SourceVoltage > 250 || cp.MinVoltage > 250) {
                IsVoltageValid = false;
            }
            if (!(IsVoltageValid)) {
                this.Quote.Warnings.Add(strLanguageText("sltMCPMinMax1Ph60HzVoltage"));
            }
        }
        else {
            switch (cp.ElectricalFrequency) {
                case ElectricalFrequencies.a60:
                    if (cp.SourceVoltage < 440 || cp.MinVoltage < 440) {
                        IsVoltageValid = false;
                    }
                    else if (cp.SourceVoltage > 505 || cp.MinVoltage > 505) {
                        IsVoltageValid = false;
                    }
                    if (!(IsVoltageValid)) {
                        this.Quote.Warnings.Add(strLanguageText("sltMCPMinMax3Ph60HzVoltage"));
                    }
                    break;
                case ElectricalFrequencies.a50:
                    if (cp.SourceVoltage < 360 || cp.MinVoltage < 360) {
                        IsVoltageValid = false;
                    }
                    else if (cp.SourceVoltage > 435 || cp.MinVoltage > 435) {
                        IsVoltageValid = false;
                    }
                    if (!(IsVoltageValid)) {
                        this.Quote.Warnings.Add(strLanguageText("sltMCPMinMax3Ph50HzVoltage"));
                    }
                    break;
            }
        }
        if (!(PanelModelOK(cp.PanelModel, {
            SystemType: eSystemType,
            HoseFeedType: eHoseFeedType,
            IsInternational: bIsInterational,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.PanelModel"], strLanguageText("sltPanelModel"));
        }
        if (!(bPivotingLateral)) {
            if (bIsTowable && bIsMaxigator) {
                if (cp.GPSFields === 1) {
                    this.Quote.Warnings.Add(strLanguageText("sltEndTowerPositioning"));
                }
            }
            else {
                if (cp.GPSFields > 1) {
                    this.Quote.Warnings.Add(strLanguageText("sltEndTowerPositioning"));
                }
            }
        }
        if (!(EndTowerPositioningOK(cp.EndTowerPositioning, {
            SystemType: eSystemType, PanelModel: cp.PanelModel
        }))) {
            this.Quote.Warnings.AddWithTargets([`ControlPanel.EndTowerPositioning`], strLanguageText("sltEndTowerPositioning"));
        }
        if (!sysf.IsLateralOnly(this.Quote.System) // Don't validate collector reel if lateral because the options might still be set from System Defaults
            && !(CollectorReelOK(cp.CollectorRing, {
            EndTowerPositioningType: cp.EndTowerPositioning,
            PanelModel: cp.PanelModel,
            SystemType: eSystemType,
            PivotPercent: dPivotPercent,
            PivotAutoReverse: cp.PivotAutoReverse,
            PivotingLateral: bPivotingLateral
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.CollectorRing"], strLanguageText("sltCollectorReel"));
        }
        if (!(SwitchMountOK(cp.SwitchMount, {
            SystemType: eSystemType,
            PanelModel: cp.PanelModel,
            EndTowerPositioningType: cp.EndTowerPositioning,
            CenterPivotType: ePivotType,
            CollectorRingType: cp.CollectorRing
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.SwitchMount"], strLanguageText("sltSwitchMount"));
        }
        if (!(PACTimerOK(cp.PACTimer, {
            PanelModel: cp.PanelModel
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.PACTimer"], strLanguageText("sltPACTimer"));
        }
        let hasPivotRiserPipeOption: boolean = false;
        let s: ISystemBase = this.Quote.System;
        if (sysf.FieldSets(s).CenterPivot.DataValid()) {
            if (this.Quote.System.SystemProperties.SystemType === SystemTypes.CenterPivot) {
                let p: CenterPivot = s.Circle.CenterPivot;
                hasPivotRiserPipeOption = (p.RiserPipeFor6InchValve || p.RiserPipeFor6x19FlangeDevice || p.RiserPipeFor8x23FlangeDevice);
            }
        }
        if (!(FlowmeterModelOK(cp.FlowmeterModel, {
            SystemType: eSystemType,
            BaseInletSize: eBaseInletSize
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.FlowmeterModel"], strLanguageText("sltRAMSFlowmeter"));
        }
        if (!(FlowmeterTypeOK(cp.Flowmeter, {
            SystemType: eSystemType,
            FlowmeterModel: cp.FlowmeterModel,
            HasPivotRiserPipeOption: hasPivotRiserPipeOption,
            VerticalFlowTubeKitAllowed: sysf.VerticalFlowTubeKitAllowed(s)
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.Flowmeter"], strLanguageText("sltRAMSFlowmeter"));
        }
        if (!(HorizontalFlowtubeWithVanesOK(cp.FlowtubeWithVanes, {
            FlowmeterLocation: cp.Flowmeter,
            IsFlowtubeKitAllowed: bFlowTubeKitAllowed,
            FlowmeterModel: cp.FlowmeterModel,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.FlowtubeWithVanes"], strLanguageText("sltRAMSFlowmeter"));
        }
        if (!(HorizontalFlowtubeWithFS100OK(cp.FlowtubeWithFS100, {
            FlowmeterLocation: cp.Flowmeter,
            IsFlowtubeKitAllowed: bFlowTubeKitAllowed,
            FlowmeterModel: cp.FlowmeterModel,
            IsFlowtubeWithVanesPresent: cp.FlowtubeWithVanes
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.FlowtubeWithFS100"], strLanguageText("sltRAMSFlowmeter"));
        }
        if (!(FlowmeterPulseOK(cp.FlowmeterPulse, { FlowmeterModel: cp.FlowmeterModel, PanelModel: cp.PanelModel }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.FlowmeterPulse"], strLanguageText("sltRAMSFlowmeter"));
        }
        if (!(PivotAutoReverseOK(cp.PivotAutoReverse, { SystemType: eSystemType, SwitchMountType: cp.SwitchMount, EndTowerPositioningType: cp.EndTowerPositioning, PanelModel: cp.PanelModel, CollectorReelType: cp.CollectorRing }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.PivotAutoReverse"], strLanguageText("sltPivotAutoReverse"));
        }
        if (!(PivotAutoStopOK(cp.PivotAutoStop,  {
            SystemType: eSystemType,
            SwitchMount: cp.SwitchMount,
            CollectorReel: cp.CollectorRing,
            EndTowerPositioning: cp.EndTowerPositioning ?? EndTowerPositioningTypes.None,
            PanelType: cp.PanelModel,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.PivotAutoStop"], strLanguageText("sltPivotAutoStop"));
        }
        if (!(EndGunShutoffOverrideOK(cp.EndGunShutoffOverride, {
            PanelModel: cp.PanelModel,
            SystemType: eSystemType,
            HoseFeedType: eHoseFeedType,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.EndGunShutoffOverride"], strLanguageText("sltEndGunShutoffOverride"));
        }
        if (!(ChemAuxControlOK(cp.ChemAuxControl, { PanelModel: cp.PanelModel }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.ChemAuxControl"], strLanguageText("sltChemAuxControl"));
        }
        if (!(InjectorPumpOK(cp.InjectorPump, {
            SystemType: eSystemType,
            HoseFeedType: eHoseFeedType,
            HasChemAuxControl: cp.ChemAuxControl,
            InjectorPumpReceptacle20A: cp.InjectorPump,
            InjectorPumpReceptacle30A: cp.InjectorPump30Amp,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.InjectorPump"], strLanguageText("sltInjectorPumpReceptacle"));
        }
        if (!(InjectorPumpReceptacle30AmpOK(cp.InjectorPump30Amp, {
            PanelModel: cp.PanelModel,
            HasChemAuxControl: cp.ChemAuxControl,
            Is230V: sysf.Is230V(this.Quote.System),
            InjectorPumpReceptacle20A: false,
            InjectorPumpReceptacle30A: false,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.InjectorPump30Amp"], strLanguageText("sltInjectorPumpReceptacle"));
        }
        if (!(RainGaugeOK(cp.RainGauge, {
            PanelModel: cp.PanelModel,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.RainGauge"], strLanguageText("sltRAMSWeather"));
        }
        if (!(WindGaugeOK(cp.WindGauge, {
            PanelModel: cp.PanelModel,
            HasRainGauge: cp.RainGauge,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.WindGauge"], strLanguageText("sltRAMSWeatherWind"));
        }
        if (!(RemotePanelOK(cp.RemotePanelOptions.RemotePanel, {
            SystemType: eSystemType,
            RemoteControlPanel: cp.RemotePanelOptions.RemotePanel,
            RemoteControlPanelLessMount: cp.RemoteMCPLessMount,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.RemotePanelOptions.RemotePanel"], strLanguageText("sltRemoteControlPanel"));
        }
        if (!(RemotePanelLessMountOK(cp.RemoteMCPLessMount, {
            SystemType: eSystemType,
            RemoteControlPanel: cp.RemotePanelOptions.RemotePanel,
            RemoteControlPanelLessMount: cp.RemoteMCPLessMount,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.RemoteMCPLessMount"], strLanguageText("sltRemoteControlPanel"));
        }
        if (!(LargeServiceWireKitOK(cp.LargeServiceWireKit, {
            PanelModel: cp.PanelModel,
            SystemType: eSystemType,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.LargeServiceWireKit"], strLanguageText("sltLargeServiceWireKit"));
        }
        if (!(LightningArrestorOK(cp.LightningArrestor, {
                PanelModel: cp.PanelModel,
            SystemType: eSystemType,
        HoseFeedType: eHoseFeedType,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.LightningArrestor"], strLanguageText("sltLightningArrestor"));
        }
        if (!(LowVoltageSafetyOK(cp.LowVoltageSafety, {
            Is230V: b230V,
            PanelModel: cp.PanelModel,
            SystemType: eSystemType,
            HoseFeedType: eHoseFeedType,
            IsInternational: bIsInterational
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.LowVoltageSafety"], strLanguageText("sltLowVoltageSafety"));
        }
        if (!(PressureSwitchOK(cp.LowPressureSafetyReg, {
            SystemType: eSystemType,
            HoseFeedType: eHoseFeedType,
            PanelModel: cp.PanelModel,
            HasOtherLPSwitch: false,
            HasUltraLowPressureSwitch: false,
            HasPressureTransducer: (cp.PressureTransducerOptions ?? PressureTransducerOptions.None) !== PressureTransducerOptions.None
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.LowPressureSafetyReg"],strLanguageText("sltLowPressureSafety"));
        }
        if (!(PressureSwitchOK(cp.LowPressureSafetyLP, {
            SystemType: eSystemType,
            HoseFeedType: eHoseFeedType,
            PanelModel: cp.PanelModel,
            HasOtherLPSwitch: cp.LowPressureSafetyReg,
            HasUltraLowPressureSwitch: true,
            HasPressureTransducer: (cp.PressureTransducerOptions ?? PressureTransducerOptions.None) !== PressureTransducerOptions.None
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.LowPressureSafetyLP"], strLanguageText("sltLowPressureSafety"));
        }
        if (!(PressureTransducer0to25PSIOk(cp.PressureTransducer0to25PSI, {
            SystemType: eSystemType,
            PanelModel: cp.PanelModel,
            HasSwingArm: sysf.HasSwingArmCorner(this.Quote.System),
            HasEndGun: sysf.HasEndGun(this.Quote.System),
            PressureTransducerOption: cp.PressureTransducerOptions,
            PsiAtTopOfInlet: sysf.PivotPressure(this.Quote.System)
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.PressureTransducer0to25PSI"], strLanguageText("sltPressureTransducer0to25PSI"));
        }
        let bHasLowPressureSafety: boolean = (cp.LowPressureSafetyReg || cp.LowPressureSafetyLP);
        if (!(GenStartOK(cp.GenStartControl, {
            SystemType: eSystemType,
            HoseFeedType: eHoseFeedType
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.GenStartControl"],strLanguageText("sltGenStartRunControl"));
        }
        if (!(PressureRestartOK(cp.PressureRestart, {
            HasLowPressureSafety: bHasLowPressureSafety,
            HasPressureTransducer: (cp.PressureTransducerOptions ?? PressureTransducerOptions.None) !== PressureTransducerOptions.None,
            PanelType: cp.PanelModel,
            HasGenerator: cp.GenStartControl
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.PressureRestart"], "Pressure Restart");
        }
        if (!(FrostControlOK(cp.FrostControl, {
            PanelModel: cp.PanelModel,
            SystemType: eSystemType,
            HoseFeedType: eHoseFeedType,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.FrostControl"], strLanguageText("sltFrostControl"));
        }
        if (!(TemperatureProbeOK(cp.TemperatureProbe, {
            PanelModel: cp.PanelModel,
            HasFrostControl: cp.FrostControl,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.TemperatureProbe"], strLanguageText("sltTemperatureProbe"));
        }
        if (!(PanelULOK(cp.PanelUL, {
            IsInternational: bIsInterational
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.PanelUL"], strLanguageText("sltMCPULCCert"));
        }
        if (!(TowerULOK(cp.TowerUL, {
            Is230V: b230V,
            Country: eCountry
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.TowerUL"], strLanguageText("sltTowerBoxCert"));
        }
        if (!(GFIOK(cp.GFI, {
            Is230V: b230V,
            IsInternational: bIsInterational,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.GFI"], strLanguageText("sltGroundFault"));
        }
        if (!(GroundingBarOK(cp.GroundingBar, {
            SystemType: eSystemType,
            HoseFeedType: eHoseFeedType,
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.GroundingBar"], strLanguageText("sltGroundingBarRod"));
        }
        if (!(CartAutoReverseOK(cp.CartAutoReverse, {
            SystemType: this.Quote.System.SystemProperties.SystemType,
            PanelModel: this.Quote.System.ControlPanel.PanelModel,
            InletType: this.Quote.System.Lateral.HoseFeed.InletType ?? InletTypes.None
        }))) {
            this.Quote.Warnings.AddWithTargets(["ControlPanel.CartAutoReverse"], strLanguageText("sltCartAutoReverse"));
        }
        if (cp.Location === PanelLocations.FlangedSide) {
            if (eSystemType !== SystemTypes.CanalFeedMaxigator) {
                this.Quote.Warnings.Add(strLanguageText("sltPanelLocation"));
            }
        }
        /*
        TODO Icon - Implement power checks
        if (!(DependencyCheckInProgress)) {
            let Amps: number = PA.EC.FullLoadAmps;
            let KW: number = PA.EC.TotalKilowatts;
            if (Amps > 45) {
                this.Quote.Warnings.Add(StringFormat(strLongLanguageText(slt.lsltHighAmpsOnPanel), 45));
            }
            else if (Amps > MaxAmpsFor10GWire && !(bIsCenterFeed) && !(bIsA100)) {
                this.Quote.Warnings.Add(StringFormat(strLongLanguageText(slt.lsltHighAmpsOnPanel), 30));
            }
            if (KW > 30) {
                this.Quote.Warnings.Add(strLanguageText("sltExceeds30KW") + " " + strLanguageText("sltContactReinkeBeforeQuoting"), WarningLevels.Decision, true);
            }
        }
        */
        if (eSystemType === SystemTypes.CanalFeedMaxigator) {
            if (cp.TenFourPowerCord > 0 || cp.FourteenTwoPowerCord > 0) {
                this.Quote.Warnings.Add(strLanguageText("sltPowerCord"));
            }
        }
        if (cp.TenFourPowerCord > 40 || cp.FourteenTwoPowerCord > 40) {
            this.Quote.Warnings.Add(strLanguageText("sltPowerCordMustBe0to40Feet"));
        }
        if ((cp.RemotePanelOptions.RollsOfShieldedResolverWire > 0 || cp.RemotePanelOptions.RollsOfUnshieldedResolverWire > 0) && ((cp.EndTowerPositioning !== EndTowerPositioningTypes.Resolver) || !(cp.RemotePanelOptions.RemotePanel))) {
            this.Quote.Warnings.Add(strLanguageText("sltResolverWire"));
        }
        if (cp.PivotAutoStop && cp.SwitchMount === SwitchMountTypes.CamPlate && (cp.CollectorRing === CollectorRingTypes.a11RingICII || cp.CollectorRing === CollectorRingTypes.a11RingUnited)) {
            this.Quote.Warnings.Add(strLanguageText("sltPivotAutoStopWithCamPlateUses11CReel"), WarningLevels.Decision);
        }
        if (cp.LowPressureSafetyReg && this.Quote.SprinklerConfigClass.DataValid() && this.Quote.System.SprinklerProperties.PivotPressure < 20) {
            this.Quote.Warnings.Add(strLanguageText("sltRecommendUltraLowPressureSwitch"), WarningLevels.Decision);
        }
        if (!(CollectorReelICCIOptionCountOk(this.Quote, (dto2: CollectorReelICIIOptionCountOkDto) => {
            this.Quote.Warnings.Add(dto2.FormattedMessage(), WarningLevels.Critical, true);
            return false;
        }
        ))) {
        }
    }
    public WritePO = (PO: PartsAssembler) => {
        let eSystemType: SystemTypes;
        let eHoseFeedType: HoseFeedTypes;
        let eGuidanceType: GuidanceTypes;
        let bCenterFeed: boolean;
        let bSACBWG: boolean;
        let bSAC: boolean;
        let b230V: boolean;
        let eCableGauge: number;
        let iGroundingBars: number;
        let ePigtailType: CollectorRingTypes;
        let bHoseFeedPivot: boolean;
        let bInternational: boolean;
        let eIntakeSize: IntakeSizes = IntakeSizes.a8;
        let bPivotingLateral: boolean;
        let bHasVRI: boolean;
        let bLastTowerHasValveBox: boolean;
        let s: ISystemBase = this.Quote.System;
        if (sysf.HasVRI(this.Quote.System)) {
            bHasVRI = true;
            if (PO.VRI.ValveBoxCount > 0) {
                bLastTowerHasValveBox = PO.VRI.LastTowerHasValveBox;
            }
        }
        bInternational = ohf.IsInternational(s.DealerProperties);
        b230V = sysf.Is230V(this.Quote.System);
        eSystemType = s.SystemProperties.SystemType;
        bSAC = sysf.HasSwingArmCorner(this.Quote.System);
        bCenterFeed = sysf.IsCenterFeed(this.Quote.System);
        switch (eSystemType) {
            case SystemTypes.CenterPivot:
                switch (s.Circle.CenterPivot.CenterPivotType) {
                    case CenterPivotTypes.E2100:
                        eIntakeSize = IntakeSizes.a10;
                        break;
                    case CenterPivotTypes.E2045:
                        eIntakeSize = IntakeSizes.a6;
                        break;
                }
                break;
            case SystemTypes.CanalFeedMaxigator:
                if (s.Lateral.CanalFeed.Pump === Pumps.a10RB) {
                    eIntakeSize = IntakeSizes.a10;
                }
                break;
            case SystemTypes.HoseFeedMaxigator:
                eHoseFeedType = s.Lateral.HoseFeed.HoseFeedType;
                bHoseFeedPivot = hff.Pivoting(s.Lateral.HoseFeed);
                bPivotingLateral = s.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral;
                break;
        }
        if (sysf.IsMaxigator(this.Quote.System)) {
            if (this.Quote.GuidanceClass.FieldSet.DataValid()) {
                eGuidanceType = s.Lateral.Guidance.GuidanceType;
            }
        }
        let Amps: number = this.Quote.System.FlangedSide.Tower.length ? PO.EC.FullLoadAmps : 0; // Electrical calculator requires at least one flanged side tower
        let bHighAmpPanel: boolean = Amps > MaxAmpsFor10GWire && Amps <= 45 && bCenterFeed;
        let cp: ControlPanel = this.Quote.System.ControlPanel;
        let bAutoReverse: boolean = cp.PivotAutoReverse || this.Quote.HasTowerAutoReverse();
        if (bPivotingLateral) {
            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "396015", 1); // MCP MTG KIT-HOSE DRAG-PIV MAXI
        }
        if (!(sysf.IsMaxigator(this.Quote.System)) && !(bPivotingLateral)) {
            switch (cp.PanelModel) {
                case PanelModels.RPMBasic:
                    if (bAutoReverse) {
                        PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350812", 1); // MCP-RPM-BASIC-TAR
                    }
                    else {
                        PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350811", 1); // MCP-RPM-BASIC
                    }
                    break;
                case PanelModels.RPMStandard:
                    if (bAutoReverse) {
                        PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350804", 1); // MCP-RPM STANDARD TWR A/R
                    }
                    else {
                        PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350801", 1); // MCP-RPM STANDARD
                    }
                    break;
                case PanelModels.RPMAdvanced:
                    PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350802", 1); // MCP-RPM ADVANCED
                    break;
                case PanelModels.RPMAdvancedPlus:
                    PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "351014", 1); // MCP-RPM ADV + 4.3"
                    break;
                case PanelModels.RPMConnect:
                    PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "351015", 1); // MCP-RPM CONNECT
                    break;
                case PanelModels.RPMPreferred:
                    PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350809", 1); // MCP-RPM PREFERRED TOUCH
                    break;
            }
        }
        else {
            switch (cp.PanelModel) {
                case PanelModels.RPMStandard:
                    if (eSystemType === SystemTypes.HoseFeedMaxigator && (eHoseFeedType === HoseFeedTypes.A100
                        || eHoseFeedType === HoseFeedTypes.CF200)
                    ) {
                        if (eHoseFeedType === HoseFeedTypes.A100) {
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350814", 1)  // MCP-RPM-A100 MAXI
                        }
                        if (eHoseFeedType === HoseFeedTypes.CF200) {
                            if (cp.PACTimer) {
                                PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "358803", 1)  // MCP-RPM STD-PAC-30A-REV2
                            } else {
                                PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "358800", 1)  // MCP-RPM STANDARD-30A-REV2
                            }
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "358935", 1)  // MOTOR RAIL ASY-REV2-MCP
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "358936", 1)  // LATERAL MOVE-REV2-MCP
                        }
                    }
                    else {
                        if (cp.CartAutoReverse) {
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350800", 1); // RPM STANDARD LATERAL MCP-TAR
                        }
                        else {
                            if (bHighAmpPanel) {
                                PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350807H", 1); // MCP-RPM LINEAR-STANDARD-45 AMP
                            }
                            else {
                                PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350807", 1); // MCP-RPM LINEAR-STANDARD
                            }
                        }
                    }
                    break;
                case PanelModels.RPMAdvanced:
                    if (bHighAmpPanel) {
                        PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350805H", 1); // MCP-RPM LINEAR-ADV-45 AMP
                    }
                    else {
                        PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350805", 1); // MCP-RPM LINEAR-ADVANCED
                    }
                    break;
                case PanelModels.RPMAdvancedPlus:
                    if (bHighAmpPanel) {
                        PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "351017H", 1)  // MCP-RPM LINEAR ADV+-45 AMP
                    } else {
                        PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "351017", 1)  // MCP-RPM LINEAR ADV+
                    }
                    break;
                case PanelModels.RPMPreferred:
                    if (bHighAmpPanel) {
                        PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350810H", 1); // MCP-RPM PREFERRED TOUCH-45 AMP
                    }
                    else {
                        if (eHoseFeedType === HoseFeedTypes.PivotingLateral) {
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350815", 1); // RPM TOUCH PIVOTING LATERAL
                        }
                        else {
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "350810", 1); // MCP-RPM LINEAR PREFERRED-TOUCH
                        }
                    }
                    break;
            }
        }
        if (this.Quote.IsA100_Only()) {
            if (Amps <= 35) {
                PO.InsertPart(schema.groups.ControlPanel_PanelFuse.groupId, "350848", 1); // FUSE OPTION-35 AMP-CLASS J
            }
            else if (Amps <= 45) {
                PO.InsertPart(schema.groups.ControlPanel_PanelFuse.groupId, "350849", 1); // FUSE OPTION-45 AMP-CLASS J
            }
            else if (true) {
                RaiseError(errOther, strLanguageText("sltPanelModel"));
            }
        }
        else if (this.Quote.IsCF200()) {
            if (Amps <= 20) {
                PO.InsertPart(schema.groups.ControlPanel_PanelFuse.groupId, "358928", 1)  // FUSE KIT 20A-REV2-MCP
            }
            else if (Amps <= 30) {
                PO.InsertPart(schema.groups.ControlPanel_PanelFuse.groupId, "358929", 1)  // FUSE KIT 30A-REV2-MCP
            }
            else if (true) {
                RaiseError(errOther, strLanguageText("sltPanelModel"));
            }
        }
        else {
            if (Amps <= MaxAmpsFor12GWire) {
                PO.InsertPart(schema.groups.ControlPanel_PanelFuse.groupId, "350951", 1); // FUSE OPTION 20 AMP-MCP
            }
            else if (Amps <= MaxAmpsFor10GWire) {
                PO.InsertPart(schema.groups.ControlPanel_PanelFuse.groupId, "350953", 1); // FUSE OPTION 30 AMP-MCP
            }
            else if (true) {
                if (!(bHighAmpPanel)) {
                    RaiseError(errOther, strLanguageText("sltPanelModel"));
                }
            }
        }
        if (this.Quote.IsTowable()) {
            if ([SystemTypes.CenterPivot, SystemTypes.KwikTow].indexOf(eSystemType) !== -1 && [PanelModels.RPMPreferred, PanelModels.RPMAdvanced, PanelModels.RPMAdvancedPlus].indexOf(cp.PanelModel) !== -1 && cp.EndTowerPositioning === EndTowerPositioningTypes.GPS) {
                PO.InsertPart(schema.groups.ControlPanel_Towable4PositionSwitch.groupId, "354056", 1); // SWITCH-TOWABLE BASE SEL KIT
            }
        }
        if (cp.PACTimer
            && ([PanelModels.RPMBasic, PanelModels.RPMStandard].indexOf(cp.PanelModel) !== -1)
            && !(eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200)) {
            // NOTE: PAC Timer option is integrated on MCP for CF200. 
            PO.InsertPart(schema.groups.ControlPanel_PACTimer.groupId, "350900", 1); // PAC OPTION-RPM STD
        }
        if (cp.PivotAutoStop) {
            switch (cp.SwitchMount) {
                case SwitchMountTypes.CamWheel:
                    switch (eSystemType) {
                        case SystemTypes.CenterPivot:
                            PO.InsertPart(schema.groups.ControlPanel_PivotAutoStopReverse.groupId, "353122", 1); // AUTO STOP SWITCH ASY-E60
                            break;
                        case SystemTypes.KwikTow:
                            PO.InsertPart(schema.groups.ControlPanel_PivotAutoStopReverse.groupId, "352559", 1); // AUTO STOP ASY-KWIK TOW
                            break;
                    }
                    break;
                case SwitchMountTypes.CamPlate:
                    PO.InsertPart(schema.groups.ControlPanel_PivotAutoStopReverse.groupId, "353115", 1); // ASY-AUTO STOP SW-CAM PLT STYLE
                    PO.InsertPart(schema.groups.ControlPanel_PivotAutoStopReverse.groupId, "350910", 1); // AUTO STOP OVERRIDE-RPM STD/ADV
                    break;
            }
        }
        if (cp.PivotAutoReverse) {
            if (cp.EndTowerPositioning === EndTowerPositioningTypes.None) {
                PO.InsertPart(schema.groups.ControlPanel_PivotAutoStopReverse.groupId, "352845", 1); // AUTO REV SW ASY-10C RPM
            }
        }
        if (cp.CartAutoReverse) {
            switch (eSystemType) {
                case SystemTypes.HoseFeedMaxigator:
                    PO.InsertPart(schema.groups.ControlPanel_PivotAutoStopReverse.groupId, "395814", 1); // CART A/R-OPT-REV ON HOSE
                    break;
                case SystemTypes.CanalFeedMaxigator:
                    PO.InsertPart(schema.groups.ControlPanel_PivotAutoStopReverse.groupId, "395813", 1); // CART A/R OPT-RAMS-CANAL FD
                    break;
            }
        }
        if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
            if (sidef.ElectricEOS(this.Quote.System.FlangedSide)) {
                PO.InsertPart(schema.groups.ControlPanel_EndGunShutoffOverride.groupId, "358902", 1)  // END GUN SW-1-REV2-STD-LAT
            }
            if (sidef.ElectricEOSSecondary(this.Quote.System.FlangedSide)) {
                PO.InsertPart(schema.groups.ControlPanel_EndGunShutoffOverride.groupId, "358903", 1)  // END GUN SW-2-REV2-STD-LAT
            }
            if (sidef.ElectricEOS(this.Quote.System.FlexSide)) {
                PO.InsertPart(schema.groups.ControlPanel_EndGunShutoffOverride.groupId, "358902", 1)  // END GUN SW-1-REV2-STD-LAT
            }
            if (sidef.ElectricEOSSecondary(this.Quote.System.FlexSide)) {
                PO.InsertPart(schema.groups.ControlPanel_EndGunShutoffOverride.groupId, "358903", 1)  // END GUN SW-2-REV2-STD-LAT
            }
        }
        if (cp.EndGunShutoffOverride && ([PanelModels.RPMBasic, PanelModels.RPMStandard].indexOf(cp.PanelModel) !== -1)) {
            if (sidef.ElectricEOS(this.Quote.System.FlangedSide)) {
                PO.InsertPart(schema.groups.ControlPanel_EndGunShutoffOverride.groupId, "350901", 1); // END GUN SHUTOFF SWITCH
            }
            if (sidef.ElectricEOS(this.Quote.System.FlexSide)) {
                PO.InsertPart(schema.groups.ControlPanel_EndGunShutoffOverride.groupId, "350901", 1); // END GUN SHUTOFF SWITCH
            }
        }
        if (cp.GenStartControl) {
            switch (cp.PanelModel) {
                case PanelModels.RPMBasic:
                    PO.InsertPart(schema.groups.ControlPanel_GeneratorStartRunControl.groupId, "350963", 1); // GEN CONTROL SWITCH RPM BASIC
                    break;
                case PanelModels.RPMStandard:
                    if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
                        PO.InsertPart(schema.groups.ControlPanel_GeneratorStartRunControl.groupId, "358906", 1)  // GEN SW-NORMALLY CLSD-STD-REV2
                    } else {
                        PO.InsertPart(schema.groups.ControlPanel_GeneratorStartRunControl.groupId, "350903", 1)  // GENERATOR CONTROL SWITCH
                    }
                    break;
                case PanelModels.RPMAdvanced:
                    PO.InsertPart(schema.groups.ControlPanel_GeneratorStartRunControl.groupId, "350903", 1); // GENERATOR CONTROL SWITCH
                    break;
                case PanelModels.RPMPreferred:
                    PO.InsertPart(schema.groups.ControlPanel_GeneratorStartRunControl.groupId, "350993", 1); // GENERATOR SWITCH-RPM TOUCH
                    break;
                case PanelModels.RPMAdvancedPlus:
                case PanelModels.RPMConnect:
                    PO.InsertPart(schema.groups.ControlPanel_GeneratorStartRunControl.groupId, "351025", 1); // GENERATOR SWITCH-RPM ADV PLUS
                    break;
            }
        }
        if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.A100) {
            PO.InsertPart(schema.groups.ControlPanel_SubmersiblePumpKit.groupId, "350960", 1); // SUBM. PUMP KIT-15 HP, 25 AMP
        }

        if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
            PO.InsertPart(schema.groups.ControlPanel_SubmersiblePumpKit.groupId, "358924", 1)  // CF200 PUMP CONTROL KIT-REV2
            PO.InsertPart(schema.groups.ControlPanel_SubmersiblePumpKit.groupId, "358932", 1)  // PRIMING PUMP-REV2-30A-MCP
        }

        if (cp.PowerAutoRestart) {
            switch (cp.PanelModel) {
                case PanelModels.RPMBasic:
                case PanelModels.RPMStandard:
                    if (bSAC) {
                        if (!(cp.PressureRestart)) {
                            PO.InsertPart(schema.groups.ControlPanel_PowerAutoRestart.groupId, "350946", 1); // RESTART-RPM STD SAC
                        }
                    }
                    else if (!((cp.PressureRestart && (!(bAutoReverse)) && (cp.LowPressureSafetyReg || cp.LowPressureSafetyLP) && cp.PanelModel === PanelModels.RPMStandard))) {
                        PO.InsertPart(schema.groups.ControlPanel_PowerAutoRestart.groupId, "350912", 1); // RESTART-RPM STD
                    }
                    break;
            }
        }
        if (cp.ChemAuxControl && ([PanelModels.RPMBasic, PanelModels.RPMStandard].indexOf(cp.PanelModel) !== -1)) {
            if (sysf.IsMaxigator(this.Quote.System)) {
                if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
                    PO.InsertPart(schema.groups.ControlPanel_ChemAuxControl.groupId, "358907", 1)  // CHEM-AUX CONT SWITCH-REV2-STD
                } else {
                    PO.InsertPart(schema.groups.ControlPanel_ChemAuxControl.groupId, "350904L", 1)  // CHEM/AUX SWITCH-LATERAL
                }
            }
            else {
                PO.InsertPart(schema.groups.ControlPanel_ChemAuxControl.groupId, "350904", 1); // CHEM/AUX CONTROL SWITCH
            }
        }

        if (cp.InjectorPump) {
            if (sysf.IsMaxigator(this.Quote.System)) {
            if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
                    PO.InsertPart(schema.groups.ControlPanel_InjectorPumpReceptacle.groupId, "358908", 1);  // CHEM INJECT ASY-20A-REV2
                }
            }
        }

        if (cp.InjectorPump30Amp) { // ROE-790 - CF200 - This option is specific to CF200 (implemented in UI)
            if (sysf.IsMaxigator(this.Quote.System)) {
                if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
                    PO.InsertPart(schema.groups.ControlPanel_InjectorPumpReceptacle.groupId, "358909", 1);  // CHEM INJECT ASY-30A-REV2
            } else {
                    PO.InsertPart(schema.groups.ControlPanel_InjectorPumpReceptacle.groupId, "350905L", 1);  // AUX RECEPTACLE KIT-LATERAL
            }
            } else {
                PO.InsertPart(schema.groups.ControlPanel_InjectorPumpReceptacle.groupId, "350905", 1);  // AUX RECEPTACLE KIT
            }
        }

        if (cp.RainGauge) {
            PO.InsertPart(schema.groups.ControlPanel_RAMSWeatherStation.groupId, "353413", 1); // RAIN GAUGE OPT-RAMS WTHR EII
            PO.InsertPart(schema.groups.ControlPanel_RAMSWeatherStation.groupId, "350956", 1); // RAIN GAUGE-RPM TOUCH CP KIT
        }
        if (cp.WindGauge) {
            PO.InsertPart(schema.groups.ControlPanel_RAMSWeatherStationWindGauge.groupId, "353420", 1); // WIND VANE-RPM PREF
        }
        if (cp.MCPSpanCableDisconnect) {
            PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "351012", 1); // MCP SPAN CABLE DISC. OPT
        }
        if (cp.RemotePanelOptions.RemotePanel) {
            PO.InsertPart(schema.groups.ControlPanel_RemoteControlPanel.groupId, "350922", 1); // PIVOT JUNCTION BOX-RPM CP KIT
            PO.InsertPart(schema.groups.ControlPanel_RemoteControlPanel.groupId, "350922A", 1); // PIVOT JUNCTION BOX W/HARDWARE
        }
        if (cp.RemoteMCPLessMount) {
            PO.InsertPart(schema.groups.ControlPanel_RemoteControlPanel.groupId, "350922", 1); // PIVOT JUNCTION BOX-RPM CP KIT
            PO.InsertPart(schema.groups.ControlPanel_RemoteControlPanel.groupId, "350922B", 1); // PIVOT JUNCTION BOX W/O RMT MTS
        }
        if (cp.LightningArrestor || bSAC) {
            if (b230V) {
                PO.InsertPart(schema.groups.ControlPanel_LightningArrestor.groupId, "350926", 1); // LIGHTNING ARRESTOR-1P
            }
            else {
                if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
                    PO.InsertPart(schema.groups.ControlPanel_LightningArrestor.groupId, "358904UL", 1);  // LIGHTNING ARRESTOR-3PH-UL-REV2
                } else {
                    PO.InsertPart(schema.groups.ControlPanel_LightningArrestor.groupId, "350902UL", 1);  // LIGHTNING ARRESTOR UL LISTED
                }
            }
        }
        if (bSAC) {
            if (this.Quote.SwingArmClass.FieldSet.DataValid()) {
                PO.InsertPart(schema.groups.ControlPanel_SACPLCDiagSwitch.groupId, "350945", 1); // DIAGNOSTIC SWITCH ASY-PLC SAC
            }
        }
        if (cp.LowVoltageSafety) {
            switch (cp.PanelModel) {
                case PanelModels.RPMBasic:
                case PanelModels.RPMStandard:
                case PanelModels.RPMAdvanced:
                case PanelModels.RPMAdvancedPlus:
                case PanelModels.RPMConnect:
                    PO.InsertPart(schema.groups.ControlPanel_LowVoltageSafety.groupId, "352292", 1); // LOW VOLT RELAY-STD
                    break;
                case PanelModels.RPMPreferred:
                    PO.InsertPart(schema.groups.ControlPanel_LowVoltageSafety.groupId, "352291", 1); // LOW VOLT RELAY-RAMS
                    break;
            }
        }
        if (cp.LowPressureSafetyReg || cp.LowPressureSafetyLP) {
            switch (cp.PanelModel) {
                case PanelModels.RPMBasic:
                    if (cp.LowPressureSafetyReg) {
                        PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350978", 1); // PRESS SW-RPM-BASIC-BARKSDALE
                    }
                    if (cp.LowPressureSafetyLP) {
                        PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350652", 1); // LOW PRESS SW-RPM BASIC-4.5 PSI
                    }
                    break;
                case PanelModels.RPMStandard:
                    if (sysf.IsMaxigator(this.Quote.System)) {
                        if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "358911", 1)  // PRESS SW-RPM STD REV2-15PSI
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "358911A", 1)  // PRESSURE DEVICE TUBING KIT-ALL
                        } else {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350908L", 1)  // PRESS SWITCH-STD-LATERAL
                        }
                    }
                    else {
                        if (cp.LowPressureSafetyReg) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350958", 1); // PRESS SW-RPM-BARKSDALE
                        }
                        if (cp.LowPressureSafetyLP) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350655", 1); // LOW PRESS SW-RPM-4.5 PSI
                        }
                    }
                    break;
                case PanelModels.RPMAdvanced:
                    if (sysf.IsMaxigator(this.Quote.System)) {
                        PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350918L", 1); // PRESS SWITCH-ADV-LATERAL
                    }
                    else {
                        if (cp.LowPressureSafetyReg) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350968", 1); // PRESS SW-RPM ADV-BARKSDALE
                        }
                        if (cp.LowPressureSafetyLP) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350653", 1); // LOW PRESS SW-RPM ADV-4.5 PSI
                        }
                    }
                    break;
                case PanelModels.RPMPreferred:
                    if (sysf.IsMaxigator(this.Quote.System)) {
                        if (bHoseFeedPivot && !(this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral)) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350909L", 1); // PRESS SWITCH-PREF-LATERAL
                        }
                        else {
                            if (sysf.FieldSets(this.Quote.System).HoseFeed.DataValid() && this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                                PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350959", 1); // PRESS SW-RPM PREF-BARKSDALE
                            }
                            else {
                                PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350909L", 1); // PRESS SWITCH-PREF-LATERAL
                            }
                        }
                    }
                    else {
                        if (cp.LowPressureSafetyReg) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350959", 1); // PRESS SW-RPM PREF-BARKSDALE
                        }
                        if (cp.LowPressureSafetyLP) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350654", 1); // LOW PRESS SW-RPM PRF-4.5 PSI
                        }
                    }
                    break;
                case PanelModels.RPMAdvancedPlus:
                case PanelModels.RPMConnect:
                    if (sysf.IsMaxigator(this.Quote.System)) {
                        if (bHoseFeedPivot && !(this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral)) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350909L", 1); // PRESS SWITCH-PREF-LATERAL
                        }
                        else {
                            if (sysf.FieldSets(this.Quote.System).HoseFeed.DataValid() && this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                                PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350959", 1); // PRESS SW-RPM PREF-BARKSDALE
                            }
                            else {
                                PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350909L", 1); // PRESS SWITCH-PREF-LATERAL
                            }
                        }
                    }
                    else {
                        if (cp.LowPressureSafetyReg) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350959", 1); // PRESS SW-RPM PREF-BARKSDALE
                        }
                        if (cp.LowPressureSafetyLP) {
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350654", 1); // LOW PRESS SW-RPM PRF-4.5 PSI
                        }
                    }
                    break;
            }
            switch (eSystemType) {
                case SystemTypes.CenterPivot:
                    PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350958A", 1); // TUBING KIT PRESS SW 7.5
                    break;
                case SystemTypes.KwikTow:
                    PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350908B", 1); // PRESSURE SWITCH-RPM-KWIK TOW
                    break;
                case SystemTypes.HoseFeedMaxigator:
                    switch (eHoseFeedType) {
                        case HoseFeedTypes.Sugargator:
                            PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350934", 1); // PRESSURE SW-RPM-SUGAR-LINEAR
                            break;

                        case HoseFeedTypes.CF200:
                            // Do nothing
                            break;
                        default:
                            if (bHoseFeedPivot && !(this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral)) {
                                PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350908B", 1); // PRESSURE SWITCH-RPM-KWIK TOW
                            }
                            else {
                                if (sysf.FieldSets(this.Quote.System).HoseFeed.DataValid() && this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                                    PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350958A", 1); // TUBING KIT PRESS SW 7.5
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_LowPressureSwitch.groupId, "350908B", 1); // PRESSURE SWITCH-RPM-KWIK TOW
                                }
                            }
                            break;
                    }
                    break;
                case SystemTypes.CanalFeedMaxigator:
                    break;
            }
            if (cp.PressureRestart) {
                switch (cp.PanelModel) {
                    case PanelModels.RPMStandard:
                        if (!(bAutoReverse)) {
                            if (!(cp.PowerAutoRestart)) {
                                if (!(bSAC)) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureStart.groupId, "350948", 1); // PRESS START OPT-RPM STD
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureStart.groupId, "350949", 1); // PRESS START - RPM STD SAC
                                }
                            }
                            else {
                                if (!(bSAC)) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureStart.groupId, "350979", 1); // AUTO-RESTART & PRESS-RST OPT
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureStart.groupId, "350976", 1); // RESTART-PRSS PWR RPM STD SAC
                                }
                            }
                        }
                        else {
                            if (!(cp.PowerAutoRestart)) {
                                if (!(bSAC)) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureStart.groupId, "350966", 1); // PRESS START OPT-RPM STD A/R
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureStart.groupId, "350967", 1); // PRESS START - RPM STD A/R SAC
                                }
                            }
                            else {
                                if (!(bSAC)) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureStart.groupId, "350966", 1); // PRESS START OPT-RPM STD A/R
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureStart.groupId, "350977", 1); // RSTRT-PRSS PWR RPM STD TAR SAC
                                }
                            }
                        }
                        break;
                    case PanelModels.RPMAdvanced:
                        PO.InsertPart(schema.groups.ControlPanel_PressureStart.groupId, "350943", 1); // RPM ADV PRESS START OPT
                        break;
                }
            }
        }
        if (cp.NonFuseCarrier) {
            PO.InsertPart(schema.groups.ControlPanel_NonFuseCarrier.groupId, "350954", 1); // NON FUSE CARRIER OPTION
        }
        if (sysf.IsMaxigator(this.Quote.System)) {
            if (this.Quote.GuidanceClass.FieldSet.DataValid()) {
                if (this.Quote.System.Lateral.Guidance.Location === GuidanceLocations.PowerTower) {
                    switch (this.Quote.System.SystemProperties.SystemType) {
                        case SystemTypes.HoseFeedMaxigator:
                            if (this.Quote.System.Lateral.HoseFeed.HoseFeedType !== HoseFeedTypes.PivotingLateral
                                && this.Quote.System.Lateral.HoseFeed.HoseFeedType !== HoseFeedTypes.CF200) { // PER ROR-135
                                PO.InsertPart(schema.groups.ControlPanel_GuidanceKit.groupId, "350932", 1); // GUIDANCE KIT-MCP-RPM LINEAR
                            }
                            if (this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.CF200) {
                                PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "358937", 1)  // POWER TOWER GUIDE-REV2-MCP
                            }
                            break;
                        case SystemTypes.CanalFeedMaxigator:
                            if (this.Quote.System.Lateral.Guidance.GuidanceType === GuidanceTypes.Cable) {
                                PO.InsertPart(schema.groups.ControlPanel_GuidanceKit.groupId, "350932", 1); // GUIDANCE KIT-MCP-RPM LINEAR
                            }
                            break;
                    }
                }
            }
            switch (this.Quote.System.SystemProperties.SystemType) {
                case SystemTypes.KwikTow:
                    if (this.Quote.System.Circle.KwikTow.SafetyValve) {
                        switch (cp.PanelModel) {
                            case PanelModels.RPMBasic:
                            case PanelModels.RPMStandard:
                            case PanelModels.RPMAdvanced:
                                PO.InsertPart(schema.groups.ControlPanel_ShutdownValveKit.groupId, "350935", 1); // ELECT KIT-SHUT DOWN VALVE-MAXI
                                break;
                            case PanelModels.RPMPreferred:
                                PO.InsertPart(schema.groups.ControlPanel_ShutdownValveKit.groupId, "350939", 1); // ELECT KIT-S.D. VALVE-RAMS
                                break;
                        }
                    }
                    break;
                case SystemTypes.HoseFeedMaxigator:
                    if (this.Quote.System.Lateral.HoseFeed.SafetyValve) {
                        switch (cp.PanelModel) {
                            case PanelModels.RPMBasic:
                            case PanelModels.RPMStandard:
                            case PanelModels.RPMAdvanced:
                                PO.InsertPart(schema.groups.ControlPanel_ShutdownValveKit.groupId, "350935", 1); // ELECT KIT-SHUT DOWN VALVE-MAXI
                                break;
                            case PanelModels.RPMPreferred:
                            case PanelModels.RPMAdvancedPlus:
                                if (this.Quote.System.Lateral.HoseFeed.HoseFeedType !== HoseFeedTypes.PivotingLateral) {
                                    PO.InsertPart(schema.groups.ControlPanel_ShutdownValveKit.groupId, "350939", 1); // ELECT KIT-S.D. VALVE-RAMS
                                }
                                break;
                        }
                    }
                    break;
            }
            switch (eGuidanceType) {
                case GuidanceTypes.BuriedWire:
                case GuidanceTypes.NavigatorGPS:
                    if (eSystemType === SystemTypes.HoseFeedMaxigator && sysf.FieldSets(this.Quote.System).HoseFeed.DataValid()) {
                        if (this.Quote.System.Lateral.HoseFeed.HoseFeedType !== HoseFeedTypes.PivotingLateral
                            && this.Quote.System.Lateral.HoseFeed.HoseFeedType !== HoseFeedTypes.CF200) { // PER ROE-135
                            PO.InsertPart(schema.groups.ControlPanel_GuidanceLampKit.groupId, "350938", 1); // LAMP KIT-BWG OR GPS GUIDE-MAXI
                        }
                    }
                    if (eSystemType === SystemTypes.CanalFeedMaxigator && sysf.FieldSets(this.Quote.System).CanalFeed.DataValid()) {
                        PO.InsertPart(schema.groups.ControlPanel_GuidanceLampKit.groupId, "350938", 1); // LAMP KIT-BWG OR GPS GUIDE-MAXI
                    }
                    break;
            }
        }
        if ((cp.PressureTransducerOptions ?? PressureTransducerOptions.None) !== PressureTransducerOptions.None) {
            if (cp.PressureTransducerOptions === PressureTransducerOptions.AtPivot) {
                switch (cp.PanelModel) {
                    case PanelModels.RPMAdvancedPlus:
                        switch (cp.EndTowerPositioning) {
                            case EndTowerPositioningTypes.None:
                                if (!(this.Quote.HoseFeedClass.DataValid())) {
                                    if (cp.PressureTransducer0to25PSI) {
                                        PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673-25", 1); // TRANS 25 PSI ADV +/CONNECT OPT
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673", 1); // TRANSDUCER OPT ADV +/CONNECT
                                    }
                                }
                                break;
                            case EndTowerPositioningTypes.Resolver:
                                if (!(this.Quote.HoseFeedClass.DataValid())) {
                                    if (cp.PressureTransducer0to25PSI) {
                                        PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673-25", 1); // TRANS 25 PSI ADV +/CONNECT OPT
                                    }
                                    else {
                                        PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673", 1); // TRANSDUCER OPT ADV +/CONNECT
                                    }
                                }
                                break;
                            case EndTowerPositioningTypes.GPS:
                                if (cp.PressureTransducer0to25PSI) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673-25", 1); // TRANS 25 PSI ADV +/CONNECT OPT
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673", 1); // TRANSDUCER OPT ADV +/CONNECT
                                }
                                if (!(sysf.IsMaxigator(this.Quote.System))) {
                                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                                }
                                break;
                        }
                        break;
                    case PanelModels.RPMConnect:
                        switch (cp.EndTowerPositioning) {
                            case EndTowerPositioningTypes.None:
                                break;
                            case EndTowerPositioningTypes.Resolver:
                                if (cp.PressureTransducer0to25PSI) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673-25", 1); // TRANS 25 PSI ADV +/CONNECT OPT
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673", 1); // TRANSDUCER OPT ADV +/CONNECT
                                }
                                break;
                            case EndTowerPositioningTypes.GPS:
                                if (cp.PressureTransducer0to25PSI) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673-25", 1); // TRANS 25 PSI ADV +/CONNECT OPT
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350673", 1); // TRANSDUCER OPT ADV +/CONNECT
                                }
                                if (!(sysf.IsMaxigator(this.Quote.System))) {
                                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                                }
                                break;
                        }
                        break;
                    case PanelModels.RPMPreferred:
                        switch (cp.EndTowerPositioning) {
                            case EndTowerPositioningTypes.None:
                                break;
                            case EndTowerPositioningTypes.Resolver:
                                if (cp.PressureTransducer0to25PSI) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670-25", 1); // TRANS 25 PSI OP PREF TOUCH MCP
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670", 1); // TRANSDUCER OPT PREF TOUCH MCP
                                }
                                break;
                            case EndTowerPositioningTypes.GPS:
                                if (cp.PressureTransducer0to25PSI) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670-25", 1); // TRANS 25 PSI OP PREF TOUCH MCP
                                }
                                else {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "350670", 1); // TRANSDUCER OPT PREF TOUCH MCP
                                }
                                if (bHasVRI) {
                                }
                                else {
                                    if (!(sysf.IsMaxigator(this.Quote.System))) {
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                                    }
                                }
                                break;
                        }
                        break;
                }
            }
            if (cp.PressureTransducerOptions === PressureTransducerOptions.AtEnd) {
                switch (cp.PanelModel) {
                    case PanelModels.RPMAdvancedPlus:
                        switch (cp.EndTowerPositioning) {
                            case EndTowerPositioningTypes.None:
                                break;
                            case EndTowerPositioningTypes.Resolver:
                                break;
                            case EndTowerPositioningTypes.GPS:
                                if (!(sysf.IsMaxigator(this.Quote.System))) {
                                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                }
                                break;
                        }
                        break;
                    case PanelModels.RPMConnect:
                        switch (cp.EndTowerPositioning) {
                            case EndTowerPositioningTypes.None:
                                break;
                            case EndTowerPositioningTypes.Resolver:
                                break;
                            case EndTowerPositioningTypes.GPS:
                                if (!(sysf.IsMaxigator(this.Quote.System))) {
                                    PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "357093", 1); // GPS CDW EOS KIT PSI TRANS
                                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                }
                                break;
                        }
                        break;
                    case PanelModels.RPMPreferred:
                        switch (cp.EndTowerPositioning) {
                            case EndTowerPositioningTypes.None:
                                break;
                            case EndTowerPositioningTypes.Resolver:
                                break;
                            case EndTowerPositioningTypes.GPS:
                                if (bHasVRI) {
                                }
                                else {
                                    if (!(sysf.IsMaxigator(this.Quote.System))) {
                                        PO.InsertPart(schema.groups.ControlPanel_VRIPanelKit.groupId, "350964", 1); // VRI CONTROL PANEL OPTION
                                        PO.InsertPart(schema.groups.ControlPanel_PressureTransducer.groupId, "357093", 1); // GPS CDW EOS KIT PSI TRANS
                                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                    }
                                }
                                break;
                        }
                        break;
                }
            }
        }
        else {
            switch (cp.PanelModel) {
                case PanelModels.RPMAdvancedPlus:
                    switch (cp.EndTowerPositioning) {
                        case EndTowerPositioningTypes.None:
                            break;
                        case EndTowerPositioningTypes.Resolver:
                            break;
                        case EndTowerPositioningTypes.GPS:
                            if (!(sysf.IsMaxigator(this.Quote.System))) {
                                PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                            }
                            break;
                    }
                    break;
                case PanelModels.RPMAdvanced:
                    switch (cp.EndTowerPositioning) {
                        case EndTowerPositioningTypes.None:
                            break;
                        case EndTowerPositioningTypes.Resolver:
                            break;
                        case EndTowerPositioningTypes.GPS:
                            if (!(sysf.IsMaxigator(this.Quote.System))) {
                                PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                            }
                            break;
                    }
                    break;
                case PanelModels.RPMConnect:
                    switch (cp.EndTowerPositioning) {
                        case EndTowerPositioningTypes.None:
                            break;
                        case EndTowerPositioningTypes.Resolver:
                            break;
                        case EndTowerPositioningTypes.GPS:
                            if (!(sysf.IsMaxigator(this.Quote.System))) {
                                PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                            }
                            break;
                    }
                    break;
                case PanelModels.RPMPreferred:
                    if (bHasVRI) {
                    }
                    else {
                        switch (cp.EndTowerPositioning) {
                            case EndTowerPositioningTypes.None:
                                break;
                            case EndTowerPositioningTypes.Resolver:
                                break;
                            case EndTowerPositioningTypes.GPS:
                                if (!(sysf.IsMaxigator(this.Quote.System))) {
                                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970", 1); // GPS CDW MCP KIT
                                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350970A", 1); // GPS CDW EOS KIT
                                }
                                break;
                        }
                    }
                    break;
            }
        }
        if (!(sysf.IsMaxigator(this.Quote.System)) && !(bLastTowerHasValveBox)) {
            if (bSAC) {
                PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "357027", 1); // TOWER BOX-RETRO MNT KIT 6-5/8"
            }
        }
        if (cp.FrostControl) {
            if (sysf.IsMaxigator(this.Quote.System)) {
                if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
                    PO.InsertPart(schema.groups.ControlPanel_TemperatureControl.groupId, "358914", 1)  // LOW TEMP SHUTDOWN-STD-REV2
                } else {
                    PO.InsertPart(schema.groups.ControlPanel_TemperatureControl.groupId, "350911L", 1)  // FROST SWITCH-RPM STD-LATERAL
                }
            }
            else {
                PO.InsertPart(schema.groups.ControlPanel_TemperatureControl.groupId, "350911", 1); // FROST SWITCH-RPM STD
            }
        }
        if (cp.TemperatureProbe) {
            PO.InsertPart(schema.groups.ControlPanel_TemperatureProbe.groupId, "350913", 1); // TEMPERATURE PROBE-RPM ADV/PREF
        }
        if (cp.PanelUL) {
            if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
                PO.InsertPart(schema.groups.ControlPanel_ULCCertification.groupId, "358938", 1)  // UL CERTIFICATION-REV2-MCP
            } else {
                PO.InsertPart(schema.groups.ControlPanel_ULCCertification.groupId, "350906", 1)  // UL CERTIFICATION-MCP
            }
        }
        if (cp.GFI) {
            if (eSystemType === SystemTypes.HoseFeedMaxigator && eHoseFeedType === HoseFeedTypes.CF200) {
                PO.InsertPart(schema.groups.ControlPanel_GroundFaultInterrupter.groupId, "358910", 1)  // GFI/REC OPTION-REV2-MCP
            } else {
                PO.InsertPart(schema.groups.ControlPanel_GroundFaultInterrupter.groupId, "350907", 1)  // RESIDUAL CURRENT DEVICE-GFI
            }
        }
        switch (cp.EndTowerPositioning) {
            case EndTowerPositioningTypes.Resolver:
                if ([PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect].indexOf(cp.PanelModel) !== -1) {
                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "351026", 1); // RPM ADV PLUS RESOLVER OPTION
                }
                else {
                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "350919", 1); // RPM ADV/PREF RESOLVER KIT
                }
                if (eSystemType === SystemTypes.CenterPivot && this.Quote.System.Circle.CenterPivot.CenterPivotType === CenterPivotTypes.E2045 && [PanelModels.RPMAdvanced, PanelModels.RPMAdvancedPlus, PanelModels.RPMConnect, PanelModels.RPMPreferred].indexOf(cp.PanelModel) !== -1) {
                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "352660", 1); // HRDWRE KIT-RESOLVER 6" PVT CTR
                }
                break;
            case EndTowerPositioningTypes.GPS:
                if (eSystemType === SystemTypes.CanalFeedMaxigator && sysf.FieldSets(this.Quote.System).CanalFeed.DataValid()) {
                    PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "357007", 1); // GPS RESOLVER OPTION MAXI
                }
                if (eSystemType === SystemTypes.HoseFeedMaxigator && sysf.FieldSets(this.Quote.System).HoseFeed.DataValid()) {
                    let hf = s.Lateral.HoseFeed;
                    if (hf.HoseFeedType === HoseFeedTypes.PivotingLateral) {
                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "395992", 1); // GPS RESOLVER OPT-PIVOT-MAXI
                    }
                    else {
                        PO.InsertPart(schema.groups.ControlPanel_EndTowerControl.groupId, "357007", 1); // GPS RESOLVER OPTION MAXI
                        if (hf.Towable || hf.CenterLift || hf.PivotOption) {
                            PO.InsertPart(schema.groups.ControlPanel_GPSBaseSelectorSwith.groupId, "351000", 1); // BASE SELECTOR SWITCH-TOW MAXI
                        }
                    }
                }
                break;
        }
        if (bSACBWG) {
            PO.InsertPart(schema.groups.ControlPanel_SACBuriedWireGuidanceTransmitter.groupId, "350920", 1); // BWG TRANSMITTER
        }
        if (b230V) {
            switch (cp.PanelModel) {
                case PanelModels.RPMBasic:
                case PanelModels.RPMStandard:
                    PO.InsertPart(schema.groups.ControlPanel_230VOption.groupId, "350924", 1); // SINGLE PHASE OPTION-RPM STD
                    break;
                default:
                    PO.InsertPart(schema.groups.ControlPanel_230VOption.groupId, "350925", 1); // SINGLE PHASE OPT-RPM ADV&PREF
                    break;
            }
        }
        if (this.Quote.System.FlangedSide.Span.length) {
            const autoGaugeResult = PO.EC.AutoGauge();
            eCableGauge = autoGaugeResult.flangedSide[0].cableGauge;
        }
        if (cp.GroundingBar) {
            PO.InsertPart(schema.groups.ControlPanel_GroundRodKit.groupId, "352562", 1); // GROUND ROD KIT- EXTRA
            iGroundingBars = 1;
            if (this.Quote.RightSpansClass.FieldSet.DataValid()) {
                iGroundingBars += sidef.NumberOfSpans(this.Quote.System.FlangedSide);
            }
            if (this.Quote.LeftSpansClass.FieldSet.DataValid()) {
                iGroundingBars += sidef.NumberOfSpans(this.Quote.System.FlexSide);
            }
            if (cp.RemotePanelOptions.RemotePanel) {
                iGroundingBars += 1;
            }
            PO.InsertPart(schema.groups.ControlPanel_GroundingBars.groupId, "352563", iGroundingBars); // GROUNDING BAR OPTION
        }
        let bFlowTubeKitAllowed: boolean = (!(cp.FlowtubeWithFS100) && !(cp.FlowtubeWithVanes) && sysf.FlowTubeKitAllowed(this.Quote.System));
        switch (cp.FlowmeterModel) {
            case FlowmeterModels.McPropeller:
                switch (cp.Flowmeter) {
                    case FlowmeterTypes.Vertical:
                        if (bFlowTubeKitAllowed && sysf.VerticalFlowTubeKitAllowed(this.Quote.System)) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "353266", 1); // FLOW TUBE-8" X 14"-MCRMTR PROP
                        }
                        if (cp.FlowmeterPulse) {
                            switch (eIntakeSize) {
                                case IntakeSizes.a10:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "355317", 1); // FLOWMETER,10"MCPROP-VRT-PULS
                                    break;
                                case IntakeSizes.a8:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "355321", 1); // FLOWMETER,8"MCPROP-VRT-PULS
                                    break;
                                case IntakeSizes.a6:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "353255", 1); // FLOWMETER,6"MCPROP-VRT
                                    break;
                            }
                        }
                        else {
                            switch (eIntakeSize) {
                                case IntakeSizes.a10:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "355318", 1); // FLOWMETER,10"MCPROP-VRT
                                    break;
                                case IntakeSizes.a8:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "355316", 1); // FLOWMETER,8"MCPROP-VRT
                                    break;
                                case IntakeSizes.a6:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "353257", 1); // FLOWMETER,6"MCPROP-VRT-PULS
                                    break;
                            }
                        }
                        break;
                    case FlowmeterTypes.Horizontal:
                        if (cp.FlowtubeWithFS100) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "353268", 1); // FLOW TUBE-8"-FOR FS100
                            PO.InsertPart(schema.groups.Flowmeters.groupId, "353269", 1); // MCCROMETER 8" FLOW STRAIGHTENR
                        }
                        else if (cp.FlowtubeWithVanes) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "353267", 1); // FLOW TUBE-8" X 34" W/VANES
                        }
                        else if (bFlowTubeKitAllowed) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "353266", 1); // FLOW TUBE-8" X 14"-MCRMTR PROP
                        }
                        if (cp.FlowmeterPulse) {
                            switch (eIntakeSize) {
                                case IntakeSizes.a10:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "353407-10", 1); // FLOWMETER,10"MCPROP-HRZ-PULS
                                    break;
                                case IntakeSizes.a8:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "353407", 1); // FLOWMETER,8"MCPROP-HRZ-PULS
                                    break;
                                case IntakeSizes.a6:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "353256", 1);  // FLOWMETER,6"MCPROP-HRZ-PULS
                                    break;
                            }
                        }
                        else {
                            switch (eIntakeSize) {
                                case IntakeSizes.a10:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "355320", 1); // FLOWMETER,10"MCPROP-HRZ
                                    break;
                                case IntakeSizes.a8:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "355319", 1); // FLOWMETER,8"MCPROP-HRZ
                                    break;
                                case IntakeSizes.a6:
                                    PO.InsertPart(schema.groups.Flowmeters.groupId, "353254", 1);  // FLOWMETER,6"MCPROP-HRZ
                                    break;
                            }
                        }
                        break;
                }
                break;
            case FlowmeterModels.McMag:
                switch (cp.Flowmeter) {
                    case FlowmeterTypes.Vertical:
                        if (bFlowTubeKitAllowed && sysf.VerticalFlowTubeKitAllowed(this.Quote.System)) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "353265", 1); // FLOW TUBE-8" X 14"-MCROMTR MAG
                        }
                        break;
                    case FlowmeterTypes.Horizontal:
                        if (cp.FlowtubeWithVanes) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "353267", 1); // FLOW TUBE-8" X 34" W/VANES
                        }
                        else if (bFlowTubeKitAllowed) {
                            PO.InsertPart(schema.groups.CenterPivot_PivotRiserPipe.groupId, "353265", 1); // FLOW TUBE-8" X 14"-MCROMTR MAG
                        }
                        break;
                }
                switch (eIntakeSize) {
                    case IntakeSizes.a10:
                        PO.InsertPart(schema.groups.Flowmeters.groupId, "353272", 1); // FLOWMETER,10"MCMAG 3000W/PULSE
                        break;
                    case IntakeSizes.a8:
                        PO.InsertPart(schema.groups.Flowmeters.groupId, "353262", 1); // FLOWMETER,8"MCMAG 3000 W/PULSE
                        break;
                    case IntakeSizes.a6:
                        PO.InsertPart(schema.groups.Flowmeters.groupId, "353252", 1); // FLOWMETER,6"MCMAG 3000 W/PULSE
                        break;
                }
                break;
            case FlowmeterModels.Seametrics:
                switch (eIntakeSize) {
                    case IntakeSizes.a10:
                        PO.InsertPart(schema.groups.Flowmeters.groupId, "353271", 1)  // FLOWMTR-SEAMETRICS 10" W/PULSE
                        break;
                    case IntakeSizes.a8:
                        PO.InsertPart(schema.groups.Flowmeters.groupId, "353261", 1)  // FLOWMETR-SEAMETRICS 8" W/PULSE
                        break;
                    case IntakeSizes.a6:
                        PO.InsertPart(schema.groups.Flowmeters.groupId, "353251", 1)  // FLOW METER-SEAMETRICS 6" PULSE
                        break;
                }
                break;
        }
        if (cp.LargeServiceWireKit) {
            PO.InsertPart(schema.groups.ControlPanel_LargeServiceWireAdapterKit.groupId, "350923", 1); // LARGE SERVICE WIRE ADAPTER
        }
        if (cp.TenFourPowerCord > 0) {
            PO.InsertPart(schema.groups.ControlPanel_104PowerCord.groupId, "351027", cp.TenFourPowerCord); // 10/4 SO CORD-BLACK-PER FT
        }
        if (cp.FourteenTwoPowerCord > 0) {
            PO.InsertPart(schema.groups.ControlPanel_142PowerCord.groupId, "351028", cp.FourteenTwoPowerCord); // 14/2 SO CORD-BLACK-PER FT
        }
        if (cp.RemotePanelOptions.RollsOfUnshieldedResolverWire > 0) {
            PO.InsertPart(schema.groups.ControlPanel_ResolverWire.groupId, "351004", cp.RemotePanelOptions.RollsOfUnshieldedResolverWire); // CABLE-RSLVR SIGNAL-DIRECT BURY
        }
        if (cp.RemotePanelOptions.RollsOfShieldedResolverWire > 0) {
            PO.InsertPart(schema.groups.ControlPanel_ResolverWire.groupId, "351005", cp.RemotePanelOptions.RollsOfShieldedResolverWire); // CABLE-RSLVR ARMOR-DIRECT BURY
        }
        if (cp.WeepHolePlug5Pack) {
            PO.InsertPart(schema.groups.ControlPanel_WeepHolePlugs.groupId, "351992", 1); // PLUG-WEEP HOLE MCP-5 PACK
        }
        if (cp.WeepHolePlug50Pack) {
            PO.InsertPart(schema.groups.ControlPanel_WeepHolePlugs.groupId, "351993", 1); // PLUG-WEEP HOLE TWR BOX-50 PACK
        }
        if (eSystemType === SystemTypes.CenterPivot) {
            switch (cp.EndTowerPositioning) {
                case EndTowerPositioningTypes.None:
                    switch (cp.CollectorRing) {
                        case CollectorRingTypes.None:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "352726", 1); // NO COLLECTOR REEL OPTION
                            break;
                        case CollectorRingTypes.a10Ring:
                            if (cp.PivotAutoReverse) {
                                PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "352844", 1); // COLL REEL-10 RING-A/R RPM PNL
                            }
                            else {
                                PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "352727", 1); // COLLECTOR REEL-10 RING
                            }
                            break;
                        case CollectorRingTypes.a11RingICII:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "353720", 1); // COLLECTOR REEL-11C-ICII
                            break;
                        case CollectorRingTypes.a11RingUnited:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "353442", 1); // ASY-COLLECTOR REEL-11 CIRCUIT
                            break;
                        case CollectorRingTypes.a14Ring:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "352728", 1); // COLLECTOR REEL-14 RING
                            break;
                    }
                    ePigtailType = cp.CollectorRing;
                    break;
                case EndTowerPositioningTypes.Resolver:
                    switch (cp.CollectorRing) {
                        case CollectorRingTypes.a10Ring:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "352729", 1); // COLLECTOR REEL-10 RING-RAMS
                            break;
                        case CollectorRingTypes.a11RingICII:
                        case CollectorRingTypes.a11RingUnited:
                            break;
                        case CollectorRingTypes.a14Ring:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "352730", 1); // COLLECTOR REEL-14 RING-RAMS
                            break;
                    }
                    ePigtailType = CollectorRingTypes.a14Ring;
                    break;
                case EndTowerPositioningTypes.GPS:
                    switch (cp.CollectorRing) {
                        case CollectorRingTypes.None:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "352726", 1); // NO COLLECTOR REEL OPTION
                            break;
                        case CollectorRingTypes.a10Ring:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "352727", 1); // COLLECTOR REEL-10 RING
                            break;
                        case CollectorRingTypes.a11RingICII:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "353720", 1); // COLLECTOR REEL-11C-ICII
                            break;
                        case CollectorRingTypes.a11RingUnited:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "353442", 1); // ASY-COLLECTOR REEL-11 CIRCUIT
                            break;
                        case CollectorRingTypes.a14Ring:
                            PO.InsertPart(schema.groups.ControlPanel_CollectorRing.groupId, "352728", 1); // COLLECTOR REEL-14 RING
                            break;
                    }
                    ePigtailType = cp.CollectorRing;
                    break;
            }
            switch (this.Quote.System.Circle.CenterPivot.PivotCenterHeight) {
                case TowerHeights.Standard:
                    switch (ePigtailType) {
                        case CollectorRingTypes.a10Ring:
                            if (eCableGauge === 12) {
                                PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392566", 1); // WIRE-MCP #12-15 1/2' STD PVT
                            }
                            else {
                                PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392576", 1); // WIRE-MCP-15 1/2' STANDARD PVT
                            }
                            break;
                        case CollectorRingTypes.a11RingICII:
                        case CollectorRingTypes.a11RingUnited:
                            PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392576-X", 1); // WIRE-MCP-11C X15 1/2'-STD PVT
                            break;
                        case CollectorRingTypes.a14Ring:
                            PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392576-14", 1); // WIRE-RAMS-14C X 15.5'-STD PVT
                            break;
                    }
                    break;
                case TowerHeights.LowProfile:
                    switch (ePigtailType) {
                        case CollectorRingTypes.a10Ring:
                            if (eCableGauge === 12) {
                                PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392565", 1); // WIRE-MCP #12-11'-LOW PRO PVT
                            }
                            else {
                                PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392575", 1); // WIRE-MCP-11'-LOW PROFILE PVT
                            }
                            break;
                        case CollectorRingTypes.a11RingICII:
                        case CollectorRingTypes.a11RingUnited:
                            PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392575-X", 1); // WIRE-MCP-11C X11'- LOW PRO PVT
                            break;
                        case CollectorRingTypes.a14Ring:
                            PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392575-14", 1); // WIRE-RAMS-14C X 10'-LP PVT
                            break;
                    }
                    break;
                case TowerHeights.Sugargator:
                    switch (ePigtailType) {
                        case CollectorRingTypes.a10Ring:
                            if (eCableGauge === 12) {
                                PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392567", 1); // WIRE-MCP #12-19FT-SG PVT
                            }
                            else {
                                PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392577", 1); // WIRE-MCP-19FT-SG PIVOT
                            }
                            break;
                        case CollectorRingTypes.a11RingICII:
                        case CollectorRingTypes.a11RingUnited:
                            PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392577-X", 1); // WIRE-MCP-11C X19'-SUGAR PVT
                            break;
                        case CollectorRingTypes.a14Ring:
                            PO.InsertPart(schema.groups.ControlPanel_PigtailWire.groupId, "392577-14", 1); // WIRE-RAMS-14C X 20'-SUGAR PVT
                            break;
                    }
                    break;
            }
        }
        switch (eSystemType) {
            case SystemTypes.CanalFeedMaxigator:
                if (bHighAmpPanel) {
                    PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350930H", 1); // 2WD KIT-45 AMP-RPM LINEAR
                }
                else {
                    PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350930", 1); // 2WD KIT-MCP-RPM LINEAR
                }
                break;
            case SystemTypes.HoseFeedMaxigator:
                switch (eHoseFeedType) {
                    case HoseFeedTypes.Standard:
                    case HoseFeedTypes.DoubleEndFeed:
                        if (bHighAmpPanel) {
                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350930H", 1); // 2WD KIT-45 AMP-RPM LINEAR
                        }
                        else {
                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350930", 1); // 2WD KIT-MCP-RPM LINEAR
                        }
                        break;
                    case HoseFeedTypes.A100:
                        PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350961", 1); // 2WD KIT-MCP-A100 MAXI
                        break;
                    case HoseFeedTypes.CF200:
                        // Do Nothing???
                        break;
                    default:
                        if (bHighAmpPanel) {
                            PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350931H", 1); // 4WD KIT-45 AMP-RPM LINEAR
                        }
                        else {
                            if (!(this.Quote.System.Lateral.HoseFeed.HoseFeedType === HoseFeedTypes.PivotingLateral)) {
                                PO.InsertPart(schema.groups.ControlPanel_MCPMaxiKit.groupId, "350931", 1); // 4WD KIT-MCP-RPM LINEAR
                            }
                        }
                        break;
                }
                break;
        }
        switch (cp.SwitchMount) {
            case SwitchMountTypes.None:
                break;
            case SwitchMountTypes.CamWheel:
                if (this.Quote.System.SystemProperties.SystemType !== SystemTypes.KwikTow) {
                    switch (this.Quote.System.Circle.CenterPivot.CenterPivotType) {
                        case CenterPivotTypes.E2045:
                            PO.InsertPart(schema.groups.ControlPanel_SwitchMount.groupId, "351323", 1); // KIT-CAM WHL & SWTCH HDWR E2045
                            break;
                        default:
                            PO.InsertPart(schema.groups.ControlPanel_SwitchMount.groupId, "351322", 1); // KIT-CAM WHEEL & SW MTG HDWARE
                            break;
                    }
                }
                break;
            case SwitchMountTypes.CamPlate:
                switch (this.Quote.System.Circle.CenterPivot.CenterPivotType) {
                    case CenterPivotTypes.E2100:
                        PO.InsertPart(schema.groups.ControlPanel_SwitchMount.groupId, "353135", 1); // ASY-SW MTG-CAM PLT STYL-10
                        break;
                    case CenterPivotTypes.E2085:
                    case CenterPivotTypes.A80G:
                    case CenterPivotTypes.S2085G:
                        PO.InsertPart(schema.groups.ControlPanel_SwitchMount.groupId, "353134", 1); // ASY-SW MTG-CAM PLT STYL-8 5/8
                        break;
                    case CenterPivotTypes.E2065:
                    case CenterPivotTypes.S2065G:
                        PO.InsertPart(schema.groups.ControlPanel_SwitchMount.groupId, "353133", 1); // ASY-SW MTG-CAM PLT STYL-6 5/8
                        break;
                    case CenterPivotTypes.E2045:
                        PO.InsertPart(schema.groups.ControlPanel_SwitchMount.groupId, "353172", 1); // ASY-SW MTG-CAM PLT TRIPOD
                        break;
                }
                break;
        }
        if (eSystemType === SystemTypes.KwikTow) {
            if (this.Quote.System.Circle.KwikTow.MotorizedCenter) {
                PO.InsertPart(schema.groups.ControlPanel_MotorizedKwikTowMCPOpt.groupId, "350944", 1); // DRIVEN PWR TWR-KT PVT-MCP OPT
            }
        }
        if (bHoseFeedPivot) {
            PO.InsertPart(schema.groups.ControlPanel_LinearPivotSwitch.groupId, "350991", 1); // PIVOT/LATERAL SWITCH-RPM MAXI
        }
        switch (cp.IntlManual) {
            case Languages.English:
                // All English manuals are embedded in the MAPICS BOM and are NOT added by ROE
                // Today, most systems get the English manuals. This will change soon.
                if (sysf.IsMaxigator(s)) {
                    // ROE-817 - New Lateral Move Operator Manuals
                    switch (cp.PanelModel) {
                        case PanelModels.RPMStandard:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355434", 1)  // EN MANUAL LATERAL W/STD
                            break;
                        case PanelModels.RPMAdvancedPlus:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355435", 1)  // EN MANUAL LATERAL W/ADV PLUS
                            break;
                        case PanelModels.RPMPreferred:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355436", 1)  // EN MANUAL LATERAL W/TOUCH
                            break;
                    }
                } else {
                    switch (cp.PanelModel) {
                        case PanelModels.RPMBasic:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355427", 1)  // EN MANUAL OPR-PVT/SAC W/BASIC
                            break;
                        case PanelModels.RPMStandard:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355422", 1)  // EN MANUAL OPR-PVT/SAC W/STD
                            break;
                        case PanelModels.RPMAdvanced:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355430", 1)  // EN MANUAL OPR-PVT/SAC W/ADV
                            break;
                        case PanelModels.RPMAdvancedPlus:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355424", 1)  // EN MANUAL OPR-PVT/SAC W/ADV+
                            break;
                        case PanelModels.RPMConnect:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "351015M", 1)  // MANUAL OPR PVT/SAC W/CONNECT
                            break;
                        case PanelModels.RPMPreferred:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355431", 1)  // EN MANUAL OPR-PVT/SAC W/TOUCH
                            break;
                    }
                }
                break;
            case Languages.Spanish:
                if (sysf.IsMaxigator(s)) {
                    // ROE-817 - New Lateral Move Operator Manuals
                    switch (cp.PanelModel) {
                        case PanelModels.RPMStandard:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355437", 1)  // SP MANUAL LATERAL W/STD
                            break;
                        case PanelModels.RPMAdvancedPlus:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355438", 1)  // SP MANUAL LATERAL W/ADV PLUS
                            break;
                        case PanelModels.RPMPreferred:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355439", 1)  // SP MANUAL LATERAL W/TOUCH
                            break;
                    }
                } else {
                    switch (cp.PanelModel) {
                        case PanelModels.RPMBasic:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355429", 1)  // SP MANUAL OPR-PVT/SAC W/BASIC
                            break;
                        case PanelModels.RPMStandard:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355421", 1)  // SP MANUAL OPR-PVT/SAC W/STD
                            break;
                        case PanelModels.RPMAdvanced:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "350928", 1)  // MANUAL-RPM ADVANCE-SPANISH
                            break;
                        case PanelModels.RPMAdvancedPlus:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355425", 1)  // SP MANUAL OPR-PVT/SAC W/ADV+
                            break;
                        case PanelModels.RPMConnect:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "350927", 1)  // MANUAL-RPM BASIC-SPANISH
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanel.groupId, "351015M", 1)  // MANUAL OPR PVT/SAC W/CONNECT
                            break;
                        case PanelModels.RPMPreferred:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355432", 1)  // SP MANUAL OPR-PVT/SAC W/TOUCH
                            break;
                    }
                }
                break;
            case Languages.Russian:
                if (sysf.IsMaxigator(s)) {
                    // ROE-817 - New Lateral Move Operator Manuals
                    switch (cp.PanelModel) {
                        case PanelModels.RPMStandard:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355440", 1)  // RU MANUAL LATERAL W/STD
                            break;
                        case PanelModels.RPMAdvancedPlus:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355441", 1)  // RU MANUAL LATERAL W/ADV PLUS
                            break;
                        case PanelModels.RPMPreferred:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355442", 1)  // RU MANUAL LATERAL W/TOUCH
                            break;
                    }
                } else {
                    switch (cp.PanelModel) {
                        case PanelModels.RPMBasic:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355428", 1)  // RU MANUAL OPR-PVT/SAC W/BASIC
                            break;
                        case PanelModels.RPMStandard:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355423", 1)  // RU MANUAL OPR-PVT/SAC W/STD
                            break;
                        case PanelModels.RPMAdvanced:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "350998", 1)  // RPM ADVANCED MANUAL-RUSSIAN
                            break;
                        case PanelModels.RPMAdvancedPlus:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355426", 1)  // RU MANUAL OPR-PVT/SAC W/ADV+
                            break;
                        case PanelModels.RPMConnect:
                            // NOTE: Connect panel is not available outside US/Canada
                            // PO.InsertRecord(POSections.ControlPanel, Pthis.OMainControlPanel, 19, 1) // 351015M	MANUAL OPR PVT/SAC W/CONNECT
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "350999", 1)  // RPM BASIC MANUAL-RUSSIAN
                            break;
                        case PanelModels.RPMPreferred:
                            PO.InsertPart(schema.groups.ControlPanel_MainControlPanelManual.groupId, "355433", 1)  // RU MANUAL OPR-PVT/SAC W/TOUCH
                            break;
                    }
                }
                break;
        }
    }
    /*public TryToSave = (bUnloadData: boolean = true): number => {
    try {let bDataChanged: boolean;
    if (bUnloadData) {
    dlgSection.UnLoadData();
    }
    let PA: PartsAssembler;
    bDataChanged = sysf.FieldSets(this.Quote.System).DataChanged();
    this.Quote.Warnings.Clear();
    if (!(this.DataOK(PA))) {
    this.Quote.Warnings.Show(dlgSection, WarningLevels.SectionBlock);
    return cdEditChanges;
    }
    this.ValidateLowPressureSafetyDevices();
    if (this.Quote.Warnings.Show(dlgSection) === MsgBoxResult.Cancel) {
    return cdEditChanges;
    }
    DataChanged = bDataChanged;
    switch (CheckDependencies(PA)) {
    case cdKeepChanges:
    dlgSection.FinalUnload();
    return cdKeepChanges;
    break;
    case cdEditChanges:
    return cdEditChanges;
    break;
    case cdAbandonChanges:
    sysf.FieldSets(this.Quote.System).RollbackEdit();
    return cdAbandonChanges;
    break;
    }
    }
    catch (ex) {
    MessageBox.Show(ex.Message, "Property Out Of Range", MessageBoxButtons.OK, MessageBoxIcon.Information);
    return cdEditChanges;
    }
    }
    *//*public ValidateLowPressureSafetyDevices = () => {
    let cp: ControlPanel = this.Quote.System.ControlPanel;
    if ((cp.ChemAuxControl && (cp.PanelModel === PanelModels.RPMBasic || cp.PanelModel === PanelModels.RPMStandard)) || cp.InjectorPump) {
    let PartNms = [];
    let pa: PartsAssembler = new PartsAssembler();
    let bom: BillOfMaterials = pa.GenerateBOM();
    if (!(bom.PartAssemblyList(0).some((n) => PartNms.indexOf(n.PartNumber) !== -1))) {
    this.Quote.Warnings.Add(slt.sltChemAuxControlOptionMissing, WarningLevels.Decision);
    }
    }
    }
    *//*protected NewForm = (): FSectionForm => {
    return new ControlPanelForm();
    }
    *//*constructor(pQuote: QuoteClass, ID: SectionIDs, DependentOnList: CSection2[]) {
    super(pQuote, ID, DependentOnList);
    }*/
}