import IPartsList from "rdptypes/roe/IPartsList";
import partsCatalog from "../data/partsPackages/2024060101/partsCatalog";
import schema from "../data/partsPackages/2024060101/poSchema";
import ElectricalCalculator from "./ElectricalCalculator";
import { PartLookup } from "./PartLookup";
import QuoteClass from "./QuoteClass";
import VRI_Zones from "./VRIClass.VRI_Zones";

export default class PartsAssembler {
    public GeneratingBOM: boolean = false;
    private m_VRI: VRI_Zones = undefined;
    private m_Quote: QuoteClass = undefined;
    public SuppressDialog = false;

    public PartsList: IPartsList = {
        entries: []
    };

    constructor(Quote: QuoteClass) {
        this.m_Quote = Quote;
    }

    #ec: ElectricalCalculator;

    public get EC(): ElectricalCalculator {
        if (!this.#ec) {
            this.#ec = new ElectricalCalculator(this.m_Quote.System, this);
        }
        return this.#ec;
    }

    public InsertPartByIteration = (groupId: string, iteration: number, qty: number, locationData?: ILocationData) => {
        const group = schema.groups[groupId];
        if (!group) {
            console.log(`Unrecognised group ID ${groupId}`);
            return;
        }

        const section = schema.sections[group.sectionId];

        const partNumber = PartLookup[section.seq][group.seq][iteration];
        if (!(partNumber in partsCatalog)) {
            console.log(`Unrecognised part GroupId=${groupId}, Iteration=${iteration}, PartNumber=${partNumber}`);
            return;
        }

        this.PartsList.entries.push({
            partNumber,
            qty,
            groupId: groupId,
            sectionId: section.seq
        });
    }

    public InsertPart = (groupId: string, partNumber: string, qty: number, locationData?: ILocationData) => {
        if (!qty) {
            // In case qty is copied directly from the system then it might be undefined.
            return;
        }

        if (!(partNumber in partsCatalog)) {
            console.log(`Unrecognised part number ${partNumber}`);
            return;
        }

        const group = schema.groups[groupId];
        if (!group) {
            console.log(`Unrecognised group ID ${groupId}`);
            return;
        }

        this.PartsList.entries.push({
            partNumber,
            qty,
            groupId: groupId,
            sectionId: schema.sections[group.sectionId].seq
        });
    }
    
    public get VRI(): VRI_Zones {
        if (this.m_VRI === undefined) {
            this.m_VRI = new VRI_Zones(this.m_Quote.System);
        }
        return this.m_VRI;
    }
}

// class VRI {
//     ValveBoxCount: number;
//     LastTowerHasValveBox: boolean;
//     ValveBoxes: ValveBox[];
//     QuarterInchTubingLength: number;
//     AirCompressorLocation: number;
// }

// class ValveBox {
//     Tower: number;
//     TowerLocation: number;
// }

export enum LocationCodes {
    NoLocationCode,
    SpansRight,
    SpansLeft,
    TowersRight,
    TowersLeft,
    Ontrac
}

export interface ILocationData {
    locationCode: LocationCodes;
    locationPosition: number;
}