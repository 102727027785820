import { ControlFunctionOptions, IMainlineValveOptionsValvesMainlineValve } from "rdptypes/project/ISystemBase.AutoGenerated";
import QuoteClass from "../QuoteClass";
import { MainlineValvePipeSizeOK, SolenoidOptionOK } from "../Valids.Valids";

export default class MainlineValvesUnitForm {

    constructor(private Quote: QuoteClass){
    }

    public validate = (): void => {
        this.Quote.System.MainlineValveOptions.Valves.MainlineValve.forEach((mv, index) => {
            this.validateMainLineValve(mv, index);
        });
    }

    private validateMainLineValve(mv: IMainlineValveOptionsValvesMainlineValve, index: number){
        if (
            mv.ControlFunctionOption === ControlFunctionOptions.RateOfFlowValve &&
            !MainlineValvePipeSizeOK(mv.PipeSize, {ControlFunctionOption: mv.ControlFunctionOption})){
            this.Quote.Warnings.AddWithTargets([`MainlineValveOptions.Valves.MainlineValve[${index}.PipeSize]`],`Mainline Valve #${index} Pipe Size not OK`);
        }
        if (!SolenoidOptionOK(mv.OnOffMode, {ValveSize: mv.Size})){
            this.Quote.Warnings.AddWithTargets([`MainlineValveOptions.Valves.MainlineValve[${index}].OnOffMode`],`Mainline Valve #${index} Solenoid Option not OK`);
        }
    }
}